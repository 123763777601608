import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { Query } from "@apollo/react-components";
import gql from "graphql-tag";
import NotFoundPage from "./404";
import Loading from "../components/Loading";
import RichTextField from "./rich-text-field/RichTextField";

const QUERY = gql`
  query PageByCode($code: String!) {
    Page: PageByCode(code: $code) {
      title
      text
    }
  }
`;

const styles = () => ({
  root: {
    maxWidth: 1024,
    margin: "auto",
    padding: 32,
  },
});

const Page = ({ classes, match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Query query={QUERY} variables={{ code: match.params.page }}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />;
        if (error) return null;

        if (!data || !data.Page) {
          return <NotFoundPage />;
        }

        return (
          <div className={classes.root}>
            <RichTextField
              textStyles={{ textAlign: "inherit", fontSize: "inherit" }}
            >
              {data.Page && data.Page.text}
            </RichTextField>
          </div>
        );
      }}
    </Query>
  );
};

export default withStyles(styles)(Page);
