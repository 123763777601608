import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import ColumnGrid from "../../components/ColumnGrid";
import PostCard from "../../components/Cards/PostCard";
import LinkIcon from "../../resources/img/external-link-symbol-black.svg";
import { getShortText, getImage } from "../../helpers";
import { NavLink } from "react-router-dom";
import {
  SectionTitle,
  SectionSubtitleTitle
} from "../../components/Typography";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { useQuery } from "@apollo/react-hooks";
import { FormattedMessage, injectIntl } from "react-intl";
import gql from "graphql-tag";
import generalMessages from "../../i18n/messages";

const POSTS_QUERY = gql`
  query Posts($categories: [String], $offset: Int, $limit: Int) {
    Posts: Posts(categories: $categories, offset: $offset, limit: $limit) {
      id
      code
      title
      category_ids
      Categories {
        id
        title
        color
      }
      Thumbnail {
        storage_name
      }
      published_date
    }
  }
`;

const styles = theme => ({
  root: {
    padding: "16px 0",
    maxWidth: 1524,
    margin: "auto",
    textAlign: "center"
  },
  linkContainer: {
    textTransform: "uppercase",
    padding: "32px 32px 0 32px",
    textAlign: "center",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    fontFamily: "lato",
    "& > div": {
      height: "30px",
      margin: "0 0 0 10px"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    }
  },
  linkText: {
    fontWeight: "bold",
    fontSize: 16,
    fontFamily: "lato",
    [theme.breakpoints.down("xs")]: {
      padding: "8px 4px 0 8px",
      fontSize: 12
    }
  },
  linkIcon: {
    [theme.breakpoints.down("xs")]: {
      height: 25,
      width: 25,
      position: "relative",
      top: 3
    }
  },
  container: {
    paddingTop: 8,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 8
    }
  },
  link: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: "-20px"
    }
  }
});

const BlogSection = ({ classes, history, intl: { formatMessage } }) => {
  const { data } = useQuery(POSTS_QUERY, {
    variables: { categories: undefined, limit: 3, offset: 0 }
  });
  const posts = (data && data.Posts) || [];

  return (
    <div className={classes.root}>
      <SectionTitle>
        <FormattedMessage
          id="landing.news.title"
          defaultMessage="NAJNOVŠIE AKTUALITY Z KINDERLANDU"
        />
      </SectionTitle>
      <SectionSubtitleTitle>
        <FormattedMessage
          id="landing.allinone.title"
          defaultMessage="Všetko dôležité na jednom mieste."
        />
      </SectionSubtitleTitle>
      <div className={classes.container}>
        <ColumnGrid
          cards={posts.map((post, idx) => (
            <PostCard
              handleOnClick={() =>
                history.push(
                  `/${formatMessage(generalMessages.linkBlog)}/${post.code}`
                )
              }
              key={idx}
              cardId={post.id}
              bgImage={post.Thumbnail && getImage(post.Thumbnail.storage_name)}
              date={post.published_date}
              actionTitle={
                <FormattedMessage
                  id="landing.textbutton.title"
                  defaultMessage="Prečítať celý článok"
                />
              }
              title={getShortText(post.title)}
            />
          ))}
          showNextSectionButton={false}
          nextSectionLink=""
          nextSection={null}
        />

        <div className={classes.linkContainer}>
          <NavLink
            className={classes.link}
            to={`/${formatMessage(generalMessages.linkBlog)}`}
          >
            <Typography className={classes.linkText}>
              <FormattedMessage
                id="landing.newssectionbutton.title"
                defaultMessage="prejsť na sekciu aktuality"
              />
            </Typography>
            <div style={{ marginLeft: 10 }}>
              <img
                className={classes.linkIcon}
                src={LinkIcon}
                alt="Link to blog"
              />
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, withRouter, withStyles(styles))(BlogSection);
