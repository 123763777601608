import React from "react";
import { withStyles } from "@material-ui/core";
import HeadingCard from "../../components/DetailView/HeadingCard";
import EventReservation from "../EventReservation/EventReservation";
import RichTextField from "../../pages/rich-text-field/RichTextField";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    paddingBottom: 40,
  },
  textContainer: {
    marginTop: 32,
    padding: 0,
    borderRadius: 16,
    maxWidth: 920,
    [theme.breakpoints.down("lg")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    textAlign: "justify",
    margin: "auto",
  },
  poster: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 16,
    width: "100%",
    height: "auto",
    maxWidth: 920,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      maxWidth: 700,
    },
  },
});

const EventView = ({
  classes,
  title = "",
  refreshHeight = () => {},
  handleClose = () => {},
  poster = null,
  cover = null,
  date = null,
  elements,
  id,
}) => (
  <div className={classes.root}>
    <HeadingCard
      handleClose={handleClose}
      title={title}
      bgImage={cover}
      date={date}
    />

    <RichTextField classes={{ root: classes.textContainer }}>
      {elements}
    </RichTextField>

    {id == 41 && <EventReservation />}

    {poster && (
      <img
        className={classes.poster}
        onLoad={refreshHeight}
        src={poster}
        alt="Poster"
      />
    )}
  </div>
);

export default withStyles(styles)(EventView);
