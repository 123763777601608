import React from "react";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    background: "none",
    borderWidth: 0,
    margin: "auto",
    textAlign: "center",
    fontFamily: "lato",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    cursor: "pointer",
    display: "inline-block",
    borderRadius: 8,
    padding: "16px 16px 16px 32px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      padding: "8px 12px 8px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      padding: "4px 8px 4px 16px",
    },
  },
  variantRaisedRoot: {
    background: "none",
    borderWidth: 0,
    margin: "auto",
    fontFamily: "lato",
    color: "#fff",
    textTransform: "uppercase",
    fontSize: "14px",
    borderRadius: 8,
    cursor: "pointer",
    boxShadow: "0 16px 24px 0 rgba(0, 0, 0, 0.4)",
    padding: "5px 0px 5px 10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      padding: "5px 10px 5px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      boxShadow: "none",
      backgroundColor: "inherit !important",
      width: "auto !important",
    },
  },
  text: {
    fontFamily: "lato",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      lineHeight: 2,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      display: "none",
    },
  },
  textDisplay: {
    fontFamily: "lato",
    fontWeight: 500,
    verticalAlign: "middle",
  },
  textRaised: {
    fontFamily: "lato",
    fontWeight: 500,
    fontSize: "14px",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  icon: {
    marginLeft: 10,
    height: 30,
    width: 30,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 3,
      height: 23,
      width: 25,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 2,
      height: 23,
      width: 25,
    },
    "& path": {
      fill: "#ff0000 !important",
    },
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textContainerResponsive: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
});

const SimpleLink = ({
  classes,
  additionalClassses = "",
  children,
  icon,
  svg,
  variant,
  color,
  fontColor,
  fontFamily,
  responsive = false,
  alwaysDisplayText,
  onActionClick,
}) => {
  const rootStyles = {};
  if (color) {
    rootStyles.backgroundColor = color;
    rootStyles.backgroundImage =
      "linear-gradient(98deg, rgba(0,0,0,0.2), rgba(0,0,0,0.0))";
    rootStyles.boxShadow = "0 16px 24px 0 rgba(0, 0, 0, 0.16)";
  }

  if (fontColor) {
    rootStyles.color = fontColor;
  }

  return (
    <button
      onClick={onActionClick}
      style={rootStyles}
      className={`${
        variant === "raised" ? classes.variantRaisedRoot : classes.root
      } ${additionalClassses}`}
    >
      <div
        className={
          responsive ? classes.textContainerResponsive : classes.textContainer
        }
      >
        <div
          style={{ fontFamily }}
          className={
            color
              ? classes.textRaised
              : alwaysDisplayText
              ? classes.textDisplay
              : classes.text
          }
        >
          {children}
        </div>
        {icon && svg === undefined && (
          <img className={classes.icon} src={icon} alt="link" />
        )}
        <div style={{ padding: "0 0px", lineHeight: 1 }}>{svg}</div>
      </div>
    </button>
  );
};

export default withStyles(styles)(SimpleLink);
