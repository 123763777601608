import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Hands from "../../resources/img/hand-3.png";

const styles = (theme) => ({
  root: {
    maxWidth: 1240,
    minHeight: 500,
    width: "100%",
    margin: "auto",
    padding: 16,
    paddingBottom: 32,
    backgroundImage: `url(${Hands})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom -200px",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center bottom -150px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center bottom -120px",
    },
  },
});

const Content = ({ children, classes }) => {
  return <div className={classes.root}>{children}</div>;
};

export default withStyles(styles)(Content);
