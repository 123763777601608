import React from "react";
import { withStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import HoursImg from "../../resources/img/icon-hours.svg";

const styles = (theme) => ({
  root: {
    padding: "0 0",
    fontSize: 14,
    fontFamily: "TypoComica",
    display: "flex",
  },
  navbarTitleImg: {
    paddingTop: 11,
  },
  navbarTitleText: {
    fontFamily: "TypoComica",
    padding: 13,
    fontSize: 14,
    color: "#555555",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
});

const OpeningHours = ({ classes }) => (
  <span className={classes.root}>
    <div className={classes.navbarTitleImg}>
      <img src={HoursImg} />
    </div>
    <div className={classes.navbarTitleText}>
      <FormattedMessage
        id="navigation.openinghours.title"
        defaultMessage="Otvorené každý deň od 09:00 do 21:00 "
      />
    </div>
  </span>
);

export default withStyles(styles)(OpeningHours);
