import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CinemaCard from "./CinemaCard";
import { getImage } from "../../helpers";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    boxShadow: "rgba(35, 31, 32, 0.2) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
  },
  cardActive: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    border: "1px solid rgb(207, 44, 145)",
    boxShadow: "rgba(207, 44, 145, 0.4) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
  },
  header: {
    fontSize: "1.2em",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
});

const StepCinemaType = ({
  classes,
  handleChange = () => {},
  rooms = [],
  defaultType,
}) => (
  <div className={classes.root}>
    {rooms.map((x) => (
      <CinemaCard
        handleChange={handleChange}
        bgImage={x.Image && getImage(x.Image.storage_name)}
        value={x.code}
        active={defaultType === x.code}
        title={x.title}
      />
    ))}
  </div>
);

export default withStyles(styles)(StepCinemaType);
