import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core";
import Message from "./Message";
import Loading from "../../components/Loading";

const styles = () => ({});

const Response = ({ error, loading }) => (
  <Fragment>
    {loading && <Loading />}
    {error &&
      error.graphQLErrors.map((x, i) => (
        <Message type="error" key={i} message={x.message} />
      ))}
  </Fragment>
);

export default withStyles(styles)(Response);
