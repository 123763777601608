import React, { useEffect } from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import handsIcon from "../../resources/img/hand-3.png";
import { Fade } from "react-reveal";
import { CustomHeading, BonusCardTitle } from "../../components/Typography";
import BonusCardForm from "./BonusCardForm";
import VisaImg from "../../resources/img/visa@2x.png";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import generalMessages from "../../i18n/messages";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import PricingItem from "./PricingItem";

const QUERY = gql`
  query getWebPricing($web: String!) {
    pricing: getWebPricing {
      id
      title
      priority
      Image {
        storage_name
      }
      Icon {
        storage_name
      }
      fullsize
      link_title
      link_href
      color
      placement
      Items {
        item
        price
        priority
      }
    }
    settings: settings(web: $web) {
      id
      key
      type
      value
      title
    }
  }
`;

const messages = defineMessages({
  title: {
    id: "pricing.title",
    defaultMessage: "Kinderland - Cenník",
  },
  description: {
    id: "pricing.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${handsIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "90%",
    backgroundPosition: "center 5%",
    [theme.breakpoints.down("md")]: {
      padding: "32px 32px 0 32px",
    },
  },
  heading: {
    marginBottom: 5,
  },
  pageContent: {
    maxWidth: "1224px",
    margin: "auto",
    paddingTop: 64,
    backgroundImage: `url(${handsIcon})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom -200px",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center bottom -150px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center bottom -120px",
    },
  },
  section: {
    paddingBottom: 64,
  },
  container: {
    textAlign: "center",
  },
  priceCategory: {
    fontSize: 24,
    color: "#555555",
    padding: "8px 0",
    margin: "24px 0",
    fontFamily: "PaytoneOne",
    fontWeight: "bold",
    borderBottom: "1px dashed #b5b5b5",
  },
});

const PricingListPage = ({
  classes,
  intl: { formatMessage, locale = "sk" },
}) => {
  const { data = { pricing: [], settings: [] }, error } = useQuery(QUERY, {
    variables: { web: `web-${locale}` },
  });

  if (error) return null;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getSetting = (key) => {
    const s = data.settings.find((s) => s.key === key);
    return s ? s.value : null;
  };

  const basePricing = data.pricing.filter((x) => x.placement === "BASE");
  const additionalPricing = data.pricing.filter(
    (x) => x.placement === "ADDITIONAL"
  );
  const specialPricing = data.pricing.filter((x) => x.placement === "SPECIAL");

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{formatMessage(messages.title)}</title>
        <meta
          name="description"
          content={formatMessage(messages.description)}
        />
      </Helmet>
      <Fade>
        <div className={classes.pageContent}>
          <CustomHeading additionalStyles={classes.heading}>
            <FormattedMessage
              id="pricing.entrance.title"
              defaultMessage="CENNÍK VSTUPOV &amp; ATRAKCIÍ"
            />
          </CustomHeading>

          <Typography
            align="center"
            style={{ marginBottom: 32, color: "#555" }}
          >
            {getSetting("pricing.date")}
          </Typography>

          <section className={classes.section}>
            <Typography className={classes.priceCategory} align="center">
              <FormattedMessage
                id="pricing.zone.base"
                defaultMessage="ZÁKLADNÁ ZÓNA"
              />
            </Typography>
            <Grid container justify="center" spacing={16}>
              {basePricing.map((pricing, idx) => {
                return <PricingItem key={idx} pricing={pricing} />;
              })}
            </Grid>
          </section>
          <section className={classes.section}>
            <Typography className={classes.priceCategory} align="center">
              <FormattedMessage
                id="pricing.extra.title"
                defaultMessage="PRÍPLATKOVÉ ZÓNY"
              />
            </Typography>

            <Grid container justify="center" spacing={16}>
              {additionalPricing.map((pricing, idx) => {
                return <PricingItem key={idx} pricing={pricing} />;
              })}
            </Grid>
          </section>

          <section className={classes.section}>
            <Typography className={classes.priceCategory} align="center">
              <FormattedMessage
                id="pricing.special.title"
                defaultMessage="ŠPECIALITY V KINDERLANDE"
              />
            </Typography>
            <Grid container justify="center" spacing={16}>
              {specialPricing.map((pricing, idx) => {
                return <PricingItem key={idx} pricing={pricing} />;
              })}
            </Grid>
          </section>

          <section
            id={formatMessage(generalMessages.linkBonusCard)}
            className={classes.section}
          >
            <BonusCardTitle>
              <FormattedMessage
                id="pricing.bonuscard.title.part1"
                defaultMessage="ZÍSKAJ KOPU VÝHOD S NAŠOU"
              />
              &nbsp;
              <span style={{ color: "#cf2c91" }}>
                <FormattedMessage
                  id="pricing.bonuscard.title.part2"
                  defaultMessage=" BONUSOVOU KARTOU"
                />
              </span>
            </BonusCardTitle>
            <div style={{ marginTop: 48, textAlign: "center" }}>
              <BonusCardForm />
              <img
                style={{
                  margin: "auto",
                  marginTop: 32,
                  width: "90%",
                  maxWidth: 600,
                }}
                src={VisaImg}
                alt="bonus card"
              />
            </div>
          </section>
        </div>
      </Fade>
    </div>
  );
};

export default injectIntl(withStyles(styles)(PricingListPage));
