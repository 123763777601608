import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Calendar from "./TrackCalendar";
import ReservationTimes from "./ReservationTimes";
import dateformat from "dateformat";
import Message from "./Message";
import { injectIntl } from "react-intl";
import messages from "../../i18n/messages";

const styles = (theme) => ({
  root: {
    padding: "0 0 16px 0",
  },
  errorMessage: {
    color: "red",
    padding: "16px 0 0 0",
  },
  message: {
    padding: 32,
    paddingTop: 50,
    textAlign: "center",
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
});

const StepDate = ({
  classes,
  errors = {},
  reservationTimes = [],
  handleDayClick = () => {},
  dateBlackList = [],
  showTrackTitle = true,
  intl: { formatMessage },
  handleChangeTime = () => {},
  reservationDate = null,
  reservationSystemId = null,
}) => {
  if (!reservationSystemId) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} spacing={32} container>
        <Grid item className={classes.gridItem} sm={12} md={4}>
          <Calendar
            dateBlackList={dateBlackList || []}
            reservationDate={reservationDate}
            handleDayClick={handleDayClick}
          />
        </Grid>
        {(dateBlackList || []).find(
          (x) => x.date === dateformat(reservationDate, "yyyy-mm-dd")
        ) ? (
          <Message
            classes={{ info: classes.message }}
            type="info"
            message={formatMessage(messages.disabledDate)}
          />
        ) : (
          <ReservationTimes
            showTrackTitle={showTrackTitle}
            reservationSystemId={reservationSystemId}
            reservationDate={reservationDate}
            reservationTimes={reservationTimes}
            handleChangeTime={handleChangeTime}
          />
        )}
      </Grid>
      <div className={classes.errorMessage}>{errors.reservationTimes}</div>
    </div>
  );
};

export default injectIntl(withStyles(styles)(StepDate));
