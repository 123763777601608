import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core";
import EshopCard from "../../components/Cards/EshopCard";
import SectionCarousel from "../../components/FullscreenCarousel";
import BonusCardSection from "../../components/BonusCardSection";
import { compose } from "recompose";
import {
  SectionTitle,
  SectionSubtitleTitle,
} from "../../components/Typography";
import SimpleReservationIcon from "../../resources/img/simple-reservation-icon.svg";
import { withRouter } from "react-router";
import { injectIntl, defineMessages } from "react-intl";
import generalMessages from "../../i18n/messages";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { getImage } from "../../helpers";

const QUERY = gql`
  query SimpleReservations {
    SimpleReservations: SimpleReservations {
      id
      code
      title
      Thumbnail {
        storage_name
      }
    }
    ReservationSystems {
      id
      code
      primary_color
      title
      caption3
      Thumbnail {
        storage_name
      }
      Icon {
        storage_name
      }
    }
  }
`;

const messages = defineMessages({
  button: {
    id: "eshopbowling.reservationbutton.title",
    defaultMessage: "OBJEDNAŤ",
  },
  title: {
    id: "eshop.teambuildingcontent.title",
    defaultMessage: "OBJEDNAJ SI U NÁS",
  },
  subtitle: {
    id: "eshop.shoppingcontent.title",
    defaultMessage:
      "Prostredníctvom formulárov nižšie je nakupovanie oveľa jednoduchšie.",
  },
  camp: { id: "slug.camp", defaultMessage: "tabor" },
  dart: { id: "slug.sipky", defaultMessage: "sipky" },
  karaoke: { id: "slug.karaoke", defaultMessage: "karaoke" },
  bowling: { id: "slug.bowling", defaultMessage: "bowling" },
  party: { id: "slug.party", defaultMessage: "party" },
  shop: { id: "slug.shop", defaultMessage: "shop" },
  cinema: { id: "slug.cinema", defaultMessage: "cinema" },
  ebike: { id: "slug.ebike", defaultMessage: "ebike" },
  car: { id: "slug.car", defaultMessage: "auto" },
});

const styles = () => ({
  root: {},
});

const EshopSection = ({ classes, history, intl: { formatMessage } }) => {
  const { data } = useQuery(QUERY);

  if (!data) return null;

  const SimpleReservations = (data && data.SimpleReservations) || [];
  const ReservationSystems = (data && data.ReservationSystems) || [];

  const SimpleReservationsCards = SimpleReservations.map((sr, key) => (
    <EshopCard
      key={sr.id}
      handleOnClick={() =>
        history.push(
          `/${formatMessage(generalMessages.linkReservation)}/${sr.code}`
        )
      }
      cardId={key}
      bgImage={sr.Thumbnail && getImage(sr.Thumbnail.storage_name)}
      icon={SimpleReservationIcon}
      primaryColor="#34B045"
      buttonBgImage="linear-gradient(#D454A3, #D454A3, #D454A3)"
      actionTitle={formatMessage(messages.button)}
      title={sr.title}
    />
  ));

  const handleOnCardClick = (rs) => {
    if (!rs.code || !messages[rs.code.toLowerCase()]) return;

    if (rs.code.toLowerCase() === "shop") {
      history.push("/eshop");
      return;
    }
    history.push(
      `/${formatMessage(generalMessages.linkReservation)}/${formatMessage(
        messages[rs.code.toLowerCase()]
      )}`
    );
  };

  const count = [...SimpleReservationsCards, ...ReservationSystems].length;

  // fix carousel alignment according to number of cards
  const align = count === 4 || count === 5 ? "left" : "center";
  const around = count === 3 || count === 4 || count === 5 ? false : true;

  return (
    <div
      id={formatMessage(generalMessages.linkReservation)}
      className={classes.root}
    >
      <SectionTitle>{formatMessage(messages.title)}</SectionTitle>
      <SectionSubtitleTitle>
        {formatMessage(messages.subtitle)}
      </SectionSubtitleTitle>
      <SectionCarousel
        cellAlign={align}
        slideIndex={count === 3 ? 1 : 0}
        wrapAround={around}
        cards={[
          ...ReservationSystems.map((rs, idx) => (
            <EshopCard
              handleOnClick={() => handleOnCardClick(rs)}
              cardId={idx}
              bgImage={rs.Thumbnail && getImage(rs.Thumbnail.storage_name)}
              key={idx}
              icon={rs.Icon && getImage(rs.Icon.storage_name)}
              primaryColor={rs.primary_color}
              buttonBgImage={`linear-gradient(336deg, ${rs.primary_color}, ${rs.primary_color})`}
              itemList={rs.caption3 ? [rs.caption3] : []}
              actionTitle={formatMessage(messages.button)}
              title={rs.title}
            />
          )),
          ...SimpleReservationsCards,
        ].filter((x) => x)}
        nextSection={<BonusCardSection />}
      />
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles)
)(EshopSection);
