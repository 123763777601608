import React from "react";
import { withStyles, Typography } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    fontFamily: "lato",
    color: "#fff",
  },
  title: {
    fontFamily: "PaytoneOne",
    color: "#fff",
    fontSize: 8,
  },
  description: {
    fontFamily: "PaytoneOne",
    color: "#fff",
    fontSize: 6,
  },
  icon: {
    height: 30,
    width: 30,
  },
  rootInactive: {
    opacity: 0.4,
  },
});

const ZoneIcon = ({
  classes,
  active = false,
  icon = null,
  title = "",
  description = "",
}) => (
  <div className={active ? classes.root : classes.rootInactive}>
    {icon && <img className={classes.icon} src={icon} alt="Zone icon" />}
    <Typography align="center" className={classes.title}>
      {title}
    </Typography>
    <Typography align="center" className={classes.description}>
      {description}
    </Typography>
  </div>
);

export default withStyles(styles)(ZoneIcon);
