import React, { Component } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { SectionTitle } from "../../components/Typography";
import HeadingCard from "../../components/DetailView/HeadingCard";
import {
  Title,
  Subtitle,
} from "../../components/Reservation/ReservationHeadings";
import StepDate from "./StepDate";
import StepPersons from "./StepPersons";
import StepTheme from "./StepTheme";
import StepAdditionalItems from "./StepAdditionalItems";
import StepPackage from "./StepPackage";
import StepSummary from "../../components/Reservation/ReservationSummary";
import StepContact from "../../components/Reservation/ReservationContact";
import { QUERY_RESERVATION_CONFIG } from "./graphql";
import { Query } from "@apollo/react-components";
import { rectangleEffect, getImage } from "../../helpers";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import generalMessages from "../../i18n/messages";
import Validator from "./Validator";
import dateformat from "dateformat";
import Section from "../../components/General/Section";
import SectionArrow from "../../components/Reservation/SectionArrow";
import { compose } from "recompose";
import BaseInfo from "../../components/Reservation/BaseInfo";
import Content from "../../components/General/Content";
import StepRoom from "./StepRoom";
import RichTextField from "../../pages/rich-text-field/RichTextField";
const ReactGA = process.browser ? require("react-ga") : {};

const styles = () => ({
  summary: {
    paddingTop: 32,
  },
  textContainer: {
    fontSize: 18,
  },
});

class PartyReservation extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState = () => {
    let d = new Date();
    d.setDate(d.getDate() + 1);

    const initialState = {
      data: {
        reservationDate: dateformat(d, "yyyy-mm-dd"),
        reservationTimes: [],
        theme: "BOY",
        room: "",
        firstname: "",
        surname: "",
        email: "",
        address: "",
        city: "",
        zip: "",
        gdpr: false,
        telephone: "",
        childrenCount: 1,
        adultCount: 0,
        celebratings: [
          {
            celebratingName: "",
            celebratingAge: "",
          },
        ],
        packageId: 0,
        extraZone: [],
      },
      errors: {
        firstname: "",
        surname: "",
        email: "",
        telephone: "",
        celebrating: "",
        address: "",
        city: "",
        zip: "",
        reservationTimes: "",
        gdpr: "",
      },
      message: {
        type: "INFO",
        text: "",
      },
      loading: false,
    };

    return initialState;
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleChangeCelebratings = (attr, value) => {
    this.setState({
      data: {
        ...this.state.data,
        celebratings: [
          {
            ...this.state.data.celebratings[0],
            [attr]: value,
          },
        ],
      },
    });
  };

  handleChangeData = (attr, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [attr]: value,
      },
    });
  };

  handleError = (attr, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [attr]: value,
      },
    });
  };

  setErrors = (errors) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    });
  };

  loading = (loading) => this.setState({ loading });

  handleSubmit = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    this.setMessage(formatMessage(messages.success), "success");
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  setMessage = (text, type) => {
    this.setState({
      message: {
        text,
        type,
      },
    });
  };

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const selectedDay = day;

    this.setState({
      data: {
        ...this.state.data,
        reservationDate: selectedDay,
        reservationTimes: [],
      },
    });
  };

  handleChangeTime = (checked, timeFrom, timeTo) => {
    this.handleChangeData("reservationTimes", [{ timeFrom, timeTo }]);
  };

  handleAdditionalItems = (additionalItemId, count) => {
    const ez = this.state.data.extraZone.find((x) => x.id === additionalItemId);

    let newExtraZone = [];

    if (ez) {
      newExtraZone = this.state.data.extraZone.map((x) => ({
        ...x,
        count: x.id === additionalItemId ? count : x.count,
      }));
    } else {
      newExtraZone = [
        ...this.state.data.extraZone,
        {
          id: additionalItemId,
          count,
        },
      ];
    }

    this.setState({
      data: {
        ...this.state.data,
        extraZone: newExtraZone.filter((x) => x.count !== 0),
      },
    });
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <Query query={QUERY_RESERVATION_CONFIG} variables={{ code: "PARTY" }}>
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error) return null;

          const { Config } = data;

          if (Config.enabled === false) {
            return "Rezervačný systém nie je momentálne dostupný. Skúste prosím neskôr.";
          }

          if (this.state.data.packageId === 0 && Config.Packages.length) {
            this.handleChangeData("packageId", Config.Packages[0].id);
          }

          const getSection = (number) => {
            return (
              (Config.sections &&
                Config.sections.find((section) => section.number == number)) ||
              {}
            );
          };

          return (
            <Content>
              <Helmet>
                <title>{formatMessage(messages.metaTitle)}</title>
                <meta
                  name="description"
                  content={formatMessage(messages.metaDescription)}
                />
              </Helmet>
              <HeadingCard
                closeLink={`/#${formatMessage(
                  generalMessages.linkReservation
                )}`}
                handleClose={() => {
                  rectangleEffect(false);
                }}
                height="350px"
                bgImage={
                  Config.CoverImage && getImage(Config.CoverImage.storage_name)
                }
                icon={Config.Icon && getImage(Config.Icon.storage_name)}
                title={Config.title}
                captions={[Config.caption1, Config.caption2]}
              />

              {Config.price_text && Config.bonus_card_text && (
                <Section>
                  <BaseInfo
                    image={
                      Config.CoverSecondaryImage &&
                      getImage(Config.CoverSecondaryImage.storage_name)
                    }
                    color={Config.primary_color}
                    priceText={Config.price_text}
                    bonusCardText={Config.bonus_card_text}
                  />
                </Section>
              )}

              <Section marginTop={30}>
                <SectionTitle>{Config.subtitle1}</SectionTitle>
                <Typography align="center" className={classes.titleCaption}>
                  {Config.subtitle2}
                </Typography>
              </Section>

              {Config.text && (
                <Section marginTop={50} textAlign="left" maxWidth={800}>
                  <RichTextField classes={{ root: classes.textContainer }}>
                    {Config.text}
                  </RichTextField>
                </Section>
              )}

              <SectionArrow variant="right" />

              <Section>
                <Grid container spacing={16}>
                  <Grid item md={12} lg={8} xl={6}>
                    <Title number={"1."}>{getSection(1).title}</Title>
                    <Subtitle>{getSection(1).subtitle}</Subtitle>
                    <StepDate
                      errors={this.state.errors}
                      dateBlackList={Config.date_blacklist}
                      reservationDate={this.state.data.reservationDate}
                      reservationTimes={this.state.data.reservationTimes}
                      handleDayClick={this.handleDayClick}
                      handleChangeTime={this.handleChangeTime}
                    />
                  </Grid>
                  <Grid item md={12} lg={4} xl={6}>
                    <Title number={"2."}>{getSection(2).title}</Title>
                    <Subtitle>{getSection(2).subtitle}</Subtitle>
                    <div style={{ maxWidth: 400, margin: "auto" }}>
                      <StepTheme
                        value={this.state.data.theme}
                        handleChange={this.handleChangeData}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Section>

              <Section>
                <Title number={"3."}>{getSection(3).title}</Title>
                <Subtitle>{getSection(3).subtitle}</Subtitle>
                <div style={{ maxWidth: 400, margin: "auto" }}>
                  <StepRoom
                    errors={this.state.errors}
                    value={this.state.data.room}
                    handleChange={this.handleChangeData}
                  />
                </div>
              </Section>

              <SectionArrow variant="left" />

              <Section>
                <Title number={"4."}>{getSection(4).title}</Title>
                <Subtitle>{getSection(4).subtitle}</Subtitle>
                <StepPersons
                  errors={this.state.errors}
                  adultCount={this.state.data.adultCount}
                  childrenCount={this.state.data.childrenCount}
                  celebratings={this.state.data.celebratings}
                  handleChange={this.handleChangeData}
                  handleChangeCelebratings={this.handleChangeCelebratings}
                />
              </Section>

              <SectionArrow variant="right" />

              <Section>
                <Title number={"5."}>{getSection(5).title}</Title>
                <Subtitle>{getSection(5).subtitle}</Subtitle>
                <StepPackage
                  selected={this.state.data.packageId}
                  handleChange={this.handleChangeData}
                  packages={Config.Packages}
                />
              </Section>

              <SectionArrow variant="left" />

              <Section>
                <Title number={"6."}>{getSection(6).title}</Title>
                <Subtitle>{getSection(6).subtitle}</Subtitle>
                <StepAdditionalItems
                  selected={this.state.data.extraZone}
                  onChange={this.handleAdditionalItems}
                  extraZone={Config.ExtraZone}
                />
              </Section>

              <SectionArrow variant="right" />

              <Section maxWidth={800}>
                <Title number={"7."}>{getSection(7).title}</Title>
                <Subtitle>{getSection(7).subtitle}</Subtitle>
                <StepContact
                  errors={this.state.errors}
                  gdpr={this.state.data.gdpr}
                  firstname={this.state.data.firstname}
                  surname={this.state.data.surname}
                  zip={this.state.data.zip}
                  address={this.state.data.address}
                  city={this.state.data.city}
                  email={this.state.data.email}
                  telephone={this.state.data.telephone}
                  handleChange={this.handleChangeData}
                />
              </Section>

              <SectionArrow variant="straight" />

              <Section
                classes={{ root: classes.summary }}
                background={"#fff"}
                maxWidth={800}
              >
                <Title color="#cf2c91" number={"8."}>
                  <FormattedMessage
                    id="party.confirmreservation.title"
                    defaultMessage="Skontroluj údaje a potvrď rezerváciu oslavy"
                  />
                </Title>
                <StepSummary
                  validate={Validator}
                  code="party"
                  setMessage={this.setMessage}
                  setErrors={this.setErrors}
                  message={this.state.message}
                  orderType="party"
                  onSuccess={() => {
                    this.resetState();
                    ReactGA.event({
                      category: "party rezervacia",
                      action: "Odoslanie formulara",
                      label: "Potvrdiť rezerváciu",
                    });
                  }}
                  data={this.state.data}
                />
              </Section>
            </Content>
          );
        }}
      </Query>
    );
  };
}

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles)
)(PartyReservation);
