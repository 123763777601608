import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    position: "fixed",
    zIndex: 999999999,
    height: "100%",
    width: "100%",
    backgroundColor: "#444",
    border: 0,
    top: 0,
    bottom: 0
  },
  vrTour: {
    border: 0,
    width: "100%",
    height: "100%"
  }
});

class VirtualTour extends Component {
  componentDidMount = () => {
    document.body.style.overflow = "hidden";
  };

  componentWillUnmount = () => {
    document.body.style.overflow = "auto";
  };

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <iframe
          className={classes.vrTour}
          allowFullScreen
          src="https://tourmkr.com/G1O46kEAzd"
        ></iframe>
      </div>
    );
  };
}

export default withStyles(styles)(VirtualTour);
