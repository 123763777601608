import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core";
import { Zoom } from "react-reveal";
import StepDate from "./StepDate";
import StepContact from "../../components/Reservation/ReservationContact";
import StepSummary from "../../components/Reservation/ReservationSummary";
import HeadingCard from "../../components/DetailView/HeadingCard";
import Validator from "./Validator";
import { MUTATE_CREATE_RESERVATION } from "./graphql";
import { Query } from "@apollo/react-components";
import { graphql } from "@apollo/react-hoc";
import { compose } from "recompose";
import { SectionTitle } from "../../components/Typography";
import {
  Title,
  Subtitle,
} from "../../components/Reservation/ReservationHeadings";
import { Typography } from "@material-ui/core";
import { rectangleEffect, getImage } from "../../helpers";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import generalMessages from "../../i18n/messages";
import gql from "graphql-tag";
import StepCinemaType from "./StepCinemaType";
import StepCategory from "./StepCategory";
import StepMovie from "./StepMovie";
import dateformat from "dateformat";
import Section from "../../components/General/Section";
import SectionArrow from "../../components/Reservation/SectionArrow";
import BaseInfo from "../../components/Reservation/BaseInfo";
import Content from "../../components/General/Content";
import RichTextField from "../../pages/rich-text-field/RichTextField";
const ReactGA = process.browser ? require("react-ga") : {};

export const QUERY_RESERVATION_CONFIG = gql`
  query ReservationSystemConfig($code: String!) {
    Config: ReservationSystemConfig(code: $code) {
      title
      subtitle1
      subtitle2
      sections {
        number
        title
        subtitle
      }
      text
      primary_color
      caption1
      enabled
      date_blacklist {
        date
      }
      caption2
      price_text
      bonus_card_text
      CoverSecondaryImage {
        storage_name
      }
      CoverImage {
        storage_name
      }
      Icon {
        storage_name
      }
      Packages {
        id
        title
        subtitle
        captions
        captions1
        captions2
        price
        priceOld
        primaryColor
        priceExtraAdult
        priceExtraChildren
        units
        Image {
          storage_name
        }
        Zones {
          id
          title
        }
      }
      ExtraZone {
        title
        Items {
          id
          title
          description
          text
          price
          units
          Image {
            storage_name
          }
        }
      }
    }
    Rooms: CinemaRooms(sortField: "ordering", sortOrder: "ASC") {
      id
      title
      code
      Image {
        storage_name
      }
    }
    Categories: CinemaCategories(sortField: "ordering", sortOrder: "ASC") {
      id
      title
      code
      Image {
        storage_name
      }
    }
  }
`;

const styles = () => ({
  summary: {
    paddingTop: 32,
  },
  textContainer: {
    fontSize: 18,
  },
});

class ReservationCinema extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState = (props) => {
    const d = new Date();

    const initialState = {
      data: {
        reservationDate: dateformat(d, "yyyy-mm-dd"),
        type: props.match.params.type ? props.match.params.type : "5d",
        category: props.match.params.category
          ? props.match.params.category
          : null,
        count: 1,
        movie: "",
        firstname: "",
        surname: "",
        email: "",
        gdpr: false,
        hasErrors: false,
        telephone: "",
        address: "",
        city: "",
        zip: "",
      },
      errors: {
        maximumReservations: "",
        firstname: "",
        surname: "",
        email: "",
        telephone: "",
        movie: "",
        gdpr: "",
        address: "",
        city: "",
        zip: "",
      },
      message: {
        type: "INFO",
        text: "",
      },
      loading: false,
    };

    return initialState;
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleChangeData = (attr, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [attr]: value,
      },
    });
  };

  handleError = (attr, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [attr]: value,
      },
    });
  };

  setErrors = (errors) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    });
  };

  loading = (loading) => this.setState({ loading });

  handleSubmit = () => {
    const { data: reservationData } = this.state;
    const {
      createReservation,
      intl: { formatMessage },
    } = this.props;

    this.loading(true);

    const valid = this.validate(reservationData);

    if (!valid) {
      this.loading(false);
      this.setMessage(formatMessage(messages.error), "error");
      return;
    }

    createReservation({
      variables: {
        data: JSON.stringify(reservationData),
      },
    })
      .then((data) => {
        this.resetState();
        this.setMessage(formatMessage(messages.success), "success");
        ReactGA.event({
          category: "bowling rezervacia",
          action: "Odoslanie formulara",
          label: "Potvrdiť rezerváciu",
        });
      })
      .catch((e) => this.loading(false));
  };

  resetState = () => {
    this.setState(this.initialState);
  };

  setMessage = (text, type) => {
    this.setState({
      message: {
        text,
        type,
      },
    });
  };

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const selectedDay = day;

    this.setState({
      data: {
        ...this.state.data,
        reservationDate: selectedDay,
        reservationTimes: [],
      },
    });
  };

  handleMoviePick = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        movies: [e.target.value],
      },
    });
  };

  validate = (reservationData) => {
    const { errors, valid } = Validator({
      data: reservationData,
      formatMessage: this.props.intl.formatMessage,
    });

    this.setErrors(errors);

    return valid;
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage },
      history,
    } = this.props;

    return (
      <Query
        query={QUERY_RESERVATION_CONFIG}
        variables={{
          code: "CINEMA",
          category: this.props.match.params.category,
          type: this.props.match.params.type,
        }}
      >
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error) return null;

          const { Config, Categories, Rooms } = data;
          if (!Config) return "Rezervačný systém nie je ešte nakonfigurovaný.";

          if (Config.enabled === false) {
            return "Rezervačný systém nie je momentálne dostupný. Skúste prosím neskôr.";
          }

          const getSection = (number) => {
            return (
              (Config.sections &&
                Config.sections.find((section) => section.number == number)) ||
              {}
            );
          };

          return (
            <Content>
              <Helmet>
                <title>{formatMessage(messages.metaTitle)}</title>
                <meta
                  name="description"
                  content={formatMessage(messages.metaDescription)}
                />
              </Helmet>
              <HeadingCard
                closeLink={`/#${formatMessage(
                  generalMessages.linkReservation
                )}`}
                handleClose={() => {
                  rectangleEffect(false);
                }}
                height="350px"
                bgImage={
                  Config.CoverImage && getImage(Config.CoverImage.storage_name)
                }
                icon={Config.Icon && getImage(Config.Icon.storage_name)}
                title={Config.title}
                captions={[Config.caption1, Config.caption2]}
              />

              {Config.price_text && Config.bonus_card_text && (
                <Section>
                  <BaseInfo
                    image={
                      Config.CoverSecondaryImage &&
                      getImage(Config.CoverSecondaryImage.storage_name)
                    }
                    color={Config.primary_color}
                    priceText={Config.price_text}
                    bonusCardText={Config.bonus_card_text}
                  />
                </Section>
              )}

              <Section marginTop={30}>
                <SectionTitle>{Config.subtitle1}</SectionTitle>
                <Typography align="center" className={classes.titleCaption}>
                  {Config.subtitle2}
                </Typography>
              </Section>

              {Config.text && (
                <Section marginTop={50} textAlign="left" maxWidth={800}>
                  <RichTextField classes={{ root: classes.textContainer }}>
                    {Config.text}
                  </RichTextField>
                </Section>
              )}

              <SectionArrow variant="right" />

              <Section>
                <Title number={"1."}>{getSection(1).title}</Title>
                <Subtitle>{getSection(1).subtitle}</Subtitle>
                <StepCinemaType
                  handleChange={(type) => {
                    history.push(`/rezervacia/cinema/${type}`);
                    this.setState({
                      data: {
                        ...this.state.data,
                        category: null,
                        movie: "",
                        type,
                      },
                    });
                  }}
                  rooms={Rooms}
                  errors={this.state.data.errors}
                  defaultType={this.state.data.type}
                />
              </Section>

              <SectionArrow variant="left" />

              <Section>
                <Title number={"2."}>{getSection(2).title}</Title>
                <Subtitle>{getSection(2).subtitle}</Subtitle>
                <StepCategory
                  categories={Categories}
                  errors={this.state.data.errors}
                  category={this.state.data.category}
                  handleChange={(category) => {
                    history.push(
                      `/rezervacia/cinema/${this.state.data.type}/${category}`
                    );
                    this.setState({
                      data: {
                        ...this.state.data,
                        movie: "",
                        category,
                      },
                    });
                  }}
                />
              </Section>

              {this.state.data.category && (
                <Fragment>
                  <SectionArrow variant="right" />
                  <Zoom>
                    <Section>
                      <Title number={"3."}>{getSection(3).title}</Title>
                      <Subtitle>{getSection(3).subtitle}</Subtitle>
                      <StepMovie
                        handleChange={(movie) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              movie,
                            },
                          });
                        }}
                        errors={this.state.data.errors}
                        category={this.state.data.category}
                        type={this.state.data.type}
                        movie={this.state.data.movie}
                      />
                    </Section>
                  </Zoom>

                  {this.state.data.movie && (
                    <Fragment>
                      <SectionArrow variant="right" />
                      <Zoom>
                        <Section>
                          <Title number={"4."}>{getSection(4).title}</Title>
                          <Subtitle>{getSection(4).subtitle}</Subtitle>
                          <StepDate
                            type={this.state.data.type}
                            handleCountChange={this.handleChangeData}
                            count={this.state.data.count}
                            dateBlackList={Config.date_blacklist}
                            reservationDate={this.state.data.reservationDate}
                            errors={this.state.data.errors}
                            handleDayClick={this.handleDayClick}
                          />
                        </Section>
                      </Zoom>

                      <SectionArrow variant="left" />

                      <Section maxWidth={800}>
                        <Title number={"5."}>{getSection(5).title}</Title>
                        <Subtitle>{getSection(5).subtitle}</Subtitle>
                        <StepContact
                          firstname={this.state.data.firstname}
                          surname={this.state.data.surname}
                          email={this.state.data.email}
                          telephone={this.state.data.telephone}
                          errors={this.state.errors}
                          gdpr={this.state.data.gdpr}
                          zip={this.state.data.zip}
                          address={this.state.data.address}
                          city={this.state.data.city}
                          handleChange={this.handleChangeData}
                        />
                      </Section>

                      <SectionArrow variant="straight" />

                      <Section
                        classes={{ root: classes.summary }}
                        background={"#fff"}
                        maxWidth={800}
                      >
                        <Title color="#cf2c91" number="6." variant="title">
                          <FormattedMessage
                            id="cinema.confirm.title"
                            defaultMessage="Potvrď rezerváciu"
                          />
                        </Title>
                        <StepSummary
                          validate={Validator}
                          code="cinema"
                          setMessage={this.setMessage}
                          setErrors={this.setErrors}
                          message={this.state.message}
                          orderType="cinema"
                          onSuccess={() => {
                            this.resetState();
                            ReactGA.event({
                              category: "cinema rezervacia",
                              action: "Odoslanie formulara",
                              label: "Potvrdiť rezerváciu",
                            });
                          }}
                          data={this.state.data}
                        />
                      </Section>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Content>
          );
        }}
      </Query>
    );
  };
}

export default compose(
  graphql(MUTATE_CREATE_RESERVATION, { name: "createReservation" }),
  injectIntl,
  withRouter
)(withStyles(styles)(ReservationCinema));
