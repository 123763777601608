import React from "react";
import { withStyles, RadioGroup } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    maxWidth: 400,
    margin: "auto",
  },
  radioControl: {
    backgroundColor: "#fff",
    margin: 5,
    marginTop: 16,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 16,
  },
  black: {
    color: "#231f20 !important",
  },
});

const StepDelivery = ({ handleChange, classes, value = "delivery" }) => (
  <RadioGroup
    aria-label="delivery"
    name="delivery"
    className={classes.root}
    value={value}
    onChange={(e) => handleChange("delivery", e.target.value)}
  >
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="personal"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage
          id="order.delivery.personal"
          defaultMessage="Osobný odber"
        />
      }
    />

    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="parcel"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage
          id="order.delivery.parcel"
          defaultMessage="Doručenie na fakturačnú adresu (3.90 €)"
        />
      }
    />
  </RadioGroup>
);

export default withStyles(styles)(StepDelivery);
