import gql from "graphql-tag";

export const QUERY_BOWLING = gql`
  {
    Bowling @client {
      reservationTimes {
        alleyId
        timeFrom
        timeTo
      }
      adultCount
      youngCount
      reservationDate
      firstname
      surname
      email
      gdpr
      hasErrors
      telephone
      errors {
        firstname
        surname
        email
        telephone
        reservationTimes
        acceptGdpr
      }
    }
    Message {
      type
      text
    }
  }
`;

export const QUERY_BOWLING_RESERVATION_TIMES = gql`
  {
    Bowling @client {
      reservationTimes {
        alleyId
        timeFrom
        timeTo
      }
      reservationDate
      hasErrors
      errors {
        firstname
        surname
        email
        telephone
        reservationTimes
        acceptGdpr
      }
    }
    Message {
      type
      text
    }
  }
`;

export const QUERY_BOWLING_WITH_DATETIMES = gql`
  {
    Bowling @client {
      reservationDate
    }
    Times: getReservationTrackTimes(
      date: $date
      reservationSystemId: $reservationSystemId
    ) {
      ReservationTrackTime {
        alleyId
        title
        times {
          time_from
          time_to
          reserved
        }
      }
    }
  }
`;

export const QUERY_BOWLING_ALLEY_TIMES = gql`
  query getReservationTrackTimes($date: String!, $reservationSystemId: Int!) {
    Alleys: getReservationTrackTimes(
      date: $date
      reservationSystemId: $reservationSystemId
    ) {
      title
      alleyId
      times {
        time_from
        time_to
        reserved
      }
    }
  }
`;

export const QUERY_BOWLING_DATE_RESERVATIONS = gql`
  query getBowlingMonthReservations($month: Int!, $year: Int!) {
    Reservations: getBowlingDateReservations(month: $month, year: $year) {
      reservation_date
      reservedCount
      totalCount
    }
  }
`;

export const QUERY_BOWLING_DATE_ALL_RESERVATIONS = gql`
  query getBowlingAllReservations {
    Reservations: getBowlingAllReservations {
      reservation_date
      reservedCount
      totalCount
    }
  }
`;

export const MUTATE_BOWLING_ADD_RESERVATION_TIME = gql`
  mutation addBowlingReservationTime(
    $alleyId: Int
    $timeFrom: String
    $timeTo: String
  ) {
    addBowlingReservationTime(
      alleyId: $alleyId
      timeFrom: $timeFrom
      timeTo: $timeTo
    ) @client
  }
`;

export const MUTATE_BOWLING_REMOVE_RESERVATION_TIME = gql`
  mutation removeBowlingReservationTime(
    $alleyId: Int
    $timeFrom: String
    $timeTo: String
  ) {
    removeBowlingReservationTime(
      alleyId: $alleyId
      timeFrom: $timeFrom
      timeTo: $timeTo
    ) @client
  }
`;

export const MUTATE_CREATE_RESERVATION = gql`
  mutation createClientReservation($data: String!) {
    createClientReservation(data: $data)
  }
`;

export const QUERY_BOWLING_PRICE = gql`
  query getBowlingPrice($data: String!) {
    Price: getBowlingPrice(data: $data) {
      ReservationTrackPriceSummary {
        alleyId
        alleyTitle
        timeFrom
        hours
        timeTo
        price
      }
      totalPrice
      totalHours
    }
  }
`;
