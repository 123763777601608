import React, { useState, useEffect, useCallback } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { compose } from "recompose";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { injectIntl, defineMessages } from "react-intl";
import Content from "../../components/General/Content";
import Helmet from "react-helmet";
import { rectangleEffect, getImage } from "../../helpers";
import generalMessages from "../../i18n/messages";
import HeadingCard from "../../components/DetailView/HeadingCard";
import BaseInfo from "../../components/Reservation/BaseInfo";
import { SectionTitle } from "../../components/Typography";
import Section from "../../components/General/Section";
import SectionArrow from "../../components/Reservation/SectionArrow";
import {
  Title as StepTitle,
  Title,
  Subtitle,
} from "../../components/Reservation/ReservationHeadings";
import ReservationSummary from "../../components/Reservation/ReservationSummary";
import ReservationContact from "../../components/Reservation/ReservationContact";
import { isEmpty, isEmail } from "validator";
import Message from "../../components/Reservation/Message";
import ReservationTimeRange from "../../components/Reservation/ReservationTimeRange";
import EBikeList from "./EBikeList";
import RichTextField from "../../pages/rich-text-field/RichTextField";
const ReactGA = process.browser ? require("react-ga") : {};

export const QUERY_RESERVATION_CONFIG = gql`
  query ReservationSystemConfig($code: String!) {
    Config: ReservationSystemConfig(code: $code) {
      title
      subtitle1
      subtitle2
      sections {
        title
        subtitle
        number
      }
      text
      primary_color
      caption1
      caption2
      price_text
      enabled
      bonus_card_text
      date_blacklist {
        date
      }
      CoverSecondaryImage {
        storage_name
      }
      CoverImage {
        storage_name
      }
      Icon {
        storage_name
      }
      ExtraZone {
        title
        Items {
          id
          title
          price
          units
          description
          Image {
            storage_name
          }
        }
      }
    }
  }
`;

const messages = defineMessages({
  error: {
    id: "reservation.simple.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje.",
  },
  success: {
    id: "reservation.simple.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás",
  },
  summary: {
    id: "reservation.summary",
    defaultMessage: "Skontroluj údaje a potvrď svoju objednávku",
  },
  metaTitle: {
    id: "reservation.ebike.title",
    defaultMessage: "Rezervácia e-bike",
  },
  metaDescription: {
    id: "reservation.simple.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
  dateError: {
    id: "reservation.ebike.dateError",
    defaultMessage: "Vyberte dátum a čas vrátenia bicykla.",
  },
  dateInvalidError: {
    id: "reservation.ebike.dateInvalidError",
    defaultMessage: "Dátum vyzdvihnutia nemôže byť neskôr ako dátum vrátenia.",
  },
  bikeError: {
    id: "reservation.bike.bikeError",
    defaultMessage: "Je potrebné vybrať aspoň jeden bicykel.",
  },
  empty: {
    id: "reservation.ebike.emptybikes",
    defaultMessage:
      "Aby si videl ponuku dostupných bicyklov, musíš najskôr zvoliť dátum vyzdvihnutia a vrátenia bicykla.",
  },
});

const styles = () => ({
  summary: {
    paddingTop: 32,
  },
  textContainer: {
    fontSize: 18,
  },
});

const ReservationEBike = ({ intl: { formatMessage }, classes }) => {
  const { data: reservation, loading, error } = useQuery(
    QUERY_RESERVATION_CONFIG,
    {
      variables: { code: "EBIKE" },
    }
  );

  const [message, setMessage] = useState({
    type: "INFO",
    text: "",
  });

  const [data, setData] = useState({
    firstname: "",
    surname: "",
    email: "",
    zip: "",
    address: "",
    city: "",
    telephone: "",
    gdpr: false,
    bikes: [],
    timeFrom: undefined,
    timeTo: undefined,
    dateFrom: undefined,
    dateTo: undefined,
  });

  const [errors, setErrors] = useState({
    firstname: "",
    surname: "",
    email: "",
    zip: "",
    address: "",
    city: "",
    telephone: "",
    gdpr: "",
    bikes: "",
    date: "",
  });

  const validator = ({ data }) => {
    const {
      firstname,
      surname,
      telephone,
      gdpr,
      email,
      city,
      zip,
      address,
      bikes,
      timeFrom,
      timeTo,
      dateFrom,
      dateTo,
    } = data;

    const errorMessages = {
      bikes: !bikes.length ? formatMessage(messages.bikeError) : "",
      firstname: isEmpty(firstname)
        ? formatMessage(generalMessages.errorFirstname)
        : "",
      surname: isEmpty(surname)
        ? formatMessage(generalMessages.errorSurname)
        : "",
      telephone: isEmpty(telephone)
        ? formatMessage(generalMessages.telephoneError)
        : "",
      city: isEmpty(city) ? formatMessage(generalMessages.cityError) : "",
      zip: isEmpty(zip) ? formatMessage(generalMessages.zipError) : "",
      address: isEmpty(address)
        ? formatMessage(generalMessages.addressError)
        : "",
      gdpr: !gdpr ? formatMessage(generalMessages.gdprError) : "",
      email: !isEmail(email) ? formatMessage(generalMessages.emailError) : "",
    };

    if (!timeFrom || !timeTo || !dateFrom || !dateTo) {
      errorMessages.date = formatMessage(messages.dateError);
    } else if (
      dateFrom.getTime() > dateTo.getTime() ||
      (dateFrom.getTime() === dateTo.getTime() && timeFrom > timeTo)
    ) {
      errorMessages.date = formatMessage(messages.dateInvalidError);
    }

    let valid = true;
    Object.keys(errorMessages).forEach((x) => {
      if (errorMessages[x] !== "" && x !== "__typename") {
        valid = false;
      }
    });

    return {
      valid,
      errors: errorMessages,
    };
  };

  const resetState = () => {
    setData({
      firstname: "",
      surname: "",
      email: "",
      zip: "",
      address: "",
      city: "",
      telephone: "",
      gdpr: false,
      bikes: [],
      timeFrom: undefined,
      timeTo: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    });

    setErrors({
      firstname: "",
      surname: "",
      email: "",
      zip: "",
      address: "",
      city: "",
      telephone: "",
      gdpr: false,
      bikes: "",
      date: "",
    });
  };

  const handleChangeData = useCallback(
    (attr, value) => {
      setData({
        ...data,
        [attr]: value,
      });
    },
    [data]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return null;
  if (error) return null;

  const { Config } = reservation;
  if (!Config) return "Rezervačný systém nie je ešte nakonfigurovaný.";

  if (Config.enabled === false) {
    return "Rezervačný systém nie je momentálne dostupný. Skúste prosím neskôr.";
  }

  const getSection = (number) => {
    return (
      (Config.sections &&
        Config.sections.find((section) => section.number == number)) ||
      {}
    );
  };

  const handleBikeSelection = (bike, count) => {
    const bikes = data.bikes.filter((b) => b.id !== bike.id);

    if (count > 0) {
      bikes.push({ id: bike.id, title: bike.title, count });
    }

    setData({
      ...data,
      bikes,
    });
  };

  return (
    <Content>
      <Helmet>
        <title>{formatMessage(messages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <HeadingCard
        closeLink={`/#${formatMessage(generalMessages.linkReservation)}`}
        handleClose={() => rectangleEffect(false)}
        icon={Config.Icon && getImage(Config.Icon.storage_name)}
        height="350px"
        bgImage={Config.CoverImage && getImage(Config.CoverImage.storage_name)}
        title={Config.title}
        captions={[Config.caption1, Config.caption2]}
      />
      {Config.price_text && Config.bonus_card_text && (
        <Section>
          <BaseInfo
            image={
              Config.CoverSecondaryImage &&
              getImage(Config.CoverSecondaryImage.storage_name)
            }
            color={Config.primary_color}
            priceText={Config.price_text}
            bonusCardText={Config.bonus_card_text}
          />
        </Section>
      )}

      <Section marginTop={30}>
        <SectionTitle>{Config.subtitle1}</SectionTitle>
        <Typography align="center" className={classes.titleCaption}>
          {Config.subtitle2}
        </Typography>
      </Section>

      {Config.text && (
        <Section marginTop={50} textAlign="left" maxWidth={800}>
          <RichTextField classes={{ root: classes.textContainer }}>
            {Config.text}
          </RichTextField>
        </Section>
      )}

      <SectionArrow variant="left" />

      <Section maxWidth={600}>
        <Title number={"1."}>{getSection(1).title}</Title>
        <Subtitle>{getSection(1).subtitle}</Subtitle>
        <ReservationTimeRange
          blackList={Config.date_blacklist || []}
          dateTo={data.dateTo}
          dateFrom={data.dateFrom}
          timeTo={data.timeTo}
          timeFrom={data.timeFrom}
          onChange={handleChangeData}
        />
        {errors.date && (
          <Message type="error" message={errors.date} disableBorder />
        )}
      </Section>

      <SectionArrow variant="right" />

      <Section>
        <Title number={"2."}>{getSection(2).title}</Title>
        <Subtitle>{getSection(2).subtitle}</Subtitle>
        {!data.timeFrom || !data.timeTo || !data.dateTo || !data.dateFrom ? (
          <Typography>{formatMessage(messages.empty)}</Typography>
        ) : (
          <EBikeList
            bikes={data.bikes}
            onChange={handleBikeSelection}
            dateFrom={data.dateFrom}
            dateTo={data.dateTo}
            timeFrom={data.timeFrom}
            timeTo={data.timeTo}
          />
        )}
        {errors.bikes && (
          <Message type="error" message={errors.bikes} disableBorder />
        )}
      </Section>

      <SectionArrow variant="left" />

      <Section maxWidth={800}>
        <Title number={"3."}>{getSection(3).title}</Title>
        <Subtitle>{getSection(3).subtitle}</Subtitle>
        <ReservationContact
          firstname={data.firstname}
          surname={data.surname}
          email={data.email}
          zip={data.zip}
          address={data.address}
          city={data.city}
          telephone={data.telephone}
          errors={errors}
          gdpr={data.gdpr}
          handleChange={handleChangeData}
        />
      </Section>

      <SectionArrow variant="summary" />

      <Section
        classes={{ root: classes.summary }}
        background={"#fff"}
        maxWidth={800}
      >
        <StepTitle color="#cf2c91" number="4." variant="title">
          {formatMessage(messages.summary)}
        </StepTitle>
        <ReservationSummary
          code="ebike"
          orderType="ebike"
          validate={validator}
          setMessage={(text, type) => setMessage({ type, text })}
          setErrors={setErrors}
          message={message}
          onSuccess={() => {
            resetState();
            ReactGA.event({
              category: "ebike rezervacia",
              action: "Odoslanie formulara",
              label: "Potvrdiť rezerváciu",
            });
          }}
          data={data}
        />
      </Section>
    </Content>
  );
};

export default compose(injectIntl, withStyles(styles))(ReservationEBike);
