import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import CinemaCard from "./CinemaCard";
import { getImage } from "../../helpers";

const styles = (theme) => ({
  root: {},
});

const StepCategory = ({
  classes,
  handleChange = () => {},
  categories = [],
  category,
}) => (
  <div className={classes.root}>
    <Grid spacing={16} container justify="center">
      {categories.map((x) => (
        <Grid xs={6} sm={4} md={3} lg={2} xl={2} item>
          <CinemaCard
            handleChange={handleChange}
            bgImage={x.Image && getImage(x.Image.storage_name)}
            value={x.code}
            active={category === x.code}
            title={x.title}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

export default withStyles(styles)(StepCategory);
