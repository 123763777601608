import React from "react";
import NotFoundPage from "./pages/404";
import BlogPage from "./pages/Blog";
import GalleryPage from "./pages/Gallery";
import ContactPage from "./pages/Contact";
import PricingListPage from "./pages/PricingList";
import Home from "./pages/LandingPage";
import ZonesPage from "./pages/Zone";
import PostPage from "./pages/Post/PostPage";
import EventPage from "./pages/Event";
import VirtualTour from "./pages/tour";
import Gallery from "./pages/Gallery/FullScreenGallery";
import ImageViewer from "./pages/imageViewer";
import PartyPage from "./pages/Party";
import BowlingPage from "./pages/Bowling";
import EshopPage from "./pages/Shop";
import CinemaPage from "./pages/Cinema";
import BonusCard from "./pages/BonusCard";
import { injectIntl } from "react-intl";
import generalMessages from "./i18n/messages";
import SimpleReservation from "./pages/SimpleReservation";
import Page from "./pages/page";
import Order from "./pages/order";
import ReservationChildrenCamp from "./pages/ReservationChildrenCamp";
import ReservationEbike from "./pages/ReservationEbike";
import ReservationCar from "./pages/ReservationCar";
import ReservationDarts from "./pages/ReservationDarts";
import ReservationKaraoke from "./pages/ReservationKaraoke";

const ImageViewerWrapper = injectIntl((props) => (
  <ImageViewer
    {...props}
    returnLink={`/#${props.intl.formatMessage(generalMessages.linkRestaurant)}`}
  />
));

const PaymentFail = (props) => <Order {...props} failed />;

const routes = [
  { path: "/", name: "home", exact: true, component: Home },
  /* SK */
  { path: "/galeria", name: "galeria", exact: true, component: GalleryPage },
  { path: "/aktuality", name: "aktuality", exact: true, component: BlogPage },
  { path: "/cennik", name: "cennik", exact: true, component: PricingListPage },
  { path: "/kontakt", name: "kontakt", exact: true, component: ContactPage },
  { path: "/zona/:id", name: "zona", exact: true, component: ZonesPage },
  {
    path: "/aktuality/:id",
    name: "aktuality",
    exact: true,
    component: PostPage,
  },
  { path: "/eventy/:id", name: "eventy", exact: true, component: EventPage },
  { path: "/event/:id", name: "eventy", exact: true, component: EventPage },
  { path: "/eshop", name: "eshop", exact: true, component: EshopPage },
  {
    path: "/rezervacia/party",
    name: "partyrezervacia",
    exact: true,
    component: PartyPage,
  },
  {
    path: "/rezervacia/sipky",
    name: "sipkyrezervacia",
    exact: true,
    component: ReservationDarts,
  },
  {
    path: "/rezervacia/karaoke",
    name: "karaokerezervacia",
    exact: true,
    component: ReservationKaraoke,
  },
  {
    path: "/rezervacia/karaoke",
    name: "karaokerezervacia",
    exact: true,
    component: ReservationDarts,
  },
  {
    path: "/rezervacia/bowling",
    name: "bowlingrezervacia",
    exact: true,
    component: BowlingPage,
  },
  {
    path: "/rezervacia/cinema",
    name: "kino",
    exact: true,
    component: CinemaPage,
  },
  {
    path: "/rezervacia/cinema/:type",
    name: "kino",
    exact: true,
    component: CinemaPage,
  },
  {
    path: "/rezervacia/cinema/:type/:category",
    name: "kino",
    exact: true,
    component: CinemaPage,
  },
  {
    path: "/rezervacia/ebike",
    name: "ebike",
    exact: true,
    component: ReservationEbike,
  },
  {
    path: "/rezervacia/auto",
    name: "auto",
    exact: true,
    component: ReservationCar,
  },
  {
    path: "/rezervacia/tabor",
    name: "rezervacia",
    exact: true,
    component: ReservationChildrenCamp,
  },
  {
    path: "/rezervacia/:code",
    name: "rezervacia",
    exact: true,
    component: SimpleReservation,
  },
  { path: "/galeria/:id", name: "galeria", exact: true, component: Gallery },
  {
    path: "/bonusova-karta",
    name: "bonusovakarta",
    exact: true,
    component: BonusCard,
  },
  /* PL */
  { path: "/tour", name: "tour", exact: true, component: VirtualTour },
  { path: "/galeria", name: "galeria", exact: true, component: GalleryPage },
  {
    path: "/aktualnosci",
    name: "aktualnosci",
    exact: true,
    component: BlogPage,
  },
  { path: "/cennik", name: "cennik", exact: true, component: PricingListPage },
  { path: "/kontakt", name: "kontakt", exact: true, component: ContactPage },
  { path: "/zona/:id", name: "zona", exact: true, component: ZonesPage },
  {
    path: "/aktualnosci/:id",
    name: "aktualnosci",
    exact: true,
    component: PostPage,
  },
  {
    path: "/wydarzenia/:id",
    name: "wydarzenia",
    exact: true,
    component: EventPage,
  },
  {
    path: "/rezerwacja/party",
    name: "partyrezerwacja",
    exact: true,
    component: PartyPage,
  },
  {
    path: "/rezerwacja/bowling",
    name: "bowlingrezerwacja",
    exact: true,
    component: BowlingPage,
  },
  {
    path: "/rezerwacja/cinema",
    name: "cinemarezerwacja",
    exact: true,
    component: CinemaPage,
  },
  {
    path: "/rezerwacja/:code",
    name: "rezerwacja",
    exact: true,
    component: SimpleReservation,
  },
  { path: "/galeria/:id", name: "galeria", exact: true, component: Gallery },
  {
    path: "/karta-rabatowa",
    name: "kartarabatowa",
    exact: true,
    component: BonusCard,
  },
  /* OTHER */
  {
    path: "/viewer/:image",
    name: "image",
    exact: true,
    component: ImageViewerWrapper,
  },
  {
    path: "/payment/order/:token",
    name: "payment",
    exact: true,
    component: Order,
  },
  {
    path: "/payment/fail/:token",
    name: "payment",
    exact: true,
    component: PaymentFail,
  },
  { path: "/:page", name: "page", exact: true, component: Page },
  { path: "*", name: "notfound", component: NotFoundPage },
];

export default routes;
