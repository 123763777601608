import React from "react";
import TimeCheckbox from "./TimeCheckbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Grid, withStyles } from "@material-ui/core";
import { QUERY_RESERVATION_TRACK_TIMES } from "./graphql";
import { Query } from "@apollo/react-components";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    padding: "0 0 32px 0",
  },
  errorMessage: {
    color: "red",
    padding: "16px 0 0 0",
  },
  dateText: {
    fontSize: "2em",
  },
  dateTextAvailable: {
    color: "#9cc682",
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
  textWrapper: {
    height: "100px",
    margin: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  title: {
    margin: "auto",
    marginTop: "5px",
    marginBottom: "8px",
    padding: "5px",
    width: "250px",
    borderBottom: "1px solid #ccc",
  },
  containerCenter: {
    width: "330px",
    margin: "auto",
    textAlign: "left",
  },
  checkboxContainer: {
    width: "250px",
    margin: "auto",
    textAlign: "left",
  },
  column: {
    textAlign: "center",
  },
});

const hasAlleyBeforeLastReservation = (alley) => {
  const beforeLast = alley.times.length - 2;
  return alley.times[beforeLast].reserved;
};

const hasAlleyBeforeLastChecked = (alley, reservationTimes) => {
  const alleyReservationTimes = reservationTimes.filter(
    (t) => t.alleyId === alley.alleyId
  );
  return alleyReservationTimes.find(
    (i) =>
      i.timeFrom === alley.times[alley.times.length - 2].time_from.slice(0, 5)
  );
};

const ReservationTimes = ({
  reservationDate = new Date(),
  reservationTimes = [],
  handleChangeTime,
  showTrackTitle = true,
  classes,
  reservationSystemId,
}) => {
  if (!reservationSystemId) {
    return null;
  }

  const checkReservationTime = (timeFrom, timeTo, alleyId) => {
    const alleyReservationTimes = reservationTimes.filter(
      (x) => x.alleyId === alleyId
    );

    if (alleyReservationTimes.length === 0) {
      return true;
    }

    let minReservedTime = Math.min.apply(
      Math,
      alleyReservationTimes.map(function(time) {
        return parseInt(time.timeFrom.slice(0, 2), 10);
      })
    );
    let maxReservedTime = Math.max.apply(
      Math,
      alleyReservationTimes.map(function(time) {
        return parseInt(time.timeTo.slice(0, 2), 10);
      })
    );

    if (
      maxReservedTime - minReservedTime < 3 &&
      alleyReservationTimes.length < 3
    ) {
      minReservedTime--;
      maxReservedTime++;
    }

    const hourFrom = parseInt(timeFrom.slice(0, 2), 10);
    const hourTo = parseInt(timeTo.slice(0, 2), 10); // TODO vyriesit pomocou scitavania a odpocitavania hodin

    if (minReservedTime <= hourFrom && hourTo <= maxReservedTime) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Query
      query={QUERY_RESERVATION_TRACK_TIMES}
      variables={{ date: reservationDate, reservationSystemId }}
      fetchPolicy="network-only"
    >
      {({ error, loading, refetch, data }) => {
        if (loading)
          return <CircularProgress style={{ margin: "auto" }} size={50} />;
        if (error) return `Error! ${error.message}`;
        const Alleys = (data && data.Alleys) || [];

        return Alleys.map((x, idx) => (
          <Grid
            key={idx}
            item
            className={classes.gridItem}
            xs={12}
            sm={6}
            md={4}
          >
            {showTrackTitle && (
              <Typography gutterBottom className={classes.title}>
                <FormattedMessage
                  id="track.name"
                  defaultMessage="Dráha č. {number}"
                  values={{ number: idx + 1 }}
                />
              </Typography>
            )}
            <div style={{ position: "relative" }}>
              <div className={classes.checkboxContainer}>
                {x.times.map((time, idx) => {
                  const enabled = checkReservationTime(
                    time.time_from,
                    time.time_to,
                    x.alleyId
                  );

                  const alleyReservationTimes = reservationTimes.filter(
                    (t) => t.alleyId === x.alleyId
                  );

                  const notCheckedBeforeLastHour = !Alleys.find((a) =>
                    hasAlleyBeforeLastChecked(a, reservationTimes)
                  );

                  const notReservedBeforeLastHour = !Alleys.find(
                    hasAlleyBeforeLastReservation
                  );

                  const checked = alleyReservationTimes.find(
                    (y) =>
                      y.timeFrom === time.time_from.slice(0, 5) &&
                      y.timeTo === time.time_to.slice(0, 5)
                  );

                  const isLastTime = idx === x.times.length - 1;
                  const isBeforeLastTime = idx === x.times.length - 2;

                  // Posledna hodina ma byt aktivovana iba ked je urobena rezervacia v predposlednu alebo ak si pouzivatel objednava aj predposlednu
                  const disabledIfBeforeLastCondition =
                    isLastTime &&
                    notReservedBeforeLastHour &&
                    notCheckedBeforeLastHour;

                  const handleChange = (checked, timeFrom, timeTo) => {
                    // Odcheckujeme posledny checkbox ak odskrtneme predposledny
                    if (
                      isBeforeLastTime &&
                      !checked &&
                      notReservedBeforeLastHour
                    ) {
                      const lastTime = x.times.length - 1;
                      const tf = x.times[lastTime].time_from.slice(0, 5);
                      const tt = x.times[lastTime].time_to.slice(0, 5);

                      handleChangeTime(checked, [
                        {
                          timeFrom,
                          timeTo,
                          alleyId: x.alleyId,
                        },
                        ...Alleys.filter((i) => {
                          return (
                            !hasAlleyBeforeLastChecked(i, reservationTimes) ||
                            i.alleyId === x.alleyId
                          );
                        }).map((a) => ({
                          timeFrom: tf,
                          timeTo: tt,
                          alleyId: a.alleyId,
                        })),
                      ]);
                    } else {
                      handleChangeTime(checked, [
                        {
                          timeFrom,
                          timeTo,
                          alleyId: x.alleyId,
                        },
                      ]);
                    }
                  };

                  return (
                    <TimeCheckbox
                      key={idx}
                      checked={checked ? true : false}
                      disabled={!enabled || disabledIfBeforeLastCondition}
                      handleChange={handleChange}
                      reserved={time.reserved}
                      timeFrom={time.time_from}
                      timeTo={time.time_to}
                    />
                  );
                })}
              </div>
            </div>
          </Grid>
        ));
      }}
    </Query>
  );
};

export default withStyles(styles)(ReservationTimes);
