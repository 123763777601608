import React from 'react';
import { withStyles, Typography } from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';
import { scrollToElement } from '../helpers';

export const CardTitle = withStyles(theme => ({
  root: {
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'auto',
    padding: '10px 0 10px 0',
    margin: 'auto',
    textShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
    fontSize: 28,
    [theme.breakpoints.down('lg')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))(({ classes, children, color, additionalStyles }) => (
  <Typography
    className={`${classes.root} ${additionalStyles}`}
    style={{ color: color ? color : '#fff' }}
    align="center"
    variant="h5"
  >
    {children}
  </Typography>
));

export const PostCardTitle = withStyles(theme => ({
  root: {
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'auto',
    padding: '10px 0 10px 0',
    margin: 'auto',
    textShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
    fontSize: 26,
    [theme.breakpoints.down('lg')]: {
      fontSize: 26,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}))(({ classes, children, color, additionalStyles }) => (
  <Typography
    className={`${classes.root} ${additionalStyles}`}
    style={{ color: color ? color : '#fff' }}
    align="center"
    variant="h5"
  >
    {children}
  </Typography>
));

export const PillTitle = withStyles(theme => ({
  root: {
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'auto',
    padding: '10px 0 10px 0',
    margin: 'auto',
    fontSize: 28,
    [theme.breakpoints.down('lg')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))(({ classes, children, color }) => (
  <Typography
    className={classes.root}
    style={{ color: color ? color : '#fff' }}
    align="center"
    variant="h5"
  >
    {children}
  </Typography>
));

export const PillPricingTitle = withStyles(theme => ({
  root: {
    textShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
    color: '#fff',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'auto',
    padding: '10px 0 10px 0',
    margin: 'auto',
    fontSize: 32,
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    },
  },
}))(({ classes, children, color }) => (
  <Typography
    className={classes.root}
    style={{ color: color ? color : '#fff' }}
    align="center"
    variant="h5"
  >
    {children}
  </Typography>
));

export const SectionTitle = withStyles(theme => ({
  root: {
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 'auto',
    margin: 'auto',
    padding: '0 32px',
    fontSize: 32,
    [theme.breakpoints.down('lg')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
}))(({ classes, children }) => (
  <Typography
    className={classes.root}
    align="center"
    gutterBottom
    variant="title"
  >
    {children}
  </Typography>
));

export const SectionSubtitleTitle = withStyles(theme => ({
  root: {
    marginBottom: 0,
    paddingBottom: 0,
    padding: '4px 32px',
    lineHeight: 1.5,
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))(({ classes, children }) => (
  <Typography className={classes.root} align="center" variant="subtitle1">
    {children}
  </Typography>
));

export const CustomHeading = withStyles(theme => ({
  root: {
    marginBottom: 16,
    paddingBottom: 0,
    fontSize: 48,
    [theme.breakpoints.down('lg')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}))(({ classes, textShadow, children, color, additionalStyles }) => (
  <Typography
    style={{ color, textShadow }}
    className={`${classes.root} ${additionalStyles}`}
    align="center"
    variant="headline"
  >
    {children}
  </Typography>
));

export const BonusCardTitle = withStyles(theme => ({
  root: {
    marginBottom: 16,
    paddingBottom: 0,
    fontSize: 40,
    [theme.breakpoints.down('lg')]: {
      fontSize: 38,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
}))(({ classes, children, color, additionalStyles }) => (
  <Typography
    style={{ color }}
    className={`${classes.root} ${additionalStyles}`}
    align="center"
    variant="headline"
  >
    {children}
  </Typography>
));

export const FooterTitle = withStyles(theme => ({
  root: {
    color: '#555555',
    fontFamily: 'PaytoneOne',
    margin: '15px 0',
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  link: {
    textDecoration: 'none',
    color: '#555555',
    '&:focus': {
      color: '#555555',
    },
    '&:hover': {
      color: '#231f20',
      // textDecoration: "underline"
    },
    '&:active': {
      color: '#555555',
    },
  },
}))(({ classes, children, color, additionalStyles, link }) =>
  link ? (
    <Link className={classes.link} to={link} scroll={el => scrollToElement(el)}>
      <Typography
        style={{ color }}
        className={`${classes.root} ${additionalStyles}`}
        gutterBottom
        align="left"
      >
        {children}
      </Typography>
    </Link>
  ) : (
    <Typography
      style={{ color }}
      className={`${classes.root} ${additionalStyles}`}
      gutterBottom
      align="left"
    >
      {children}
    </Typography>
  )
);
