import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    fontFamily: "lato",
    fontSize: 12,
    color: "#231f20",
  },
});

const PackageItem = ({ classes, children }) => (
  <div className={classes.root}>{children}</div>
);

export default withStyles(styles)(PackageItem);
