import React, { Component } from "react";
import { Typography, TextField, withStyles } from "@material-ui/core";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import CustomButton from "../CustomButton";
import { FormattedMessage } from "react-intl";
const ReactGA = process.browser ? require("react-ga") : {};

const NEWSLETTER_URL =
  "https://kinderlandno.us19.list-manage.com/subscribe/post?u=e5e87a296af2b4fe1217898c9&amp;id=eff7451d1f";

const styles = (theme) => ({
  root: {},
  title: {
    color: "#555555",
    fontFamily: "PaytoneOne",
    fontSize: "1.2rem",
    margin: "15px 0",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  textField: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 300,
    },
    margin: "0 0 24px 0",
  },
  button: {
    fontSize: 16,
    margin: "auto",
    padding: "12px 32px",
    [theme.breakpoints.down("md")]: {
      margin: "20px auto",
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto",
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
      fontSize: 12,
    },
  },
  infoText: {
    fontSize: 14,
    color: "#777777",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
});

class NewsletterSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      error: "",
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render = () => {
    return (
      <MailchimpSubscribe
        url={NEWSLETTER_URL}
        render={({ subscribe, status, message }) => {
          return (
            <div className={this.props.classes.container}>
              <Typography className={this.props.classes.title} gutterBottom>
                <FormattedMessage
                  id="footer.newslettersignbutton.title"
                  defaultMessage="Prihlás sa k odberu noviniek"
                />
              </Typography>
              <Typography className={this.props.classes.infoText}>
                <FormattedMessage
                  id="footer.newsletter.title"
                  defaultMessage="Zanechaj nám tvoj email a získaj akcie a novinky ako prvý. "
                />
              </Typography>
              <div style={{ textAlign: "center" }}>
                <TextField
                  id="email"
                  label="E-MAIL"
                  fullWidth
                  value={this.state.email}
                  onChange={this.handleChange("email")}
                  margin="normal"
                  InputLabelProps={{
                    classes: {
                      root: this.props.classes.textField,
                    },
                  }}
                  className={this.props.classes.textField}
                />
              </div>
              <CustomButton
                addtionalClasses={this.props.classes.button}
                handleOnClick={() => subscribe({ EMAIL: this.state.email })}
                variant="contained"
                color="secondary"
              >
                <FormattedMessage
                  id="footer.newsletter.registratonbutton"
                  defaultMessage="REGISTROVAŤ SA"
                />
              </CustomButton>
              {status === "success" &&
                ReactGA &&
                typeof ReactGA.event === "function" &&
                ReactGA.event({
                  category: "newsletter",
                  action: "Odoslanie formulara",
                  label: "Registracia",
                })}
              {status === "sending" && <div style={{ color: "#b9b9b9" }} />}
              {status === "error" && (
                <div
                  style={{ color: "#b9b9b9" }}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
              {status === "success" && (
                <div style={{ color: "#b9b9b9" }}>
                  <FormattedMessage
                    id="footer.newsletter.success"
                    defaultMessage="Úspešne ste sa zaregistrovali na odber noviniek z Kinderlandu. Ďakujeme za prejavený záujem"
                  />
                </div>
              )}
            </div>
          );
        }}
      />
    );
  };
}

export default withStyles(styles)(NewsletterSubscription);
