import React from "react";
import { withStyles, Typography, Grid } from "@material-ui/core";
import { hexToRgba } from "../../helpers";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 16,
    padding: "32px 32px 32px 32px",
    display: "flex",
    alignItems: "center",
    height: 326,
    [theme.breakpoints.down("xs")]: {
      height: 600,
    },
    background: "#fff",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  priceText: {
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      marginTop: "-5px",
      marginBottom: 7,
    },
  },
});

const PillCard = ({ classes, color = "#232323", priceItems = [] }) => {
  const shadowColor = hexToRgba(color, 0.3);

  return (
    <div
      className={classes.root}
      style={{ color, boxShadow: `0 16px 48px 0 ${shadowColor}` }}
    >
      <div>
        {priceItems.map((priceItem, idx) => (
          <Grid key={idx} container spacing={8}>
            <Grid item xs={12} sm={8}>
              <Typography className={classes.title} variant="body1">
                {priceItem.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography
                style={{ color, fontWeight: "bold" }}
                className={classes.priceText}
                variant="body1"
              >
                {priceItem.text}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default withStyles(styles)(PillCard);
