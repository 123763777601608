import React from "react";
import { withStyles, RadioGroup } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormattedMessage } from "react-intl";

const styles = () => ({
  radioControl: {
    backgroundColor: "#fff",
    margin: 5,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 16,
  },
  blue: {
    color: "#4a90e2 !important",
  },
  pink: {
    color: "#cf2c91 !important",
  },
  black: {
    color: "#231f20 !important",
  },
  errorText: {
    color: "red",
    padding: 16,
    fontFamily: "lato",
  },
});

const StepRoom = ({ errors, handleChange, classes, value = "" }) => (
  <RadioGroup
    aria-label="theme"
    name="theme"
    className={classes.root}
    value={value}
    onChange={(e) => handleChange("room", e.target.value)}
  >
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="RESTAURANT"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage
          id="partyroom.restaurant.title"
          defaultMessage="Reštaurácia"
        />
      }
    />
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="CAFE"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage id="partyroom.cafe.title" defaultMessage="Kaviareň" />
      }
    />
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="BOWLING"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage
          id="partyroom.bowling.title"
          defaultMessage="Bowling"
        />
      }
    />
    <div className={classes.errorText}>{errors.room}</div>
  </RadioGroup>
);

export default withStyles(styles)(StepRoom);
