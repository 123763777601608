import React from "react";
import { FormattedMessage } from "react-intl";
import { withStyles } from "@material-ui/core";
import BurgerIcon from "../../resources/img/element-icon-burger-menu.svg";

const styles = (theme) => ({
  root: {
    float: "right",
    cursor: "pointer",
    background: "#fff",
    border: 0,
    fontSize: 16,
    outline: "none",
    marginLeft: 8,
    fontWeight: 500,
    fontFamily: "lato",
    display: "none",
    "& > img": {
      position: "relative",
      top: 2,
      right: "-2px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});

const MobileMenuButton = ({ toggleMenu, classes }) => (
  <button onClick={toggleMenu} className={classes.root}>
    <span>
      <FormattedMessage
        id="navigation.burgermenu.title"
        defaultMessage="MENU"
      />
    </span>{" "}
    <img src={BurgerIcon} alt="Burger menu icon" />
  </button>
);

export default withStyles(styles)(MobileMenuButton);
