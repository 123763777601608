import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    color: "#fff",
    textTransform: "uppercase",
    fontSize: 14,
    bottom: 60,
    borderRadius: 8,
    cursor: "pointer",
    boxShadow: "0 16px 24px 0 rgba(0, 0, 0, 0.4)",
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
      bottom: 10,
      paddingLeft: "8px",
      paddingRight: "4px",
      width: "190px",
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
      fontSize: 12,
      boxShadow: "none",
      backgroundColor: "inherit !important",
      width: "auto !important",
      bottom: 10,
      width: "auto",
    },
    [theme.breakpoints.down("320")]: {
      bottom: 10,
    },
    position: "absolute",
    left: 0,
    right: 0,
    fontSize: 14,
    fontFamily: "lato",
    margin: "auto",
    width: "230px",
    paddingLeft: "16px",
    paddingRight: "8px",
  },
  rootSmall: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },

    position: "absolute",
    left: 0,
    right: 0,
    bottom: 20,
    [theme.breakpoints.down("320")]: {
      bottom: 10,
    },
  },
  text: {
    fontFamily: "lato",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      display: "none",
    },
  },
  container: {
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      height: 40,
    },
  },
  icon: {
    margin: "0 10px",
    height: 23,
    width: 23,
    [theme.breakpoints.down("xs")]: {
      height: 22,
      width: 22,
    },
    "& path": {
      fill: "#ff0000 !important",
    },
  },
});

const EshopButton = ({
  classes,
  additionalClassses = "",
  children,
  icon,
  color,
  fontColor,
  backgroundImage,
  onActionClick,
}) => {
  const rootStyles = {};
  if (color) {
    rootStyles.backgroundColor = color;
  }

  if (backgroundImage) {
    rootStyles.backgroundImage = backgroundImage;
  }

  if (fontColor) {
    rootStyles.color = fontColor;
  }

  return (
    <Fragment>
      <a
        onClick={(e) => {
          e.preventDefault();
          onActionClick && onActionClick();
        }}
        style={rootStyles}
        className={`${classes.root} ${additionalClassses}`}
      >
        <div className={classes.container}>
          <div className={classes.text}>{children}</div>
          <div style={{ lineHeight: 1 }}>
            {icon && <img className={classes.icon} src={icon} alt="link" />}
          </div>
        </div>
      </a>
      <a
        onClick={(e) => {
          e.preventDefault();
          onActionClick && onActionClick();
        }}
        className={`${classes.rootSmall} ${additionalClassses}`}
      >
        {icon && <img className={classes.icon} src={icon} alt="link" />}
      </a>
    </Fragment>
  );
};

export default withStyles(styles)(EshopButton);
