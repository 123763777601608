import React from "react";
import { withStyles } from "@material-ui/core";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { defineMessages, injectIntl } from "react-intl";
import { compose } from "recompose";
import globalMessages from "../i18n/messages";

const styles = (theme) => ({
  root: {},
});

const messages = defineMessages({
  button: {
    id: "cookies.button",
    defaultMessage: "Súhlasím",
  },
  gdpr: {
    id: "cookies.gdpr.text",
    defaultMessage:
      "Táto stránka používa cookies. Viac informácií o spracovaní Vašich osobných údajov nájdete v ",
  },
  gdprLink: {
    id: "cookies.gdpr.link",
    defaultMessage: "informácie o spracovaní osobných údajov",
  },
});

const EuCookies = ({ classes, intl: { formatMessage } }) => (
  <CookieConsent
    location="bottom"
    buttonText={formatMessage(messages.button)}
    expires={90}
    style={{
      background: "#2B373B",
      maxWidth: 700,
      width: "100%",
      margin: "auto",
      left: 0,
      right: 0,
    }}
    buttonStyle={{
      color: "#555555",
      background: "#fff",
      borderRadius: 8,
      fontSize: 14,
      color: "#bc2482",
      textTransform: "uppercase",
      padding: "8px 24px",
      fontWeight: "600",
      border: "2px solid #bc2482",
    }}
  >
    {formatMessage(messages.gdpr)}
    <a
      style={{ color: "#fff" }}
      target="_blank"
      rel="noopener noreferrer"
      href={`/${formatMessage(globalMessages.linkGdpr)}`}
    >
      {formatMessage(messages.gdprLink)}
    </a>
  </CookieConsent>
);

export default compose(withStyles(styles), injectIntl)(EuCookies);
