import React, { Fragment } from "react";
import { withStyles, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import EuLogo from "../../resources/img/eu.jpg";

const styles = (theme) => ({
  root: {
    fontSize: 12,
    color: "#7d7d7d",
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
      fontSize: 11,
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      fontSize: 10,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontSize: 8,
    },
  },
  euLogo: {
    margin: "0 4px",
    width: "auto",
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
  },
});

const Copyrights = ({ classes }) => (
  <Fragment>
    <Typography className={classes.root}>
      <FormattedMessage
        id="footer.copyrightsmessage.title"
        defaultMessage="© COPYRIGHT 2018 - ELMAR-SK, S.R.O. VŠETKY PRÁVA VYHRADENÉ"
      />
    </Typography>
    <img className={classes.euLogo} src={EuLogo} alt="EU logo" />
  </Fragment>
);

export default withStyles(styles)(Copyrights);
