import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from "@material-ui/core";
import { Mutation } from "@apollo/react-components";
import CustomButton from "../../components/CustomButton";
import { isEmpty, isEmail } from "validator";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
const ReactGA = process.browser ? require("react-ga") : {};

const ORDER_BONUS_CARD = gql`
  mutation orderBonusCard($data: String!) {
    orderBonusCard(data: $data) {
      id
    }
  }
`;

const styles = theme => ({
  root: {
    textAlign: "center",
    padding: "48px 96px",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 16,
    margin: "auto",
    background: "#fff",
    maxWidth: 800,
    [theme.breakpoints.down("sm")]: {
      padding: "16px"
    }
  },

  textField: {
    textTransform: "uppercase",
    fontSize: "14px"
  },
  label: {
    fontSize: 14
  },
  nameTextField: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  nameTextFieldRoot: {
    textTransform: "uppercase",
    fontSize: "14px"
  },
  button: {
    height: 56,
    padding: "8px 32px",
    [theme.breakpoints.down("sm")]: {
      height: 50,
      padding: "4px 16px"
    },
    [theme.breakpoints.down("xs")]: {
      height: 40,
      padding: "4px 16px",
      fontSize: 12
    }
  }
});

class BonusCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  resetData = () => {
    this.setState(this.getInitialState());
  };

  getInitialState = () => {
    const initialData = {
      data: {
        name: "",
        email: "",
        telephone: "",
        address: "",
        city: "",
        psc: "",
        gdpr: false
      },
      errors: {
        name: "",
        psc: "",
        email: "",
        telephone: "",
        address: "",
        city: "",
        gdpr: ""
      }
    };

    return initialData;
  };

  handleChange = name => event => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: event.target.value
      }
    });
  };

  handleChecked = name => event => {
    this.setState({
      data: {
        ...this.state.data,
        gdpr: !this.state.data.gdpr
      }
    });
  };

  validate = () => {
    const { data } = this.state;
    const {
      intl: { formatMessage }
    } = this.props;

    let valid = true;

    const errors = {};

    if (isEmpty(data.name)) {
      errors.name = formatMessage(messages.nameError);
      valid = false;
    }

    if (isEmpty(data.telephone)) {
      errors.telephone = formatMessage(messages.telephoneError);
      valid = false;
    }

    if (!isEmail(data.email)) {
      errors.email = formatMessage(messages.emailError);
      valid = false;
    }

    if (isEmpty(data.address)) {
      errors.address = formatMessage(messages.addressError);
      valid = false;
    }

    if (isEmpty(data.city)) {
      errors.city = formatMessage(messages.cityError);
      valid = false;
    }

    if (data.gdpr === false) {
      errors.gdpr = formatMessage(messages.gdprError);
      valid = false;
    }

    if (isEmpty(data.psc) || (data.psc && data.psc.legnth < 5)) {
      errors.psc = formatMessage(messages.pscError);
      valid = false;
    }

    if (!valid) {
      this.setState({
        errors
      });
      return false;
    }

    return true;
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage }
    } = this.props;

    return (
      <div className={this.props.classes.root}>
        <Typography
          variat="body1"
          style={{ textAlign: "center", margin: "16px 0", fontSize: "1em" }}
        >
          <FormattedMessage
            id="pricinglist.freebonuscard.title"
            defaultMessage="Požiadaj o ňu prostredníctvom kontaktného formulára nižšie. Je úplne zdarma. Pri každej návšteve Kinderlandu sa stačí preukázať pri pokladni a automaticky získaš kopu výhod."
          />
        </Typography>
        <div style={{ padding: "16px" }}>
          <Grid container spacing={16}>
            <Grid item sm={12} xs={12}>
              <TextField
                error={this.state.errors.name && true}
                id="name"
                InputLabelProps={{
                  classes: {
                    root: classes.nameTextFieldRoot
                  }
                }}
                className={classes.nameTextField}
                label={this.state.errors.name || formatMessage(messages.name)}
                value={this.state.data.name}
                onChange={this.handleChange("name")}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: "16px" }}>
          <Grid container spacing={16}>
            <Grid item sm={6}>
              <TextField
                id="address"
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
                className={classes.textField}
                error={this.state.errors.address && true}
                fullWidth
                label={
                  this.state.errors.address || formatMessage(messages.address)
                }
                value={this.state.data.address}
                onChange={this.handleChange("address")}
                // margin="normal"
              />
              <TextField
                id="email"
                fullWidth
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
                className={classes.textField}
                error={this.state.errors.email && true}
                label={this.state.errors.email || formatMessage(messages.email)}
                value={this.state.data.email}
                onChange={this.handleChange("email")}
                // margin="normal"
              />
            </Grid>
            <Grid item sm={6}>
              <div style={{ display: "flex" }}>
                <TextField
                  id="city"
                  fullWidth
                  style={{ marginRight: 16 }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textField
                    }
                  }}
                  className={classes.textField}
                  error={this.state.errors.city && true}
                  label={this.state.errors.city || formatMessage(messages.city)}
                  value={this.state.data.city}
                  onChange={this.handleChange("city")}
                />
                <TextField
                  id="psc"
                  style={{ maxWidth: 80 }}
                  InputLabelProps={{
                    classes: {
                      root: classes.textField
                    }
                  }}
                  className={classes.textField}
                  error={this.state.errors.psc && true}
                  fullWidth
                  label={this.state.errors.psc || formatMessage(messages.psc)}
                  value={this.state.data.psc}
                  onChange={this.handleChange("psc")}
                  // margin="normal"
                />
              </div>
              <TextField
                id="telephone"
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
                className={classes.textField}
                label={
                  this.state.errors.telephone ||
                  formatMessage(messages.telephone)
                }
                fullWidth
                error={this.state.errors.telephone && true}
                value={this.state.data.telephone}
                onChange={this.handleChange("telephone")}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: 32 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.data.gdpr}
                onChange={this.handleChecked("gdpr")}
                color="primary"
              />
            }
            label={
              <NavLink style={{ color: "#231f20" }} to={`/gdpr`}>
                <FormattedMessage
                  id="pricinglist.gdprbonuscard.title"
                  defaultMessage="Súhlasím so spracovaním osobných údajov podľa GDPR"
                />
              </NavLink>
            }
          />
          {this.state.errors.gdpr && (
            <FormHelperText style={{ color: "red", textAlign: "center" }}>
              {this.state.errors.gdpr}
            </FormHelperText>
          )}
        </div>

        <Mutation mutation={ORDER_BONUS_CARD} ssr={false}>
          {(orderBonusCard, { data, loading, error }) => (
            <div>
              <CustomButton
                addtionalClasses={classes.button}
                handleOnClick={() => {
                  if (this.validate()) {
                    orderBonusCard({
                      variables: {
                        data: JSON.stringify(this.state.data)
                      }
                    }).then(res => {
                      this.resetData();
                      ReactGA.event({
                        category: "bonusova karta",
                        action: "Registracia karty",
                        label: "Odoslať"
                      });
                    });
                  }
                }}
              >
                <FormattedMessage
                  id="pricinglist.wantbonuscardbutton.title"
                  defaultMessage="CHCEM BONUSOVÚ KARTU"
                />
              </CustomButton>
              <div
                style={{
                  textAlign: "center",
                  padding: "16px 16px 0 16px",
                  fontSize: 20,
                  fontFamily: "lato"
                }}
              >
                {loading && formatMessage(messages.loading)}
                {error && (
                  <p style={{ color: "red" }}>
                    {error.graphQLErrors.map(x => x.message)}
                  </p>
                )}
                {data && formatMessage(messages.success)}
              </div>
            </div>
          )}
        </Mutation>
      </div>
    );
  };
}

export default injectIntl(withStyles(styles)(BonusCardForm));
