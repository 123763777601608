/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { withStyles, Chip, Checkbox, Typography } from "@material-ui/core";
import WeekDayPicker from "./WeekDayPicker";
import dateFormat from "dateformat";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 8,
    overflow: "hidden",
    minHeight: 270,
    position: "relative",
    background: theme.palette.common.white,
  },
  title: {
    fontFamily: "PaytoneOne",
    lineHeight: 1,
  },
  titleSection: {
    padding: 8,
    paddingBottom: 0,
    background: theme.palette.grey[100],
  },
  datesTitle: {
    fontFamily: "TypoComica",
    fontSize: 12,
    color: theme.palette.grey[600],
    lineHeight: 2,
  },
  chip: {
    fontSize: 12,
    padding: 0,
    height: 25,
    borderRadius: 16,
  },
  datesSection: {
    background: theme.palette.common.white,
    paddingTop: 4,
    paddingBottom: 4,
  },
  priceTitle: {
    height: 20,
    display: "flex",
    alignItems: "center",
    paddingLeft: 8,
    paddingRight: 4,
  },
  priceTypography: {
    fontSize: 14,
    fontWeight: 600,
  },
  priceSection: {
    paddingLeft: 8,
    paddingRight: 8,
    height: 40,
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.grey[100],
  },
  chipLabel: {
    padding: 8,
    paddingLeft: 6,
    paddingRight: 6,
  },
  allWeekTypography: {
    color: theme.palette.grey[700],
    fontSize: 12,
  },
  checkboxSection: {
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    alignItems: "center",
    display: "flex",
    cursor: "pointer",
    height: 25,
    background: theme.palette.grey[200],
    "&:hover": {
      background: theme.palette.grey[300],
    },
  },
  checkbox: {
    padding: 0,
    paddingRight: 6,
  },
  checked: {
    color: "#ea98ed !important",
  },
  isDisabled: {
    color: theme.palette.grey[400],
  },
});

const messages = defineMessages({
  allWeek: {
    id: "reservation.camp.allWeekTitle",
    defaultMessage: "VYBRAŤ CELÝ TÝŽDEŇ",
  },
  week: {
    id: "reservation.camp.week",
    defaultMessage: "týždeň",
  },
  day: {
    id: "reservation.camp.day",
    defaultMessage: "deň",
  },
});

const ChildCampCard = ({
  camp,
  classes,
  handleDates,
  personCount,
  selectedDays,
  intl: { formatMessage },
}) => {
  const isPartionalChecked = (selectedDays, camp) => {
    return (
      selectedDays.length > 0 && selectedDays.length !== camp.CampDates.length
    );
  };

  const checkDisabled = (dates) => {
    const leastOneWithoutCapacity = dates.find(
      (d) => d.capacity < personCount && d.capacity !== null
    );
    if (leastOneWithoutCapacity) {
      return true;
    }
    return false;
  };

  const isDisabledAllWeek = checkDisabled(camp.CampDates);
  return (
    <div className={classes.root}>
      <div className={classes.titleSection}>
        <Typography className={classes.title}>{camp.title}</Typography>
        <Typography className={classes.datesTitle}>
          {dateFormat(camp.date_from, "d.m.yyyy")} -{" "}
          {dateFormat(camp.date_to, "d.m.yyyy")}
        </Typography>
      </div>
      {!isDisabledAllWeek ? (
        <div
          onClick={() =>
            handleDates(
              camp,
              camp.reservationDates &&
                camp.reservationDates.length === camp.CampDates.length
                ? []
                : camp.CampDates.map((x) => x.date)
            )
          }
          className={classes.checkboxSection}
        >
          <Checkbox
            checked={selectedDays.length > 0}
            className={classes.checkbox}
            disabled={isDisabledAllWeek}
            classes={{
              checked:
                isPartionalChecked(selectedDays, camp) && classes.checked,
            }}
            tabIndex={-1}
            disableRipple
          />
          <Typography
            className={clsx(
              classes.allWeekTypography,
              isDisabledAllWeek && classes.isDisabled
            )}
          >
            {formatMessage(messages.allWeek)}
          </Typography>
        </div>
      ) : (
        <div className={classes.checkboxSection} />
      )}
      <div className={classes.datesSection}>
        <WeekDayPicker
          camp={camp}
          personCount={personCount}
          selected={selectedDays}
          handleDayPick={handleDates}
        />
      </div>
      <div className={classes.priceSection}>
        <Chip
          label={`${camp.price_day} € / ${formatMessage(messages.day)}`}
          className={classes.chip}
          classes={{ label: classes.chipLabel }}
          color="secondary"
        />
        {!isDisabledAllWeek && (
          <Chip
            label={`${camp.price} € / ${formatMessage(messages.week)}`}
            className={classes.chip}
            classes={{ label: classes.chipLabel }}
            color="secondary"
          />
        )}
      </div>
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(ChildCampCard);
