import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import { PillTitle } from "./Typography";
import { hexToRgba } from "../helpers";
import clsx from "clsx";
import RichTextField from "../pages/rich-text-field/RichTextField";

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: 16,
    display: "flex",
    padding: 0,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    height: 326,
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
  imgSection: {
    width: "50%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  contentSection: {
    width: "50%",
    padding: 16,
    position: "relative",
  },
  titleCenter: {
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    width: "90%",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  sectionText: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& a": {
      color: "inherit",
    },
  },
});

const ZoneSection = ({
  classes,
  image = null,
  bgColor = "#fff",
  imgPosition = "left",
  title = "",
  elements = "",
}) => (
  <div
    className={classes.root}
    style={{
      backgroundImage:
        imgPosition !== "left"
          ? "linear-gradient(126deg, #ffffff, #ffffff)"
          : `linear-gradient(126deg, ${hexToRgba(bgColor, 0.8)}, ${hexToRgba(
              bgColor,
              1
            )})`,
    }}
  >
    {imgPosition === "left" && (
      <div
        className={classes.imgSection}
        style={{
          backgroundImage: `url(${image})`,
          borderRadius: "16px 0 0 16px",
        }}
      />
    )}
    <div className={classes.contentSection}>
      <div className={classes.titleCenter}>
        <PillTitle color={imgPosition === "right" ? bgColor : "#fff"}>
          {title.toUpperCase()}
        </PillTitle>

        <RichTextField
          style={{
            color: imgPosition === "right" ? bgColor : "#fff",
            textAlign: "center",
          }}
          textStyles={{
            color: "inherit",
            fontSize: "inherit",
            fontFamily: "inherit",
            textAlign: "center",
          }}
          classes={{ root: clsx(classes.sectionText) }}
        >
          {elements}
        </RichTextField>
      </div>
    </div>
    {imgPosition === "right" && (
      <div
        className={classes.imgSection}
        style={{
          backgroundImage: `url(${image})`,
          borderRadius: "0 16px 16px 0",
        }}
      />
    )}
  </div>
);

export default withStyles(styles)(ZoneSection);
