import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  withStyles,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { defineMessages, injectIntl } from "react-intl";
import { compose } from "recompose";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/Person";

const styles = (theme) => ({
  label: {
    fontSize: 16,
  },
  textField: {
    borderRadius: 4,
    background: theme.palette.grey[100],
  },
  personContainer: {
    maxHeight: 400,
    overflowY: "auto",
  },
  button: {
    fontSize: 14,
  },
});

const messages = defineMessages({
  name: {
    id: "personpicker.name",
    defaultMessage: "MENO A PRIEZVISKO",
  },
  birthDate: {
    id: "personpicker.birthdate",
    defaultMessage: "DÁTUM NARODENIA",
  },
  description: {
    id: "personpicker.description",
    defaultMessage: "DÔLEŽITÉ INFORMÁCIE O DIEŤATI",
  },
  addPersonButton: {
    id: "personpicker.addPersonButton",
    defaultMessage: "PRIDAŤ DIEŤA",
  },
  delete: {
    id: "personpicker.delete",
    defaultMessage: "Odobrať",
  },
  personHeading: {
    id: "personpicker.personHeading",
    defaultMessage: "ZOZNAM PRIDANÝCH OSÔB",
  },
  errorBirthDate: {
    id: "personpicker.errorBirthDate",
    defaultMessage: "VYPLŇTE DÁTUM NARODENIA",
  },
  errorName: {
    id: "personpicker.errorName",
    defaultMessage: "VYLPŇTE MENO DIEŤAŤA",
  },
});

const PersonPicker = ({ intl: { formatMessage }, classes, onSubmitPerson }) => {
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [description, setDescription] = useState("");
  const [persons, setPersons] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    onSubmitPerson(persons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persons]);

  const removePerson = useCallback(
    (idx) => {
      const f = persons.filter((p, i) => i !== idx);
      setPersons(f);
    },
    [setPersons, persons]
  );

  const handleAddPerson = useCallback(() => {
    const err = {};

    if (!name) err.name = formatMessage(messages.errorName);
    if (!birthDate) err.birthDate = formatMessage(messages.errorBirthDate);

    if (Object.keys(err).length > 0) {
      setErrors(err);
      return;
    }

    const newPersons = [...persons, { name, birthDate, description }];

    setPersons(newPersons);
    setBirthDate("");
    setDescription("");
    setName("");
    setErrors({});
  }, [
    setErrors,
    formatMessage,
    setPersons,
    name,
    birthDate,
    description,
    persons,
  ]);

  return (
    <Grid container spacing={16}>
      <Grid item xs={6}>
        <TextField
          id="personpicker-name"
          fullWidth
          classes={{ root: classes.textField }}
          error={errors.name}
          label={errors.name || formatMessage(messages.name)}
          value={name}
          InputLabelProps={{ classes: { root: classes.label } }}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="personpicker-birthdate"
          fullWidth
          classes={{ root: classes.textField }}
          error={errors.birthDate}
          InputLabelProps={{ classes: { root: classes.label } }}
          label={errors.birthDate || formatMessage(messages.birthDate)}
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="personpicker-description"
          label={formatMessage(messages.description)}
          fullWidth
          classes={{ root: classes.textField }}
          error={errors.description}
          value={description}
          rows="4"
          InputLabelProps={{ classes: { root: classes.label } }}
          multiline
          onChange={(e) => setDescription(e.target.value)}
          variant="outlined"
        />
      </Grid>
      {persons.length > 0 && (
        <Grid item xs={12}>
          <div className={classes.personContainer}>
            <List dense>
              {persons.map((person, idx) => (
                <ListItem key={idx}>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                  <ListItemText
                    primary={`${person.name} (${person.birthDate})`}
                    secondary={person.description}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={formatMessage(messages.delete)}>
                      <IconButton
                        onClick={() => removePerson(idx)}
                        aria-label={formatMessage(messages.delete)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          onClick={handleAddPerson}
          className={classes.button}
          variant="outlined"
        >
          {formatMessage(messages.addPersonButton)}
        </Button>
      </Grid>
    </Grid>
  );
};

export default compose(withStyles(styles), injectIntl)(PersonPicker);
