import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "karaoke.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje.",
  },
  success: {
    id: "karaoke.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás",
  },
  metaTitle: {
    id: "karaoke.meta.title",
    defaultMessage: "Kinderland - Karaoke",
  },
  metaDescription: {
    id: "karaoke.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
  title: {
    id: "karaoke.title",
    defaultMessage: "Karaoke",
  },
  caption1: {
    id: "karaoke.caption1",
    defaultMessage: "dve dráhy",
  },
  caption2: {
    id: "karaoke.caption2",
    defaultMessage: "možnosť objednávky online",
  },
  errorFirstname: {
    id: "contact.error.firstname",
    defaultMessage: "Zadajte meno",
  },
  errorSurname: {
    id: "contact.error.surname",
    defaultMessage: "Zadajte priezvisko",
  },
  errorTelephone: {
    id: "contact.error.telephone",
    defaultMessage: "Zadajte telefónne číslo",
  },
  errorGdpr: {
    id: "contact.error.gdpr",
    defaultMessage:
      "Potrebujeme od Vás povolenie pre spracovanie osobných údajov.",
  },
  errorTimes: {
    id: "contact.error.times",
    defaultMessage: "Vyberte čas rezervácie",
  },
  errorEmail: {
    id: "contact.error.email",
    defaultMessage: "Zadajte e-mailovú adresu",
  },
  errorCity: {
    id: "contact.error.city",
    defaultMessage: "Zadajte obec",
  },
  errorAddress: {
    id: "contact.error.address",
    defaultMessage: "Zadajte ulicu a popisné číslo",
  },
  errorZip: {
    id: "contact.error.zip",
    defaultMessage: "Zadajte PSČ",
  },
});

export default messages;
