import React from "react";
import { withStyles } from "@material-ui/core";
import { HashLink as Link } from "react-router-hash-link";
import { scrollToElement } from "../../helpers";

const styles = (theme) => ({
  root: {
    fontFamily: "lato",
  },
  square: {
    margin: "12px 10px 0 0",
    float: "left",
    height: "5px",
    width: "5px",
  },
  link: {
    textDecoration: "none",
    color: "#777777",
    "&:focus": {
      color: "#777777",
    },
    "&:hover": {
      color: "#231f20",
      fontWeight: 600,
      //textDecoration: "underline"
    },
    "&:active": {
      color: "#777777",
    },
  },
});

const ListItem = ({ classes, children, bulletColor = "#000", link }) => (
  <li className={classes.root}>
    {link ? (
      <Link
        className={classes.link}
        to={link}
        scroll={(el) => scrollToElement(el)}
      >
        <div
          className={classes.square}
          style={{ background: bulletColor }}
        ></div>
        {children}
      </Link>
    ) : (
      <div>
        <div
          className={classes.square}
          style={{ background: bulletColor }}
        ></div>
        {children}
      </div>
    )}
  </li>
);

export default withStyles(styles)(ListItem);
