import React, { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Card from "../../components/Reservation/AdditionalZone/Card";
import { getImage } from "../../helpers";
import { Grid, withStyles, Typography } from "@material-ui/core";
import Loading from "../../components/Loading";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
// import FetchIcon from "@material-ui/icons/Cached";

const styles = (theme) => ({
  button: {
    marginTop: 24,
  },
  fetchButton: {
    marginLeft: 8,
  },
  card: {
    height: 340,
    [theme.breakpoints.down("sm")]: {
      height: 320,
    },
    [theme.breakpoints.down("xs")]: {
      height: 320,
    },
  },
  gridItem: {
    [theme.breakpoints.down(420)]: {
      width: "100%",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
});

const QUERY = gql`
  query getAvailableCarRentals(
    $offset: Int
    $limit: Int
    $dateFrom: DateTime
    $dateTo: DateTime
    $timeFrom: Int
    $timeTo: Int
  ) {
    cars: getAvailableCarRentals(
      offset: $offset
      limit: $limit
      dateFrom: $dateFrom
      dateTo: $dateTo
      timeFrom: $timeFrom
      timeTo: $timeTo
    ) {
      id
      title
      subtitle
      text
      available_count
      count
      default_count
      price
      available
      Image {
        storage_name
      }
      image_id
    }
  }
`;

const messages = defineMessages({
  notFound: {
    id: "reservation.car.notFound",
    defaultMessage: "V zvolený termín nie je dostupné žiadne auto",
  },
});

const CarList = ({
  cars = [],
  classes,
  onChange = () => {},
  dateTo,
  dateFrom,
  timeFrom,
  intl: { formatMessage },
  timeTo,
}) => {
  const { data, loading } = useQuery(QUERY, {
    variables: {
      offset: 0,
      limit: 100,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    },
    fetchPolicy: "cache-and-network",
  });

  const items = (data && data.cars) || [];

  const getCount = (itemId) => {
    const item = cars.find((b) => b.id === itemId);
    return (item && item.count) || 0;
  };

  return (
    <Fragment>
      {!loading && items.length === 0 && (
        <Typography>{formatMessage(messages.notFound)}</Typography>
      )}
      <Grid justify="center" container spacing={8}>
        {items.map((car, idx) => (
          <Grid
            className={classes.gridItem}
            item
            xs={6}
            sm={4}
            md={3}
            xl={3}
            key={idx}
          >
            <Card
              classes={{ root: classes.card }}
              title={car.title}
              defaultCount={car.default_count}
              onChange={(count) => onChange(car, count)}
              image={car.Image && getImage(car.Image.storage_name)}
              count={getCount(car.id)}
              maxCount={car.available_count}
              subtitle={car.subtitle}
              elements={car.text}
              price={car.price}
              unit="ks"
              padding={8}
              height={320}
            />
          </Grid>
        ))}
      </Grid>
      {/* <Button
        className={classes.button}
        onClick={() => {
          fetchMore({
            variables: { offset: items.length },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                cars: [...prev.cars, ...fetchMoreResult.cars]
              });
            }
          }).then(x => {
            console.log(x);
          });
        }}
        variant="outlined"
      >
        Zobraziť ďalšie bicykle
        <FetchIcon className={classes.fetchButton} />
      </Button> */}
      {loading && <Loading />}
    </Fragment>
  );
};

export default compose(withStyles(styles), injectIntl)(CarList);
