import React from "react";
import { NavLink } from "react-router-dom";
import SimpleLink from "../../components/SimpleLink";
import FetchMoreIcon from "../../resources/img/arrow-load-black.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import FeedCard from "../../components/Cards/Feed";
import { useQuery } from "@apollo/react-hooks";
import LazyGrid from "../../components/LazyGrid";
import MediaView from "../../components/FullscreenCarousel/FullScreen";
import PostView from "../Post/PostView";
import EventView from "../Event/EventView";
import { withStyles } from "@material-ui/core";
import { getAssetPath, getImage } from "../../helpers";
import gql from "graphql-tag";
import { injectIntl, defineMessages } from "react-intl";
import generalMessages from "../../i18n/messages";

const FEEDS_QUERY = gql`
  query Feed(
    $tag: String
    $blogOffset: Int
    $eventOffset: Int
    $galleryOffset: Int
    $limit: Int
  ) {
    Feed: Feed(
      tag: $tag
      blogOffset: $blogOffset
      galleryOffset: $galleryOffset
      eventOffset: $eventOffset
      limit: $limit
    ) {
      # MediaFeed {
      #     storage_name
      #     resource_type
      # }
      GalleryFeed {
        id
        title
        Files {
          storage_name
          resource_type
        }
      }
      BlogFeed {
        id
        code
        title
        perex_text
        user_id
        cover_image_id
        thumbnail_image_id
        tag_ids
        category_ids
        Thumbnail {
          storage_name
        }
        CoverImage {
          storage_name
        }
        text
        published
        published_date
      }
      EventFeed {
        id
        code
        title
        perex_text
        user_id
        cover_image_id
        date_from
        date_to
        thumbnail_image_id
        Poster {
          storage_name
        }
        Thumbnail {
          storage_name
        }
        CoverImage {
          storage_name
        }
        text
        published
      }
    }
  }
`;

const messages = defineMessages({
  cardEventCtaTitle: {
    id: "feed.card.cta.title",
    defaultMessage: "Viac o akcii",
  },
  cardPostCtaTitle: {
    id: "feed.card.post.cta.title",
    defaultMessage: "Prečítať celý článok",
  },
  fetchMore: {
    id: "feed.fetchMore.button.title",
    defaultMessage: "NAČÍTAŤ VIAC PRÍSPEVKOV",
  },
});

const styles = (theme) => ({
  feedContainer: {
    "& .card-content": {
      top: "40%",
    },
    padding: 8,
  },
  fetchMoreButton: {
    fontWeight: 800,
    fontSize: 16,
    padding: 6,
    textAlign: "center",
    lineHeight: "35px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      lineHeight: "20px",
    },
  },
  fetchMoreContainer: {
    textAlign: "center",
    padding: "32px 0 0px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0 0 0",
    },
  },
});

const Feed = ({
  feed,
  color,
  refreshHeight,
  intl: { formatMessage },
  classes,
}) => {
  const { data, loading, fetchMore } = useQuery(FEEDS_QUERY, {
    variables: {
      tag: feed,
      galleryOffset: 0,
      blogOffset: 0,
      eventOffset: 0,
      limit: 4,
    },
  });
  const f = (data && data.Feed) || {};
  const BlogFeed = f.BlogFeed || [];
  const GalleryFeed = f.GalleryFeed || [];
  const EventFeed = f.EventFeed || [];

  if (loading)
    return (
      <CircularProgress
        style={{ margin: "auto", color: color || "#555" }}
        size={65}
      />
    );

  setTimeout(function() {
    refreshHeight();
  }, 500);

  const eventFeedViews = EventFeed.map((feed, idx) => (
    <EventView
      key={idx}
      text={feed.text}
      title={feed.title}
      date={feed.date_from}
      cover={feed.CoverImage && getImage(feed.CoverImage.storage_name)}
      poster={feed.Poster && getAssetPath(feed.Poster.storage_name)}
    />
  ));

  const blogFeedViews = BlogFeed.map((feed, idx) => (
    <PostView
      key={idx}
      title={feed.title}
      date={feed.published_date}
      text={feed.text}
      cover={feed.CoverImage && getAssetPath(feed.CoverImage.storage_name)}
    />
  ));

  const mediaFeedViews = GalleryFeed.map((feed, idx) => (
    <MediaView
      cover={
        feed.Files &&
        feed.Files.length &&
        getAssetPath(feed.Files[0].storage_name)
      }
      key={idx}
      activeSlideHeight="fullscreen"
    />
  ));

  const eventFeedCards = EventFeed.map((feed, idx) => (
    <NavLink
      key={idx}
      to={`/${formatMessage(generalMessages.linkEvents)}/${feed.code}`}
    >
      <FeedCard
        feed
        key={idx}
        bgImage={feed.Thumbnail && getImage(feed.Thumbnail.storage_name)}
        date={feed.date_from}
        actionTitle={formatMessage(messages.cardEventCtaTitle)}
        title={feed.title}
        height="350px"
      />
    </NavLink>
  ));

  const blogFeedCards = BlogFeed.map((feed, idx) => (
    <NavLink
      key={idx}
      to={`/${formatMessage(generalMessages.linkBlog)}/${feed.code}`}
    >
      <FeedCard
        feed={true}
        bgImage={feed.Thumbnail && getImage(feed.Thumbnail.storage_name)}
        date={feed.published_date}
        actionTitle={formatMessage(messages.cardPostCtaTitle)}
        title={feed.title}
        height="350px"
      />
    </NavLink>
  ));

  const mediaFeedCards = GalleryFeed.map((feed, idx) => (
    <NavLink
      key={idx}
      to={`/${formatMessage(generalMessages.linkGallery)}/${feed.code}`}
    >
      <FeedCard
        feed={true}
        key={idx}
        shading={false}
        bgImage={
          feed.Files &&
          feed.Files.length &&
          getImage(feed.Files[0].storage_name)
        }
        height="300px"
      />
    </NavLink>
  ));

  return (
    <div>
      <div
        className={classes.feedContainer}
        style={{ textAlign: "center", margin: "-8px" }}
      >
        <LazyGrid
          views={[...eventFeedViews, ...blogFeedViews, ...mediaFeedViews]}
          cards={[...eventFeedCards, ...blogFeedCards, ...mediaFeedCards]}
        />
      </div>
      <div className={classes.fetchMoreContainer}>
        <SimpleLink
          alwaysDisplayText
          responsive
          additionalClassses={classes.fetchMoreButton}
          fontColor="#555555"
          onActionClick={() =>
            fetchMore({
              variables: {
                galleryOffset: GalleryFeed.length,
                blogOffset: BlogFeed.length,
                eventOffset: EventFeed.length,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                console.log(prev);
                console.log(fetchMoreResult);
                return Object.assign({}, prev, {
                  Feed: {
                    __typename: "Feed",
                    GalleryFeed: [
                      ...prev.Feed.GalleryFeed,
                      ...fetchMoreResult.Feed.GalleryFeed,
                    ],
                    BlogFeed: [
                      ...prev.Feed.BlogFeed,
                      ...fetchMoreResult.Feed.BlogFeed,
                    ],
                    EventFeed: [
                      ...prev.Feed.EventFeed,
                      ...fetchMoreResult.Feed.EventFeed,
                    ],
                  },
                });
              },
            })
          }
          icon={FetchMoreIcon}
        >
          {formatMessage(messages.fetchMore)}
        </SimpleLink>
      </div>
    </div>
  );
};

export default injectIntl(withStyles(styles)(Feed));
