import React from "react";
import { Typography, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    fontFamily: "PaytoneOne",
    fontSize: 20,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      paddingBottom: 8,
      flexDirection: "column",
      "& > div": {
        textAlign: "center",
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      textAling: "center",
    },
  },
  icon: {
    marginLeft: 8,
    position: "relative",
    top: "2px",
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 30,
    },
  },
});

const ContactFormTitle = ({ classes, icon, color, children }) => (
  <Typography className={classes.root}>
    <div>
      <img src={icon} className={classes.icon} alt="icon" />
    </div>
    <div style={{ padding: 10, color }}>{children}</div>
  </Typography>
);

export default withStyles(styles)(ContactFormTitle);
