import React from "react";
import { withStyles } from "@material-ui/core";
import LogoYoutube from "../resources/img/element-icon-youtube-gray.svg";
import LogoCircle from "../resources/img/element-icon-instagram-gray.svg";
import LogoFb from "../resources/img/element-icon-facebook-gray.svg";

const styles = (theme) => ({
  root: {
    textAlign: "right",
    paddingTop: 5,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 10,
      textAlign: "center",
    },
    "& img": {
      marginRight: 8,
      width: 40,
      height: 40,
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: 32,
        height: 32,
      },
    },
  },
});

const Social = ({
  classes,
  facebookUrl = "",
  youtubeUrl = "",
  instagramUrl = "",
}) => (
  <div className={classes.root}>
    <a href={facebookUrl} target="_blank">
      <img src={LogoFb} alt="facebook" />
    </a>
    <a href={instagramUrl} target="_blank">
      <img src={LogoCircle} alt="instagram" />
    </a>
    <a href={youtubeUrl} target="_blank">
      <img src={LogoYoutube} alt="youtube" />
    </a>
  </div>
);

export default withStyles(styles)(Social);
