import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import CheckmarkIcon from "../../resources/img/checkmark.svg";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 16,
    overflow: "hidden",
    height: 400,
    [theme.breakpoints.down("xs")]: {
      height: 500,
    },
    background: "#fff",
  },
  contactTitle: {
    fontSize: 22,
    fontFamily: "PaytoneOne",
    marginBottom: 12,
  },
  image: {
    width: "50%",
    height: "100%",
    display: "inline-block",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      height: "45%",
      width: "100%",
      borderRadius: "16px 16px 0 0",
    },
  },
  descriptionText: {
    fontFamily: "TypoComica",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("390")]: {
      fontSize: 11,
    },
  },
  text: {
    width: "50%",
    position: "relative",
    height: "100%",
    display: "inline-block",
    padding: "50px 16px 10px 16px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 16px 10px 16px",
      height: "55%",
      width: "100%",
    },
  },
  textContainer: {
    padding: 48,
    fontSize: 20,
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
    [theme.breakpoints.down("390")]: {
      padding: 16,
    },
  },
  contactRoleText: {
    color: "#555555",
    fontFamily: "lato",
    fontSize: 18,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 2,
    letterSpacing: "-0.5px",
  },
});

const PillCard = ({
  classes,
  width = "auto",
  color = "#4a4a4a",
  title = "",
  text,
  float = "left",
  bgImage,
}) => (
  <div className={classes.root} style={{ width }}>
    <div
      className={classes.image}
      style={{
        float,
        backgroundImage: bgImage && `url(${bgImage})`,
        backgroundColor: color,
      }}
    />
    <div className={classes.text}>
      <div className={classes.textContainer}>
        <Typography
          style={{ color }}
          className={classes.contactTitle}
          align="center"
        >
          {title.toUpperCase()}
        </Typography>
        <Typography className={classes.descriptionText} align="center">
          {text}
        </Typography>
      </div>
    </div>
  </div>
);

export default withStyles(styles)(PillCard);
