import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import SimpleLink from "../../components/SimpleLink";
import { PillPricingTitle } from "../../components/Typography";
import { hexToRgba } from "../../helpers";
import { ArrowRight } from "../../components/SvgIcon";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    borderRadius: 16,
    height: 326,
    [theme.breakpoints.down("xs")]: {
      height: 600,
    },
    background: "#fff",
  },
  image: {
    textAlign: "center",
    position: "relative",
    borderRadius: "16px 0 0 16px",
    width: "50%",
    height: "100%",
    display: "inline-block",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "50%",
      borderRadius: "16px 16px 0 0",
    },
  },
  imageContainer: {
    textAlign: "center",
    borderRadius: "16px 0 0 16px",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "16px 16px 0 0",
    },
  },
  imageContainerContent: {
    textAlign: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  icon: {
    height: 96,
    width: 96,
  },
  textContainer: {
    position: "relative",
    width: "50%",
    height: "100%",
    padding: 16,
    display: "inline-block",
    backgroundPosition: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "50%",
    },
  },
  textWrapper: {
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    padding: 16,
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    "& > ul": {
      margin: 0,
      padding: 0,
      listStyle: "none",
      textAling: "center",
      " & > li": {
        textAling: "center",
        padding: "0 0 10px 0",
      },
    },
  },
  priceItemTitle: {
    textAlign: "center",
    fontFamily: "lato",
    color: "#555555",
    fontWeight: 500,
    fontSize: "18px",
    padding: "0 0 8px 0",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
  },
  priceItemText: {
    fontFamily: "TypoComica",
    fontSize: "32px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
  },
  link: {
    position: "absolute",
    right: 5,
    left: 5,
    textAling: "center",
    bottom: 20,
  },
  price: {
    fontFamily: "TypoComica",
    fontSize: 52,
    textAlign: "center",
  },
  text: {
    textAlign: "center",
    color: "#555555",
    fontFamily: "TypoComica",
    fontSize: 22,
  },
});

const PillCard = ({
  classes,
  color = "#232323",
  title = "",
  image = null,
  icon = null,
  text = null,
  price = null,
  comment = "",
  priceItems = [],
  ctaTitle = "",
  ctaLink = "",
}) => {
  const imageStyles = {};

  if (image) {
    imageStyles.backgroundImage = `url(${image})`;
  }

  const shadowColor = hexToRgba(color, 0.2);
  const cardColor = hexToRgba(color, 0.24);

  const imageContainerStyles = {
    backgroundImage: `linear-gradient(157deg, rgba(35, 31, 32, 0.5), rgba(35, 31, 32, 0.5))`,
  };

  return (
    <div
      className={classes.root}
      style={{ color, boxShadow: `0 16px 48px 0 ${shadowColor}` }}
    >
      <div className={classes.image} style={imageStyles}>
        <div className={classes.imageContainer} style={imageContainerStyles}>
          <div className={classes.imageContainerContent}>
            {icon && <img src={icon} alt="icon" className={classes.icon} />}
            <PillPricingTitle>{title}</PillPricingTitle>
          </div>
        </div>
      </div>
      <div className={classes.textContainer}>
        <div className={classes.textWrapper}>
          <ul>
            {priceItems.map((priceItem, idx) => (
              <li key={idx}>
                <div className={classes.priceItemTitle}>{priceItem.title}</div>
                <div style={{ color }} className={classes.priceItemText}>
                  {priceItem.text}
                </div>
              </li>
            ))}
          </ul>
          {text && <div className={classes.text}>{text}</div>}
          {price && <div className={classes.price}>{price}</div>}
          <Typography
            variant="body1"
            style={{ fontSize: "0.9em" }}
            align="center"
          >
            {comment}
          </Typography>
        </div>
        {ctaTitle && ctaLink && (
          <SimpleLink
            fontColor={color}
            alwaysDisplayText
            responsive
            additionalClassses={classes.link}
            onActionClick={() => window.open(ctaLink)}
            svg={<ArrowRight color={color} />}
          >
            {ctaTitle}
          </SimpleLink>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(PillCard);
