import React from "react";
import { withStyles } from "@material-ui/core";
import AdditionalZone from "../../components/Reservation/AdditionalZone";
import { getImage } from "../../helpers";

const styles = () => ({
  root: {},
});

const StepAdditionalItems = ({
  extraZone = [],
  onChange = () => {},
  selected = [],
}) => (
  <div>
    <AdditionalZone
      selected={selected}
      onChange={onChange}
      items={extraZone.map((x) => ({
        ...x,
        Items: x.Items.map((i) => ({
          ...i,
          image: i.Image && getImage(i.Image.storage_name),
        })),
      }))}
    />
  </div>
);

export default withStyles(styles)(StepAdditionalItems);
