import React from "react";
import RightArrowImg from "../../resources/img/arrow-right.svg";
import LeftArrowImg from "../../resources/img/arrow-left.svg";
import { withStyles } from "@material-ui/core";

export const NextButton = withStyles({
  root: {
    position: "absolute",
    right: 16,
    zIndex: 999,
    top: 0,
    bottom: 50,
    cursor: "pointer",
    margin: "auto",
    height: 50
  }
})(({ onClick, classes }) => (
  <img
    className={classes.root}
    onClick={onClick}
    src={RightArrowImg}
    alt="Right arrow"
  />
));

export const PrevButton = withStyles({
  root: {
    position: "absolute",
    left: 16,
    zIndex: 99999,
    top: 0,
    margin: "auto",
    cursor: "pointer",
    bottom: 50,
    height: 50
  }
})(({ onClick, classes }) => (
  <img
    className={classes.root}
    onClick={onClick}
    src={LeftArrowImg}
    alt="Left arrow"
  />
));
