import React from "react";
import { withStyles } from "@material-ui/core";
import { CustomHeading } from "../../components/Typography";
import ContactHeaderImage from "../../resources/img/kontakty-header.jpg";
import { FormattedMessage } from "react-intl";

const styles = theme => ({
  root: {
    margin: "0 32px",
    position: "relative",
    borderRadius: 16,
    boxShadow: `0 16px 48px 0 rgba(35, 31, 32, 0.16)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 400,
    [theme.breakpoints.down("xs")]: {
      height: 220
    },
    backgroundImage: `url(${ContactHeaderImage})`
  },
  heading: {
    margin: 0,
    position: "absolute",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
});

const ContactHeader = ({ classes, title, image }) => (
  <div className={classes.root}>
    <CustomHeading color="#fff" additionalStyles={classes.heading}>
      <FormattedMessage
        id="contact.header.title"
        defaultMessage="KTO STOJÍ ZA KINDERLANDOM?"
      />
    </CustomHeading>
  </div>
);

export default withStyles(styles)(ContactHeader);
