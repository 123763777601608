import React from "react";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  image: {
    borderRadius: 16,
    height: 492,
    width: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      height: 320,
    },
    [theme.breakpoints.down("md")]: {
      height: 250,
    },
    [theme.breakpoints.down("sm")]: {
      height: 250,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
  },
});

const Card = ({ classes, bgImage }) => (
  <img className={classes.image} src={bgImage} alt="icon" />
);

export default withStyles(styles)(Card);
