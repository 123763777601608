import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Hands from "../../resources/img/hand-3.png";
import BonusCardSection from "../../components/BonusCardSection";
import ZoneSection from "./ZoneSection";
import EshopSection from "./EshopSection";
import EventSection from "./EventSection";
import RestaurantSection from "./RestaurantSection";
import BlogSection from "./BlogSection";
import Header from "../../components/Header/Header";
import { Fade } from "react-reveal";
import Helmet from "react-helmet";
import { compose } from "recompose";
import { defineMessages, injectIntl } from "react-intl";
import { useEffect } from "react";
import { useLang } from "../../helpers";

const CustomFade = ({
  children,
  timeout = 1000,
  delay = 0,
  style = {},
  bottom = false,
  left = false,
  right = false,
  top = false,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const elementRef = React.useRef(null);

  useEffect(() => {
    const checkVisibility = () => {
      if (!elementRef.current) return;

      const rect = elementRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const threshold = 150;

      const isInView = rect.top <= windowHeight - threshold;

      if (isInView && !isVisible) {
        setTimeout(() => {
          setIsVisible(true);
        }, delay);
      }
    };

    window.addEventListener("scroll", checkVisibility);
    checkVisibility();

    return () => {
      window.removeEventListener("scroll", checkVisibility);
    };
  }, [delay, isVisible]);

  const getTransform = () => {
    if (!isVisible) {
      if (bottom) return "translateY(20px)";
      if (top) return "translateY(-20px)";
      if (left) return "translateX(-20px)";
      if (right) return "translateX(20px)";
    }
    return "translate(0)";
  };

  return (
    <div
      ref={elementRef}
      style={{
        transition: `opacity ${timeout}ms ease-out, transform ${timeout}ms ease-out`,
        opacity: isVisible ? 1 : 0,
        transform: getTransform(),
        ...style,
      }}
    >
      {children}
    </div>
  );
};

const messages = defineMessages({
  metaTitle: {
    id: "landing.meta.title",
    defaultMessage: "Kinderland",
  },
  metaDescription: {
    id: "landing.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${Hands})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom -200px",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center bottom -150px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center bottom -120px",
    },
  },
  section: {
    minHeight: "450px",
    backgroundImage: `url(${Hands})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    padding: "16px 0",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      padding: "0 0",
      minHeight: 150,
    },
  },
  blogSection: {
    minHeight: 450,
    backgroundImage: `url(${Hands})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    padding: "32px 0",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("xs")]: {
      padding: "32 0",
      minHeight: "auto",
    },
  },
  sectionWithoutBgImage: {
    minHeight: 450,
    marginTop: 42,
    backgroundSize: "contain",
    backgroundPosition: "center",
    padding: "16px 0",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down("sm")]: {
      marginTop: 24,
      padding: "10px 0",
      minHeight: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      padding: "10px 0",
      minHeight: "auto",
    },
  },
  bonusCardSection: {
    padding: "32px 0",
    marginBottom: 16,
  },
});

const Home = ({ classes, intl: { formatMessage }, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = useLang();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>{formatMessage(messages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>

      <Fragment>
        <Header />
        <section className={classes.section}>
          <Fade>
            <ZoneSection />
          </Fade>
        </section>
        <section className={classes.blogSection}>
          <Fade>
            <BlogSection />
          </Fade>
        </section>
        {/* <section className={classes.section}>
          <Fade>
            <EventSection />
          </Fade>
        </section> */}
        <section className={classes.bonusCardSection}>
          <Fade>
            <BonusCardSection />
          </Fade>
        </section>

        <section className={classes.section}>
          <CustomFade>
            <RestaurantSection />
          </CustomFade>
        </section>
        {lang === "sk" && (
          <section className={classes.sectionWithoutBgImage}>
            <CustomFade>
              <EshopSection />
            </CustomFade>
          </section>
        )}
      </Fragment>
    </div>
  );
};

export default compose(injectIntl, withRouter, withStyles(styles))(Home);
