import React, { Fragment } from "react";
import { withStyles, Grid } from "@material-ui/core";
import PriceCard from "../../components/PriceCard";
import BonusCard from "../../components/BonusCard";
import { SectionTitle } from "../../components/Typography";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    padding: "8px 0 24px 0",
    margin: "8px -8px",
  },
});

const PricingSection = ({
  classes,
  color = "#ffffff",
  pricingImage,
  priceText = "",
  bonusCardText = "",
}) => (
  <Fragment>
    <SectionTitle>
      <FormattedMessage
        id="zone.pricing.title"
        defaultMessage="KOĽKO TO STOJÍ?"
      />
    </SectionTitle>

    <div className={classes.root}>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={6}>
          <PriceCard
            backgroundColor={color}
            image={pricingImage}
            price={priceText}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <BonusCard color={color} text={bonusCardText} />
        </Grid>
      </Grid>
    </div>
  </Fragment>
);

export default withStyles(styles)(PricingSection);
