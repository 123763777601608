import React from "react";
import { withStyles } from "@material-ui/core";
import Title from "./Title";
import ZoneList from "./ZoneList";
import Caption from "./Caption";
import PackageItem from "./PackageItem";
import ExtraFeeItem from "./ExtraFeeItem";
import { hexToRgba } from "../../../helpers";

const styles = (theme) => ({
  root: {
    backgroundColor: "#fff",
    borderRadius: 16,
    height: 420,
    //  margin: "0 8px",
    cursor: "pointer",
    // width: 280,
    overflow: "hidden",
  },
  topContainer: {
    height: "40%",
    color: "#fff",
    position: "relative",
    padding: 16,
    textAlign: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    //  backgroundColor: "#353535"
  },
  imageWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: 8,
  },
  middleContainer: {
    padding: 16,
    textAlign: "center",
    height: "22%",
  },
  bottomContainer: {
    padding: 16,
    backgroundColor: "#f7f7f7",
    textAlign: "center",
    height: "38%",
    position: "relative",
  },
  extraFeesContainer: {
    padding: "16px 0",
    position: "absolute",
    bottom: 0,
    margin: "auto",
    width: "100%",
    margin: "0 -16px",
  },
  zoneList: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    padding: 8,
  },
});

const PackageCard = ({
  classes,
  availableZones = [],
  title = "",
  priceOld = null,
  price = null,
  zones = [],
  selected = false,
  captions = [],
  items,
  image,
  extraFeeItems = [],
  handleChange,
  shadowColor,
  color = "#231f20",
}) => {
  const defaultShadow = "0 16px 48px 0 rgba(35, 31, 32, 0.2)";
  const topContainerStyle = {};

  const rootStyles = {
    boxShadow: shadowColor
      ? `0 16px 48px 0 ${hexToRgba(shadowColor, 0.4)}`
      : defaultShadow,
    border: selected ? `1px solid ${shadowColor}` : "none",
  };

  if (image) {
    topContainerStyle.backgroundImage = `url(${image})`;
  }

  return (
    <div onClick={handleChange} style={rootStyles} className={classes.root}>
      <div style={topContainerStyle} className={classes.topContainer}>
        <div
          className={classes.imageWrapper}
          style={{ backgroundColor: `rgba(35, 31, 32, 0.5)` }}
        >
          <Title title={title} price={price} priceOld={priceOld} />
          <div className={classes.zoneList}>
            <ZoneList availableZones={availableZones} selectedZones={zones} />
          </div>
        </div>
      </div>
      <div className={classes.middleContainer}>
        {captions.map((caption, idx) => (
          <Caption key={idx}>{caption}</Caption>
        ))}
      </div>
      <div className={classes.bottomContainer}>
        {items.map((item, idx) => (
          <PackageItem key={idx}>{item}</PackageItem>
        ))}
        <div className={classes.extraFeesContainer}>
          {extraFeeItems.map((item, idx) => (
            <ExtraFeeItem color={color} key={idx}>
              {item}
            </ExtraFeeItem>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(PackageCard);
