import React from "react";
import { withStyles, Typography, Button } from "@material-ui/core";
import BonusCardImg from "../resources/img/bonusova-karta-1.jpg";
import { PillTitle } from "../components/Typography";
import SimpleLink from "./SimpleLink";
import { ArrowRight } from "./SvgIcon";
import { HashLink as Link } from "react-router-hash-link";
import { scrollToElement } from "../helpers";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../i18n/messages";

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: 16,
    display: "flex",
    padding: 0,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    height: 326,
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
  imgSection: {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${BonusCardImg})`,
    width: "50%",
    borderRadius: "0 16px 16px 0",
  },
  contentSection: {
    width: "50%",
    height: "100%",
    background: "#fff",
    borderRadius: "16px 0px 0px 16px",
    textAlign: "center",
    position: "relative",
  },
  textContainer: {
    padding: 32,
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    width: "90%",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
  },
  text: {
    fontSize: 24,
    color: "#555555",
    fontFamily: "lato",
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  ctaButton: {
    margin: "auto",
  },
  link: {
    position: "absolute",
    bottom: 10,
    margin: "auto",
    left: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: 5,
      lineHeight: "10px",
    },
  },
});

const BonusCard = ({
  classes,
  color = "#000",
  text,
  intl: { formatMessage },
}) => (
  <div className={classes.root}>
    <div className={classes.contentSection}>
      <div style={{ padding: "14px 16px 8px 16px" }}>
        <PillTitle color="#231f20">
          <FormattedMessage
            id="generalmessage.bonuscard.title"
            defaultMessage="S BONUSOVOU KARTOU"
          />
        </PillTitle>
      </div>

      <div className={classes.textContainer}>
        <Typography className={classes.text} gutterBottom>
          {text}
        </Typography>
      </div>

      <Link
        to={`/${formatMessage(generalMessages.linkPricing)}#${formatMessage(
          generalMessages.linkBonusCard
        )}`}
        scroll={(el) => scrollToElement(el)}
        style={{ textDecoration: "none" }}
      >
        <SimpleLink
          alwaysDisplayText
          responsive
          additionalClassses={classes.link}
          svg={<ArrowRight color={color} />}
          fontColor={color}
        >
          <FormattedMessage
            id="bonuscard.wantedlink.title"
            defaultMessage="CHCEM JU ZÍSKAŤ"
          />
        </SimpleLink>
      </Link>
    </div>
    <div className={classes.imgSection} />
  </div>
);

export default injectIntl(withStyles(styles)(BonusCard));
