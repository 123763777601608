"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grid = void 0;
const React = __importStar(require("react"));
const richtext_utils_1 = require("./richtext-utils");
const getSpacingStyles = ({ props }) => {
    const { alignItems = "self-start", display = "grid", columnGap, gap = 4, height, justifyContent = "start", justifyItems = "stretch", margin, marginTop, marginRight, marginBottom, marginLeft, padding, paddingTop, paddingRight, paddingBottom, paddingLeft, rowGap, width = "100%" } = props, restProps = __rest(props, ["alignItems", "display", "columnGap", "gap", "height", "justifyContent", "justifyItems", "margin", "marginTop", "marginRight", "marginBottom", "marginLeft", "padding", "paddingTop", "paddingRight", "paddingBottom", "paddingLeft", "rowGap", "width"]);
    return Object.assign({ alignItems,
        display, columnGap: typeof columnGap === "number"
            ? (0, richtext_utils_1.spacing)(columnGap)
            : typeof gap === "number"
                ? (0, richtext_utils_1.spacing)(gap)
                : gap, gap: typeof gap === "number" ? (0, richtext_utils_1.spacing)(gap) : gap, height: typeof height === "number" ? (0, richtext_utils_1.spacing)(height) : height, justifyContent,
        justifyItems, margin: typeof margin === "number" ? (0, richtext_utils_1.spacing)(margin) : margin, marginTop: typeof marginTop === "number" ? (0, richtext_utils_1.spacing)(marginTop) : marginTop, marginRight: typeof marginRight === "number" ? (0, richtext_utils_1.spacing)(marginRight) : marginRight, marginBottom: typeof marginBottom === "number" ? (0, richtext_utils_1.spacing)(marginBottom) : marginBottom, marginLeft: typeof marginLeft === "number" ? (0, richtext_utils_1.spacing)(marginLeft) : marginLeft, padding: typeof padding === "number" ? (0, richtext_utils_1.spacing)(padding) : padding, paddingTop: typeof paddingTop === "number" ? (0, richtext_utils_1.spacing)(paddingTop) : paddingTop, paddingRight: typeof paddingRight === "number" ? (0, richtext_utils_1.spacing)(paddingRight) : paddingRight, paddingBottom: typeof paddingBottom === "number"
            ? (0, richtext_utils_1.spacing)(paddingBottom)
            : paddingBottom, paddingLeft: typeof paddingLeft === "number" ? (0, richtext_utils_1.spacing)(paddingLeft) : paddingLeft, rowGap: typeof rowGap === "number"
            ? (0, richtext_utils_1.spacing)(rowGap)
            : typeof gap === "number"
                ? (0, richtext_utils_1.spacing)(gap)
                : gap, width: typeof width === "number" ? (0, richtext_utils_1.spacing)(width) : width }, restProps);
};
const getScreenSizeStyles = ({ size, props }) => {
    if (!props || !size)
        return {};
    if (size === "xl")
        return {
            [richtext_utils_1.breakpoints.up("lg")]: getSpacingStyles({ props }),
        };
    const media = richtext_utils_1.breakpoints.down(size);
    return {
        [media]: getSpacingStyles({ props }),
    };
};
const Base = ({ xs, xl, lg, md, sm, sx, children }) => {
    return (React.createElement("div", { style: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, getSpacingStyles({ props: sx !== null && sx !== void 0 ? sx : {} })), getScreenSizeStyles({ size: "xl", props: xl })), getScreenSizeStyles({ size: "lg", props: lg })), getScreenSizeStyles({ size: "md", props: md })), getScreenSizeStyles({ size: "sm", props: sm })), getScreenSizeStyles({ size: "xs", props: xs })) }, children));
};
const Grid = ({ sx, xs, sm, md, lg, xl, children }) => {
    return (React.createElement(Base, { sx: sx, xs: xs, sm: sm, md: md, lg: lg, xl: xl }, children));
};
exports.Grid = Grid;
