import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { rectangleEffect } from "../../helpers";
import DetailView from "../DetailView/DetailView";

const styles = (theme) => ({
  root: {
    padding: 8,
  },
  grid: {
    "& > div": {
      padding: "16px !important",
      [theme.breakpoints.down("sm")]: {
        padding: "4px !important",
      },
    },
  },
});

class ColumnGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: props.slideIndex || 0,
      fullscreenViewIdx: 0,
      rectangle: null,
    };
  }

  toggleFullscreen = (e, idx) => {
    const rectangle = e.currentTarget.getBoundingClientRect();
    this.setState({
      rectangle,
      fullscreenViewIdx: idx,
    });
  };

  handleOnClose = () => {
    this.setState({
      rectangle: null,
    });
  };

  rectangleEffect = (rectangleBox, callback) => {
    const bodyRectangle = document.body.getBoundingClientRect();
    const el = document.createElement("div");

    el.style.width = `${rectangleBox.width}px`;
    el.style.overflow = "hidden";
    el.style.position = "absolute";
    el.style.backgroundImage = "linear-gradient(to right, #f6f6f6, #b9b9b9)";
    el.style.borderRadius = "16px";
    el.style.height = `${rectangleBox.height}px`;
    el.style.left = `${rectangleBox.left}px`;
    el.style.top = `${Math.abs(bodyRectangle.top) + rectangleBox.top}px`;
    el.style.transition = `all ${this.ANIMATION_DURATION}ms cubic-bezier(.4,.2,0,1)`;
    document.body.appendChild(el);

    window.getComputedStyle(el).left;
    window.getComputedStyle(el).width;
    window.getComputedStyle(el).height;
    window.getComputedStyle(el).top;

    el.style.top = `${Math.abs(bodyRectangle.top)}px`;
    el.style.left = "0px";
    el.style.width = "100%";
    el.style.height = "100%";
    el.style.borderRadius = "0";

    setTimeout(function() {
      document.body.removeChild(el);
      callback();
    }, this.ANIMATION_DURATION);
  };

  render = () => {
    const {
      classes,
      cards,
      nextSectionLink,
      fullScreenMode = true,
      showNextSectionButton = true,
      resource = null,
      resourceId = 0,
    } = this.props;

    return (
      <div className={classes.root}>
        {resourceId || (this.state.rectangle && fullScreenMode) ? (
          <DetailView
            nextSectionLink={nextSectionLink}
            showNextSectionButton={showNextSectionButton}
            resource={resource}
            resourceId={resourceId}
            activeItemId={resourceId || this.state.fullscreenViewIdx}
            items={this.props.items || []}
            cards={this.props.cards || []}
            views={this.props.views || []}
            nextSection={this.props.nextSection}
            handleOnClose={this.handleOnClose}
            rectangleBox={this.state.rectangle}
          />
        ) : null}
        <Grid container justify="center" spacing={16} className={classes.grid}>
          {cards.map((card, idx) =>
            card && idx < 3 ? (
              <Grid key={idx} item xs={4} sm={4} md={4} lg={4} xl={4}>
                {React.cloneElement(card, {
                  handleOnClick: (e) => {
                    const rectangle = e.currentTarget.getBoundingClientRect();
                    rectangleEffect(true, rectangle, card.props.handleOnClick);
                  },
                })}
              </Grid>
            ) : null
          )}
        </Grid>
      </div>
    );
  };
}

export default withStyles(styles)(ColumnGrid);
