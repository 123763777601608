import React from "react";
import { withStyles } from "@material-ui/core";
import HeadingCard from "../../components/DetailView/HeadingCard";
import BonusCard from "../../components/BonusCardSection";
import RichTextField from "../../pages/rich-text-field/RichTextField";

const styles = (theme) => ({
  textContainer: {
    marginTop: 32,
    padding: 0,
    borderRadius: 16,
    maxWidth: 920,
    [theme.breakpoints.down("lg")]: {
      maxWidth: 700,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    textAlign: "justify",
    margin: "auto",
  },
  root: {
    paddingBottom: 64,
  },
});

const PostView = ({
  classes,
  text,
  title = "",
  handleClose,
  date = null,
  cover = null,
  closeLink,
}) => (
  <div className={classes.root}>
    <HeadingCard
      closeLink={closeLink}
      handleClose={handleClose}
      height="350px"
      title={title}
      date={date}
      bgImage={cover}
    />

    <RichTextField
      textStyles={{ textAlign: "inherit", fontSize: "inherit" }}
      classes={{ root: classes.textContainer }}
    >
      {text}
    </RichTextField>

    <BonusCard />
  </div>
);

export default withStyles(styles)(PostView);
