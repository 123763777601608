import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Carousel from "nuka-carousel";
import withSizes from "react-sizes";
import RightArrowImg from "../../resources/img/arrow-right.svg";
import LeftArrowImg from "../../resources/img/arrow-left.svg";
import { rectangleEffect } from "../../helpers";

const styles = (theme) => ({
  root: {
    position: "relative",
    "& > .slider > .slider-frame": {
      padding: "24px 0 50px 0 !important",
      [theme.breakpoints.down("sm")]: {
        padding: "14px 0 45px 0 !important",
      },
      "& > .slider-list": {
        [theme.breakpoints.down("sm")]: {
          left: "-18%",
        },
      },
    },
  },
  card: {
    // margin: "10px",
    borderRadius: 16,
    opacity: 0.3,
    backgroundImage: "linear-gradient(159deg, #260018, #510133)",
    boxSizing: "border-box",
    textAlign: "center",
    color: "#fff",
    paddingTop: "50%",
    textDecoration: "none",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    height: "350px",
    "& button": {
      margin: "auto",
      display: "none",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
    },
    "&:hover": {
      transition: "all 0.2s linear",
      position: "absolute",
      top: "-5px",
      left: "-5px",
      bottom: "-5px",
      right: "-5px",
      height: "360px",
      "& button": {
        display: "block",
      },
    },
  },
  leftArrowIcon: {
    position: "absolute",
    left: "15%",
    top: 0,
    bottom: 0,
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 40,
    },
  },
  rightArrowIcon: {
    position: "absolute",
    right: "15%",
    top: 0,
    bottom: 0,
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 40,
    },
  },
  shadowLeft: {
    position: "absolute",
    top: 24,
    bottom: 50,
    left: 0,
    width: "10%",
    backgroundImage: "linear-gradient(to left, rgba(0, 0, 0, 0), #231f20)",
    [theme.breakpoints.down("sm")]: {
      top: 14,
      bottom: 45,
    },
  },
  shadowRight: {
    position: "absolute",
    top: 24,
    bottom: 50,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      top: 14,
      bottom: 45,
    },
    width: "10%",
    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), #231f20)",
  },
});

const ANIMATION_DURATION = 300;

class FullscreenCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: props.slideIndex || 0,
      slidesToShow: 4,
      framePadding: 32,
      transitionMode: "scroll",
      withoutControls: true,
      keyboardControls: false,
      fullscreenViewIdx: 0,
      rectangle: null,
    };
  }

  toggleFullscreen = (e, idx) => {
    const rectangle = e.currentTarget.getBoundingClientRect();
    this.setState({
      rectangle,
      fullscreenViewIdx: idx,
    });
  };

  handleOnClose = () => {
    this.setState({
      rectangle: null,
    });
  };

  componentDidMount = () => {
    const { cards = [], cellAlign = "left" } = this.props;

    let element = null;

    if (cellAlign === "left") {
      this.carousel.frame.firstChild.style.marginLeft = "20px";
    }

    if (cards.length < 3 && cards.length % 2 === 0) {
      element = this.carousel && this.carousel.frame.firstChild;
    }

    if (element) {
      function change1(e) {
        if (e && e.matches) element.style.left = "-16%";
        else element.style.left = "-13%";
      }

      const mq = window.matchMedia("(max-width: 960px)");

      change1(mq);

      mq && mq.addListener(change1);
    }
    // setTimeout(() => {
    //     this.carousel.setSlideHeightAndWidth();
    //     this.carousel.setDimensions();
    // }, 600)

    // this.setState({ slideIndex: 0 });
  };

  rectangleEffect = (rectangleBox, callback) => {
    const bodyRectangle = document.body.getBoundingClientRect();
    const el = document.createElement("div");

    el.style.width = `${rectangleBox.width}px`;
    el.style.overflow = "hidden";
    el.style.position = "absolute";
    el.style.backgroundImage = "linear-gradient(to right, #f6f6f6, #b9b9b9)";
    el.style.borderRadius = "16px";
    el.style.height = `${rectangleBox.height}px`;
    el.style.left = `${rectangleBox.left}px`;
    el.style.top = `${Math.abs(bodyRectangle.top) + rectangleBox.top}px`;
    el.style.transition = `all ${this.ANIMATION_DURATION}ms cubic-bezier(.4,.2,0,1)`;
    document.body.appendChild(el);

    window.getComputedStyle(el).left;
    window.getComputedStyle(el).width;
    window.getComputedStyle(el).height;
    window.getComputedStyle(el).top;

    el.style.top = `${Math.abs(bodyRectangle.top)}px`;
    el.style.left = "0px";
    el.style.width = "100%";
    el.style.height = "100%";
    el.style.borderRadius = "0";

    setTimeout(function() {
      document.body.removeChild(el);
      callback();
    }, this.ANIMATION_DURATION);
  };

  render = () => {
    const {
      isMobile,
      isLarge,
      isMedium,
      isTablet,
      isUltra,
      lazyLoading,
      classes,
      cardWidth = 0.95,
    } = this.props;

    let numSlides = 4;
    if (isMobile) numSlides = 3;
    else if (isTablet) numSlides = 3;
    else if (isMedium || isLarge) numSlides = 4;
    else if (isUltra) numSlides = 4;

    let cellSpacing = 32;

    if (isMobile) cellSpacing = 8;
    if (isTablet) cellSpacing = 16;

    let cards = null;
    if (lazyLoading) {
      cards = this.props.cards.map((card, key) => {
        if (
          key <= this.state.slideIndex + 4 ||
          key >= this.props.cards.length - this.state.slideIndex - 3
        ) {
          return React.cloneElement(card, {
            handleOnClick: (e) => {
              const rectangle = e.currentTarget.getBoundingClientRect();
              rectangleEffect(true, rectangle, card.props.handleOnClick);
            },
          });
        }
      });
    } else {
      cards = this.props.cards.map((card, key) => {
        return React.cloneElement(card, {
          handleOnClick: (e) => {
            const rectangle = e.currentTarget.getBoundingClientRect();
            rectangleEffect(true, rectangle, card.props.handleOnClick);
          },
        });
      });
    }

    return (
      <div className={classes.root}>
        <Carousel
          ref={(ref) => (this.carousel = ref)}
          slidesToShow={numSlides}
          cellAlign={this.props.cellAlign || "left"}
          withoutControls={this.state.withoutControls}
          afterSlide={(idx) => {
            this.setState({ slideIndex: idx });
          }}
          transitionMode={this.state.transitionMode}
          slideWidth={cardWidth}
          disableKeyboardControls={true}
          cellSpacing={cellSpacing}
          wrapAround={this.props.wrapAround || false}
          swiping={true}
          slideIndex={this.state.slideIndex}
          heightMode={this.state.heightMode}
          speed={300}
          easing="easeLinear"
          edgeEasing="easeLinear"
        >
          {cards}
        </Carousel>
        {this.props.cards.length > 4 && [
          this.props.cellAlign === "left" &&
          this.state.slideIndex === 0 ? null : (
            <div key="left" className={classes.shadowLeft}>
              <img
                className={this.props.classes.leftArrowIcon}
                src={LeftArrowImg}
                onClick={() =>
                  this.setState({ slideIndex: this.state.slideIndex - 1 })
                }
                alt="Left arrow"
              />
            </div>
          ),
          this.props.cellAlign === "left" &&
          this.state.slideIndex >= this.props.cards.length - 4 ? null : (
            <div key="right" className={classes.shadowRight}>
              <img
                className={this.props.classes.rightArrowIcon}
                src={RightArrowImg}
                onClick={() =>
                  this.setState({ slideIndex: this.state.slideIndex + 1 })
                }
                alt="Right arrow"
              />
            </div>
          ),
        ]}
      </div>
    );
  };
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
  isTablet: width >= 768 && width < 960,
  isMedium: width >= 960 && width < 1200,
  isLarge: width >= 1200 && width < 1640,
  isUltra: width >= 1640,
});

export default withSizes(mapSizesToProps)(
  withStyles(styles)(FullscreenCarousel)
);
