import React from "react";
import { Route, Switch } from "react-router";
import routes from "../routes";
import Navbar from "./Navigation/Navbar";
import { withStyles } from "@material-ui/core/styles";
import Footer from "./Footer/Footer";
import { IntlProvider } from "react-intl";
import translations from "../i18n/locales";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import sk from "react-intl/locale-data/sk";
import pl from "react-intl/locale-data/pl";
import { getLang } from "../helpers";
import EuCookies from "./EuCookies";
import GlobalCss from "./GlobalCss";
const ReactGA = process.browser ? require("react-ga") : {};
const ReactPixel = process.browser
  ? require("react-facebook-pixel").default
  : {};

addLocaleData([...en, ...sk, ...pl]);

const styles = (theme) => ({
  root: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 72,
    },
    backgroundRepeat: "no-repeat",
    backgroundImage: "linear-gradient(to right, #f6f6f6, #b9b9b9)",
  },
  content: {
    minHeight: 500,
  },
});

if (process.env.NODE_ENV === "production" && process.browser) {
  const lang = getLang();
  const gaCode = lang === "sk" ? "UA-102452161-10" : "UA-102452161-11";
  const pixelCode = lang === "sk" ? "531608643910724" : "600720190427604";
  ReactGA.initialize(gaCode);
  ReactPixel.init(pixelCode);
}

function logPageView() {
  if (process.env.NODE_ENV === "production" && process.browser) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
    ReactPixel.pageView();
  }

  return null;
}

const Layout = ({ classes, lang = "sk" }) => (
  <IntlProvider locale={lang} messages={translations[lang]}>
    <div className={classes.root}>
      <GlobalCss />
      <Navbar />
      <Route path="/" component={logPageView} />
      <div className={classes.content}>
        <Switch>
          {routes.map((route) => (
            <Route key={`route-${route.name}`} {...route} />
          ))}
        </Switch>
      </div>
      <Footer />
      {lang === "sk" && <EuCookies />}
    </div>
  </IntlProvider>
);

export default withStyles(styles)(Layout);
