/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from "react";
import CloseIcon from "../resources/img/cancel.svg";
import { HashLink as Link } from "react-router-hash-link";
import { withStyles } from "@material-ui/core";
import { getImage } from "../helpers";
import { parse } from "query-string";
import { CustomHeading } from "../components/Typography";
import { scrollToElement } from "../helpers";

const styles = (theme) => ({
  root: {
    backgroundImage: "linear-gradient(to right, #f6f6f6, #b9b9b9)",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    left: 0,
    right: 0,
    overflow: "auto",
  },
  heading: {
    position: "absolute",
    paddingTop: 10,
    top: 0,
    height: 100,
    width: "100%",
  },
  container: {
    position: "absolute",
    top: 100,
    bottom: 0,
    width: "100%",
    textAlign: "center",
  },
  image: {
    height: "90%",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      maxWidth: "90vw",
      maxHeight: "90vh",
    },
    width: "auto",
    borderRadius: 16,
  },
  fullsizeImage: {
    borderRadius: 16,
    marginBottom: 16,
    height: "auto",
    margin: "auto",
    width: "85%",
    maxWidth: 1224,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  closeButtonContainer: {
    cursor: "pointer",
    position: "absolute",
    top: 24,
    right: 24,
    zIndex: 99999,
    height: 50,
    width: 50,
    "& > a": {
      height: 50,
      width: 50,
      display: "block",
    },
  },
  closeButton: {
    cursor: "pointer",
    height: 50,
    width: 50,
  },
});

const VirtualTour = ({
  classes,
  returnLink,
  image,
  handleClose,
  match,
  location,
}) => {
  useEffect(() => {
    window.document.body.style.overflow = "hidden";
    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, []);

  const { name: specialMenuName, mode } = parse(location.search);

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <CustomHeading>{specialMenuName}</CustomHeading>
        <div className={classes.closeButtonContainer}>
          {returnLink ? (
            <Link to={returnLink} scroll={(el) => scrollToElement(el)}>
              <img
                className={classes.closeButton}
                src={CloseIcon}
                alt="Close icon"
              />
            </Link>
          ) : (
            <img
              className={classes.closeButton}
              onClick={handleClose}
              src={CloseIcon}
              alt="Close icon"
            />
          )}
        </div>
      </div>
      <div className={classes.container}>
        <img
          alt="Fullscreen"
          className={
            mode === "fullsize" ? classes.fullsizeImage : classes.image
          }
          src={image || getImage(match.params.image)}
          alt="Fullscreen image"
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(VirtualTour);
