import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import CheckmarkImage from "../../resources/img/checkmark-silver.svg";
import dateFormat from "dateformat";
import { hexToRgba } from "../../helpers";
import { CustomHeading } from "../Typography";
import CloseButton from "./CloseButton";

const styles = (theme) => ({
  root: {
    width: "100%",
    position: "relative",
    padding: "32px",
    borderRadius: 16,
    textAlign: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  categoryTag: {
    border: "1px solid red",
    display: "inline-block",
    padding: "7px 10px",
    borderRadius: "5px",
    marginRight: "5px",
    color: "#fff",
  },
  title: {
    color: "#fff",
  },
  captionsContainer: {
    fontFamily: "TypoComica",
    color: "#fff",
    margin: "auto",
    width: "100%",
    fontSize: "1.2rem",
    marginTop: "40px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  caption: {
    fontFamily: "TypoComica",
    display: "inline-block",
    fontSize: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
    color: "#fff",
    width: "50%",
    "& > div": {
      display: "inline-block",
    },
    "& > img": {
      marginRight: 10,
      [theme.breakpoints.down("md")]: {
        height: 16,
        width: 18,
      },
    },
  },
  categoriesContainer: {
    float: "left",
  },
  textContainer: {
    width: "90%",
    padding: 10,
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  date: {
    color: "#ddd",
    fontSize: "1.2rem",
    padding: 10,
    fontFamily: "TypoComica",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  headingTitle: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  headerIcon: {
    width: 128,
    height: 128,
    borderRadius: 10,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      height: 120,
      width: 120,
    },
    [theme.breakpoints.down("md")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      width: 50,
    },
  },
});

const HeadingCard = ({
  classes,
  title = "",
  bgImage = null,
  icon = null,
  captions = [],
  closeLink = "/",
  categories = [],
  closeButton = true,
  handleClose = () => {},
  date = null,
  primaryColor = "#231f20",
  fullscreen,
}) => {
  const shadowColor = hexToRgba(primaryColor, 0.16);
  //const cardColor = hexToRgba(primaryColor, 0.24);
  const cardColor = "rgba(35, 31, 32, 0.5)";

  const rootStyle = {
    backgroundImage: bgImage ? `url(${bgImage})` : "none",
    boxShadow: shadowColor
      ? `0 16px 48px 0 ${shadowColor}`
      : "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
  };

  const contentStyle = {
    backgroundColor: `rgba(35, 31, 32, 0.5)`,
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderRadius: 16,
  };

  if (fullscreen) {
    rootStyle.height =
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0) -
      160;
  }

  return (
    <div className={classes.root} style={rootStyle}>
      <div className={classes.content} style={contentStyle}>
        <div className={classes.categoriesContainer}>
          {categories.map((category, idx) => (
            <div key={idx} className={classes.categoryTag}>
              {category}
            </div>
          ))}
        </div>

        <div className={classes.textContainer}>
          {date && (
            <Typography className={classes.date} variant="caption">
              {dateFormat(date, "dddd, d. mmmm yyyy")}
            </Typography>
          )}
          {icon && <img className={classes.headerIcon} src={icon} alt="icon" />}
          <CustomHeading
            textShadow="0 4px 16px rgba(0, 0, 0, 0.5)"
            additionalStyles={classes.headingTitle}
            color="#fff"
          >
            {title.toUpperCase()}
          </CustomHeading>
          <div className={classes.captionsContainer}>
            {captions.map((description, idx) => (
              <div className={classes.caption} key={idx}>
                <img src={CheckmarkImage} alt="checkmark" />
                {description}
              </div>
            ))}
          </div>
        </div>
        {closeButton && (
          <CloseButton handleClose={handleClose} href={closeLink} />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(HeadingCard);
