import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import BlackLogo from "../../resources/img/logo-black.png";
import BlackLogoInt from "../../resources/img/logo-black-int.png";
import Social from "../Social";
import ListItem from "./FooterListItem";
import NewsletterSubscription from "./NewsletterSubscription";
import { FooterTitle } from "../Typography";
import { Query } from "@apollo/react-components";
import withSizes from "react-sizes";
import { NavLink as Link } from "react-router-dom";
import gql from "graphql-tag";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../i18n/messages";
import { getLang } from "../../helpers";
import Copyrights from "./Copyrights";

const QUERY_FOOTER_INFO = gql`
  query PageFooter {
    Zones: Zones {
      id
      code
      title
      primaryColor
    }
    Restaurant: Restaurant {
      speciality
      DayMenu {
        storage_name
      }
      Menu {
        storage_name
      }
    }
    Pages: Pages {
      title
      footer_region
      code
    }
  }
`;

const styles = (theme) => ({
  root: {},
  footerContent: {
    backgroundImage: "linear-gradient(to right, #ffffff, #f7f7f7)",
    padding: "64px 64px",
    [theme.breakpoints.down("xs")]: {
      padding: "32px",
    },
  },
  grid: {
    maxWidth: 1524,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      margin: "-16px",
    },
  },
  list: {
    listStyle: "none",
    color: "#777777",
    lineHeight: 2,
    paddingLeft: "0px",
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  logo: {
    maxWidth: "180px",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "32px auto",
    },
  },
  copyrightsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  links: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  logoContainer: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  twoColumnsList: {
    listStyle: "none",
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    "& > div": {
      paddingLeft: 16,
      paddingRight: 16,
      width: "50%",
    },
  },
});

const mapSizesToProps = ({ width }) => ({
  isTablet: width < 960,
  isLarge: width > 1640,
});

const Footer = injectIntl(
  withSizes(mapSizesToProps)(
    withStyles(styles)(
      ({
        classes,
        intl: { formatMessage },
        isTablet,
        zones = [],
        restaurant = {},
        pages = [],
      }) => (
        <footer className={classes.root}>
          <div className={classes.footerContent}>
            <Grid container className={classes.grid} spacing={32}>
              <Grid className={classes.links} item xs={6} md={3}>
                <FooterTitle link={`/#${formatMessage(messages.linkZones)}`}>
                  <FormattedMessage
                    id="footer.zones.title"
                    defaultMessage="ZAŽI AJ TY"
                  />
                </FooterTitle>
                <ul className={classes.list}>
                  {zones.map((zone) => (
                    <ListItem
                      key={zone.id}
                      link={`/${formatMessage(messages.linkZone)}/${zone.code}`}
                      bulletColor={zone.primaryColor}
                    >
                      {zone.title && zone.title.toUpperCase()}
                    </ListItem>
                  ))}
                </ul>
              </Grid>
              <Grid className={classes.links} item xs={6} md={3}>
                {/* <FooterTitle link={`/#${formatMessage(messages.linkEvents)}`}>
                  <FormattedMessage
                    id="footer.events.title"
                    defaultMessage="AKCIE A EVENTY"
                  />
                </FooterTitle> */}
                <FooterTitle
                  link={`/#${formatMessage(messages.linkRestaurant)}`}
                >
                  <FormattedMessage
                    id="footer.restaurant.title"
                    defaultMessage="REŠTAURÁCIA"
                  />
                </FooterTitle>
                <ul className={classes.list}>
                  {restaurant &&
                    restaurant.DayMenuImage &&
                    data.DayMenuImage.storage_name && (
                      <ListItem
                        link={`/#${formatMessage(messages.linkRestaurant)}`}
                        bulletColor="#F48035"
                      >
                        <FormattedMessage
                          id="footer.restaurant.lunchmenu"
                          defaultMessage="OBEDOVÉ MENU"
                        />
                      </ListItem>
                    )}
                  {restaurant && restaurant.speciality && (
                    <ListItem
                      link={`/#${formatMessage(messages.linkRestaurant)}`}
                      bulletColor="#FEC723"
                    >
                      <FormattedMessage
                        id="footer.restaurant.speciality"
                        defaultMessage="ROZVOZ MENU"
                      />
                    </ListItem>
                  )}
                  <ListItem
                    link={`/#${formatMessage(messages.linkRestaurant)}`}
                    bulletColor="#3FB74F"
                  >
                    <FormattedMessage
                      id="footer.restaurant.menu"
                      defaultMessage="JEDÁLNY LÍSTOK"
                    />
                  </ListItem>
                </ul>
                <FooterTitle
                  link={`/#${formatMessage(messages.linkReservation)}`}
                >
                  <FormattedMessage
                    id="footer.reservation.link"
                    defaultMessage="E-SHOP"
                  />
                </FooterTitle>
                <ul className={classes.list}>
                  <ListItem
                    link={`/${formatMessage(
                      messages.linkReservation
                    )}/${formatMessage(messages.linkBowling)}`}
                    bulletColor="#34B045"
                  >
                    <FormattedMessage
                      id="footer.reservation.bowling"
                      defaultMessage="BOWLING"
                    />
                  </ListItem>
                  <ListItem
                    link={`/${formatMessage(
                      messages.linkReservation
                    )}/${formatMessage(messages.linkParty)}`}
                    bulletColor="#E3BE37"
                  >
                    <FormattedMessage
                      id="footer.reservation.party"
                      defaultMessage="PÁRTY IZBA"
                    />
                  </ListItem>
                </ul>
              </Grid>
              {isTablet ? (
                <div className={classes.twoColumnsList}>
                  <div>
                    <FooterTitle link={`/${formatMessage(messages.linkBlog)}`}>
                      <FormattedMessage
                        id="footer.blog.link"
                        defaultMessage="AKTUALITY"
                      />
                    </FooterTitle>
                  </div>
                  <div>
                    <FooterTitle
                      link={`/${formatMessage(messages.linkPricing)}`}
                    >
                      <FormattedMessage
                        id="footer.pricing.link"
                        defaultMessage="CENNÍK"
                      />
                    </FooterTitle>
                  </div>
                  <div>
                    <FooterTitle
                      link={`/${formatMessage(messages.linkGallery)}`}
                    >
                      <FormattedMessage
                        id="footer.gallery.link"
                        defaultMessage="GALÉRIA"
                      />
                    </FooterTitle>
                  </div>
                  <div>
                    <FooterTitle
                      link={`/${formatMessage(messages.linkContact)}`}
                    >
                      <FormattedMessage
                        id="footer.contact.link"
                        defaultMessage="KONTAKTY"
                      />
                    </FooterTitle>
                  </div>
                  {pages.map((page, idx) => (
                    <div key={idx}>
                      <FooterTitle key={idx} link={`/${page.code}`}>
                        {page.title && page.title.toUpperCase()}
                      </FooterTitle>
                    </div>
                  ))}
                </div>
              ) : (
                <Grid className={classes.links} item xs={6} md={3}>
                  <FooterTitle link={`/${formatMessage(messages.linkBlog)}`}>
                    <FormattedMessage
                      id="footer.blog.link2"
                      defaultMessage="AKTUALITY"
                    />
                  </FooterTitle>
                  <FooterTitle link={`/${formatMessage(messages.linkPricing)}`}>
                    <FormattedMessage
                      id="footer.pricing.link2"
                      defaultMessage="CENNÍK"
                    />
                  </FooterTitle>
                  <FooterTitle link={`/${formatMessage(messages.linkGallery)}`}>
                    <FormattedMessage
                      id="footer.gallery.link2"
                      defaultMessage="GALÉRIA"
                    />
                  </FooterTitle>
                  <FooterTitle link={`/${formatMessage(messages.linkContact)}`}>
                    <FormattedMessage
                      id="footer.contact.link2"
                      defaultMessage="KONTAKTY"
                    />
                  </FooterTitle>
                  {pages.map((page, idx) => (
                    <FooterTitle key={idx} link={`/${page.code}`}>
                      {page.title && page.title.toUpperCase()}
                    </FooterTitle>
                  ))}
                </Grid>
              )}
              <Grid item xs={12} md={3} lg={3}>
                <NewsletterSubscription />
              </Grid>
            </Grid>
            <Grid container className={classes.grid} spacing={32}>
              <Grid
                className={classes.logoContainer}
                item
                xs={12}
                sm={4}
                md={3}
              >
                <Link to={"/"}>
                  <img
                    src={getLang() === "sk" ? BlackLogo : BlackLogoInt}
                    className={classes.logo}
                    alt="logo"
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={5}
                className={classes.copyrightsContainer}
              >
                <Copyrights />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Social
                  facebookUrl="https://www.facebook.com/kinderlandno.sk/"
                  instagramUrl="https://www.instagram.com/kinderland_no/"
                  youtubeUrl="https://www.youtube.com/channel/UCHejcU8gIDP3OusZhISyvaw"
                />
              </Grid>
            </Grid>
          </div>
        </footer>
      )
    )
  )
);

const FooterWithQuery = () => (
  <Query query={QUERY_FOOTER_INFO}>
    {({ data, loading, error }) => {
      if (loading) return null;
      if (error) return null;

      const zones = data.Zones;
      const restaurant = data.Restaurant;

      const pages = data.Pages.filter((x) => x.footer_region === true);

      return <Footer zones={zones} pages={pages} restaurant={restaurant} />;
    }}
  </Query>
);

export default FooterWithQuery;
