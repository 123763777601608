import React from "react";
import { withStyles, Typography } from "@material-ui/core";

const styles = () => ({
  root: {},
  title: {
    fontFamily: "PaytoneOne",
    fontSize: 20,
    color: "#fff",
    paddingBottom: 8,
  },
  priceContainer: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  priceOld: {
    fontFamily: "TypoComica",
    color: "#fff",
    textDecoration: "line-through",
  },
  price: {
    fontFamily: "TypoComica",
    color: "#fff",
    fontSize: 30,
  },
});

const Title = ({ classes, title = "", priceOld = null, price = null }) => (
  <div className={classes.root}>
    <Typography className={classes.title}>{title}</Typography>
    <div className={classes.priceContainer}>
      {priceOld && <div className={classes.priceOld}>{priceOld}€</div>}
      &nbsp;&nbsp;
      <div className={classes.price}>{price}€</div>
    </div>
  </div>
);

export default withStyles(styles)(Title);
