import React from "react";
import NumberInput from "../../components/Reservation/NumberInput";
import { FormattedMessage } from "react-intl";
import { withStyles, Grid, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    maxWidth: 700,
    margin: "auto"
  },
  error: {
    padding: 32,
    textAlign: "center",
    fontSize: 16,
    color: "red",
    width: "100%"
  },
  text: {
    fontFamily: "lato",
    fontSize: 14,
    padding: 8
  },
  leftCol: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  rightCol: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  errorText: {
    color: "red",
    padding: 16,
    fontFamily: "lato"
  }
});

const StepPersons = ({
  classes,
  adultCount,
  priceText,
  childrenCount,
  handleChange,
  simpleMode,
  errors
}) => (
  <div className={classes.root} spacing={32}>
    {!simpleMode && (
      <Grid container>
        <Grid className={classes.leftCol} item lg={6} md={6} sm={12} xs={12}>
          <Typography className={classes.text}>
            <FormattedMessage
              id="reservation.simple.children.count"
              defaultMessage="POČET DETÍ"
            />
          </Typography>
        </Grid>
        <Grid className={classes.rightCol} lg={6} md={6} sm={12} xs={12}>
          <NumberInput
            value={childrenCount}
            max={5}
            onChange={value => handleChange("childrenCount", value)}
          />
        </Grid>
      </Grid>
    )}
    <Grid container>
      <Grid className={classes.leftCol} item lg={6} md={6} sm={12} xs={12}>
        <Typography className={classes.text}>
          {priceText ||
            (simpleMode ? (
              <FormattedMessage
                id="reservation.simple.ticket.count"
                defaultMessage="POČET LÍSTKOV"
              />
            ) : (
              <FormattedMessage
                id="reservation.simple.adult.count"
                defaultMessage="POČET DOSPELÝCH"
              />
            ))}
        </Typography>
      </Grid>
      <Grid className={classes.rightCol} item lg={6} md={6} sm={12} xs={12}>
        <NumberInput
          value={adultCount}
          max={5}
          onChange={value => handleChange("adultCount", value)}
        />
      </Grid>
      {errors.ticketCount && (
        <div className={classes.error}>{errors.ticketCount}</div>
      )}
    </Grid>
  </div>
);

export default withStyles(styles)(StepPersons);
