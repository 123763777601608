import React, { useEffect } from "react";
import { withStyles, Grid } from "@material-ui/core";
import PillCard from "../../components/PillCard";
import handsIcon from "../../resources/img/hand-3.png";
import { Fade } from "react-reveal";
import { CustomHeading } from "../../components/Typography";
import ContactForm from "./ContactForm";
import ContactTitle from "./ContactTitle";
import CompanyIcon from "../../resources/img/kontakty-firma.svg";
import AddressIcon from "../../resources/img/kontakty-adresa.svg";
import EmailIcon from "../../resources/img/kontakty-email.svg";
import TelIcon from "../../resources/img/kontakty-tel.svg";
import SimpleMap from "./SimpleMap";
import Helmet from "react-helmet";
import HeaderPill from "./HeaderPill";
import ContactHeader from "./ContactHeader";
import { withSizes } from "react-sizes";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import gql from "graphql-tag";
import Content from "../../components/General/Content";
import { useQuery } from "@apollo/react-hooks";
import { compose } from "recompose";
import { getImage } from "../../helpers";

const QUERY = gql`
  query getContacts($web: String!) {
    contacts {
      id
      title
      text
      primaryColor
      captions
      priority
      Image {
        storage_name
      }
    }
    settings(web: $web) {
      id
      key
      type
      value
      title
    }
  }
`;

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${handsIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "90%",
    backgroundPosition: "center 5%",
  },
  pageContent: {
    maxWidth: "1224px",
    margin: "auto",
    paddingTop: 32,
    backgroundImage: `url(${handsIcon})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom -200px",
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "center bottom -150px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center bottom -120px",
    },
  },
  contacts: {
    padding: 32,
    paddingTop: 24,
    marginBottom: "64px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
    },
  },
  info: {
    marginBottom: "64px",
    padding: 32,
    paddingTop: 32,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
    "& ul": {
      padding: 0,
      listStyle: "none",
    },
  },
  mapContainer: {
    overflow: "hidden",
    height: "500px",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    background: "#888",
    width: "100%",
    borderRadius: 16,
    [theme.breakpoints.down("sm")]: {
      height: 350,
    },
    [theme.breakpoints.down("xs")]: {
      height: 250,
    },
  },
  contactFormContainer: {
    textAlign: "center",
    padding: 32,
  },
  contactInfoContainer: {
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  contactInfoList: {
    display: "inline-block",
    margin: "auto",
    textAlign: "left",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    "& > *": {
      fontFamily: "lato",
      fontSize: 14,
    },
    "& > li": {
      paddingBottom: 4,
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
  },
  vrContainer: {
    margin: "16px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      height: 350,
      margin: "0 0 96px 0",
    },
    [theme.breakpoints.down("xs")]: {
      height: 350,
      margin: "0 0 0 0",
    },
    padding: "32px 32px 0px 32px",
  },
  container: {
    margin: "64px 0 0 0",
    [theme.breakpoints.down("sm")]: {
      height: 350,
      margin: "0 0 96px 0",
    },
    [theme.breakpoints.down("xs")]: {
      height: 350,
      margin: "0 0 0 0",
    },
    padding: "32px 32px 96px 32px",
  },
  vrTour: {
    width: "100%",
    height: "100%",
    border: 0,
  },
});

const messages = defineMessages({
  metaTitle: {
    id: "contact.meta.title",
    defaultMessage: "Kinderland - Kontakty",
  },
  metaDescription: {
    id: "contact.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const ContactPage = ({
  classes,
  isTablet,
  intl: { formatMessage, locale = "sk" },
}) => {
  const { data = { contacts: [], settings: [] } } = useQuery(QUERY, {
    variables: { web: `web-${locale}` },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let counter = 0;
  let align = "left";

  const getSetting = (key) => {
    const s = data.settings.find((s) => s.key === key);
    return s ? s.value : null;
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{formatMessage(messages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <Fade>
        <Content>
          <ContactHeader />
          <div className={classes.contacts}>
            <Grid container spacing={24}>
              {data.contacts.map((contact, idx) => {
                if (counter >= 2) {
                  align = align === "left" ? "right" : "left";
                  align = isTablet
                    ? align === "left"
                      ? "right"
                      : "left"
                    : align;
                  counter = 0;
                }

                idx !== 1 && counter++;

                return idx === 0 ? (
                  <Grid key={contact.id} item xs={12} sm={12} md={12} lg={12}>
                    <HeaderPill
                      title={contact.title}
                      color={contact.primaryColor}
                      text={contact.text}
                      float="right"
                      bgImage={
                        contact.Image && getImage(contact.Image.storage_name)
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} key={contact.id}>
                    <PillCard
                      title={contact.title}
                      subtitle={contact.subtitle}
                      float={align}
                      bgImage={
                        contact.Image && getImage(contact.Image.storage_name)
                      }
                      color={contact.primaryColor}
                      text={contact.text}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </div>

          <CustomHeading>
            <FormattedMessage
              id="contact.headerinfo.title"
              defaultMessage="KONTAKTY"
            />
          </CustomHeading>

          <div className={classes.info}>
            <Grid container spacing={16}>
              <Grid item md={3} sm={6} xs={12}>
                <ContactTitle icon={TelIcon} color="#f47521">
                  <FormattedMessage
                    id="contact.phoneinfo.title"
                    defaultMessage="TELEFÓN"
                  />
                </ContactTitle>
                <div className={classes.contactInfoContainer}>
                  <ul className={classes.contactInfoList}>
                    <li>
                      <strong>
                        <FormattedMessage
                          id="contact.infonumber.title"
                          defaultMessage="Informácie:"
                        />
                      </strong>
                      <br />
                      {getSetting("contact.phone.primary")}
                    </li>
                    <li>
                      <strong>
                        <FormattedMessage
                          id="contact.eventsnumber.title"
                          defaultMessage="Oslavy, eventy, akcie:"
                        />
                      </strong>
                      <br />
                      {getSetting("contact.phone.secondary")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <ContactTitle icon={EmailIcon} color="#39b54a">
                  <FormattedMessage
                    id="contact.headeremail.title"
                    defaultMessage="E-MAIL"
                  />
                </ContactTitle>
                <div className={classes.contactInfoContainer}>
                  <ul className={classes.contactInfoList}>
                    <li>
                      <strong>
                        <FormattedMessage
                          id="contact.infoemail.title"
                          defaultMessage="Informácie:"
                        />
                      </strong>
                      <br />
                      {getSetting("contact.mail.info")}
                    </li>
                    <li>
                      <strong>
                        <FormattedMessage
                          id="contact.organizationemail.title"
                          defaultMessage="Organizácie, akcie (manager):"
                        />
                      </strong>
                      <br />
                      {getSetting("contact.mail.manager")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <ContactTitle icon={AddressIcon} color="#F2B912">
                  <FormattedMessage
                    id="contact.addressheader.title"
                    defaultMessage="ADRESA"
                  />
                </ContactTitle>
                <div className={classes.contactInfoContainer}>
                  <ul className={classes.contactInfoList}>
                    <li>
                      {getSetting("contact.address.street")}
                      <br />
                      {getSetting("contact.address.city")}
                      <br />
                      {getSetting("contact.address.sr")}
                    </li>
                  </ul>
                </div>
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <ContactTitle icon={CompanyIcon} color="#3f54a5">
                  <FormattedMessage
                    id="contact.operatoraddress.title"
                    defaultMessage="PREVÁDZKOVATEĽ"
                  />
                </ContactTitle>
                <div className={classes.contactInfoContainer}>
                  <ul className={classes.contactInfoList}>
                    <li>
                      {getSetting("contact.company.name")}
                      <br />
                      {getSetting("contact.company.address")}
                      <br />
                      {getSetting("contact.company.city")}
                      <br />
                      {getSetting("contact.company.ico")}
                      <br />
                      {getSetting("contact.company.ico.dph")}
                      <br />
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={classes.contactFormContainer}>
            <ContactForm />
          </div>

          <div className={classes.vrContainer}>
            <div className={classes.mapContainer}>
              <iframe
                className={classes.vrTour}
                allowFullScreen
                src="https://tourmkr.com/G1O46kEAzd"
              ></iframe>
            </div>
          </div>

          <div className={classes.container}>
            <div className={classes.mapContainer}>
              <SimpleMap />
            </div>
          </div>
        </Content>
      </Fade>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 600,
  isTablet: width >= 600 && width < 960,
  isMedium: width >= 960 && width < 1200,
  isLarge: width >= 1200 && width < 1640,
  isUltra: width >= 1640,
});

export default compose(
  injectIntl,
  withSizes(mapSizesToProps),
  withStyles(styles)
)(ContactPage);
