import React from "react";
import TimeRadio from "../../components/Reservation/TimeRadio";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography, Grid, withStyles } from "@material-ui/core";
import { Query } from "@apollo/react-components";

const styles = (theme) => ({
  root: {
    padding: "0 0 32px 0",
  },
  errorMessage: {
    color: "red",
    padding: "16px 0 0 0",
  },
  dateText: {
    fontSize: "2em",
  },
  dateTextAvailable: {
    color: "#9cc682",
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
  textWrapper: {
    height: "100px",
    margin: "auto",
    position: "absolute",
    top: 0,
    bottom: 0,
  },
  title: {
    margin: "auto",
    marginTop: "5px",
    marginBottom: "8px",
    padding: "5px",
    width: "250px",
    borderBottom: "1px solid #ccc",
  },
  containerCenter: {
    width: "330px",
    margin: "auto",
    textAlign: "left",
  },
  checkboxContainer: {
    width: "250px",
    margin: "auto",
    textAlign: "left",
  },
  column: {
    textAlign: "center",
  },
});

const ReservationTimes = ({
  reservationTimes = [],
  handleChangeTime,
  reservedTimes,
}) => {
  const isChecked = (timeFrom, timeTo) => {
    const find = reservationTimes.find(
      (x) => x.timeFrom === timeFrom && x.timeTo === timeTo
    );
    return find ? true : false;
  };

  const isReserved = (timeFrom, timeTo) => {
    const find = reservedTimes.find(
      (x) => x.timeFrom === timeFrom && x.timeTo === timeTo
    );
    return find ? true : false;
  };

  return (
    <div>
      <TimeRadio
        checked={isChecked("09:00:00", "11:45:00")}
        disabled={isReserved("09:00:00", "11:45:00")}
        handleChange={(checked, timeFrom, timeTo) =>
          handleChangeTime(checked, timeFrom, timeTo)
        }
        reserved={isReserved("09:00:00", "11:45:00")}
        timeFrom={"09:00:00"}
        timeTo={"11:45:00"}
      />
      <TimeRadio
        checked={isChecked("12:00:00", "14:45:00")}
        disabled={isReserved("12:00:00", "14:45:00")}
        handleChange={(checked, timeFrom, timeTo) =>
          handleChangeTime(checked, timeFrom, timeTo)
        }
        reserved={isReserved("12:00:00", "14:45:00")}
        timeFrom={"12:00:00"}
        timeTo={"14:45:00"}
      />
      <TimeRadio
        checked={isChecked("15:00:00", "17:45:00")}
        disabled={isReserved("15:00:00", "17:45:00")}
        handleChange={(checked, timeFrom, timeTo) =>
          handleChangeTime(checked, timeFrom, timeTo)
        }
        reserved={isReserved("15:00:00", "17:45:00")}
        timeFrom={"15:00:00"}
        timeTo={"17:45:00"}
      />
      <TimeRadio
        checked={isChecked("18:00:00", "20:45:00")}
        disabled={isReserved("18:00:00", "20:45:00")}
        handleChange={(checked, timeFrom, timeTo) =>
          handleChangeTime(checked, timeFrom, timeTo)
        }
        reserved={isReserved("18:00:00", "20:45:00")}
        timeFrom={"18:00:00"}
        timeTo={"20:45:00"}
      />
    </div>
  );
};

export default withStyles(styles)(ReservationTimes);
