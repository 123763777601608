import React, { Children } from "react";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    borderRadius: 8,
    border: "1px solid black",
    padding: "4px 16px",
    whiteSpace: "nowrap",
    margin: "4px",
    fontWeight: 500,
    color: "#ffffff",
    fontSize: 9,
    textTransform: "uppercase",
    fontFamily: "lato",
    cursor: "pointer"
  }
});

const Category = ({ color, children, classes }) => (
  <div style={{ borderColor: color }} className={classes.root}>
    {children}
  </div>
);

export default withStyles(styles)(Category);
