import React from "react";
import { withSizes } from "react-sizes";
import Section from "../../components/ZoneSection";
import { Grid } from "@material-ui/core";
import { getImage } from "../../helpers";

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
  isTablet: width >= 768 && width < 960,
  isMedium: width >= 960 && width < 1200,
  isLarge: width >= 1200 && width < 1640,
  isUltra: width >= 1640,
});

const ZoneSectionList = withSizes(mapSizesToProps)(
  ({ sections = [], defaultColor = "#fff", isTablet, isMobile }) => {
    let position = "left";
    let counter = 0;

    return (
      <Grid container spacing={16}>
        {sections.map((section, idx) => {
          if (isTablet || isMobile) {
            position = position === "left" ? "right" : "left";
          } else if (counter > 1) {
            counter = 0;
            position = position === "left" ? "right" : "left";
          }

          counter++;

          return (
            <Grid key={idx} item xs={12} sm={12} md={6}>
              <Section
                title={section.title}
                elements={section.text}
                imgPosition={position}
                bgColor={section.primaryColor || defaultColor}
                image={
                  section.SectionImage &&
                  getImage(section.SectionImage.storage_name)
                }
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }
);

export default ZoneSectionList;
