import { isEmpty, isEmail } from "validator";
import messages from "./messages";

const Validator = ({ data, formatMessage }) => {
  const {
    firstname,
    city,
    zip,
    address,
    surname,
    telephone,
    gdpr,
    email,
    reservationTimes
  } = data;

  const errors = {
    firstname: isEmpty(firstname) ? formatMessage(messages.errorFirstname) : "",
    surname: isEmpty(surname) ? formatMessage(messages.errorSurname) : "",
    city: isEmpty(city) ? formatMessage(messages.errorCity) : "",
    zip: isEmpty(zip) ? formatMessage(messages.errorZip) : "",
    address: isEmpty(address) ? formatMessage(messages.errorAddress) : "",
    telephone: isEmpty(telephone) ? formatMessage(messages.errorTelephone) : "",
    gdpr: !gdpr ? formatMessage(messages.errorGdpr) : "",
    email: !isEmail(email) ? formatMessage(messages.errorEmail) : "",
    reservationTimes:
      reservationTimes.length === 0 ? formatMessage(messages.errorTimes) : ""
  };
  console.log(errors);
  let valid = true;
  Object.keys(errors).forEach(x => {
    if (errors[x] !== "" && x !== "__typename") {
      valid = false;
    }
  });

  return {
    valid,
    errors
  };
};

export default Validator;
