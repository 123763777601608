import React from "react";
import { parseIsoDate } from "./utils";
import DayPicker from "react-day-picker";
import { calendarI18N, getLang } from "../../helpers";

const modifiers = {
  reservation1: {
    color: "#231f20",
    backgroundColor: "#ceecce",
  },
  reservation2: {
    color: "#231f20",
    backgroundColor: "#d2f9cf",
  },
  reservation3: {
    color: "#231f20",
    backgroundColor: "#f0ffea",
  },
  free: {
    color: "#231f20",
    backgroundColor: "#b3d9ab",
  },
  today: {
    color: "#231f20",
    backgroundColor: "#b3d9ab",
  },
  outside: {
    backgroundColor: "none",
  },
  selected: {
    color: "#fff",
    backgroundColor: "#52adcc",
  },
  disabled: {
    color: "#aaa",
    backgroundColor: "none",
  },
};

const lang =
  typeof window !== "undefined" ? getLang(window.location.host) : global.lang;

const Calendar = ({
  month,
  year,
  handleChangeMonth,
  handleDayClick = () => {},
  dateBlackList = [],
  reservationDate = new Date(),
  reservations,
}) => (
  <div
    style={{
      backgroundColor: "#fff",
      display: "inline-block",
      fontFamily: "lato",
      borderRadius: 0,
      boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    }}
  >
    <DayPicker
      locale={lang}
      months={calendarI18N.MONTHS[lang]}
      weekdaysLong={calendarI18N.WEEKDAYS_LONG[lang]}
      weekdaysShort={calendarI18N.WEEKDAYS_SHORT[lang]}
      firstDayOfWeek={calendarI18N.FIRST_DAY_OF_WEEK[lang]}
      labels={calendarI18N.LABELS[lang]}
      disabledDays={[
        ...dateBlackList.map((x) => new Date(x.date)),
        new Date(),
        {
          before: new Date(),
        },
      ]}
      fromMonth={new Date()}
      selectedDays={parseIsoDate(reservationDate)}
      onDayClick={handleDayClick}
      modifiers={{
        today: new Date(),
        free: {
          after: new Date(),
        },
        selected: parseIsoDate(reservationDate),
      }}
      modifiersStyles={modifiers}
    />
  </div>
);

export default Calendar;
