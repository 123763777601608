import React from "react";
import { withStyles } from "@material-ui/core";
import CheckmarkIcon from "../../../resources/img/checkmark-black.svg";

const styles = () => ({
  root: {
    fontFamily: "lato",
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#231f20",
    fontSize: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1px 0",
  },
  checkmark: {
    marginRight: 4,
    height: 12,
    width: 12,
  },
});

const Caption = ({ classes, children }) => (
  <div className={classes.root}>
    <img
      className={classes.checkmark}
      src={CheckmarkIcon}
      alt="checkmark icon"
    />
    {children}
  </div>
);

export default withStyles(styles)(Caption);
