import React, { Component } from "react";
import DetailView from "../../components/DetailView/DetailView";
import { Query } from "@apollo/react-components";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import PostCard from "../../components/Cards/PostCard";
import PostView from "./PostView";
import { getImage, getShortText } from "../../helpers";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";

const POSTS = gql`
  query getPostView($id: Int, $limit: Int) {
    Posts: getPostView(id: $id, limit: $limit) {
      id
      code
      title
      perex_text
      user_id
      cover_image_id
      thumbnail_image_id
      tag_ids
      category_ids
      Thumbnail {
        storage_name
      }
      CoverImage {
        storage_name
      }
      text
      published
      published_date
    }
  }
`;

class PostPage extends Component {
  slideChange = (code) => {
    const {
      history,
      intl: { formatMessage },
    } = this.props;
    history.push(`/${formatMessage(generalMessages.linkBlog)}/${code}`);
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  getCards = (items) => {
    const item = this.props.match.params.id;

    let prev = null;
    let view = null;
    let next = null;

    const isNumber = !isNaN(item);
    const attr = isNumber ? "id" : "code";

    for (let i = 0; i < items.length; i++) {
      if (items[i][attr] == item) {
        view = items[i];
        prev = items[i === 0 ? items.length - 1 : i - 1];
        next = items[i === items.length - 1 ? 0 : i + 1];
      }
    }

    return {
      prev,
      view,
      next,
    };
  };

  render = () => (
    <Query query={POSTS}>
      {({ data, loading, error }) => {
        if (loading) return null;
        if (error) return null;

        const posts = data.Posts;
        const { prev, view, next } = this.getCards(posts);

        if (!prev || !view || !next) {
          this.props.history.push(`/404`);
          return null;
        }

        return (
          <DetailView
            slideIndex={1}
            onSlideChange={this.slideChange}
            prevCard={
              <PostCard
                resourceId={prev.code}
                bgImage={
                  prev.CoverImage && getImage(prev.CoverImage.storage_name)
                }
                date={prev.published_date}
                actionTitle={
                  <FormattedMessage
                    id="post.wholearticlebutton.title"
                    defaultMessage="Prečítať celý článok"
                  />
                }
                title={getShortText(prev.title)}
              />
            }
            activeCard={
              <PostView
                resourceId={view.code}
                closeLink="/aktuality"
                date={view.published_date}
                title={view.title}
                text={view.text}
                cover={
                  view.CoverImage && getImage(view.CoverImage.storage_name)
                }
              />
            }
            nextCard={
              <PostCard
                resourceId={next.code}
                bgImage={
                  next.CoverImage && getImage(next.CoverImage.storage_name)
                }
                date={next.published_date}
                actionTitle={
                  <FormattedMessage
                    id="post.nextarticlebutton.title"
                    defaultMessage="Prečítať celý článok"
                  />
                }
                title={getShortText(next.title)}
              />
            }
          />
        );
      }}
    </Query>
  );
}

export default injectIntl(withRouter(PostPage));
