import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { getMainDefinition } from "apollo-utilities";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  /*
  onError receives a callback in the event a GraphQL or network error occurs.
  This example is a bit contrived, but in the real world, you could connect
  a logging service to the errorLink or perform a specific action in response
  to an error.
  */
  if (graphQLErrors)
    graphQLErrors.map(({ message, location, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${location}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const subscriptionLink = (config = {}) =>
  new WebSocketLink({
    uri:
      process.env.NODE_ENV !== "production"
        ? "ws://localhost:3010/subscriptions"
        : "ws://localhost:3010/subscriptions",
    options: { reconnect: true },
    ...config
  });

export const queryOrMutationLink = (config = {}) => {
  // turn on CDN support via GET
  return new HttpLink({
    ...config,
    credentials: "same-origin"
  });
};

export const requestLink = ({ queryOrMutationLink }) =>
  /*
    This link checks if the operation is a subscription.
    If it is, we use our subscription link to retrieve data over WebSockets.
    If it is a query or mutation, we retrieve data over HTTP.
  */
  ApolloLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition"; // && operation === 'subscription';
    },
    //subscriptionLink,
    queryOrMutationLink
  );

export const headersLink = lang =>
  setContext((_, { headers }) => {
    const httpHeaders = {
      headers: {
        ...headers,
        "Content-Language": lang
        //'Accept-Language': lang
      }
    };

    return httpHeaders;
  });
