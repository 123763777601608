import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import LinkIcon from "../../resources/img/external-link-symbol.svg";
import dateFormat from "dateformat";
import { CardTitle } from "../Typography";
import SimpleLink from "../SimpleLink";
import { hexToRgba } from "../../helpers";
import { ExternalLink } from "../SvgIcon";

const styles = (theme) => ({
  root: {
    background: "#7f7fc7",
    borderRadius: 16,
    cursor: "pointer",
    position: "relative",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage:
      "linear-gradient(130deg, rgba(35, 31, 32, 0.16), rgba(35, 31, 32, 0.46))",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  feed: {
    height: 326,
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
    },
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
  },
  content: {
    padding: 10,
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 16,
    [theme.breakpoints.down("320")]: {
      padding: 4,
    },
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  date: {
    padding: "8px 0 0 0",
    fontFamily: "TypoComica",
    color: "#d1d3d4",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  oldEvent: {
    position: "absolute",
    left: 0,
    borderRadius: 16,
    top: 0,
    height: "100%",
    width: "100%",
    backgroundImage:
      "linear-gradient(130deg, rgba(255,255,255, 0.4), rgba(100, 100, 100, 0.6))",
  },
  link: {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 50,
    [theme.breakpoints.down("md")]: {
      bottom: 15,
    },
    [theme.breakpoints.down("320")]: {
      bottom: 0,
    },
  },
});

const EventCard = ({
  classes,
  width = "auto",
  bgImage = null,
  title,
  feed = false,
  handleOnClick,
  date,
  onActionClick,
  primaryColor = "#231f20",
  oldEvent = false,
  actionTitle,
  active,
}) => {
  const defaultBoxShadow = "0 16px 32px 0 rgba(35, 31, 32, 0.16)";

  const shadowColor = hexToRgba(primaryColor, 0.3);

  const rootStyles = {
    width,
    boxShadow: primaryColor ? `0 16px 32px 0 ${shadowColor}` : defaultBoxShadow,
  };

  if (bgImage) {
    rootStyles.backgroundImage = `url(${bgImage})`;
    rootStyles.opacity = oldEvent ? 0.8 : 1;
  }

  const background = { backgroundColor: `rgba(35, 31, 32, 0.5)` };

  return (
    <div
      onClick={handleOnClick}
      className={`${classes.root} ${feed ? classes.feed : ""}`}
      style={rootStyles}
    >
      <div className={classes.imageWrapper} style={background}>
        {oldEvent && <div className={classes.oldEvent}></div>}
        <div className={`card-content ${classes.content}`}>
          {date && (
            <Typography className={classes.date} align="center" variant="body1">
              {dateFormat(date, "dddd, d. mmmm yyyy")}
            </Typography>
          )}
          <CardTitle additionalStyles={classes.title}>{title}</CardTitle>
        </div>
      </div>
      {actionTitle && (
        <SimpleLink
          responsive
          additionalClassses={classes.link}
          onActionClick={onActionClick}
          icon={LinkIcon}
          svg={<ExternalLink />}
        >
          {actionTitle}
        </SimpleLink>
      )}
    </div>
  );
};

export default withStyles(styles)(EventCard);
