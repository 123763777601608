import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import HeadingCard from "../../components/DetailView/HeadingCard";
import { SectionTitle } from "../../components/Typography";
import SectionList from "./ZoneSectionList";
import Feed from "./Feed";
import ZoneVideo from "./ZoneVideo";
import PricingSection from "./PricingSection";
import RichTextField from "../../pages/rich-text-field/RichTextField";

const styles = (theme) => ({
  body: {
    [theme.breakpoints.down("sm")]: {
      margin: "0 -25px",
      paddingTop: 16,
    },
  },
  textContainer: {
    marginTop: 32,
    marginBottom: 48,
    padding: "8px 0 0 0",
    borderRadius: 10,
    maxWidth: 1024,
    fontSize: 18,
    lineHeight: 1.5,
    margin: "auto",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      marginBottom: 8,
      fontSize: 14,
    },
  },
  sectionsContainer: {
    margin: "8px -8px",
    padding: 8,
  },
  section: {
    marginTop: 64,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  feedSection: {
    marginTop: 54,
    marginLeft: "-8px",
    marginRight: "-8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
});

const ZoneView = ({
  title = "",
  text = "",
  classes,
  primaryColor = "#000",
  refreshHeight,
  price = "",
  captions = [],
  sections = [],
  feed = null,
  secondaryTitle = "",
  bonusCardText = "",
  icon = null,
  coverSecondaryImage = null,
  thumbnailSecondary = null,
  coverImage = null,
  closeLink,
  elements,
  handleClose,
}) => (
  <div>
    <HeadingCard
      closeLink={closeLink}
      handleClose={handleClose}
      icon={icon}
      primaryColor={primaryColor}
      captions={captions}
      bgImage={coverImage}
      title={title}
    />

    <div className={classes.body}>
      <div className={classes.section}>
        <SectionTitle>
          {secondaryTitle
            ? secondaryTitle
            : `ČO ZAŽIJEŠ V ${title
                .toUpperCase()
                .replace("ZÓNA", "")
                .trim()} ZÓNE`}
        </SectionTitle>

        {coverSecondaryImage && (
          <ZoneVideo
            color={primaryColor}
            resourceType={coverSecondaryImage.resource_type}
            url={coverSecondaryImage.storage_name}
          />
        )}
      </div>

      <RichTextField
        classes={{ root: classes.textContainer }}
        textStyles={{ textAlign: "inherit", fontSize: "inherit" }}
      >
        {elements}
      </RichTextField>

      {sections && (
        <div className={classes.sectionsContainer}>
          <SectionList defaultColor={primaryColor} sections={sections} />
        </div>
      )}

      <div className={classes.section}>
        <PricingSection
          color={primaryColor}
          pricingImage={thumbnailSecondary}
          priceText={price}
          bonusCardText={bonusCardText}
        />
      </div>

      {feed && (
        <div className={classes.feedSection}>
          <SectionTitle>#{title.toUpperCase()} FEED</SectionTitle>

          <div className={classes.sectionsContainer}>
            <Feed
              feed={feed}
              color={primaryColor}
              refreshHeight={refreshHeight}
            />
          </div>
        </div>
      )}
    </div>
  </div>
);

export default withStyles(styles)(ZoneView);
