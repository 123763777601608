import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { formatTime } from "./utils";
import { FormattedMessage } from "react-intl";

const styles = theme => ({
  root: {
    display: "inline-flex",
    verticalAlign: "middle",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center"
    },
    width: "100%",
    margin: "auto"
  },
  input: {
    padding: "7px",
    textAlign: "center",
    display: "inline-block",
    border: 0,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px"
  },
  label: {
    color: "#cf2c91"
  },
  labelRoot: {
    margin: "0"
  },
  reservedText: {
    color: "rgba(0, 0, 0, 0.38)"
  }
});

class TimeRadio extends Component {
  handleChange = () => {
    const { handleChange, timeFrom, timeTo, checked } = this.props;
    handleChange(!checked, timeFrom, timeTo);
  };

  render = () => {
    const {
      label,
      classes,
      reserved,
      timeFrom,
      checked,
      timeTo,
      disabled
    } = this.props;

    return (
      <div className={classes.root}>
        <FormControlLabel
          classes={{
            root: classes.labelRoot,
            label: checked ? classes.label : null
          }}
          disabled={reserved || disabled}
          control={
            <Radio
              classes={{ checked: classes.checkedStyle }}
              style={{ padding: "3px" }}
              checked={checked}
              onChange={this.handleChange}
              value={label}
            />
          }
          labelPlacement="start"
          label={`${formatTime(timeFrom)} - ${formatTime(timeTo)}`}
        />
        {reserved ? (
          <span>
            <Typography className={classes.reservedText}>
              <FormattedMessage
                id="reservationtimeradio.bowlingconfirmed.title"
                defaultMessage="Rezervované"
              />
            </Typography>
          </span>
        ) : null}
      </div>
    );
  };
}

export default withStyles(styles)(TimeRadio);
