import React from "react";
import Status from "../components/Status";
import { withStyles } from "@material-ui/core";
import image404 from "../resources/img/page404_image.png";
import arrow1 from "../resources/img/page404_arrow1.svg";
import arrow2 from "../resources/img/page404_arrow2.svg";
import { FormattedMessage } from "react-intl";

const styles = () => ({
  content: {
    fontFamily: "lato",
    padding: 32,
    textAlign: "center",
    "& > h1": {
      fontSize: 32,
      fontFamily: "PaytoneOne",
      color: "#231f20"
    }
  },
  image: {
    margin: "auto",
    width: "100%",
    maxWidth: 500,
    margin: 32
  },
  text: {
    textAlign: "center",
    color: "#555555",
    fontSize: 18,
    maxWidth: 850,
    margin: "auto"
  },
  bottomContainer: {
    textAlign: "center",
    paddingBottom: 24,
    "& > img": {
      margin: 16
    }
  }
});

const NotFoundPage = ({ classes }) => (
  <Status code={404}>
    <div>
      <div className={classes.content}>
        <h1>
          <FormattedMessage
            id="notfound.title"
            defaultMessage="UPS, TÁTO STRÁNKA SA NIEKDE SKRYLA…"
          />
        </h1>
        <img className={classes.image} src={image404} alt="Page 404" />
        <div className={classes.text}>
          <FormattedMessage
            id="notfound.text.part1"
            defaultMessage="Stránka ktorú ste hľadali sa hrá na slepú s niektorým z našich animátorov."
          />
          <br />
          <FormattedMessage
            id="notfound.text.part2"
            defaultMessage="Ale to nevadí. Stále si môžete vybrať z ponuky nižšie."
          />
          <br />
          <FormattedMessage
            id="notfound.text.part3"
            defaultMessage="A tam sa určite nestratíte ;)"
          />
        </div>
      </div>
      <div className={classes.bottomContainer}>
        <img src={arrow1} alt="Arrow" />
        <img src={arrow2} alt="Arrow" />
      </div>
    </div>
  </Status>
);

export default withStyles(styles)(NotFoundPage);
