"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseTime = exports.formatTime = exports.getLangWithHostDetection = exports.getLang = exports.useLang = exports.getTopLevelDomainFromHost = exports.setDocumentTitle = exports.scrollToElement = exports.i18nDateformat = exports.calendarI18N = exports.rectangleEffect = exports.hexToRgba = exports.hexToRgb = exports.getShortText = exports.getImage = exports.getAssetPath = void 0;
const getAssetPath = (asset) => {
    return `/media/${asset}`;
};
exports.getAssetPath = getAssetPath;
const getImage = (name, size) => {
    const allowedSizes = ["thumbnail", "medium", "small"];
    const path = (0, exports.getAssetPath)(name);
    let img = path;
    if (size) {
        img = img.replace(".", "_" + size + ".");
    }
    return img;
};
exports.getImage = getImage;
const getShortText = (text, length = 50, maxWidth = 960) => {
    if (!text) {
        return false;
    }
    if (text.length <= length) {
        return text;
    }
    return `${text.substring(0, length)}...`;
};
exports.getShortText = getShortText;
const hexToRgb = (hex) => {
    if (!hex) {
        return { r: 0, g: 0, b: 0 };
    }
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        }
        : null;
};
exports.hexToRgb = hexToRgb;
const hexToRgba = (hex, opacity) => {
    const rgb = (0, exports.hexToRgb)(hex);
    const rgba = rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})` : hex;
    return rgba;
};
exports.hexToRgba = hexToRgba;
const rectangleEffect = (toggle = true, rectangleBox = window.rectangleBox, callback) => {
    var _a, _b, _c, _d, _e;
    const ANIMATION_DURATION = 300;
    const bodyRectangle = document.body.getBoundingClientRect();
    const el = document.createElement("div");
    document.body.appendChild(el);
    if (toggle) {
        el.style.width = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.width}px`;
        el.style.overflow = "hidden";
        el.style.position = "absolute";
        el.style.backgroundImage = "linear-gradient(to right, #f6f6f6, #b9b9b9)";
        el.style.borderRadius = "16px";
        el.style.height = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.height}px`;
        el.style.left = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.left}px`;
        el.style.top = `${Math.abs(bodyRectangle === null || bodyRectangle === void 0 ? void 0 : bodyRectangle.top) + (rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.top)}px`;
        el.style.transition = `all ${ANIMATION_DURATION - 100}ms linear`;
        window.getComputedStyle(el).left;
        window.getComputedStyle(el).width;
        window.getComputedStyle(el).height;
        window.getComputedStyle(el).top;
        el.style.top = `${Math.abs(bodyRectangle === null || bodyRectangle === void 0 ? void 0 : bodyRectangle.top)}px`;
        el.style.left = "0px";
        el.style.width = "100%";
        el.style.height = "120%";
        el.style.borderRadius = "0";
    }
    else {
        el.style.transition = `all ${ANIMATION_DURATION}ms cubic-bezier(.4,.2,0,1)`;
        el.style.backgroundImage = "linear-gradient(to right, #f6f6f6, #b9b9b9)";
        el.style.top = `${Math.abs((_a = window.bodyRectangle) === null || _a === void 0 ? void 0 : _a.top)}px`;
        el.style.zIndex = 9999;
        el.style.position = "absolute";
        el.style.left = "0px";
        el.style.width = "100%";
        el.style.height = "100%";
        window.getComputedStyle(el).absolute;
        window.getComputedStyle(el).left;
        window.getComputedStyle(el).width;
        window.getComputedStyle(el).height;
        window.getComputedStyle(el).top;
        el.style.width = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.width}px`;
        el.style.backgroundImage = "linear-gradient(to right, #f6f6f6, #b9b9b9)";
        el.style.borderRadius = "16px";
        el.style.opacity = "0.8";
        el.style.height = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.height}px`;
        el.style.left = `${rectangleBox === null || rectangleBox === void 0 ? void 0 : rectangleBox.left}px`;
        el.style.top = `${Math.abs((_b = window.bodyRectangle) === null || _b === void 0 ? void 0 : _b.top) +
            ((_c = window.rectangleBox) === null || _c === void 0 ? void 0 : _c.top)}px`;
        const y = ((_d = window.rectangleBox) === null || _d === void 0 ? void 0 : _d.top) +
            Math.abs((_e = window.bodyRectangle) === null || _e === void 0 ? void 0 : _e.top) -
            148;
        window.scroll({
            top: y,
        });
    }
    window.rectangleBox = rectangleBox;
    window.bodyRectangle = bodyRectangle;
    setTimeout(() => {
        document.body.removeChild(el);
        if (typeof callback === "function") {
            callback();
        }
    }, toggle ? ANIMATION_DURATION : ANIMATION_DURATION + 400);
};
exports.rectangleEffect = rectangleEffect;
exports.calendarI18N = {
    WEEKDAYS_SHORT: {
        sk: ["Ne", "Po", "Ut", "St", "Št", "Pi", "So"],
        en: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        pl: ["Ni", "Po", "Wt", "Śr", "Cz", "Pi", "So"],
    },
    MONTHS: {
        sk: [
            "Január",
            "Február",
            "Marec",
            "Apríl",
            "Máj",
            "Jún",
            "Júl",
            "August",
            "September",
            "Október",
            "November",
            "December",
        ],
        en: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        pl: [
            "Styczeń",
            "Luty",
            "Marsz",
            "Kwiecień",
            "Może",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
        ],
    },
    WEEKDAYS_LONG: {
        sk: [
            "Nedeľa",
            "Pondelok",
            "Utorok",
            "Streda",
            "Štvrtok",
            "Piatok",
            "Sobota",
        ],
        en: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ],
        pl: [
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
        ],
    },
    FIRST_DAY_OF_WEEK: {
        sk: 1,
        en: 1,
        pl: 1,
    },
    LABELS: {
        sk: { nextMonth: "Ďalší mesiac", previousMonth: "Predošlý mesiac" },
        en: { nextMonth: "Next month", previousMonth: "Previous month" },
        pl: {
            nextMonth: "W przyszłym miesiącu",
            previousMonth: "Poprzedni miesiąc",
        },
    },
};
// export const i18nDateformat = {
//     sk: {
//         dayNames: [
//             'Ne', 'Po', 'Ut', 'St', 'Št', 'Pi', 'So',
//             'Nedeľa', 'Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota'
//         ],
//         monthNames: [
//             'Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec',
//             'Január', 'Február', 'Marec', 'Apríl', 'Máj', 'Jún', 'Júl', 'August', 'September', 'Október', 'November', 'December'
//         ],
//         timeNames: [
//             'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
//         ]
//     },
//     pl: {
//         dayNames: [
//             'Ni', 'Po', 'Wt', 'Śr', 'Cz', 'Pi', 'So',
//             'Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'
//         ],
//         monthNames: [
//             'Sty', 'Lut', 'Mar', 'Kwi', 'Moż', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru',
//             'Styczeń', 'Luty', 'Marsz', 'Kwiecień', 'Może', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'
//         ],
//         timeNames: [
//             'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
//         ]
//     },
//     en: {
//         dayNames: [
//             'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa',
//             'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
//         ],
//         monthNames: [
//             'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
//             'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
//         ],
//         timeNames: [
//             'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
//         ]
//     }
// };
exports.i18nDateformat = {
    sk: {
        dayNames: [
            "Ne",
            "Po",
            "Ut",
            "St",
            "Št",
            "Pi",
            "So",
            "Nedeľa",
            "Pondelok",
            "Utorok",
            "Streda",
            "Štvrtok",
            "Piatok",
            "Sobota",
        ],
        monthNames: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Máj",
            "Jún",
            "Júl",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec",
            "Január",
            "Február",
            "Marec",
            "Apríl",
            "Máj",
            "Jún",
            "Júl",
            "August",
            "September",
            "Október",
            "November",
            "December",
        ],
        timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
    },
    pl: {
        dayNames: [
            "Ni",
            "Po",
            "Wt",
            "Śr",
            "Cz",
            "Pi",
            "So",
            "Niedziela",
            "Poniedziałek",
            "Wtorek",
            "Środa",
            "Czwartek",
            "Piątek",
            "Sobota",
        ],
        monthNames: [
            "Sty",
            "Lut",
            "Mar",
            "Kwi",
            "Moż",
            "Cze",
            "Lip",
            "Sie",
            "Wrz",
            "Paź",
            "Lis",
            "Gru",
            "Styczeń",
            "Luty",
            "Marsz",
            "Kwiecień",
            "Może",
            "Czerwiec",
            "Lipiec",
            "Sierpień",
            "Wrzesień",
            "Październik",
            "Listopad",
            "Grudzień",
        ],
        timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
    },
    en: {
        dayNames: [
            "Su",
            "Mo",
            "Tu",
            "We",
            "Th",
            "Fr",
            "Sa",
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ],
        monthNames: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
    },
};
const scrollToElement = (el) => {
    let y = el.getBoundingClientRect().top + window.scrollY;
    const mq = window.matchMedia("(max-width: 960px)");
    if (mq.matches) {
        y = y - 100;
    }
    else {
        y = y - 40;
    }
    window.scroll({ top: y });
};
exports.scrollToElement = scrollToElement;
const setDocumentTitle = (title) => {
    const subtitle = title ? ` - ${title}` : ``;
    document.title = `Kinderland${subtitle}`;
};
exports.setDocumentTitle = setDocumentTitle;
const ALLOWED_LANGS = ["sk", "pl"];
const getTopLevelDomainFromHost = (host) => {
    if (host.indexOf(".") === -1) {
        return;
    }
    const domain = host.substring(host.lastIndexOf(".") + 1, host.length);
    return ALLOWED_LANGS.includes(domain) ? domain : "sk";
};
exports.getTopLevelDomainFromHost = getTopLevelDomainFromHost;
const useLang = () => {
    const lang = (0, exports.getLang)();
    return lang;
};
exports.useLang = useLang;
const getLang = (host) => {
    const DEFAULT_LANG = "sk";
    if (host)
        return (0, exports.getTopLevelDomainFromHost)(host) || DEFAULT_LANG;
    else
        return (0, exports.getLangWithHostDetection)() || DEFAULT_LANG;
};
exports.getLang = getLang;
const getLangWithHostDetection = () => {
    if (typeof window !== "undefined")
        return (0, exports.getTopLevelDomainFromHost)(window.location.host);
    else
        return global.lang;
};
exports.getLangWithHostDetection = getLangWithHostDetection;
const formatTime = (time) => {
    return time.substring(0, 5);
};
exports.formatTime = formatTime;
const parseTime = (time) => {
    const date = new Date(`2000/01/01 ${time}`);
    return date.getHours();
};
exports.parseTime = parseTime;
