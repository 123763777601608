import {
  List,
  withStyles,
  ListItem,
  Checkbox,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import dateFormat from "dateformat";

const styles = (theme) => ({
  root: {
    background: theme.palette.common.white,
  },
  listItem: {
    paddingLeft: 0,
    padding: 0,
  },
  typography: {
    fontSize: 14,
    color: theme.palette.grey[600],
  },
  checkbox: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 0,
    paddingBottom: 0,
  },
  capacity: {
    position: "absolute",
    right: 6,
    fontSize: 14,
    color: theme.palette.error.light,
  },
  capacityDisabled: {
    position: "absolute",
    right: 6,
    fontSize: 14,
    color: theme.palette.grey[300],
  },
});

const inflectWord = (count) => {
  if (count == 1) {
    return "miesto";
  }
  if (count >= 2 && count <= 4) {
    return "miesta";
  }
  return "miest";
};

const WeekDayPicker = ({
  camp = { CampDates: [] },
  classes,
  personCount,
  selected = [],
  handleDayPick = () => {},
}) => {
  const isDayDisabled = useCallback(
    (day) => {
      return (
        (day.capacity < 1 && day.capacity !== null) ||
        (day.capacity < personCount && day.capacity !== null)
      );
    },
    [personCount]
  );

  const handleClick = useCallback(
    (camp, day) => {
      const reservationDates = camp.reservationDates || [];
      const checked = reservationDates.find((x) => x === day);
      if (checked) {
        handleDayPick(
          camp,
          reservationDates.filter((x) => x !== day)
        );
      } else {
        handleDayPick(camp, [...reservationDates, day]);
      }
    },
    [handleDayPick]
  );

  return (
    <List disablePadding className={classes.root} dense>
      {camp.CampDates.map((day) => (
        <ListItem
          key={day.date}
          className={classes.listItem}
          onClick={() => !isDayDisabled(day) && handleClick(camp, day.date)}
          button
        >
          <Checkbox
            className={classes.checkbox}
            disabled={isDayDisabled(day)}
            checked={selected.indexOf(day.date) !== -1}
            tabIndex={-1}
            disableRipple
          />
          <Typography
            style={{ color: isDayDisabled(day) && "#ddd" }}
            className={classes.typography}
          >
            {dateFormat(day.date, "d.m.yyyy")} - {dateFormat(day.date, "dddd")}
          </Typography>
          {day.capacity > 0 ? (
            <Typography
              className={
                isDayDisabled(day) ? classes.capacityDisabled : classes.capacity
              }
            >
              ostáva {day.capacity} {inflectWord(day.capacity)}
            </Typography>
          ) : null}
        </ListItem>
      ))}
    </List>
  );
};

export default withStyles(styles)(WeekDayPicker);
