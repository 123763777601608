import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import LinkIcon from "../../resources/img/external-link-symbol.svg";
import { CardTitle } from "../Typography";
import SimpleLink from "../SimpleLink";
import { ExternalLink } from "../SvgIcon";

const styles = (theme) => ({
  root: {
    background: "#7f7fc7",
    borderRadius: 16,
    cursor: "pointer",
    position: "relative",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage:
      "linear-gradient(130deg, rgba(35, 31, 32, 0.16), rgba(35, 31, 32, 0.56))",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 16,
  },
  content: {
    padding: 10,
    margin: 0,
    position: "absolute",
    top: "10%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, 0)",
  },
  icon: {
    width: 136,
    height: 136,
    borderRadius: 10,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      height: 136,
      width: 136,
    },
    [theme.breakpoints.down("md")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      width: 50,
    },
    [theme.breakpoints.down("320")]: {
      height: 40,
      width: 40,
    },
  },
  title: {
    color: "#fff",
    textTransform: "uppercase",
    textAling: "center",
    width: "auto",
    margin: "auto",
  },
  subtitle: {
    color: "#ffffff",
    textTransform: "uppercase",
    textAling: "center",
    fontSize: "18px",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  text: {
    color: "#ffc415",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "1.2em",
    padding: "10px 24px",
    bottom: 50,
    [theme.breakpoints.down("lg")]: {
      bottom: 30,
      fontSize: "1em",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px 16px",
      bottom: 20,
      fontSize: "0.8em",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 8px",
      fontSize: "0.6em",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 0px",
      bottom: 10,
      padding: 0,
      fontSize: "0.5em",
      height: 40,
    },
    [theme.breakpoints.down("520")]: {
      display: "none",
    },
  },
  link: {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 50,
    [theme.breakpoints.down("md")]: {
      bottom: 20,
    },
    [theme.breakpoints.down("xs")]: {
      bottom: 10,
    },
    [theme.breakpoints.down("320")]: {
      bottom: 0,
    },
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down("lg")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
});

const RestaurantCard = ({
  classes,
  width = "auto",
  icon = null,
  bgImage = null,
  title,
  subtitle,
  text,
  primaryColor = "#231f20",
  actionTitle,
  onActionClick,
}) => (
  <div
    onClick={onActionClick}
    className={classes.root}
    style={{ width, backgroundImage: `url(${bgImage})` }}
  >
    <div
      className={classes.imageWrapper}
      style={{ backgroundColor: `rgba(35, 31, 32, 0.5)` }}
    >
      <div className={`card-content ${classes.content}`}>
        {icon ? <img className={classes.icon} src={icon} alt="icon" /> : null}
        <CardTitle additionalStyles={classes.title}>{title}</CardTitle>
        {subtitle && (
          <Typography className={classes.subtitle} align="center" variant="h6">
            {subtitle}
          </Typography>
        )}
        {text && (
          <Typography className={classes.text} align="center" variant="body2">
            {text}
          </Typography>
        )}
      </div>

      {actionTitle && (
        <SimpleLink
          responsive
          additionalClassses={classes.link}
          icon={LinkIcon}
          svg={<ExternalLink color={primaryColor} />}
        >
          {actionTitle}
        </SimpleLink>
      )}
    </div>
  </div>
);

export default withStyles(styles)(RestaurantCard);
