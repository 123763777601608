import React from "react";
import { withStyles } from "@material-ui/core";

const styles = theme => ({
  root: {
    position: "relative",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    background: "#fff",
    marginTop: 32,
    borderRadius: 16,
    "&:before": {
      display: "block",
      content: '""',
      width: "100%",
      paddingTop: "56.25%",
      borderRadius: 16
    },
    "& > .content": {
      overflow: "hidden",
      position: "absolute",
      top: 0,
      left: 0,
      right: "-1px",
      bottom: 0,
      borderRadius: 16,
      zIndex: 1,
      webkitTransform: "rotate(0.000001deg)"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 8
    }
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
    borderRadius: 16
  }
});

const Video = ({ classes, color, resourceType, url }) => (
  <div className={classes.root} style={{ backgroundColor: color }}>
    <div className="content">
      {resourceType === "online" ? (
        <iframe
          className={classes.video}
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      ) : (
        <div
          style={{
            widht: "100%",
            height: "100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${getAssetPath(
              coverSecondaryImage.storage_name
            )})`
          }}
        />
      )}
    </div>
  </div>
);

export default withStyles(styles)(Video);
