import React from "react";
import { withStyles } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import logo from "../../resources/img/logo.png";
import logoInt from "../../resources/img/logo-int.png";
import { getLang } from "../../helpers";

const styles = (theme) => ({
  logo: {
    width: "auto",
    height: 104,
    margin: "0 16px",
    marginTop: 2,
    [theme.breakpoints.down("md")]: {
      height: 80,
    },
    [theme.breakpoints.down("sm")]: {
      height: 60,
      paddingLeft: 0,
      width: "auto",
      margin: "0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
    [theme.breakpoints.down(391)]: {
      height: 35,
    },
    [theme.breakpoints.down(340)]: {
      display: "none",
    },
  },
  center: {
    height: 65,
    width: "100%",
    padding: "8px 0",
    textAlign: "center",
  },
});

const Logo = ({ classes, center = false }) => {
  const lang = getLang();

  return center ? (
    <div className={classes.center}>
      <NavLink to="/">
        <img
          className={classes.logo}
          style={{ margin: "auto", float: "none" }}
          src={lang !== "sk" ? logoInt : logo}
          alt="logo"
        />
      </NavLink>
    </div>
  ) : (
    <NavLink to="/">
      <img
        className={classes.logo}
        src={lang !== "sk" ? logoInt : logo}
        alt="logo"
      />
    </NavLink>
  );
};

export default withStyles(styles)(Logo);
