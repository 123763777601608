import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "bowling.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje."
  },
  success: {
    id: "bowling.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás"
  },
  metaTitle: {
    id: "bowling.meta.title",
    defaultMessage: "Kinderland - Bowling"
  },
  metaDescription: {
    id: "bowling.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo"
  },
  title: {
    id: "bowling.title",
    defaultMessage: "Bowling"
  },
  caption1: {
    id: "bowling.caption1",
    defaultMessage: "dvojdráhový Brunswick"
  },
  caption2: {
    id: "bowling.caption2",
    defaultMessage: "možnosť objednávky bowlingu online"
  },
  errorFirstname: {
    id: "bowling.error.firstname",
    defaultMessage: "Zadajte meno"
  },
  errorSurname: {
    id: "bowling.error.surname",
    defaultMessage: "Zadajte priezvisko"
  },
  errorTelephone: {
    id: "bowling.error.telephone",
    defaultMessage: "Zadajte telefónne číslo"
  },
  errorGdpr: {
    id: "bowling.error.gdpr",
    defaultMessage:
      "Potrebujeme od Vás povolenie pre spracovanie osobných údajov."
  },
  errorTimes: {
    id: "bowling.error.times",
    defaultMessage: "Vyberte čas rezervácie"
  },
  errorEmail: {
    id: "bowling.error.email",
    defaultMessage: "Zadajte e-mailovú adresu"
  },
  errorCity: {
    id: "bowling.error.city",
    defaultMessage: "Zadajte obec"
  },
  errorAddress: {
    id: "bowling.error.address",
    defaultMessage: "Zadajte ulicu a popisné číslo"
  },
  errorZip: {
    id: "bowling.error.zip",
    defaultMessage: "Zadajte PSČ"
  }
});

export default messages;
