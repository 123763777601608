import React from "react";
import { withStyles } from "@material-ui/core";
import SectionCarousel from "../../components/FullscreenCarousel";
import EventCard from "../../components/Cards/EventCard";
import RestaurantSection from "./RestaurantSection";
import { getShortText, getImage } from "../../helpers";
import {
  SectionTitle,
  SectionSubtitleTitle,
} from "../../components/Typography";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";
import { compose } from "recompose";

const EVENTS_QUERY = gql`
  query Events {
    Events: Events {
      id
      code
      title
      date_from
      Thumbnail {
        storage_name
      }
    }
  }
`;

const styles = () => ({
  cursorIconContainer: {
    textAlign: "center",
    position: "relative",
    bottom: "-16px",
  },
  cursorIcon: {
    margin: "auto",
    height: 60,
  },
});

const EventSection = ({ classes, history, intl: { formatMessage } }) => {
  const { data } = useQuery(EVENTS_QUERY);
  const events = (data && data.Events) || [];

  if (!events.length) return null;

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  let e = [...events];

  e.sort(function(a, b) {
    var dateA = new Date(a.date_from),
      dateB = new Date(b.date_from);
    return dateA - dateB;
  });

  let firstIdx = 0;
  for (let i = 0; i < e.length; i++) {
    if (new Date(e[i].date_from) >= currentDate) {
      firstIdx = i + 1;
      break;
    }
  }

  const sortedEvents = e.map((x) => ({
    ...x,
    oldEvent: new Date(x.date_from) < currentDate ? true : false,
  }));

  return (
    <div
      id={formatMessage(generalMessages.linkEvents)}
      className={classes.root}
    >
      <SectionTitle>
        <FormattedMessage
          id="eventsection.whatsnext.title"
          defaultMessage="ČO PRE TEBA PRIPRAVUJEME"
        />
      </SectionTitle>
      <SectionSubtitleTitle>
        <FormattedMessage
          id="eventsection.nearestevent.title"
          defaultMessage="Najbližšia akcia v Kinderlande. Nezmeškaj ju!"
        />
      </SectionSubtitleTitle>
      <SectionCarousel
        cellAlign="center"
        slideIndex={firstIdx}
        wrapAround={true}
        cards={sortedEvents.map((event, idx) => (
          <EventCard
            handleOnClick={() =>
              history.push(
                `/${formatMessage(generalMessages.linkEvents)}/${event.code}`
              )
            }
            key={idx}
            cardId={event.id}
            active={idx === firstIdx ? true : false}
            primaryColor={idx === firstIdx ? "#510133" : undefined}
            oldEvent={event.oldEvent}
            bgImage={event.Thumbnail && getImage(event.Thumbnail.storage_name)}
            date={event.date_from}
            actionTitle={
              <FormattedMessage
                id="eventsection.moreinfo.title"
                defaultMessage="Viac o akcii"
              />
            }
            title={getShortText(event.title)}
          />
        ))}
        nextSectionLink={`/#${formatMessage(generalMessages.linkRestaurant)}`}
        nextSection={<RestaurantSection />}
      />
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles)
)(EventSection);
