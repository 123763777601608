import React from "react";
import { withStyles } from "@material-ui/core";
import ZoneCard from "../../components/Cards/ZoneCard";
import SectionCarousel from "../../components/FullscreenCarousel";
import { getAssetPath, getShortText, getImage } from "../../helpers";
import {
  SectionTitle,
  SectionSubtitleTitle,
} from "../../components/Typography";
import { withRouter } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import { compose } from "recompose";
import gql from "graphql-tag";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";

const ZONES_QUERY = gql`
  query Zones {
    data: Zones {
      id
      code
      title
      primaryColor
      secondaryColor
      Icon {
        resource_type
        storage_name
      }
      Thumbnail {
        resource_type
        storage_name
      }
    }
  }
`;

const styles = () => ({
  root: {},
});

const ZoneSection = ({ classes, history, intl: { formatMessage } }) => {
  const { data } = useQuery(ZONES_QUERY);
  const zones = (data && data.data) || [];

  if (!zones.length) return null;

  return (
    <div id={formatMessage(generalMessages.linkZones)} className={classes.root}>
      <SectionTitle>
        <FormattedMessage
          id="zonesection.experiencemenu.title"
          defaultMessage="ČO MÔŽEŠ U NÁS ZAŽIŤ"
        />
      </SectionTitle>
      <SectionSubtitleTitle>
        <FormattedMessage
          id="zonesection.allzones.title"
          defaultMessage="Spoznaj všetky zóny v Kinderlande, cenník vstupov a výhody bonusovej karty."
        />
      </SectionSubtitleTitle>
      <SectionCarousel
        cellAlign="center"
        wrapAround={true}
        handleOnClick={() => {}}
        cards={zones.map((zone, idx) => (
          <ZoneCard
            key={idx}
            handleOnClick={() =>
              history.push(
                `/${formatMessage(generalMessages.linkZone)}/${zone.code}`
              )
            }
            cardId={zone.id}
            icon={zone.Icon && getAssetPath(zone.Icon.storage_name)}
            bgImage={zone.Thumbnail && getImage(zone.Thumbnail.storage_name)}
            primaryColor={zone.primaryColor}
            secondaryColor={zone.secondaryColor}
            actionTitle={
              <FormattedMessage
                id="zonesection.wantknowmore.title"
                defaultMessage="Chcem spoznať viac"
              />
            }
            title={getShortText(zone.title)}
          />
        ))}
      />
    </div>
  );
};

export default compose(injectIntl, withRouter, withStyles(styles))(ZoneSection);
