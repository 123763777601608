import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    fontFamily: "lato",
    fontSize: 10,
    textTransform: "uppercase",
  },
});

const ExtraFeeItem = ({ classes, children, color = "#000" }) => (
  <div style={{ color }} className={classes.root}>
    {children}
  </div>
);

export default withStyles(styles)(ExtraFeeItem);
