import React, { useCallback } from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Grid, withStyles } from "@material-ui/core";
import { compose } from "recompose";
import ChildCampCard from "./ChildCampCard";

const QUERY = gql`
  {
    camps: getCampsData {
      items {
        id
        title
        week_no
        date_from
        date_to
        price_day
        price
        CampDates {
          date
          capacity
        }
      }
      totalCount
    }
  }
`;

const styles = () => ({
  root: {},
});

const DayOrWeekPicker = ({
  selectedCamps,
  handleDates,
  classes,
  personCount,
}) => {
  const { data } = useQuery(QUERY);
  const camps = (data && data.camps && data.camps.items) || [];

  const getSelectedDays = useCallback(
    (camp) => {
      const c = selectedCamps.find((c) => c.id === camp.id);
      if (!c) return [];
      return c.reservationDates;
    },
    [selectedCamps]
  );

  return (
    <div className={classes.root}>
      <Grid container justify="center" spacing={8}>
        {camps.map((camp) => (
          <Grid key={camp.id} item xs={12} sm={6} md={4} lg={3}>
            <ChildCampCard
              camp={camp}
              personCount={personCount}
              selectedDays={getSelectedDays(camp)}
              handleDates={handleDates}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default compose(withStyles(styles))(DayOrWeekPicker);
