import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "party.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje."
  },
  success: {
    id: "party.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás"
  },
  metaTitle: {
    id: "party.meta.title",
    defaultMessage: "Kinderland - Párty oslava"
  },
  metaDescription: {
    id: "party.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo"
  },
  title: {
    id: "party.title",
    defaultMessage: "Párty oslava"
  },
  caption1: {
    id: "party.caption1",
    defaultMessage: "oslavujte štýlovo"
  },
  caption2: {
    id: "party.caption2",
    defaultMessage: "miestnosť plná prekvapení"
  },
  errorFirstname: {
    id: "party.error.firstname",
    defaultMessage: "Zadajte meno"
  },
  errorSurname: {
    id: "party.error.surname",
    defaultMessage: "Zadajte priezvisko"
  },
  errorTelephone: {
    id: "party.error.telephone",
    defaultMessage: "Zadajte telefónne číslo"
  },
  errorGdpr: {
    id: "party.error.gdpr",
    defaultMessage:
      "Potrebujeme od Vás povolenie pre spracovanie osobných údajov."
  },
  errorTimes: {
    id: "party.error.times",
    defaultMessage: "Vyberte čas rezervácie"
  },
  errorEmail: {
    id: "party.error.email",
    defaultMessage: "Zadajte e-mailovú adresu"
  },
  errorCelebrating: {
    id: "party.error.celebrating",
    defaultMessage: "Zadajte meno a vek oslávenca"
  },
  summaryChildren: {
    id: "party.summary.children",
    defaultMessage: "Deti"
  },
  summaryAdults: {
    id: "party.summary.adults",
    defaultMessage: "Dospelí"
  },
  summaryCelebrating: {
    id: "party.summary.celebrating",
    defaultMessage: "Oslávenec"
  },
  summaryBoy: {
    id: "party.summary.boy",
    defaultMessage: "Chlapec"
  },
  summaryGirl: {
    id: "party.summary.girl",
    defaultMessage: "Dievča"
  },
  summaryUniversal: {
    id: "party.summary.universal",
    defaultMessage: "Univerzálna"
  },
  successMessage: {
    id: "party.summary.success",
    defaultMessage: "Rezervácia prebehla úspešne, tešíme sa na Vás."
  },
  errorMessage: {
    id: "party.summary.error",
    defaultMessage: "Vyskytla sa neočakávaná chyba, skúste prosím neskôr."
  },
  errorCity: {
    id: "party.error.city",
    defaultMessage: "Zadajte obec"
  },
  errorRoom: {
    id: "party.error.room",
    defaultMessage: "Vyberte miestnosť"
  },
  errorAddress: {
    id: "party.error.address",
    defaultMessage: "Zadajte ulicu a popisné číslo"
  },
  errorZip: {
    id: "party.error.zip",
    defaultMessage: "Zadajte PSČ"
  }
});

export default messages;
