import React from "react";
import messages from "../../i18n/messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "recompose";
import { HashLink as Link } from "react-router-hash-link";
import CustomButton from "../CustomButton";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    fontSize: 12,
    padding: "0 16px",
    height: 32,
    minHeight: 25,
    fontFamily: "PaytoneOne",
    margin: "0 15px 0 0",
    boxShadow: "0 4px 16px 0 rgba(207, 44, 145, 0.24)",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      padding: "0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      padding: "0 8px",
    },
  },
});

const BonusCardButton = ({ classes, intl: { formatMessage } }) => (
  <Link
    to={`/${formatMessage(messages.linkBonusCard)}`}
    scroll={(el) => scrollToElement(el)}
    style={{ textDecoration: "none" }}
  >
    <CustomButton classes={{ root: classes.root }}>
      <FormattedMessage
        id="navigation.bonuscard.button.title"
        defaultMessage="ZÍSKAJ BONUSOVÚ KARTU"
      />
    </CustomButton>
  </Link>
);

export default compose(injectIntl, withStyles(styles))(BonusCardButton);
