import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core";
import Arrow1 from "../../resources/img/icon-arrow-1.svg";
import Arrow2 from "../../resources/img/icon-arrow-2.svg";
import Arrow3 from "../../resources/img/icon-arrow-3.svg";

const styles = () => ({
  root: {
    width: "100%",
    padding: 8,
    textAlign: "center",
  },
});

const SectionArrow = ({ variant = "straight", classes }) => {
  const getArrow = useCallback(() => {
    if (variant === "left") return Arrow2;
    if (variant === "right") return Arrow3;
    if (variant === "straight") return Arrow1;
    if (variant === "summary") return Arrow1;
  }, [variant]);

  return (
    <div className={classes.root}>
      <img src={getArrow()} alt="Arrow" />
    </div>
  );
};

export default withStyles(styles)(SectionArrow);
