import React from "react";
import { withStyles, Grid } from "@material-ui/core";
import PackageCard from "./PackageCard";
import Carousel from "nuka-carousel";

const styles = (theme) => ({
  root: {
    display: "flex",
    maxWidth: 924,
    margin: "auto",
    flexFlow: "row wrap",
    justifyContent: "center",
  },
  item: {
    width: "33%",
    //  flex: "1 33.3333333%"
  },
});

const EshopPackages = ({
  packages = [],
  selected,
  handleChange,
  availableZones = [],
  classes,
}) => (
  <div className={classes.root}>
    <Grid justify="center" container spacing={16}>
      {packages.map((p) => (
        <Grid key={p.id} item xl={4} lg={4} md={4} sm={6} xs={12}>
          <PackageCard
            handleChange={() => handleChange(p.id)}
            selected={p.id === selected ? true : false}
            key={p.id}
            availableZones={availableZones}
            title={p.title}
            color={selected === p.id ? "#cf2c91" : p.color}
            image={p.image}
            shadowColor={selected === p.id ? "#cf2c91" : p.shadowColor}
            price={p.price}
            priceOld={p.priceOld}
            zones={p.zones}
            captions={p.captions}
            items={p.items}
            extraFeeItems={p.extraFeeItems}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

export default withStyles(styles)(EshopPackages);
