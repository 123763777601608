import React, { Component, Fragment } from "react";
import { withStyles, Button, Typography, Divider } from "@material-ui/core";
import gql from "graphql-tag";
import ResponseStatus from "./ResponseStatus";
import { Query, Mutation } from "@apollo/react-components";
import { FormattedMessage } from "react-intl";

const MUTATION = gql`
  mutation applyVoucher($voucherCode: String!, $orderToken: String!) {
    applyVoucher(voucherCode: $voucherCode, orderToken: $orderToken) {
      code
      scope
      price
      date_from
      date_to
    }
  }
`;

const MUTATION_VOUCHER_PAYMENT = gql`
  mutation voucherPayment($voucherCode: String!, $orderToken: String!) {
    voucherPayment(voucherCode: $voucherCode, orderToken: $orderToken)
  }
`;

const QUERY = gql`
  query checkVoucher($voucherCode: String!, $orderToken: String!) {
    Voucher: checkVoucher(voucherCode: $voucherCode, orderToken: $orderToken) {
      code
      scope
      price
      date_from
      date_to
    }
  }
`;

const styles = (theme) => ({
  root: {
    padding: "0 32px",
  },
  inputRoot: {
    padding: "16px 16px 32px 16px",
  },
  input: {
    padding: "8px 10px",
    borderRadius: 8,
    border: "1px solid rgb(207, 44, 145)",
    outline: "none",
    marginRight: 8,
    marginLeft: 8,
    marginBottom: 8,
  },
  button: {
    padding: "0 12px",
    fontSize: "0.9em",
  },
  text: {
    paddingTop: 8,
    fontSize: "0.9em",
  },
});

class VoucherInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  handleChange = (e) => {
    this.setState({
      code: e.target.value,
    });
  };

  render = () => {
    const { classes, orderToken, refetchOrder, order } = this.props;

    return (
      <div className={classes.root}>
        <Divider />
        <Typography className={classes.text}>
          <FormattedMessage
            id="order.voucher.text"
            defaultMessage="Si držiteľom nášho vouchra? Ak áno, napíš nám jeho osemmiestny kód a suma na vouchri sa Ti odpočíta z objednávky. Ak je suma na vouchri vyššia ako je suma objednávky, môžeš ním uhradiť celú objednávku."
          />
        </Typography>
        <div className={classes.inputRoot}>
          <input
            onChange={this.handleChange}
            className={classes.input}
            type="text"
            value={this.state.code}
          />
          {this.state.code.length > 7 && (
            <Query
              query={QUERY}
              fetchPolicy="network-only"
              variables={{ voucherCode: this.state.code, orderToken }}
            >
              {({ data, loading, error }) => {
                if (loading || error)
                  return <ResponseStatus loading={loading} error={error} />;

                return (
                  <Fragment>
                    {data.Voucher.price >= order.total_vat ? (
                      <Mutation
                        onCompleted={(data) => {
                          window.location.href = `/payment/order/${orderToken}`;
                        }}
                        onError={() =>
                          (window.location.href = `/payment/fail/${orderToken}`)
                        }
                        mutation={MUTATION_VOUCHER_PAYMENT}
                      >
                        {(voucherPayment, { data, loading, error }) => (
                          <Fragment>
                            <Button
                              onClick={() => {
                                voucherPayment({
                                  variables: {
                                    voucherCode: this.state.code,
                                    orderToken,
                                  },
                                });
                              }}
                              variant="outlined"
                              classes={{ root: classes.button }}
                            >
                              <FormattedMessage
                                id="order.voucher.button"
                                defaultMessage="UPLATNIŤ A UHRADIŤ VOUCHEROM"
                              />
                            </Button>
                            <ResponseStatus loading={loading} error={error} />
                          </Fragment>
                        )}
                      </Mutation>
                    ) : (
                      <Mutation
                        onCompleted={(data) => {
                          this.setState({
                            code: "",
                          });
                          refetchOrder();
                        }}
                        mutation={MUTATION}
                      >
                        {(applyVoucher, { data, loading, error }) => (
                          <Fragment>
                            <Button
                              onClick={() => {
                                applyVoucher({
                                  variables: {
                                    voucherCode: this.state.code,
                                    orderToken,
                                  },
                                });
                              }}
                              variant="outlined"
                              classes={{ root: classes.button }}
                            >
                              <FormattedMessage
                                id="order.voucher.usevoucher"
                                defaultMessage="POUŽIŤ VOUCHER"
                              />
                            </Button>
                            <ResponseStatus loading={loading} error={error} />
                          </Fragment>
                        )}
                      </Mutation>
                    )}
                  </Fragment>
                );
              }}
            </Query>
          )}
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(VoucherInput);
