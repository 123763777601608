/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import RightArrowImg from "../../resources/img/arrow-right.svg";
import LeftArrowImg from "../../resources/img/arrow-left.svg";
import Carousel from "nuka-carousel";
import Slide from "./Slide";
import { getImage } from "../../helpers";
import withSizes from "react-sizes";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useState } from "react";

const HEADER_QUERY = gql`
  query Header {
    data: Header {
      title
      HeaderSlides {
        title
        text
        ctaTitle
        ctaLink
        primaryColor
        secondaryColor
        priority
        header_id
        image_id
        Image {
          storage_name
          resource_type
        }
        ImageSecondary {
          storage_name
        }
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      minHeight: "75vw",
    },
    position: "relative",
    "& .slider-frame": {
      padding: "16px 0 50px 0 !important",
    },
  },
  leftArrowIcon: {
    position: "absolute",
    left: "25%",
    top: 0,
    bottom: 0,
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 20,
    },
  },
  rightArrowIcon: {
    position: "absolute",
    right: "25%",
    top: 0,
    bottom: 0,
    margin: "auto",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: 20,
      width: 20,
    },
  },
  carousel: {
    "& .slider-frame": {
      padding: "50px 0",
    },
  },
  whiteBackgroundBox: {
    position: "absolute",
    height: 250,
    background: "#fff",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 100,
    },
  },
  shadowLeft: {
    position: "absolute",
    top: 16,
    bottom: 50,
    left: 0,
    width: "7%",
    borderRadius: "0 16px 16px 0",
    backgroundImage: "linear-gradient(to left, rgba(0, 0, 0, 0), #231f20)",
  },
  shadowRight: {
    position: "absolute",
    top: 16,
    bottom: 50,
    right: 0,
    width: "7%",
    borderRadius: "16px 0 0 16px",
    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), #231f20)",
  },
});

const Header = ({
  classes,
  cellAlign = "center",
  transitionMode = "scroll",
  withoutControls = true,
  isTablet = false,
  framePadding = 0,
  fullscreenViewIdx = 0,
  rectangle = null,
  keyboardControls = false,
  isMobile = false,
  cellSpacing = 32,
  wrapAround = true,
  slidesToShow = 2,
  heightMode = "first",
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const { data } = useQuery(HEADER_QUERY);
  if (!data) return null;

  const slides = (data && data.data && data.data.HeaderSlides) || [];
  return (
    <header className={classes.root}>
      <div className={classes.whiteBackgroundBox} />
      <Carousel
        className={classes.carousel}
        slideWidth={1.5}
        autoplay={true}
        autoplayInterval={4000}
        afterSlide={(idx) => setSlideIndex(idx)}
        cellAlign={cellAlign}
        withoutControls={withoutControls}
        transitionMode={transitionMode}
        disableKeyboardControls={true}
        cellSpacing={isTablet || isMobile ? 8 : cellSpacing}
        wrapAround={wrapAround}
        slidesToShow={slidesToShow}
        slideIndex={slideIndex}
        heightMode={heightMode}
      >
        {slides.map((slide, idx) => (
          <Slide
            key={idx}
            title={slide.title}
            text={slide.text}
            backgroundImage={
              isMobile && slide && slide.ImageSecondary
                ? getImage(slide.ImageSecondary.storage_name)
                : slide && slide.Image && getImage(slide.Image.storage_name)
            }
            ctaColor={slide.primaryColor || slide.secondaryColor || "red"}
            fontColor={slide.secondaryColor || "#fff"}
            ctaTitle={slide.ctaTitle}
            ctaLink={slide.ctaLink}
          />
        ))}
      </Carousel>
      <div key="left" className={classes.shadowLeft}>
        <img
          className={classes.leftArrowIcon}
          src={LeftArrowImg}
          onClick={() => setSlideIndex(slideIndex - 1)}
          alt="Left arrow"
        />
      </div>
      <div key="right" className={classes.shadowRight}>
        <img
          className={classes.rightArrowIcon}
          src={RightArrowImg}
          onClick={() => setSlideIndex(slideIndex + 1)}
          alt="Right arrow"
        />
      </div>
    </header>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 768,
  isTablet: width >= 768 && width < 960,
  isMedium: width >= 960 && width < 1200,
  isLarge: width >= 1200 && width < 1640,
  isUltra: width >= 1640,
});

export default withSizes(mapSizesToProps)(withStyles(styles)(Header));
