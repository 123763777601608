import React from 'react';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import HandsImg from '../resources/img/hand-2@2x.png';
import CustomButton from './CustomButton';
import { compose } from 'recompose';
import { HashLink as Link } from 'react-router-hash-link';
import { scrollToElement } from '../helpers';
import { FormattedMessage, injectIntl } from 'react-intl';
import generalMessages from '../i18n/messages';

const styles = theme => ({
  root: {
    background: `url(${HandsImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    padding: '32px 0',
    backgroundSize: 'contain',
    maxWidth: '1200px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px 8px 8px',
    },
  },
  titleContainer: {
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
  },
  title: {
    fontSize: 30,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      display: 'none',
    },
  },
  titleMobile: {
    fontSize: 30,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      display: 'block',
    },
  },
  icon: {
    marginRight: 12,
    position: 'relative',
    top: 3,
    [theme.breakpoints.down('xs')]: {
      height: 16,
      width: 16,
    },
  },
  bonusCardItem: {
    fontFamily: 'TypoComica',
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '12px',
    },
  },
  bonusCardContent: {
    maxWidth: 700,
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 400,
    },
  },
  button: {
    height: 56,
    fontSize: 16,
    padding: '8px 32px',
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      height: 50,
      padding: '4px 16px',
      marginTop: 26,
    },
    [theme.breakpoints.down('xs')]: {
      height: 35,
      marginTop: 26,
      padding: '4px 16px',
      fontSize: 12,
    },
  },
  bonusCard: {
    background: '#fff',
    borderRadius: '10px',
    padding: '64px',
    boxShadow: '0 16px 48px 0 rgba(35, 31, 32, 0.16)',
    margin: 'auto',
    maxWidth: '960px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      padding: '32px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      padding: 32,
    },
  },
  captionsContainer: {
    margin: '32px 0',
    [theme.breakpoints.down('sm')]: {
      margin: '24px 0',
      marginLeft: '-16px',
      textAlign: 'center',
      justifyContent: 'left',
    },
    [theme.breakpoints.down('md')]: {
      margin: '26px 0 30px 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '8px 0 12px 0',
    },
    //
  },
});

const BonusCardSection = ({ classes, intl: { formatMessage } }) => (
  <div className={classes.root}>
    <div className={classes.bonusCard}>
      <div className={classes.bonusCardContent}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="title">
            <FormattedMessage
              id="section.bonuscard.title.part1"
              defaultMessage="ZÍSKAJ KOPU VÝHOD S "
            />
            &nbsp;
            <span style={{ color: '#cf2c91' }}>
              <FormattedMessage
                id="section.bonuscard.title.part2"
                defaultMessage="BONUSOVOU KARTOU."
              />
            </span>
          </Typography>
          <Typography className={classes.title} variant="title">
            <FormattedMessage
              id="section.bonuscard.message"
              defaultMessage="REGISTRÁCIA JE ZADARMO."
            />
          </Typography>
          <Typography className={classes.titleMobile} variant="title">
            <FormattedMessage
              id="section.bonuscard.title2.part1"
              defaultMessage="ZÍSKAJ KOPU VÝHOD S "
            />
            &nbsp;
            <span style={{ color: '#cf2c91' }}>
              <FormattedMessage
                id="section.bonuscard.title2.part2"
                defaultMessage="BONUSOVOU KARTOU."
              />
            </span>
            <FormattedMessage
              id="section.bonuscard.message2"
              defaultMessage="REGISTRÁCIA JE ZADARMO."
            />
          </Typography>
        </div>

        <Link
          to={`/${formatMessage(generalMessages.linkBonusCard)}`}
          scroll={el => scrollToElement(el)}
          style={{ textDecoration: 'none' }}
        >
          <CustomButton classes={{ root: classes.button }}>
            <FormattedMessage
              id="section.bonuscard.button"
              defaultMessage="CHCEM BONUSOVÚ KARTU"
            />
          </CustomButton>
        </Link>
      </div>
    </div>
  </div>
);

export default compose(injectIntl, withStyles(styles))(BonusCardSection);
