import React from "react";
import { withStyles } from "@material-ui/core";
import { CardTitle } from "../Typography";
import SimpleLink from "../SimpleLink";
import { hexToRgba } from "../../helpers";
import { ExternalLink } from "../SvgIcon";

const styles = (theme) => ({
  root: {
    background: "#7f7fc7",
    borderRadius: 16,
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderRadius: 16,
  },
  content: {
    padding: 10,
    height: 250,
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
    [theme.breakpoints.down("sm")]: {
      height: 150,
    },
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("320")]: {
      paddingTop: 20,
    },
  },
  icon: {
    width: 136,
    height: 136,
    borderRadius: 10,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      height: 136,
      width: 136,
    },
    [theme.breakpoints.down("md")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      width: 50,
    },
    [theme.breakpoints.down("320")]: {
      height: 40,
      width: 40,
    },
  },
  title: {
    fontSize: 40,
    [theme.breakpoints.down("lg")]: {
      fontSize: 30,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("320")]: {
      padding: "4px 0",
    },
  },
  link: {
    // lineHeight: "22px",
    fontSize: 14,
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 25,
    [theme.breakpoints.down("md")]: {
      bottom: 15,
    },
    [theme.breakpoints.down("320")]: {
      bottom: 0,
    },
    // marginTop: "-10px"
  },
});

const ZoneCard = ({
  classes,
  width = "auto",
  icon = null,
  bgImage = null,
  title,
  primaryColor = "#231f20",
  handleOnClick,
  actionTitle,
  onActionClick,
}) => {
  const defaultBoxShadow = "0 16px 32px 0 rgba(35, 31, 32, 0.16)";
  const shadowColor = hexToRgba(primaryColor, 0.3);

  const rootStyle = {
    backgroundImage: bgImage
      ? `url(${bgImage})`
      : "linear-gradient(157deg, rgba(0, 0, 0, 0.1), rgba(55, 55, 55, 0.3))",
    width,
    boxShadow: shadowColor ? `0 16px 32px 0 ${shadowColor}` : defaultBoxShadow,
  };

  return (
    <div onClick={handleOnClick} className={classes.root} style={rootStyle}>
      <div
        className={classes.imageWrapper}
        style={{ backgroundColor: `rgba(35, 31, 32, 0.5)` }}
      >
        <div className={`card-content ${classes.content}`}>
          {icon ? <img className={classes.icon} src={icon} alt="icon" /> : null}
          <CardTitle additionalStyles={classes.title}>{title}</CardTitle>
        </div>
        {actionTitle && (
          <SimpleLink
            responsive
            additionalClassses={classes.link}
            onActionClick={onActionClick}
            svg={<ExternalLink color={primaryColor} />}
          >
            {actionTitle}
          </SimpleLink>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(ZoneCard);
