import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "../../resources/img/cancel.svg";
import { HashLink as Link } from "react-router-hash-link";
import { scrollToElement } from "../../helpers";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    border: "0",
    cursor: "pointer",
    width: 110,
    position: "absolute",
    right: 8,
    outline: "none",
    top: 20,
    fontSize: "11px",
    zIndex: "9999",
    color: "#efefef",
    background: "none",
    textAlign: "center",
    lineHeight: 2,
    fontFamily: "lato",
    [theme.breakpoints.down("md")]: {
      top: 10,
      right: 5,
      width: 70,
    },
    [theme.breakpoints.down("sm")]: {
      top: 8,
      right: 5,
      width: 50,
    },
    [theme.breakpoints.down("xs")]: {
      top: 8,
      right: 5,
      width: 40,
    },
  },
  icon: {
    width: "80%",
    outline: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const CloseButton = ({ classes, handleClose, href = "/" }) => (
  <Link
    style={{ outline: "none" }}
    to={href}
    scroll={(el) => scrollToElement(el)}
  >
    <button onClick={handleClose} className={classes.root}>
      <img className={classes.icon} src={CloseIcon} alt="Close button" />
      <div className={classes.title}>
        <FormattedMessage
          id="general.closebutton.title"
          defaultMessage="ZATVORIŤ KARTU"
        />
      </div>
    </button>
  </Link>
);

export default withStyles(styles)(CloseButton);
