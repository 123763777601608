import React from "react";
import NumberInput from "../../components/Reservation/NumberInput";
import { FormattedMessage } from "react-intl";
import { withStyles, Grid, TextField, Typography } from "@material-ui/core";

const styles = theme => ({
  root: {
    maxWidth: 700,
    margin: "auto"
  },
  text: {
    fontFamily: "lato",
    fontSize: 14,
    padding: 8
  },
  leftCol: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  rightCol: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  errorText: {
    color: "red",
    padding: 16,
    fontFamily: "lato"
  }
});

const StepPersons = ({
  errors,
  classes,
  adultCount,
  childrenCount,
  celebratings,
  handleChange,
  handleChangeCelebratings
}) => (
  <div className={classes.root} spacing={32}>
    <Grid container>
      <Grid className={classes.leftCol} item lg={6} md={6} sm={12} xs={12}>
        <Typography className={classes.text}>
          <FormattedMessage
            id="partyreservation.quantitycildren.title"
            defaultMessage="POČET DETÍ VRÁTANE OSLÁVENCA"
          />
        </Typography>
      </Grid>
      <Grid className={classes.rightCol} lg={6} md={6} sm={12} xs={12}>
        <NumberInput
          value={childrenCount}
          onChange={value => handleChange("childrenCount", value)}
        />
      </Grid>
    </Grid>
    <Grid container>
      <Grid className={classes.leftCol} item lg={6} md={6} sm={12} xs={12}>
        <Typography className={classes.text}>
          <FormattedMessage
            id="partyreservation.quantityadults.title"
            defaultMessage="POČET DOSPELÝCH"
          />
        </Typography>
      </Grid>
      <Grid className={classes.rightCol} item lg={6} md={6} sm={12} xs={12}>
        <NumberInput
          value={adultCount}
          onChange={value => handleChange("adultCount", value)}
        />
      </Grid>
    </Grid>
    <Grid justify="center" container spacing={32}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          //error={this.state.errors.name && true}
          id="name"
          label={
            <FormattedMessage
              id="partyreservation.honoreename.title"
              defaultMessage="Meno oslávenca"
            />
          }
          onClick={e => e.target.focus()}
          //value={this.state.data.name}
          fullWidth
          onChange={e =>
            handleChangeCelebratings("celebratingName", e.target.value)
          }
          InputLabelProps={{
            classes: {
              root: classes.textField
            }
          }}
          value={celebratings[0].celebratingName}
          //className={classes.textField}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          //error={this.state.errors.name && true}
          onChange={e =>
            handleChangeCelebratings("celebratingAge", e.target.value)
          }
          label={
            <FormattedMessage
              id="partyreservation.honoreeage.title"
              defaultMessage="Vek oslávenca"
            />
          }
          onClick={e => e.target.focus()}
          value={celebratings[0].celebratingAge}
          fullWidth
          //onChange={this.handleChange('name')}
          InputLabelProps={{
            classes: {
              root: classes.textField
            }
          }}
          //className={classes.textField}
        />
      </Grid>
      <div className={classes.errorText}>{errors.celebrating}</div>
    </Grid>
  </div>
);

export default withStyles(styles)(StepPersons);
