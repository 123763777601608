import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import Card from "./Card";

const styles = (theme) => ({
  root: {},
  activeTab: {
    borderRadius: "8px 8px 0 0",
    backgroundColor: "#fff",
    fontFamily: "PaytoneOne",
    marginBottom: "-16px",
    padding: "8px 8px 24px 8px",
    marginRight: 8,
    cursor: "pointer",
    zIndex: 1,
  },
  tab: {
    borderRadius: "8px 8px 0 0",
    backgroundColor: "#f1f1f1",
    fontFamily: "PaytoneOne",
    marginBottom: "-16px",
    padding: "8px 8px 24px 8px",
    marginRight: 8,
    cursor: "pointer",
    zIndex: 1,
    textAlign: "center",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    zIndex: 1,
  },
  content: {
    backgroundImage:
      "linear-gradient(to bottom, #ffffff, rgba(216, 216, 216, 0))",
    display: "flex",
    borderRadius: "16px 16px 0 0",
    padding: 16,
    zIndex: 2,
    position: "relative",
  },
});

class AdditionalZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  setActiveTab = (idx) => {
    this.setState({
      activeTab: idx,
    });
  };

  getItemCount = (itemId) => {
    const { selected } = this.props;
    const item = selected.find((x) => x.id === itemId);
    const count = (item && item.count) || 0;
    return count;
  };

  render = () => {
    const { classes, items = [], onChange } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.tabContainer}>
          {items.map((category, idx) => (
            <div
              onClick={() => this.setActiveTab(idx)}
              className={
                this.state.activeTab === idx ? classes.activeTab : classes.tab
              }
              key={idx}
            >
              {category.title}
            </div>
          ))}
        </div>
        <div className={classes.content}>
          <Grid container justify="center" spacing={16}>
            {items[this.state.activeTab] &&
              items[this.state.activeTab].Items.map((item, idx) => (
                <Grid key={idx} item lg={2} md={3} sm={4} xs={6}>
                  <Card
                    onChange={(count) => onChange(item.id, count)}
                    key={idx}
                    {...item}
                    count={this.getItemCount(item.id)}
                    unit={item.units}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(AdditionalZone);
