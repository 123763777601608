import React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";
import { scrollToElement } from "../../helpers";

const styles = (theme) => ({
  root: {
    listStyle: "none",
    padding: 0,
  },
  link: {
    padding: "8px 0",
    color: "#353535",
    textDecoration: "none",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "lato",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100%",
      display: "block",
    },
  },
  activeLink: {
    padding: "8px 0",
    fontFamily: "lato",
    fontWeight: 500,
    fontSize: "14px",
    color: theme.palette.secondary.main,
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      width: "100%",
      display: "block",
    },
  },
});

const NavbarLink = ({
  title,
  href,
  location,
  handleOnClick,
  classes,
  children,
}) => {
  const isActive = location.pathname + location.hash === href;

  return (
    <li onClick={handleOnClick} className={classes.root}>
      <Link
        className={isActive ? classes.activeLink : classes.link}
        to={href}
        scroll={(el) => scrollToElement(el)}
      >
        {title ? title.toUpperCase() : children}
      </Link>
    </li>
  );
};

export default withStyles(styles)(withRouter(NavbarLink));
