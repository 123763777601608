import React, { Component } from "react";
import SkFlag from "../../resources/img/sk_flag.png";
import PlFlag from "../../resources/img/pl_flag.png";
import { getLang } from "../../helpers";
import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
  root: {
    backgroundColor: "#cdcdcd",
    borderRadius: 8,
    padding: 0,
    paddingRight: 8,
    paddingLeft: 8,
    height: 32,
  },
  image: {
    height: 32,
    cursor: "pointer",
  },
  menuItem: {
    fontSize: 14,
  },
});

class LangMenu extends Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (lang) => {
    if (lang === "sk") {
      window.location = "https://www.kinderlandno.sk";
    }

    if (lang === "pl") {
      window.location = "https://www.kinderlandno.pl";
    }

    this.setState({ anchorEl: null });
  };

  getFlag = (lang) => {
    if (lang === "sk") return SkFlag;
    if (lang === "pl") {
      return PlFlag;
    }
  };

  render = () => {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const lang = getLang();

    return (
      <div className={classes.root}>
        <img
          onClick={this.handleClick}
          className={classes.image}
          src={this.getFlag(lang)}
          alt="Flag"
        />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            className={classes.menuItem}
            onClick={() => this.handleClose("sk")}
          >
            <img className={classes.image} src={SkFlag} alt="Flag" />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={() => this.handleClose("pl")}
          >
            <img className={classes.image} src={PlFlag} alt="Flag" />
          </MenuItem>
        </Menu>
      </div>
    );
  };
}

export default withStyles(styles)(LangMenu);
