import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import Slider from "react-slick";
import { NextButton, PrevButton } from "./NavButtons";
import SimpleLink from "../../components/SimpleLink";
import { ExternalLink } from "../../components/SvgIcon";

const styles = (theme) => ({
  root: {
    "& .slick-slide": {
      marginLeft: "32px",
      [theme.breakpoints.down("md")]: {
        marginLeft: 8,
      },
      borderRadius: 16,
      boxShadow: "0 6px 24px 0 rgba(35, 31, 32, 0.24)",
      "& > div": {
        borderRadius: 16,
      },
    },
    "& .slick-list": {
      marginLeft: "-32px",
      [theme.breakpoints.down("md")]: {
        marginLeft: "-8px",
      },
    },
    "& .slick-track": {
      padding: "10px 0 50px 0",
    },
  },
  titleContainer: {
    padding: "0 32px 32px 32px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      padding: "0 16px 16px 16px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px 0 16px",
    },
  },
  galleryTitle: {
    fontSize: 34,
    [theme.breakpoints.down("lg")]: {
      fontSize: 34,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  icon: {
    display: "inline-block",
    height: "96px",
    width: "96px",
    margin: "0 15px",
    [theme.breakpoints.down("lg")]: {
      height: 82,
      width: "auto",
    },
    [theme.breakpoints.down("md")]: {
      height: 50,
      width: "auto",
    },
  },
  linkButton: {
    position: "absolute",
    margin: 5,
    right: "64px",
    fontSize: "0.8em",
    fontWeight: "bold",
  },
  linkContainer: {
    textTransform: "uppercase",
    fontSize: "1.2rem",
    cursor: "pointer",
    fontWeight: "bold",
    display: "flex",
    fontFamily: "lato",
    paddingTop: "15px",
    position: "absolute",
    right: 50,
  },
  link: {
    fontWeight: 800,
    fontSize: 14,
    color: "#231f20",
    fontFamily: "lato",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

const Gallery = ({
  classes,
  images = [],
  title = "",
  icon = null,
  color = "#231f20",
  ctaLink = "",
  dragEnd = () => {},
  dragging = false,
  ctaTitle = "",
}) => {
  const settings = {
    className: "slider variable-width",
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        {icon && <img className={classes.icon} src={icon} alt="Gallery Icon" />}

        <Typography
          className={classes.galleryTitle}
          gutterBottom
          align="center"
          variant="title"
        >
          {title}
        </Typography>
        {ctaLink && ctaTitle && (
          <div className={classes.linkContainer}>
            <a
              style={{ display: "flex", textDecoration: "none" }}
              href={ctaLink}
            >
              <SimpleLink
                additionalClassses={classes.link}
                svg={<ExternalLink width={25} height={20} color={color} />}
              >
                {ctaTitle}
              </SimpleLink>
            </a>
          </div>
        )}
      </div>
      <Slider onSwipe={dragEnd} swipeEvent={dragging} {...settings}>
        {images.map((image) => {
          return image;
        })}
      </Slider>
    </div>
  );
};

export default withStyles(styles)(Gallery);
