import React from "react";
import { withStyles } from "@material-ui/core";
import { CustomHeading } from "../../components/Typography";

const styles = theme => ({
  root: {
    position: "relative",
    borderRadius: 16,
    boxShadow: `0 16px 48px 0 rgba(35, 31, 32, 0.16)`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: 500,
    [theme.breakpoints.down("xs")]: {
      height: 220
    }
  },
  heading: {
    margin: 0,
    textTransform: "uppercase",
    position: "absolute",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
});

const ContactHeader = ({ classes, title, image }) => (
  <div style={{ backgroundImage: `url(${image})` }} className={classes.root}>
    <CustomHeading color="#fff" additionalStyles={classes.heading}>
      {title}
    </CustomHeading>
  </div>
);

export default withStyles(styles)(ContactHeader);
