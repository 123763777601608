import React, { Component } from "react";
import {
  withStyles,
  Grid,
  TextField,
  FormControlLabel,
  FormHelperText,
  Checkbox
} from "@material-ui/core";
import CustomButton from "../../components/CustomButton";
import ContactFormTitle from "./ContactFormTitle";
import MessageIcon from "../../resources/img/kontakty-message.svg";
import { Mutation } from "@apollo/react-components";
import { isEmpty, isEmail } from "validator";
import { NavLink } from "react-router-dom";
import gql from "graphql-tag";
const ReactGA = process.browser ? require("react-ga") : {};
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";
import messages from "./messages";

const CONTACT_MESSAGE = gql`
  mutation sendClientMessage($data: String!) {
    sendClientMessage(data: $data) {
      id
    }
  }
`;

const styles = theme => ({
  root: {
    padding: "32px 64px 16px 64px",
    borderRadius: 16,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    margin: "auto",
    background: "#fff",
    maxWidth: 800,
    [theme.breakpoints.down("sm")]: {
      padding: "32px 0px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "16px 0px"
    }
  },
  label: {
    fontSize: 14
  },
  textArea: {
    height: "100%",
    width: "100%",
    border: "1px solid rgba(0,0,0, 0.42)",
    "&:hover": {
      border: "2px solid rgb(24, 21, 22)",
      borderLeft: 0,
      borderTop: 0
    },
    "&::placeholder": {
      fontSize: 14
    },
    borderLeft: 0,
    resize: "none",
    borderTop: 0,
    fontFamily: "lato",
    fontSize: 18,
    padding: 8
  },
  gridContainer: {
    padding: 16,
    [theme.breakpoints.down("xs")]: {
      padding: 16
    }
  },
  textAreaError: {
    "&::placeholder": {
      color: "red"
    }
  },
  textField: {
    textTransform: "uppercase",
    fontSize: 14
  },
  textFieldError: {
    fontSize: 8
  },
  button: {
    height: 48,
    padding: "0px 32px",
    [theme.breakpoints.down("sm")]: {
      height: 48,
      padding: "4px 16px"
    },
    [theme.breakpoints.down("xs")]: {
      height: 40,
      padding: "4px 16px",
      fontSize: 14
    }
  },
  message: {
    fontFamily: "lato",
    fontSize: 20
  }
});

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState = {
      data: {
        name: "",
        email: "",
        telephone: "",
        message: "",
        gdpr: false
      },
      errors: {
        name: "",
        email: "",
        telephone: "",
        message: "",
        gdpr: ""
      }
    };

    return initialState;
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleChange = (e, name) => {
    const cleanErrors = {
      name: "",
      email: "",
      telephone: "",
      message: "",
      gdpr: ""
    };

    this.setState({
      data: {
        ...this.state.data,
        [name]: e.target.value
      },
      errors: cleanErrors
    });
  };

  handleChecked = () => {
    this.setState({
      data: {
        ...this.state.data,
        gdpr: !this.state.data.gdpr
      }
    });
  };

  validate = () => {
    const { data } = this.state;
    const {
      intl: { formatMessage }
    } = this.props;

    let valid = true;

    const errors = {};

    if (isEmpty(data.name)) {
      errors.name = formatMessage(messages.nameError);
      valid = false;
    }

    if (isEmpty(data.telephone)) {
      errors.telephone = formatMessage(messages.telephoneError);
      valid = false;
    }

    if (!isEmail(data.email)) {
      errors.email = formatMessage(messages.emailError);
      valid = false;
    }

    if (isEmpty(data.message)) {
      errors.message = formatMessage(messages.messageError);
      valid = false;
    }

    if (data.gdpr === false) {
      errors.gdpr = formatMessage(messages.gdprError);
      valid = false;
    }

    if (!valid) {
      this.setState({
        errors
      });
      return false;
    }

    return true;
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage }
    } = this.props;

    return (
      <div className={this.props.classes.root}>
        <div style={{ textAlign: "center" }}>
          <ContactFormTitle color="#B61C7C" icon={MessageIcon}>
            <FormattedMessage
              id="contact.form.title"
              defaultMessage="POŠLITE NÁM SPRÁVU"
            />
          </ContactFormTitle>
        </div>
        <div className={this.props.classes.gridContainer}>
          <Grid container spacing={32}>
            <Grid item sm={6}>
              <TextField
                error={this.state.errors.name ? true : false}
                label={this.state.errors.name || formatMessage(messages.name)}
                value={this.state.data.name}
                fullWidth
                onChange={e => this.handleChange(e, "name")}
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
              />
              <TextField
                label={this.state.errors.email || formatMessage(messages.email)}
                error={this.state.errors.email ? true : false}
                value={this.state.data.email}
                fullWidth
                onChange={e => this.handleChange(e, "email")}
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
                className={classes.textField}
              />
              <TextField
                label={
                  this.state.errors.telephone ||
                  formatMessage(messages.telephone)
                }
                error={this.state.errors.telephone ? true : false}
                value={this.state.data.telephone}
                fullWidth
                onChange={e => this.handleChange(e, "telephone")}
                InputLabelProps={{
                  classes: {
                    root: classes.textField
                  }
                }}
                className={classes.textField}
              />
            </Grid>
            <Grid item style={{ paddingTop: 26 }} sm={6} xs={12}>
              <textarea
                value={this.state.data.message}
                placeholder={
                  this.state.errors.message
                    ? this.state.errors.message
                    : formatMessage(messages.messagePlaceholder)
                }
                className={`${classes.textArea} ${this.state.errors.message &&
                  classes.textAreaError}`}
                onChange={e => this.handleChange(e, "message")}
              />
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: 16 }}>
          <FormControlLabel
            classes={{ label: classes.label }}
            control={
              <Checkbox
                checked={this.state.data.gdpr}
                onChange={e => this.handleChecked(e, "gdpr")}
                color="primary"
              />
            }
            label={
              <NavLink
                style={{ color: "#231f20" }}
                to={`/${formatMessage(generalMessages.linkGdpr)}`}
              >
                <FormattedMessage
                  id="contact.gdpr.title"
                  defaultMessage="Súhlasím so spracovaním osobných údajov podľa GDPR"
                />
              </NavLink>
            }
          />
          {this.state.errors.gdpr && (
            <FormHelperText style={{ color: "red", textAlign: "center" }}>
              {this.state.errors.gdpr}
            </FormHelperText>
          )}
        </div>
        <div>
          <Mutation mutation={CONTACT_MESSAGE} ssr={false}>
            {(sendClientMessage, { data, loading, error }) => (
              <div>
                <CustomButton
                  addtionalClasses={classes.button}
                  handleOnClick={() => {
                    if (this.validate()) {
                      sendClientMessage({
                        variables: {
                          data: JSON.stringify(this.state.data)
                        }
                      }).then(res => {
                        this.resetState();
                        ReactGA.event({
                          category: "kontakt",
                          action: "Odoslanie formulara",
                          label: "Odoslať"
                        });
                      });
                    }
                  }}
                >
                  <FormattedMessage
                    id="contact.form.submit.button.title"
                    defaultMessage="ODOSLAŤ"
                  />
                </CustomButton>
                <div
                  className={classes.message}
                  style={{ padding: "16px 16px 0 16px" }}
                >
                  {loading && formatMessage(messages.loading)}
                  {error && formatMessage(messages.error)}
                  {data && formatMessage(messages.success)}
                </div>
              </div>
            )}
          </Mutation>
        </div>
      </div>
    );
  };
}

export default injectIntl(withStyles(styles)(ContactForm));
