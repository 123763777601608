import React, { useEffect } from "react";
import { withStyles, Grid, Divider, Typography } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { defineMessages } from "react-intl";
import dateFormat from "dateformat";
import { compose } from "recompose";

const styles = (theme) => ({
  root: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px",
    },
  },
  table: {
    marginTop: 16,
    width: "100%",
    border: 0,
    fontFamily: "lato",
  },
  head: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  body: {
    "& > tr": {
      "& > td": {
        padding: 5,
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
    },
    "& > tr:nth-child(odd)": {
      backgroundColor: "#f6f6f6",
    },
  },
  totalPriceContainer: {
    fontSize: 16,
    fontFamily: "lato",
    fontWeight: 800,
    padding: "16px 0",
    textAlign: "right",
    borderTop: "1px solid #bbb",
  },
  buttonContainer: {
    marginTop: 32,
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      padding: "4px !important",
    },
  },
  gridContainer: {
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  rowTitle: {
    fontWeight: 800,
    fontSize: 16,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  rowText: {
    fontSize: 16,
    textAlign: "left",
  },
  message: {
    fontSize: 18,
    paddingTop: 16,
    fontFamily: "lato",
  },
  errorRow: {
    fontSize: 18,
    paddingTop: 8,
    color: "red",
    paddingBottom: 8,
  },
});

const messages = defineMessages({
  successMessage: {
    id: "reservation.simple.summary.success",
    defaultMessage: "Rezervácia prebehla úspešne, tešíme sa na Vás.",
  },
  errorMessage: {
    id: "reservation.simple.summary.error",
    defaultMessage: "Vyskytla sa neočakávaná chyba, skúste prosím neskôr.",
  },
  itemName: {
    id: "reservation.simple.item.name",
    defaultMessage: "Názov",
  },
  itemUnitPrice: {
    id: "reservation.simple.item.unitprice",
    defaultMessage: "Jednotková cena",
  },
  itemCount: {
    id: "reservation.simple.item.count",
    defaultMessage: "Počet",
  },
  itemUnit: {
    id: "reservation.simple.item.unit",
    defaultMessage: "Jednotka",
  },
  itemTotal: {
    id: "reservation.simple.item.price",
    defaultMessage: "Cena",
  },
  itemTotalVat: {
    id: "reservation.simple.item.pricevat",
    defaultMessage: "Cena s DPH",
  },
  totalWithoutTax: {
    id: "reservation.simple.withoutVat",
    defaultMessage: "Cena bez DPH:",
  },
  total: {
    id: "reservation.simple.total",
    defaultMessage: "Spolu celkom k úhrade:",
  },
  errorFields: {
    id: "reservation.simple.error.fields",
    defaultMessage: "Zabudli ste vyplniť niektoré polia.",
  },
  button: {
    id: "reservation.simple.submit.button",
    defaultMessage: "POTVRDIŤ REZERVÁCIU",
  },
  reservationDate: {
    id: "reservation.date.label",
    defaultMessage: "Dátum rezervácie: ",
  },
});

const StepSummary = ({ classes, order, intl: { formatMessage } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getByKey = (key) => {
    const data = order.Data.find((x) => x.key === key);
    return data && data.value;
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.gridContainer}>
          <Grid container spacing={8}>
            <Grid
              className={classes.gridItem}
              align="right"
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              <Typography className={classes.rowTitle}>
                <FormattedMessage
                  id="reservation.simple.summary.contact"
                  defaultMessage="Kontaktné údaje: "
                />
              </Typography>
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              lg={8}
              md={8}
              sm={8}
              xs={12}
            >
              <Typography className={classes.rowText}>
                {getByKey("firstname")} {getByKey("surname")},{" "}
                {getByKey("email")}, {getByKey("telephone")}
                <br />
                {getByKey("address")}, {getByKey("zip")} {getByKey("city")},
              </Typography>
            </Grid>
            <Grid
              className={classes.gridItem}
              align="right"
              item
              lg={4}
              md={4}
              sm={4}
              xs={12}
            >
              {getByKey("date") && (
                <Typography className={classes.rowTitle}>
                  {formatMessage(messages.reservationDate)}
                </Typography>
              )}
            </Grid>
            <Grid
              className={classes.gridItem}
              item
              lg={8}
              md={8}
              sm={8}
              xs={12}
            >
              {getByKey("date") && (
                <Typography className={classes.rowText}>
                  {getByKey("date").includes(".")
                    ? getByKey("date")
                    : dateFormat(getByKey("date"), "d.m.yyyy")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <table cellSpacing="0" className={classes.table}>
          <thead className={classes.head}>
            <tr>
              <th>{formatMessage(messages.itemName)}</th>
              <th>{formatMessage(messages.itemUnitPrice)}</th>
              <th>{formatMessage(messages.itemCount)}</th>
              <th>{formatMessage(messages.itemUnit)}</th>
              <th>{formatMessage(messages.itemTotalVat)}</th>
            </tr>
          </thead>
          <tbody className={classes.body}>
            {order.Items.map((item, idx) => (
              <tr key={idx}>
                <td style={{ textAlign: "left" }}>{item.title}</td>
                <td>{item.unit_price_vat} €</td>
                <td>{item.count}</td>
                <td>{item.unit}</td>
                <td>{item.price_vat} €</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Divider />
        <div className={classes.totalPriceContainer}>
          {formatMessage(messages.totalWithoutTax)} {order.total}€<br />
          <b style={{ fontSize: "1.3rem" }}>
            {formatMessage(messages.total)} {order.total_vat}€
          </b>
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(StepSummary);
