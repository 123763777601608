import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        textAlign: "center",
        padding: 20
    },
    progress: {

    }
})

const Loading = ({classes}) => (
    <div className={classes.root}>
        <CircularProgress className={classes.progress} />
    </div>
)

export default withStyles(styles)(Loading);
