import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  time: {
    fontSize: "1.2em",
    color: theme.palette.error.main,
    padding: 8,
    marginBottom: 32,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.error.main,
    borderRadius: 8,
    margin: "auto",
    maxWidth: 300,
    width: "100%",
  },
  message: {
    color: theme.palette.error.main,
    padding: 8,
    marginBottom: 8,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.error.main,
    borderRadius: 8,
    margin: "auto",
    maxWidth: 300,
    width: "100%",
  },
});

const TimeEncounter = ({ date, onExpired, classes }) => {
  const [state, setState] = useState({
    expirationTime: date,
    expired: false,
    time: "",
  });

  useEffect(() => {
    const now = new Date().getTime();
    const countDownDate = new Date(state.expirationTime).getTime();
    const d = countDownDate - now;

    if (d < 0) onExpired();

    setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        onExpired();
        setState({
          ...state,
          expired: true,
        });
      } else {
        setState({
          ...state,
          time: `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`,
        });
      }
    }, 1000);
  }, [onExpired, state]);

  return (
    <div className={classes.root}>
      {state.expired && (
        <div className={classes.message}>
          <FormattedMessage
            id="order.expiration"
            defaultMessage="Objednávka expirovala"
          />
        </div>
      )}
      {!state.expired && state.time && (
        <div className={classes.time}>
          <FormattedMessage
            id="order.expiration.atd"
            defaultMessage="Objednávka expiruje o: "
          />
          <b> {state.time}</b>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(TimeEncounter);
