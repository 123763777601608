/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core";
import Card from "./Card";
import { useQuery } from "@apollo/react-hooks";
import { getAssetPath, getImage } from "../../helpers";
import handsIcon from "../../resources/img/hand-3.png";
import { withRouter } from "react-router-dom";
import { CustomHeading } from "../../components/Typography";
import Gallery from "./Gallery";
import Helmet from "react-helmet";
import gql from "graphql-tag";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import generalMessages from "../../i18n/messages";
import { compose } from "recompose";

const GALLERIES_QUERY = gql`
  query Galleries($filter: String) {
    data: getPageOfGalleries(filter: $filter) {
      items {
        id
        title
        code
        color
        Files {
          id
          storage_name
          resource_type
        }
        actionTitle
        actionHref
        Icon {
          storage_name
        }
      }
      totalCount
    }
  }
`;

const messages = defineMessages({
  metaTitle: {
    id: "gallery.meta.title",
    defaultMessage: "Kinderland - Galéria",
  },
  metaDescription: {
    id: "gallery.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const styles = (theme) => ({
  root: {
    paddingTop: 64,
    paddingBottom: 32,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 24,
    },
  },
  galleryContainer: {},
  gallery: {
    backgroundImage: `url(${handsIcon})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  filterContainer: {
    padding: 0,
  },
  link: {
    display: "block !important",
    borderRadius: 16,
  },
});

const GalleryPage = ({ intl: { formatMessage }, classes, history }) => {
  const [dragging, setDragging] = useState();
  const { data } = useQuery(GALLERIES_QUERY);
  const items = (data && data.data && data.data.items) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleDragging = () => {
    setDragging(true);
    setTimeout(() => setDragging(false), 1000);
  };

  const dragEnd = () => {
    setDragging(true);
    setTimeout(() => setDragging(false), 200);
  };

  const onClickHandler = (event, code, fileId) => {
    if (dragging) {
      event.preventDefault();
    } else {
      history.push(
        `/${formatMessage(generalMessages.linkGallery)}/${code}?file=${fileId}`
      );
    }
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{formatMessage(messages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(messages.metaDescription)}
        />
      </Helmet>
      <CustomHeading>
        <FormattedMessage
          id="gallery.galleryheader.title"
          defaultMessage="GALÉRIA"
        />
      </CustomHeading>
      <br />
      <div className={classes.filterContainer} />
      <div className={classes.galleryContainer}>
        {items
          .filter((x) => x.Files.length)
          .map((gallery, idx) => (
            <div
              id={`gallery-${gallery.id}`}
              className={classes.gallery}
              key={idx}
            >
              <Gallery
                galleryId={gallery.id}
                dragEnd={dragEnd}
                dragging={toggleDragging}
                ctaTitle={gallery.actionTitle}
                color={gallery.color}
                ctaLink={gallery.actionHref}
                icon={gallery.Icon && getAssetPath(gallery.Icon.storage_name)}
                title={gallery.title.toUpperCase()}
                images={
                  gallery.Files
                    ? gallery.Files.map((f, idx) => (
                        <div
                          className={classes.link}
                          key={idx}
                          onClick={(e) => onClickHandler(e, gallery.code, f.id)}
                        >
                          <Card
                            key={idx}
                            shading={false}
                            bgImage={getImage(f.storage_name)}
                          />
                        </div>
                      ))
                    : []
                }
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default compose(injectIntl, withRouter, withStyles(styles))(GalleryPage);
