import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "recompose";
import CustomButton from "../CustomButton";
import { withStyles } from "@material-ui/core";

const VRLINK = "/tour";

const styles = (theme) => ({
  root: {
    fontSize: "12px",
    padding: "0 16px",
    height: 32,
    minHeight: 25,
    fontFamily: "PaytoneOne",
    margin: "0 15px 0 0",
    boxShadow: "0 4px 16px 0 rgba(207, 44, 145, 0.24)",
    [theme.breakpoints.down("md")]: {
      fontSize: 10,
      padding: "0 8px",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      padding: "0 8px",
    },
  },
  link: {
    textDecoration: "none",
  },
});

const BonusCardButton = ({ classes }) => (
  <a className={classes.link} href={VRLINK} target="_blank">
    <CustomButton variant="outlined" classes={{ root: classes.root }}>
      <FormattedMessage
        id="navigation.button.vrtour"
        defaultMessage="Virtuálna prehliadka"
      />
    </CustomButton>
  </a>
);

export default compose(injectIntl, withStyles(styles))(BonusCardButton);
