import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { getImage } from "../../helpers";
import { Query } from "@apollo/react-components";
import gql from "graphql-tag";
import CinemaCard from "./CinemaCard";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {},
  card: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    boxShadow: "rgba(35, 31, 32, 0.2) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
  },
  cardActive: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    border: "1px solid rgb(207, 44, 145)",
    boxShadow: "rgba(207, 44, 145, 0.4) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
  },
});

const QUERY_MOVIES = gql`
  query getMovies($category: String, $type: String) {
    Movies: Movies(category: $category, type: $type) {
      id
      title
      price
      Image {
        storage_name
      }
    }
  }
`;

const StepMovie = ({
  classes,
  handleChange = () => {},
  category,
  type,
  movie = [],
}) => (
  <div className={classes.root}>
    <Grid spacing={16} container justify="center">
      <Query query={QUERY_MOVIES} variables={{ category, type }}>
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error) return null;

          if (!data.Movies || data.Movies.length === 0) {
            return (
              <div style={{ textAlign: "center" }}>
                <FormattedMessage
                  id="cinema.empty.category.message"
                  defaultMessage="V tejto kategórii sa nenachádza žiaden film"
                />
              </div>
            );
          }

          return data.Movies.map((x) => (
            <Grid xs={6} sm={4} md={3} lg={2} xl={2} item>
              <CinemaCard
                bgImage={x.Image && getImage(x.Image.storage_name)}
                handleChange={handleChange}
                value={x.id}
                price={x.price}
                active={movie === x.id}
                title={x.title}
              />
            </Grid>
          ));
        }}
      </Query>
    </Grid>
  </div>
);

export default withStyles(styles)(StepMovie);
