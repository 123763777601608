import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "shop.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje."
  },
  success: {
    id: "shop.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás"
  },
  metaTitle: {
    id: "shop.meta.title",
    defaultMessage: "Kinderland - Eshop"
  },
  metaDescription: {
    id: "shop.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo"
  },
  errorFirstname: {
    id: "shop.error.firstname",
    defaultMessage: "Zadajte meno"
  },
  errorSurname: {
    id: "shop.error.surname",
    defaultMessage: "Zadajte priezvisko"
  },
  errorTelephone: {
    id: "shop.error.telephone",
    defaultMessage: "Zadajte telefónne číslo"
  },
  errorGdpr: {
    id: "shop.error.gdpr",
    defaultMessage:
      "Potrebujeme od Vás povolenie pre spracovanie osobných údajov."
  },
  errorEmail: {
    id: "shop.error.email",
    defaultMessage: "Zadajte e-mailovú adresu"
  },
  errorMessage: {
    id: "shop.summary.error",
    defaultMessage: "Vyskytla sa neočakávaná chyba, skúste prosím neskôr."
  },
  dissabledEshopMessage: {
    id: "shop.dissabled.eshop",
    defaultMessage:
      "Rezervačný systém nie je momentálne dostupný. Skúste prosím neskôr."
  },
  disabledDate: {
    id: "shop.disabled.date",
    defaultMessage:
      "Vo vybranom dátume nie je možné vytvoriť rezerváciu. Zvoľte prosím iný dátum."
  },
  errorCity: {
    id: "shop.error.city",
    defaultMessage: "Zadajte obec"
  },
  errorAddress: {
    id: "shop.error.address",
    defaultMessage: "Zadajte ulicu a popisné číslo"
  },
  errorZip: {
    id: "shop.error.zip",
    defaultMessage: "Zadajte PSČ"
  },
  errorExtraZone: {
    id: "shop.error.extrazone",
    defaultMessage: "Je potrebné vybrať aspoň jednu položku z obchodu"
  }
});

export default messages;
