import React, { Fragment } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import Card from "../../components/Reservation/AdditionalZone/Card";
import { getImage } from "../../helpers";
import { Grid, withStyles, Typography } from "@material-ui/core";
import Loading from "../../components/Loading";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";
// import FetchIcon from "@material-ui/icons/Cached";

const styles = (theme) => ({
  button: {
    marginTop: 24,
  },
  fetchButton: {
    marginLeft: 8,
  },
  card: {
    height: 340,
    [theme.breakpoints.down("sm")]: {
      height: 320,
    },
    [theme.breakpoints.down("xs")]: {
      height: 320,
    },
  },
  gridItem: {
    [theme.breakpoints.down(420)]: {
      width: "100%",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
});

const QUERY = gql`
  query getAvailableBikes(
    $offset: Int
    $limit: Int
    $dateFrom: DateTime
    $dateTo: DateTime
    $timeFrom: Int
    $timeTo: Int
  ) {
    bikes: getAvailableBikes(
      offset: $offset
      limit: $limit
      dateFrom: $dateFrom
      dateTo: $dateTo
      timeFrom: $timeFrom
      timeTo: $timeTo
    ) {
      id
      title
      subtitle
      text
      available_count
      count
      price
      available
      Image {
        storage_name
      }
      image_id
    }
  }
`;

const messages = defineMessages({
  empty: {
    id: "reservation.ebike.emptybikes",
    defaultMessage:
      "Aby si videl ponuku dostupných bicyklov, musíš najskôr zvoliť dátum vyzdvihnutia a vrátenia bicykla.",
  },
});

const EBikeList = ({
  bikes = [],
  classes,
  onChange = () => {},
  dateTo,
  dateFrom,
  timeFrom,
  timeTo,
  intl: { formatMessage },
}) => {
  const { data, loading } = useQuery(QUERY, {
    variables: {
      offset: 0,
      limit: 100,
      dateFrom,
      dateTo,
      timeFrom,
      timeTo,
    },
    fetchPolicy: "cache-and-network",
  });

  if (!timeFrom || !timeTo || !dateTo || !dateFrom) {
    return null;
  }

  const items = (data && data.bikes) || [];

  const getEbikeCount = (bikeId) => {
    const bike = bikes.find((b) => b.id === bikeId);
    return (bike && bike.count) || 0;
  };

  return (
    <Fragment>
      <Grid justify="center" container spacing={8}>
        {items.map((bike, idx) => (
          <Grid
            className={classes.gridItem}
            item
            xs={6}
            sm={4}
            md={3}
            xl={3}
            key={idx}
          >
            <Card
              classes={{ root: classes.card }}
              title={bike.title}
              onChange={(count) => onChange(bike, count)}
              image={bike.Image && getImage(bike.Image.storage_name)}
              count={getEbikeCount(bike.id)}
              maxCount={bike.available_count}
              subtitle={bike.subtitle}
              elements={bike.text}
              price={bike.price}
              unit="ks"
              padding={8}
              height={320}
            />
          </Grid>
        ))}
      </Grid>
      {/* <Button
        className={classes.button}
        onClick={() => {
          fetchMore({
            variables: { offset: items.length },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return Object.assign({}, prev, {
                bikes: [...prev.bikes, ...fetchMoreResult.bikes]
              });
            }
          }).then(x => {
            console.log(x);
          });
        }}
        variant="outlined"
      >
        Zobraziť ďalšie bicykle
        <FetchIcon className={classes.fetchButton} />
      </Button> */}
      {loading && <Loading />}
    </Fragment>
  );
};

export default compose(withStyles(styles), injectIntl)(EBikeList);
