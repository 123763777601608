import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import CheckmarkIcon from "../../resources/img/checkmark.svg";
import LinkIcon from "../../resources/img/external-link-symbol-2.svg";
import dateFormat from "dateformat";

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "100vh",
    width: "auto",
    borderRadius: 16,
    "& > img": {
      borderRadius: 16,
      boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.24)",
      width: "auto",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
      maxWidth: "90vw",
      maxHeight: "90vh",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
});

const Card = ({ classes, bgImage }) => (
  <div className={classes.root}>
    <img className={classes.image} src={bgImage} alt="icon" />
  </div>
);

export default withStyles(styles)(Card);
