import React from "react";
import {
  TextField,
  MenuItem,
  withStyles,
  InputAdornment,
} from "@material-ui/core";
import TimeIcon from "@material-ui/icons/Timer";

const styles = (theme) => ({
  input: {
    fontSize: 16,
    color: theme.palette.grey[600],
  },
  label: {
    fontSize: 16,
    color: theme.palette.grey[500],
  },
  textField: {
    borderRadius: 4,
    width: 205,
    marginLeft: 16,
    background: theme.palette.grey[100],
  },
  menuItem: {
    fontSize: "12px !important",
  },
  adornment: {
    color: theme.palette.grey[500],
  },
});

const RESERVATION_TIMES = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

const TimeInput = ({
  value = "",
  times = RESERVATION_TIMES,
  classes,
  onChange = () => {},
  enabledFrom,
  ...rest
}) => {
  return (
    <TextField
      select
      SelectProps={{
        displayEmpty: true,
        MenuProps: { MenuListProps: { classes: { root: classes.list } } },
      }}
      InputProps={{
        classes: { root: classes.input },
        endAdornment: (
          <InputAdornment className={classes.adornment} position="end">
            <TimeIcon />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{
        shrink: value ? true : false,
        classes: { root: classes.label },
      }}
      classes={{ root: classes.textField }}
      variant="outlined"
      value={value}
      onChange={onChange}
      {...rest}
    >
      {times.map((x) => (
        <MenuItem
          disabled={enabledFrom && x <= enabledFrom}
          key={x}
          classes={{ classes: { root: classes.menuItem } }}
          value={x}
        >
          {x}:00
        </MenuItem>
      ))}
    </TextField>
  );
};

export default withStyles(styles)(TimeInput);
