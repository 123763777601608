import React, { useState, Fragment, useEffect } from "react";
import {
  withStyles,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Chip,
} from "@material-ui/core";
import NumberInput from "../NumberInput";
import { injectIntl } from "react-intl";
import { compose } from "recompose";
import messages from "../../../i18n/messages";
import clsx from "clsx";
import RichTextField from "../../../pages/rich-text-field/RichTextField";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    position: "relative",
    borderRadius: 16,
    height: 270,
    overflow: "hidden",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
  },
  bottomPanel: {
    position: "absolute",
    bottom: 4,
    left: 0,
    right: 0,
  },
  imageContainer: {
    height: "45%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  content: {
    padding: 16,
    height: "55%",
    position: "relative",
  },
  title: {
    fontFamily: "lato",
    fontSize: 16,
    fontWeight: 600,
  },
  subtitle: {
    fontSize: 14,
  },
  price: {
    textAlign: "center",
    fontFamily: "TypoComica",
    fontSize: 14,
    fontWeight: 600,
  },
  descriptionButton: {
    fontSize: "0.8rem",
    padding: "5px 6px 5px 6px",
    lineHeight: 1,
    marginTop: 8,
  },
  dialogTitle: {
    fontSize: "1.6rem",
    fontFamily: "PaytoneOne",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    color: theme.palette.common.white,
  },
  dialogSubtitle: {
    fontSize: "1rem",
    fontFamily: "PaytoneOne",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    color: theme.palette.common.white,
  },
  fixedHeight: {
    height: "25vh",
  },
  chip: {
    fontFamily: "TypoComica",
    fontSize: "1rem",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.grey[200],
    margin: 0,
    padding: 8,
  },
  dialogContent: {
    padding: 16,
    paddingTop: 8,
  },
  dialog: {
    width: "100%",
    maxWidth: 500,
  },
  imageOverlay: {
    width: "100%",
    height: "100%",
    backgroundImage: `linear-gradient(157deg, rgba(35, 31, 32, 0.7), rgba(35, 31, 32, 0.26))`,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  textCount: {
    fontSize: "0.8em",
  },
});

const PackageCard = ({
  classes,
  title = "",
  subtitle,
  image = null,
  count,
  maxCount,
  onChange = () => {},
  defaultCount,
  price = null,
  unit,
  elements,
  intl: { formatMessage },
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const imageStyles = {};

  useEffect(() => {
    defaultCount && onChange(defaultCount);
  }, [defaultCount]);

  if (image) imageStyles.backgroundImage = `url(${image})`;

  return (
    <div className={classes.root}>
      <div style={imageStyles} className={classes.imageContainer}></div>
      <div className={classes.content}>
        <Typography align="center" className={classes.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography align="center" classes={{ root: classes.subtitle }}>
            {subtitle}
          </Typography>
        )}

        {elements && (
          <Fragment>
            <Button
              onClick={() => setShowDescription(!showDescription)}
              className={classes.descriptionButton}
              variant="outlined"
            >
              {formatMessage(messages.showDescription)}
            </Button>
            <Dialog
              classes={{ paper: classes.dialog }}
              onClose={() => setShowDescription(false)}
              open={showDescription}
            >
              <div
                style={imageStyles}
                className={clsx(classes.imageContainer, classes.fixedHeight)}
              >
                <div className={classes.imageOverlay}>
                  <Typography
                    gutterBottom
                    classes={{ root: classes.dialogTitle }}
                  >
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography classes={{ root: classes.dialogSubtitle }}>
                      {subtitle}
                    </Typography>
                  )}
                </div>
              </div>
              <DialogContent className={classes.dialogContent}>
                <RichTextField>{elements}</RichTextField>
              </DialogContent>
              <DialogActions className={classes.actions}>
                {price && unit ? (
                  <Chip
                    color="secondary"
                    className={classes.chip}
                    label={`${price}€ / ${unit}`}
                  />
                ) : null}
                <NumberInput
                  classes={{ root: classes.dialogNumberInput }}
                  onChange={onChange}
                  max={maxCount}
                  value={count}
                />
                <Button
                  onClick={() => setShowDescription(false)}
                  variant="outlined"
                >
                  {formatMessage(messages.closeButton)}
                </Button>
              </DialogActions>
            </Dialog>
          </Fragment>
        )}
        <div className={classes.bottomPanel}>
          {price && unit ? (
            <div className={classes.price}>
              {price}€ / {unit}
            </div>
          ) : null}
          {maxCount ? (
            <Typography
              variant="caption"
              className={classes.textCount}
              gutterBottom
            >
              Počet dostupných kusov: {maxCount}
            </Typography>
          ) : null}
          <NumberInput
            max={maxCount}
            onChange={onChange}
            value={count}
            fullwidth
          />
        </div>
      </div>
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(PackageCard);
