import React from "react";
import { withStyles } from "@material-ui/core";
import { hexToRgba } from "../helpers";
import SimpleLink from "./SimpleLink";
import { ArrowRight } from "./SvgIcon";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../i18n/messages";

const styles = (theme) => ({
  root: {
    width: "100%",
    borderRadius: 16,
    display: "flex",
    padding: 0,
    height: 326,
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },
  imgSection: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    width: "50%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: 16,
    background: "#fff",
    marginRight: 8,
  },
  contentSection: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    marginLeft: 8,
    textAlign: "center",
    padding: 32,
    width: "50%",
    height: "100%",
    position: "relative",
    borderRadius: 16,
    boxShadow: "0 16px 48px 0 rgba(57, 181, 74, 0.24)",
    //backgroundImage: "linear-gradient(138deg, #51c862, #39b54a)"
  },
  price: {
    margin: "auto",
    textAlign: "center",
    position: "absolute",
    padding: 16,
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    fontFamily: "TypoComica",
    color: "#fff",
    fontSize: "2.5em",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2.4em",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.6em",
    },
  },
  link: {
    position: "absolute",
    bottom: 10,
    margin: "auto",
    left: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      bottom: 5,
      lineHeight: "10px",
    },
  },
});

const PriceCard = ({
  classes,
  price = "",
  image = null,
  button = true,
  backgroundColor = "#fff",
  intl: { formatMessage },
}) => {
  const imgSectionStyle = {};

  if (image) {
    imgSectionStyle.backgroundImage = `url(${image})`;
  }

  const backgroundImage = `linear-gradient(126deg, ${hexToRgba(
    backgroundColor,
    0.8
  )}, ${hexToRgba(backgroundColor, 1)})`;

  return (
    <div className={classes.root}>
      <div style={imgSectionStyle} className={classes.imgSection} />
      <div
        style={{
          backgroundImage,
          boxShadow: `0 16px 48px 0 ${hexToRgba(backgroundColor, 0.24)}`,
        }}
        className={classes.contentSection}
      >
        <div className={classes.price}>{price}</div>
        {button && (
          <SimpleLink
            alwaysDisplayText
            additionalClassses={classes.link}
            responsive
            svg={<ArrowRight color="#fff" />}
            onActionClick={() =>
              (window.location = `/${formatMessage(messages.linkPricing)}`)
            }
            fontColor="#fff"
          >
            <FormattedMessage
              id="price.card.pricebutton.title"
              defaultMessage="PREJSŤ NA CENNÍK"
            />
          </SimpleLink>
        )}
      </div>
    </div>
  );
};

export default injectIntl(withStyles(styles)(PriceCard));
