import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import LinkIcon from "../../resources/img/external-link-symbol-2.svg";
import dateFormat from "dateformat";
import { CardTitle } from "../Typography";
import SimpleLink from "../SimpleLink";
import { ExternalLink2 } from "../SvgIcon";

const styles = (theme) => ({
  root: {
    background: "#7f7fc7",
    // margin: "0 10px",
    borderRadius: 16,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage:
      "linear-gradient(130deg, rgba(35, 31, 32, 0.16), rgba(35, 31, 32, 0.56))",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  feed: {
    height: 326,
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down("md")]: {
      height: 280,
    },
    [theme.breakpoints.down("sm")]: {
      height: 200,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  content: {
    padding: 32,
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
    [theme.breakpoints.down("320")]: {
      padding: 4,
    },
  },
  title: {
    lineHeight: 1.5,
    fontSize: 24,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
      paddingBottom: 32,
    },
  },
  date: {
    fontFamily: "TypoComica",
    padding: "8px 0",
    color: "#d1d3d4",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  link: {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 50,
    [theme.breakpoints.down("md")]: {
      bottom: 15,
    },
    [theme.breakpoints.down("320")]: {
      bottom: 5,
      fontSize: 8,
    },
  },
});

const PostCard = ({
  classes,
  width = "auto",
  bgImage,
  feed = false,
  title,
  onActionClick,
  date,
  primaryColor = "#231f20",
  actionTitle,
  handleOnClick,
}) => (
  <div
    onClick={handleOnClick}
    className={`${classes.root} ${feed ? classes.feed : ""}`}
    style={{ width, backgroundImage: `url(${bgImage})` }}
  >
    <div
      className={classes.imageWrapper}
      style={{ backgroundColor: `rgba(35, 31, 32, 0.5)` }}
    >
      <div className={`card-content ${classes.content}`}>
        {date && (
          <Typography className={classes.date} align="center" variant="body1">
            {dateFormat(date, "dddd, d. mmmm yyyy")}
          </Typography>
        )}
        <CardTitle additionalStyles={classes.title}>{title}</CardTitle>
      </div>
      {actionTitle && (
        <SimpleLink
          responsive
          additionalClassses={classes.link}
          onActionClick={onActionClick}
          icon={LinkIcon}
          svg={<ExternalLink2 />}
        >
          {actionTitle}
        </SimpleLink>
      )}
    </div>
  </div>
);

export default withStyles(styles)(PostCard);
