import React, { Fragment, useState, useRef } from "react";
import { withStyles } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import PaymentSummary from "../components/Reservation/PaymentSummary";
import CustomButton from "../components/CustomButton";
import { Title } from "../components/Reservation/ReservationHeadings";
import { FormattedMessage } from "react-intl";
import VoucherInput from "../components/Reservation/VoucherInput";
import TimeEncounter from "../components/Reservation/TimeEncounter";
import Message from "../components/Reservation/Message";
import config from "../config";
import { injectIntl, defineMessages } from "react-intl";
import Loading from "../components/Loading";
import { compose } from "recompose";
import { useEffect } from "react";
import Content from "../components/General/Content";
import Section from "../components/General/Section";

const ORDER_INFO = gql`
  query Order($token: String!) {
    Order: Order(token: $token) {
      total
      total_vat
      createdAt
      paid
      expirationTime
      Items {
        title
        unit_price
        unit_price_vat
        price
        count
        unit
        price_vat
      }
      Data {
        key
        title
        value
      }
      PaymentData {
        clientId
        oid
        amount
        okurl
        failurl
        transactionType
        currency
        storeType
        lang
        rnd
        instalment
        hashAlgorithm
        encoding
        msAuthType
        msKey
        hash
      }
    }
  }
`;

const styles = () => ({
  section: {
    paddingTop: 16,
    paddingBottom: 32,
  },
  submitButton: {
    margin: "auto",
  },
  error: {
    marginTop: 32,
  },
  errorRow: {
    background: "#fff",
  },
});

const messages = defineMessages({
  success: {
    id: "order.message.success",
    defaultMessage: "Objednávka je úspešne uhradená.",
  },
  error: {
    id: "order.message.fail",
    defaultMessage: "Vyskytol sa problém pri úhrade",
  },
});

const Order = ({ classes, failed, intl: { formatMessage }, match }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [expired, setExpired] = useState(false);
  const { data, loading, error, refetch } = useQuery(ORDER_INFO, {
    variables: { token: match.params.token },
    fetchPolicy: "no-cache",
  });
  const form = useRef(null);
  const order = data && data.Order;

  if (loading) return <Loading />;

  if (error) {
    return (
      <div className={classes.error}>
        {error.graphQLErrors.map((x, i) => (
          <Message
            message={x.message}
            classes={{ error: classes.errorRow }}
            type="error"
            key={i}
          />
        ))}
      </div>
    );
  }

  if (!order) return null;

  const {
    clientId,
    oid,
    amount,
    okurl,
    failurl,
    transactionType,
    currency,
    storeType,
    lang,
    rnd,
    instalment,
    hashAlgorithm,
    encoding,
    hash,
  } = order.PaymentData;

  return (
    <Content>
      <Section
        classes={{ root: classes.section }}
        background="#fff"
        maxWidth={800}
      >
        {!data.Order.paid && !failed && (
          <TimeEncounter
            onExpired={() => setExpired(true)}
            date={data.Order.expirationTime}
          />
        )}
        {!data.Order.paid && !failed && (
          <Title color="#cf2c91">
            <FormattedMessage
              id="order.summary.title"
              defaultMessage="Skontroluj si údaje pred úhradou"
            />
          </Title>
        )}
        <PaymentSummary order={data.Order} />
        {!expired && (
          <Fragment>
            {!data.Order.paid && amount > 0 && !failed && (
              <VoucherInput
                refetchOrder={refetch}
                order={data.Order}
                orderToken={match.params.token}
              />
            )}
            <form ref={form} method="post" action={config.paygateUrl}>
              <input type="hidden" name="clientid" value={clientId} />
              <input type="hidden" name="storetype" value={storeType} />
              <input type="hidden" name="hash" value={hash} />
              <input type="hidden" name="trantype" value={transactionType} />
              <input type="hidden" name="amount" value={amount} />
              <input type="hidden" name="currency" value={currency} />
              <input type="hidden" name="instalment" value={instalment} />
              <input type="hidden" name="oid" value={oid} />
              <input type="hidden" name="okUrl" value={okurl} />
              <input type="hidden" name="failUrl" value={failurl} />
              <input type="hidden" name="lang" value={lang} />
              <input type="hidden" name="rnd" value={rnd} />
              <input type="hidden" name="hashAlgorithm" value={hashAlgorithm} />
              <input type="hidden" name="encoding" value={encoding} />
              <input type="hidden" name="token" value={match.params.token} />
              {data.Order.paid && !failed && (
                <Message
                  type="success"
                  message={formatMessage(messages.success)}
                />
              )}
              {!data.Order.paid && !failed && (
                <CustomButton
                  handleOnClick={() => form.current.submit()}
                  classes={{ root: classes.submitButton }}
                  type="submit"
                >
                  <FormattedMessage
                    id="order.movetopayment"
                    defaultMessage="PREJSŤ K ÚHRADE"
                  />
                </CustomButton>
              )}
            </form>
          </Fragment>
        )}
        {failed && amount > 0 && (
          <Fragment>
            <Message message={formatMessage(messages.error)} type="error" />
            <br />
            <CustomButton
              handleOnClick={() =>
                (window.location.href = `/payment/order/${match.params.token}`)
              }
              classes={{ root: classes.submitButton }}
              type="submit"
            >
              <FormattedMessage
                id="order.backtoorder"
                defaultMessage="VRÁTIŤ SA NASPÄŤ K OBJEDNÁVKE"
              />
            </CustomButton>
          </Fragment>
        )}
      </Section>
    </Content>
  );
};

export default compose(injectIntl, withStyles(styles))(Order);
