import React from "react";
import { withStyles, Grid, Divider, Typography } from "@material-ui/core";
import CustomButton from "../CustomButton";
import gql from "graphql-tag";
import { Query, Mutation } from "@apollo/react-components";
import { FormattedMessage, injectIntl } from "react-intl";
import { defineMessages } from "react-intl";
import { withRouter } from "react-router";
import RefetchButton from "../RefetchButton";
import Loading from "../Loading";
import { compose } from "recompose";

const styles = (theme) => ({
  root: {
    padding: "16px 32px",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 16px",
    },
  },
  table: {
    marginTop: 16,
    width: "100%",
    border: 0,
    fontFamily: "lato",
  },
  head: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  body: {
    "& > tr": {
      "& > td": {
        padding: 5,
        [theme.breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
    },
    "& > tr:nth-child(odd)": {
      backgroundColor: "#f6f6f6",
    },
  },
  totalPriceContainer: {
    fontSize: 16,
    fontFamily: "lato",
    fontWeight: 800,
    padding: "16px 0",
    textAlign: "right",
    borderTop: "1px solid #bbb",
  },
  buttonContainer: {
    marginTop: 32,
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      padding: "4px !important",
    },
  },
  gridContainer: {
    paddingBottom: 16,
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
  },
  rowTitle: {
    fontWeight: 800,
    fontSize: 16,
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  rowText: {
    fontSize: 16,
    textAlign: "left",
  },
  message: {
    fontSize: 18,
    paddingTop: 16,
    fontFamily: "lato",
  },
  errorRow: {
    fontSize: 18,
    paddingTop: 8,
    color: "red",
    paddingBottom: 8,
  },
});

const ORDER_MUTATION = gql`
  mutation addOrder($code: String!, $orderType: String!, $data: String!) {
    addOrder(code: $code, orderType: $orderType, data: $data) {
      id
      token
      type
      total
      total_vat
      Items {
        id
        title
        unit_price
        price
        unit
        price_vat
        order_id
      }
    }
  }
`;

const QUERY_ORDER = gql`
  query getClientOrderInfo(
    $code: String!
    $orderType: String!
    $data: String!
  ) {
    Order: getClientOrderInfo(code: $code, orderType: $orderType, data: $data) {
      total
      total_vat
      Items {
        title
        unit_price_vat
        price
        count
        unit
        price_vat
      }
      Data {
        title
        value
      }
    }
  }
`;

const messages = defineMessages({
  successMessage: {
    id: "reservation.simple.summary.success",
    defaultMessage: "Rezervácia prebehla úspešne, tešíme sa na Vás.",
  },
  errorMessage: {
    id: "reservation.simple.summary.error",
    defaultMessage: "Vyskytla sa neočakávaná chyba, skúste prosím neskôr.",
  },
  itemName: {
    id: "reservation.simple.item.name",
    defaultMessage: "Názov",
  },
  itemUnitPrice: {
    id: "reservation.simple.item.unitprice",
    defaultMessage: "Jednotková cena",
  },
  itemCount: {
    id: "reservation.simple.item.count",
    defaultMessage: "Počet",
  },
  itemUnit: {
    id: "reservation.simple.item.unit",
    defaultMessage: "Jednotka",
  },
  itemTotal: {
    id: "reservation.simple.item.price",
    defaultMessage: "Cena",
  },
  itemTotalVat: {
    id: "reservation.simple.item.pricevat",
    defaultMessage: "Cena s DPH",
  },
  totalWithoutTax: {
    id: "reservation.simple.withoutVat",
    defaultMessage: "Cena bez DPH:",
  },
  total: {
    id: "reservation.simple.total",
    defaultMessage: "Spolu celkom k úhrade:",
  },
  errorFields: {
    id: "reservation.simple.error.fields",
    defaultMessage: "Zabudli ste vyplniť niektoré polia.",
  },
  button: {
    id: "reservation.simple.submit.button",
    defaultMessage: "POTVRDIŤ REZERVÁCIU",
  },
});

const getData = (code, orderData) => {
  const reservationType = code.toLowerCase();
  if (reservationType === "bowling") {
    return {
      reservationDate: orderData.reservationDate,
      reservationTimes: orderData.reservationTimes,
    };
  }
  if (reservationType === "party") {
    return {
      reservationTimes: orderData.reservationTimes,
      childrenCount: orderData.childrenCount,
      adultCount: orderData.adultCount,
      packageId: orderData.packageId,
      extraZone: orderData.extraZone,
      theme: orderData.theme,
    };
  }
  if (reservationType === "cinema") {
    return {
      reservationTimes: orderData.reservationTimes,
      type: orderData.type,
      category: orderData.category,
      count: orderData.count,
      movie: orderData.movie,
      reservationDate: orderData.reservationDate,
    };
  }
  if (reservationType === "shop") {
    return {
      extraZone: orderData.extraZone,
      delivery: orderData.delivery,
    };
  }
  if (reservationType === "camp") {
    return {
      camps: orderData.camps,
      children: orderData.children,
    };
  }
  if (reservationType === "ebike") {
    return {
      bikes: orderData.bikes,
      timeFrom: orderData.timeFrom,
      timeTo: orderData.timeTo,
      dateFrom: orderData.dateFrom,
      dateTo: orderData.dateTo,
    };
  }
  if (reservationType === "car") {
    return {
      cars: orderData.cars,
      timeFrom: orderData.timeFrom,
      timeTo: orderData.timeTo,
      dateFrom: orderData.dateFrom,
      dateTo: orderData.dateTo,
    };
  }
  if (reservationType === "event") {
    return {
      childrenCount: orderData.childrenCount,
      adultCount: orderData.adultCount,
    };
  }
  return orderData;
};

const ReservationSummary = ({
  orderType,
  classes,
  code,
  setErrors,
  onSuccess,
  setMessage,
  history,
  message,
  validate,
  data: orderData,
  intl: { formatMessage },
}) => {
  // getne iba polozky, ktore ovplyvnuju cenu
  const d = getData(orderType, orderData);

  return (
    <div className={classes.root}>
      <Query
        query={QUERY_ORDER}
        variables={{ code, orderType, data: JSON.stringify(d) }}
      >
        {({ data, loading, error, refetch }) => {
          if (loading) return <Loading />;
          if (error) return <RefetchButton refetch={refetch} />;
          if (!data.Order) return null;

          return (
            <div>
              {(orderData.firstname ||
                orderData.surname ||
                orderData.email ||
                orderData.telephone) && (
                <div className={classes.gridContainer}>
                  <Grid container spacing={16}>
                    <Grid
                      className={classes.gridItem}
                      align="right"
                      item
                      lg={4}
                      md={4}
                      sm={4}
                      xs={12}
                    >
                      <Typography className={classes.rowTitle}>
                        <FormattedMessage
                          id="reservation.simple.summary.contact"
                          defaultMessage="Kontaktné údaje: "
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      className={classes.gridItem}
                      item
                      lg={8}
                      md={8}
                      sm={8}
                      xs={12}
                    >
                      <Typography className={classes.rowText}>
                        {orderData.firstname} {orderData.surname}
                        {(orderData.firstname || orderData.surname) && ","}{" "}
                        {orderData.email}
                        {orderData.email && ","} {orderData.telephone}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
              <table cellSpacing="0" className={classes.table}>
                <thead className={classes.head}>
                  <tr>
                    <th>{formatMessage(messages.itemName)}</th>
                    <th>{formatMessage(messages.itemUnitPrice)}</th>
                    <th>{formatMessage(messages.itemCount)}</th>
                    <th>{formatMessage(messages.itemUnit)}</th>
                    <th>{formatMessage(messages.itemTotalVat)}</th>
                  </tr>
                </thead>
                <tbody className={classes.body}>
                  {data.Order.Items.map((item, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: "left" }}>{item.title}</td>
                      <td>{item.unit_price_vat} €</td>
                      <td>{item.count}</td>
                      <td>{item.unit}</td>
                      <td>{item.price_vat} €</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <Divider />

              <div className={classes.totalPriceContainer}>
                {formatMessage(messages.totalWithoutTax)} {data.Order.total}€
                <br />
                <b style={{ fontSize: "1.3rem" }}>
                  {formatMessage(messages.total)} {data.Order.total_vat}€
                </b>
              </div>

              <Mutation mutation={ORDER_MUTATION}>
                {(addOrder, { data, loading, error }) => (
                  <div className={classes.buttonContainer}>
                    <CustomButton
                      handleOnClick={() => {
                        const { errors, valid } = validate({
                          data: orderData,
                          formatMessage,
                        });

                        setErrors(errors);

                        if (valid === false) {
                          setMessage(
                            formatMessage(messages.errorFields),
                            "ERROR"
                          );
                          return false;
                        }

                        addOrder({
                          variables: {
                            code,
                            orderType,
                            data: JSON.stringify(orderData),
                          },
                        }).then(({ data }) => {
                          history.push(`/payment/order/${data.addOrder.token}`);
                          onSuccess();

                          setMessage(
                            formatMessage(messages.successMessage),
                            "SUCCESS"
                          );
                        });
                      }}
                    >
                      {formatMessage(messages.button)}
                    </CustomButton>
                    <div className={classes.statusBar}>
                      {loading && <Loading />}
                      {error &&
                        error.graphQLErrors.map((x, i) => (
                          <div className={classes.errorRow} key={i}>
                            {x.message}
                          </div>
                        ))}
                    </div>
                    <div
                      className={classes.message}
                      style={{
                        textAlign: "center",
                        paddding: 10,
                        color: message.type === "ERROR" ? "red" : "#000",
                      }}
                    >
                      {message.text}
                    </div>
                  </div>
                )}
              </Mutation>
            </div>
          );
        }}
      </Query>
    </div>
  );
};

export default compose(
  withRouter,
  injectIntl,
  withStyles(styles)
)(ReservationSummary);
