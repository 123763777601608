import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Calendar from "./Calendar";
import NumberInput from "../../components/Reservation/NumberInput";
import { FormattedMessage } from "react-intl";
import Message from "../../components/Reservation/Message";
import dateformat from "dateformat";
import { injectIntl } from "react-intl";
import messages from "../Shop/messages";

const styles = (theme) => ({
  root: {
    padding: "0 0 16px 0",
  },
  grid: {
    alignItems: "center",
  },
  errorMessage: {
    color: "red",
    padding: "16px 0 0 0",
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
  message: {
    padding: 32,
    paddingTop: 50,
    textAlign: "center",
  },
});

const StepDate = ({
  classes,
  errors = [],
  count,
  handleDayClick = () => {},
  handleCountChange = () => {},
  dateBlackList = [],
  type,
  intl: { formatMessage },
  reservationDate = null,
}) => (
  <div className={classes.root}>
    <Grid className={classes.grid} spacing={32} container>
      <Grid item className={classes.gridItem} sm={12} md={6}>
        <Calendar
          dateBlackList={dateBlackList}
          reservationDate={reservationDate}
          handleDayClick={handleDayClick}
        />
      </Grid>
      <Grid item align="left" className={classes.gridItem} sm={12} md={6}>
        {dateBlackList.find(
          (x) => x.date === dateformat(reservationDate, "yyyy-mm-dd")
        ) ? (
          <Message
            classes={{ info: classes.message }}
            type="info"
            message={formatMessage(messages.disabledDate)}
          />
        ) : (
          <NumberInput
            min={1}
            max={type === "5d" ? 9 : 2}
            label={
              <FormattedMessage
                id="reservation.cinema.ticketcount.label"
                defaultMessage="Počet osôb"
              />
            }
            value={count}
            onChange={(value) => handleCountChange("count", value)}
          />
        )}
      </Grid>
    </Grid>
    <div className={classes.errorMessage}>{errors.reservationTimes}</div>
  </div>
);

export default injectIntl(withStyles(styles)(StepDate));
