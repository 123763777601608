import React, { Component } from "react";
import { withStyles, Grid } from "@material-ui/core";
import { BonusCardTitle } from "../../components/Typography";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import PillSection from "./PillSection";
import VisaImg from "../../resources/img/visa@2x.png";
import BowlingImg from "../../resources/img/bonuscard-bowling.jpg";
import FunImg from "../../resources/img/bonuscard-fun.jpg";
import FunImg2 from "../../resources/img/bonuscard-fun2.jpg";
import JumpImg from "../../resources/img/bonuscard-jump.jpg";
import CinemaImg from "../../resources/img/bonuscard-cinema.jpg";
import RestaurantImg from "../../resources/img/bonuscard-restaurant.jpg";
import BonusCardHeaderImg from "../../resources/img/bonusova-karta-header-1490x580.jpg";
import BonusCardForm from "../PricingList/BonusCardForm";
import Header from "./Header";
import FunIcon from "../../resources/img/zone-fun-logo.svg";
import JumpIcon from "../../resources/img/zone-jump-logo.svg";
import CinemaIcon from "../../resources/img/zone-cinema-logo.svg";
import RestaurantIcon from "../../resources/img/element-icon-restauracia-kaviaren.svg";
import BowlingIcon from "../../resources/img/element-icon-zona-bowling.svg";
import handsIcon from "../../resources/img/hand-3.png";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${handsIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center 104%",
    maxWidth: 1224,
    margin: "auto",
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "center 102%",
    },
  },
  bonusCardSection: {
    marginTop: 64,
  },
  section: {
    marginTop: 24,
    padding: 0,
  },
  description: {
    fontFamily: "lato",
    maxWidth: 1224,
    color: "#555555",
    fontSize: 24,
    textAlign: "center",
    margin: "64px auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      margin: "32px auto",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  note: {
    marginTop: 26,
    color: "#555555",
    fontSize: 24,
    textAlign: "center",
  },
});

const messages = defineMessages({
  metaTitle: {
    id: "bonuscard.meta.title",
    defaultMessage: "Kinderland - Bonusová karta",
  },
  metaDescription: {
    id: "bonuscard.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
  // note: {
  //   id: "bonuscard.note",
  //   defaultMessage:
  //     "Zľava do FUN ZÓNY a JUMP ZÓNY sa vzťahuje len na dni počas školského roka."
  // }
});

class BonusCardPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <div className={classes.root}>
        <Helmet>
          <title>{formatMessage(messages.metaTitle)}</title>
          <meta
            name="description"
            content={formatMessage(messages.metaDescription)}
          />
        </Helmet>
        <Header
          title={
            <FormattedMessage
              id="bonuscard.header.title"
              defaultMessage="získaj bonusovú kartu"
            />
          }
          image={BonusCardHeaderImg}
        />
        <div className={classes.description}>
          <FormattedMessage
            id="bonuscardpage.content.title"
            defaultMessage="Získaj kopu výhod pri každej návšteve Kinderlandu. 
                    Registruj sa pomocou formulára nižšie a užívaj si svoju bonusovú kartu. 
                    Príde až k tebe domov úplne zdarma. Preukážeš sa pri pokladni a užívaš si jednotlivé výhody. 
                    Zľavy nie je možné medzi sebou kombinovať."
          />
        </div>

        <div className={classes.section}>
          <Grid justify="center" container spacing={24}>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                title={
                  <FormattedMessage
                    id="bonuscardpage.funzoneicon.title"
                    defaultMessage="FUN ZÓNA"
                  />
                }
                icon={FunIcon}
                image={FunImg}
                text={
                  <FormattedMessage
                    id="bonuscardpage.funzonebonus.title"
                    defaultMessage="v pondelok 50% zľava*"
                  />
                }
                color="#cf2c91"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                title={
                  <FormattedMessage
                    id="bonuscardpage.funzoneicon.title"
                    defaultMessage="FUN ZÓNA"
                  />
                }
                icon={FunIcon}
                image={FunImg2}
                text={
                  <FormattedMessage
                    id="bonuscardpage.funzonebonus.title2"
                    defaultMessage="v utorok dospelý zdarma*"
                  />
                }
                color="#cf2c91"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                icon={JumpIcon}
                image={JumpImg}
                title={
                  <FormattedMessage
                    id="bonuscardpage.jumpicon.title"
                    defaultMessage="JUMP ZÓNA"
                  />
                }
                text={
                  <FormattedMessage
                    id="bonuscardpage.jumpbonus.title"
                    defaultMessage="v stredu 50% zľava*"
                  />
                }
                color="#39b54a"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                title={
                  <FormattedMessage
                    id="bonuscardpage.bowlingicon.title"
                    defaultMessage="BOWLING"
                  />
                }
                icon={BowlingIcon}
                image={BowlingImg}
                text={
                  <FormattedMessage
                    id="bonuscardpage.bowlingbonus.title"
                    defaultMessage="vo štvrtok zľava z 15 na 10 eur"
                  />
                }
                color="#39b54a"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                title={
                  <FormattedMessage
                    id="bonuscardpage.restauranticon.title"
                    defaultMessage="REŠTAURÁCIA & KAVIAREŇ"
                  />
                }
                image={RestaurantImg}
                icon={RestaurantIcon}
                text={
                  <FormattedMessage
                    id="bonuscardpage.restaurantbonus.title"
                    defaultMessage="zvýhodnené ceny na jedlá a nápoje"
                  />
                }
                color="#f47521"
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <PillSection
                image={CinemaImg}
                icon={CinemaIcon}
                title={
                  <FormattedMessage
                    id="bonuscardpage.cinemaicon.title"
                    defaultMessage="9D CINEMA"
                  />
                }
                text={
                  <FormattedMessage
                    id="bonuscardpage.cinemabonus.title"
                    defaultMessage="1+1 zdarma"
                  />
                }
                color="#f47521"
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.note}>
          <FormattedMessage id="bonuscardpage.note" defaultMessage=" " />
        </div>

        <div className={classes.bonusCardSection}>
          <BonusCardTitle>
            <FormattedMessage
              id="bonuscard.title"
              defaultMessage={`ZÍSKAJ KOPU VÝHOD S NAŠOU`}
            />
            &nbsp;
            <span style={{ color: "#cf2c91" }}>
              <FormattedMessage
                id="bonuscard.title.part2"
                defaultMessage=" BONUSOVOU KARTOU"
              />
            </span>
          </BonusCardTitle>
          <div style={{ marginTop: 48, textAlign: "center" }}>
            <BonusCardForm />
            <img
              style={{
                margin: "auto",
                marginTop: 32,
                width: "90%",
                maxWidth: 600,
              }}
              src={VisaImg}
              alt="bonus card"
            />
          </div>
        </div>
      </div>
    );
  };
}

export default injectIntl(withRouter(withStyles(styles)(BonusCardPage)));
