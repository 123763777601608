import React from "react";
import { withStyles } from "@material-ui/core";
import ColumnGrid from "../../components/ColumnGrid";
import RestaurantCard from "../../components/Cards/RestaurantCard";
import RestaurantIcon1 from "../../resources/img/restaurant-1.svg";
import RestaurantIcon2 from "../../resources/img/restaurant-2.svg";
import RestaurantIconMiddle from "../../resources/img/restaurant-delivery-icon.svg";
import RestaurantIcon3 from "../../resources/img/restaurant-3.svg";
import RestaurantImg1 from "../../resources/img/restaurant2.jpg";
import RestaurantImg3 from "../../resources/img/restaurant1.jpg";
import { getImage } from "../../helpers";
import { compose } from "recompose";
import {
  SectionTitle,
  SectionSubtitleTitle,
} from "../../components/Typography";
import { useQuery } from "@apollo/react-hooks";
import { Query } from "@apollo/react-components";
import { withRouter } from "react-router-dom";
import gql from "graphql-tag";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";
import deliveryImg from "../../resources/img/rozvoz-menu-img2.jpg";

const messages = defineMessages({
  restaurantDayMenuTitle: {
    id: "restaurant.daymenu.title",
    defaultMessage: "Obedové menu",
  },
  restaurantMenuTitle: {
    id: "restaurant.menu.title",
    defaultMessage: "JEDÁLNY LÍSTOK",
  },
  restaurantSpecialityTitle: {
    id: "restaurant.speciality.title",
    defaultMessage: "ROZVOZ MENU",
  },
});

const RESTAURANT_QUERY = gql`
  query Restaurant {
    data: Restaurant {
      speciality
      DayMenu {
        storage_name
      }
      Menu {
        storage_name
      }
      DayMenuImage {
        storage_name
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    padding: "0 0",
    marginTop: 16,
    width: "100%",
    maxWidth: 1524,
    margin: "auto",
    textAlign: "center",
  },
  carouselWrapper: {
    margin: "auto",
    paddingTop: 8,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 8,
    },
  },
});

const RestaurantSection = ({ history, classes, intl: { formatMessage } }) => {
  const { data } = useQuery(RESTAURANT_QUERY);
  const restaurant = (data && data.data) || {};
  const special = restaurant.speciality;
  const dayMenuImage =
    restaurant.DayMenuImage && restaurant.DayMenuImage.storage_name;
  const menu = restaurant.Menu && restaurant.Menu.storage_name;
  const dayMenu = restaurant.DayMenu && restaurant.DayMenu.storage_name;

  return (
    <div>
      <div
        id={formatMessage(generalMessages.linkRestaurant)}
        className={classes.root}
      >
        <SectionTitle>
          <FormattedMessage
            id="restaurantsection.invitationclaim.title"
            defaultMessage="PRÍĎ SA K NÁM NAJESŤ. NEBUDEŠ ĽUTOVAŤ"
          />
        </SectionTitle>
        <SectionSubtitleTitle>
          <FormattedMessage
            id="restaurantsection.thebest.title"
            defaultMessage="Najlepšia kuchyňa, široko ďaleko."
          />
        </SectionSubtitleTitle>
        {data && (
          <div className={classes.carouselWrapper}>
            <ColumnGrid
              fullScreenMode={false}
              cards={[
                dayMenu && (
                  <RestaurantCard
                    bgImage={RestaurantImg1}
                    icon={RestaurantIcon1}
                    primaryColor="#f47521"
                    actionTitle={
                      <FormattedMessage
                        id="restaurantsection.weeklymenu.title"
                        defaultMessage="Ponuka jedál na tento týždeň"
                      />
                    }
                    subtitle={
                      <FormattedMessage
                        id="restaurantsection.dailyfourtasty.title"
                        defaultMessage="4 fantastické jedlá. Každý pracovný deň."
                      />
                    }
                    onActionClick={() => {
                      history.push(`/viewer/${dayMenu}?mode=fullsize`);
                    }}
                    title={formatMessage(messages.restaurantDayMenuTitle)}
                    key="1"
                  />
                ),
                special && (
                  <RestaurantCard
                    // bgImage={menu && getImage(dayMenuImage)}
                    bgImage={deliveryImg}
                    icon={RestaurantIconMiddle}
                    onActionClick={() =>
                      dayMenuImage &&
                      window.open(getImage(dayMenuImage), "_blank")
                    }
                    // onActionClick={() => {
                    //   history.push(`/viewer/${dayMenuImage}`);
                    // }}
                    actionTitle={
                      <FormattedMessage
                        id="restaurantsection.delivery.title"
                        defaultMessage="Ponuka jedál"
                      />
                    }
                    primaryColor="#ffc415"
                    text={special}
                    title={formatMessage(messages.restaurantSpecialityTitle)}
                    key="2"
                  />
                ),
                <RestaurantCard
                  bgImage={RestaurantImg3}
                  icon={RestaurantIcon3}
                  subtitle={
                    <FormattedMessage
                      id="restaurantsection.guarantedexperience.title"
                      defaultMessage="garantujeme kvalitný gurmánsky zážitok."
                    />
                  }
                  primaryColor="#39b54a"
                  actionTitle={
                    <FormattedMessage
                      id="restaurantsection.allmenulist.title"
                      defaultMessage="Celá ponuka jedál a nápojov"
                    />
                  }
                  onActionClick={() => window.open(getImage(menu), "_blank")}
                  title={formatMessage(messages.restaurantMenuTitle)}
                  key="3"
                />,
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  withStyles(styles)
)(RestaurantSection);
