import React from "react";
import { withStyles } from "@material-ui/core";

const styles = () => ({
  root: {
    margin: "auto",
    marginTop: 16,
    marginBottom: 16,
    borderRadius: 8,
    textAlign: "center",
  },
});

const Section = ({
  classes,
  children,
  maxWidth,
  background,
  textAlign,
  marginTop,
}) => {
  return (
    <section
      style={{ maxWidth, background, textAlign, marginTop }}
      className={classes.root}
    >
      {children}
    </section>
  );
};

export default withStyles(styles)(Section);
