/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { withStyles } from "@material-ui/core";
import CheckmarkIcon from "../../resources/img/checkmark-white.svg";
import LinkIcon from "../../resources/img/external-link-symbol.svg";
import { CardTitle } from "../Typography";
import EshopButton from "./EshopButton";
import { hexToRgba } from "../../helpers";
import clsx from "clsx";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    background: "#7f7fc7",
    cursor: "pointer",
    borderRadius: 16,
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    textAlign: "center",
    backgroundImage:
      "linear-gradient(130deg, rgba(35, 31, 32, 0.16), rgba(35, 31, 32, 0.56))",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
  },
  imageWrapper: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "100%",
    height: "100%",
    borderRadius: 16,
  },
  content: {
    padding: 10,
    margin: 0,
    position: "absolute",
    top: "30%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -30%)",
    textAlign: "center",
  },
  icon: {
    width: 106,
    height: 106,
    borderRadius: 10,
    margin: "auto",
    [theme.breakpoints.down("lg")]: {
      height: 106,
      width: 106,
    },
    [theme.breakpoints.down("md")]: {
      height: 96,
      width: 96,
    },
    [theme.breakpoints.down("xs")]: {
      height: 50,
      width: 50,
    },
    [theme.breakpoints.down("320")]: {
      height: 40,
      width: 40,
    },
  },
  itemList: {
    padding: "16px 0px 0px 0px",
    color: "#fff",
    margin: "auto",
    fontSize: 22,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    listStyle: "none",
    textAlign: "left",
    "& > li": {
      textAlign: "center",
      fontFamily: "TypoComica",
      paddingBottom: 10,
    },
  },
  checkmarkIcon: {
    height: 15,
  },
  title: {
    padding: "32px 0 0 8px",
    fontSize: 32,
    [theme.breakpoints.down("lg")]: {
      fontSize: 28,
      padding: "20px 0 0 8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      padding: "8px 0 0 4px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  titleLong: {
    padding: "32px 0 0 8px",
    fontSize: 24,
    [theme.breakpoints.down("lg")]: {
      fontSize: 20,
      padding: "20px 0 0 8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      padding: "8px 0 0 4px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
});

const EshopCard = ({
  classes,
  width = "auto",
  icon = null,
  bgImage,
  title,
  buttonBgImage,
  primaryColor = "#34B045",
  onActionClick,
  actionTitle,
  handleOnClick,
  itemList = [],
}) => {
  const shadowColor = hexToRgba(primaryColor, 0.3);
  const rootStyle = {
    backgroundImage: bgImage
      ? `url(${bgImage})`
      : "linear-gradient(157deg, rgba(0, 0, 0, 0.1), rgba(55, 55, 55, 0.3))",
    width,
    boxShadow: `0 16px 48px 0 ${shadowColor}`,
  };

  return (
    <div onClick={handleOnClick} className={classes.root} style={rootStyle}>
      <div
        className={classes.imageWrapper}
        style={{ backgroundColor: `rgba(35, 31, 32, 0.5)` }}
      >
        <div className={`card-content ${classes.content}`}>
          {icon ? <img className={classes.icon} src={icon} alt="icon" /> : null}
          <CardTitle
            additionalStyles={clsx(
              classes.title,
              title.length > 30 && classes.titleLong
            )}
          >
            {title}
          </CardTitle>
          <ul className={classes.itemList}>
            {itemList.map((x, idx) => (
              <li key={idx}>
                <img
                  className={classes.checkmarkIcon}
                  src={CheckmarkIcon}
                  alt="checkmark"
                />
                {x}
              </li>
            ))}
          </ul>
        </div>
        {actionTitle && (
          <EshopButton
            variant="raised"
            backgroundImage={buttonBgImage}
            additionalClassses={classes.link}
            onActionClick={onActionClick}
            icon={LinkIcon}
          >
            {actionTitle}
          </EshopButton>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(EshopCard);
