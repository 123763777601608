import { isEmpty, isEmail } from "validator";
import { defineMessages } from "react-intl";

const messages = defineMessages({
  errorFirstname: {
    id: "reservation.simple.error.firstname",
    defaultMessage: "Zadajte meno"
  },
  errorSurname: {
    id: "reservation.simple.error.surname",
    defaultMessage: "Zadajte priezvisko"
  },
  errorTelephone: {
    id: "reservation.simple.error.telephone",
    defaultMessage: "Zadajte telefónne číslo"
  },
  errorGdpr: {
    id: "reservation.simple.error.gdpr",
    defaultMessage:
      "Potrebujeme od Vás povolenie pre spracovanie osobných údajov."
  },
  errorEmail: {
    id: "reservation.simple.error.email",
    defaultMessage: "Zadajte e-mailovú adresu"
  },
  errorTicketCount: {
    id: "reservation.simple.ticketcount.error",
    defaultMessage: "Je potrebné zadať počet lístkov."
  },
  errorCity: {
    id: "reservation.error.city",
    defaultMessage: "Zadajte obec"
  },
  errorAddress: {
    id: "reservation.error.address",
    defaultMessage: "Zadajte ulicu a popisné číslo"
  },
  errorZip: {
    id: "reservation.error.zip",
    defaultMessage: "Zadajte PSČ"
  }
});

const Validator = ({ data, formatMessage }) => {
  const {
    firstname,
    surname,
    telephone,
    gdpr,
    email,
    city,
    zip,
    address,
    adultCount,
    childrenCount
  } = data;

  const errors = {
    firstname: isEmpty(firstname) ? formatMessage(messages.errorFirstname) : "",
    surname: isEmpty(surname) ? formatMessage(messages.errorSurname) : "",
    city: isEmpty(city) ? formatMessage(messages.errorCity) : "",
    zip: isEmpty(zip) ? formatMessage(messages.errorZip) : "",
    address: isEmpty(address) ? formatMessage(messages.errorAddress) : "",
    telephone: isEmpty(telephone) ? formatMessage(messages.errorTelephone) : "",
    gdpr: !gdpr ? formatMessage(messages.errorGdpr) : "",
    email: !isEmail(email) ? formatMessage(messages.errorEmail) : "",
    ticketCount:
      !adultCount && !childrenCount
        ? formatMessage(messages.errorTicketCount)
        : ""
  };

  let valid = true;
  Object.keys(errors).forEach(x => {
    if (errors[x] !== "" && x !== "__typename") {
      valid = false;
    }
  });

  return {
    valid,
    errors
  };
};

export default Validator;
