import React, { useState, useEffect } from "react";
import { withStyles, Button, Grid } from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import BonusCardSection from "../../components/BonusCardSection";
import handsIcon from "../../resources/img/hand-3.png";
import { Fade } from "react-reveal";
import PostCard from "./PostCard";
import { getAssetPath } from "../../helpers";
import { NavLink } from "react-router-dom";
import LinkIcon from "../../resources/img/arrow-load-black.svg";
import { CustomHeading } from "../../components/Typography";
import CategoryFilter from "./CategoryFilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl, defineMessages } from "react-intl";
import gql from "graphql-tag";
import messages from "../../i18n/messages";
import { compose } from "recompose";

const POSTS_QUERY = gql`
  query Posts($categories: [String], $offset: Int, $limit: Int) {
    Posts: Posts(categories: $categories, offset: $offset, limit: $limit) {
      id
      code
      title
      category_ids
      Categories {
        id
        title
        color
      }
      Thumbnail {
        storage_name
      }
      published_date
    }
  }
`;

const styles = (theme) => ({
  root: {
    backgroundImage: `url(${handsIcon})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "90% auto",
    backgroundPosition: "center 12%",
  },
  pageContent: {
    maxWidth: "1224px",
    margin: "auto",
    paddingTop: "64px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "1024px",
    },
  },
  postList: {
    padding: 32,
    paddingTop: 16,
    //minHeight: 200
  },
  categoryFilter: {},
  bonusCardSection: {
    padding: "64px 0",
  },
  fetchMoreButton: {
    marginTop: 32,
    fontWeight: "bold",
    fontSize: 16,
    color: "#555555",
    fontFamily: "lato",
  },
  buttonIcon: {
    marginLeft: 16,
  },
});

const localMessages = defineMessages({
  metaTitle: {
    id: "blog.meta.title",
    defaultMessage: "Kinderland - Aktuality z Kinderlandu",
  },
  metaDescription: {
    id: "blog.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const BlogPage = ({ classes, intl: { formatMessage } }) => {
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const { data, loading, fetchMore } = useQuery(POSTS_QUERY, {
    variables: {
      categories: selectedCategory ? [selectedCategory] : undefined,
      offset: 0,
      limit: 6,
    },
    fetchPolicy: "cache-and-network",
  });

  const Posts = (data && data.Posts) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{formatMessage(localMessages.metaTitle)}</title>
        <meta
          name="description"
          content={formatMessage(localMessages.metaDescription)}
        />
      </Helmet>
      <Fade>
        <div className={classes.pageContent}>
          <CustomHeading>
            <FormattedMessage
              id="blog.title"
              defaultMessage="AKTUALITY Z KINDERLANDU"
            />
          </CustomHeading>
          <div className={classes.categoryFilter}>
            <CategoryFilter
              selectedCategory={selectedCategory}
              onCategoryStatusChange={(categoryId) =>
                setSelectedCategory(categoryId)
              }
            />
          </div>
          <div className={classes.postList}>
            <div>
              <Grid container spacing={16} className={classes.root}>
                {Posts.map((item, idx) => (
                  <Grid key={idx} item xs={12} sm={6}>
                    <NavLink
                      to={`/${formatMessage(messages.linkBlog)}/${item.code}`}
                    >
                      <PostCard
                        key={idx}
                        date={item.published_date}
                        title={item.title}
                        categories={item.Categories}
                        bgImage={
                          item.Thumbnail &&
                          getAssetPath(item.Thumbnail.storage_name)
                        }
                        width="100%"
                        actionTitle={
                          <FormattedMessage
                            id="blog.readpost.button.title"
                            defaultMessage="Prečítať celý článok"
                          />
                        }
                      />
                    </NavLink>
                  </Grid>
                ))}
              </Grid>
              <Button
                onClick={() =>
                  fetchMore({
                    variables: {
                      offset: Posts.length,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return Object.assign({}, prev, {
                        Posts: [...prev.Posts, ...fetchMoreResult.Posts],
                      });
                    },
                  }).then((x) => {
                    console.log(x);
                  })
                }
                className={classes.fetchMoreButton}
              >
                <FormattedMessage
                  id="blog.fetchmore.button.title"
                  defaultMessage="NAČÍTAŤ VIAC PRÍSPEVKOV "
                />{" "}
                <img
                  className={classes.buttonIcon}
                  src={LinkIcon}
                  alt="Link icon"
                />
              </Button>
              {loading && (
                <CircularProgress
                  style={{ margin: "auto", color: "#555" }}
                  size={65}
                />
              )}
            </div>
          </div>
          <div className={classes.bonusCardSection}>
            <BonusCardSection />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(BlogPage);
