/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";
import Carousel from "nuka-carousel";
import withSizes from "react-sizes";
import NextSectionButton from "../../resources/img/element-button-go-to.svg";
import HandsImage from "../../resources/img/hand-3.png";
import RightArrowImg from "../../resources/img/arrow-right.svg";
import LeftArrowImg from "../../resources/img/arrow-left.svg";
import { HashLink as NavLink } from "react-router-hash-link";
import CircularProgress from "@material-ui/core/CircularProgress";
import { scrollToElement } from "../../helpers";
import Helmet from "react-helmet";
import { FormattedMessage } from "react-intl";
import { compose } from "recompose";

const styles = (theme) => ({
  root: {
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    backgroundImage: "linear-gradient(to right, #f6f6f6, #b9b9b9)",
    transition: "all 250ms cubic-bezier(.4,.2,0,1)",
    paddingTop: 16,
    minHeight: 500,
    "& .slider-list": {
      cursor: "auto !important",
    },
    outline: "none",
  },
  leftArrowIcon: {
    position: "absolute",
    right: 0,
    bottom: "25%",
    margin: "auto",
    cursor: "pointer",
    height: 48,
    width: 52,
    [theme.breakpoints.down("md")]: {
      height: 40,
      width: 40,
    },
    [theme.breakpoints.down("sm")]: {
      height: 35,
      width: 35,
      bottom: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      height: 30,
      width: 30,
      bottom: "15%",
      right: "-10px",
    },
    [theme.breakpoints.down("320")]: {
      height: 20,
      width: 20,
      right: "-5px",
    },
    [theme.breakpoints.down("280")]: {
      display: "none",
    },
  },
  rightArrowIcon: {
    position: "absolute",
    left: 0,
    bottom: "25%",
    margin: "auto",
    cursor: "pointer",
    height: 48,
    width: 52,
    [theme.breakpoints.down("md")]: {
      height: 40,
      width: 40,
    },
    [theme.breakpoints.down("sm")]: {
      height: 35,
      width: 35,
      bottom: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      height: 30,
      width: 30,
      bottom: "15%",
      left: "-10px",
    },
    [theme.breakpoints.down("320")]: {
      height: 20,
      width: 20,
      left: "-5px",
    },
    [theme.breakpoints.down("280")]: {
      display: "none",
    },
  },
  shadowLeft: {
    position: "absolute",
    top: 144,
    cursor: "pointer",
    textAling: "center",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      top: 88,
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
    left: 0,
    width: "7%",
    borderRadius: "0 16px 16px 0",
    backgroundImage: "linear-gradient(to left, rgba(0, 0, 0, 0), #231f20)",
  },
  shadowRight: {
    position: "absolute",
    top: 128 + 16,
    cursor: "pointer",
    textAling: "center",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      top: 88,
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    [theme.breakpoints.down("320")]: {
      height: 150,
    },
    right: 0,
    width: "7%",
    borderRadius: "16px 0 0 16px",
    backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), #231f20)",
  },
  backgroundContainer: {
    backgroundImage: `url(${HandsImage})`,
    height: "500px",
    top: 492,
    [theme.breakpoints.down("lg")]: {
      top: 400,
    },
    [theme.breakpoints.down("md")]: {
      top: 300,
    },
    [theme.breakpoints.down("sm")]: {
      top: 280,
    },
    [theme.breakpoints.down("xs")]: {
      top: 180,
    },
    left: "-10%",
    right: "-10%",
    // width: "100%",
    position: "absolute",
    zIndex: -1,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  card: {
    minHeight: 492,
    height: "auto",
    marginTop: "40px",
    transition: "none",
    "& .view-container": {
      display: "none",
    },
    "& button": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  nextSection: {
    marginTop: "-120px",
    paddingTop: 150,
    // top: "-24px",
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: '" "',
      position: "absolute",
      top: 0,
      background:
        "linear-gradient(to bottom, rgba(200,200,200,0) 0%, rgba(180,180,180,0.48) 40%, rgba(78,78,78,0.6) 100%)",
      //backgroundPosition: "0px -24px",
      bottom: 0,
      right: 0,
      left: 0,
    },
  },
  activeCard: {
    borderRadius: 16,
    outline: "none",
    "& > button": {
      margin: "auto",
      display: "block",
    },
    "& .card-container": {
      display: "none",
    },
    "& .view-container": {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
    },
  },
  cardPrev: {
    height: "492px",
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    "& .card-content": {
      left: "auto",
      right: 0,
      margin: 0,
      transform: "translate(0%, -80%)",
      textAlign: "center",
      width: "200px",
      [theme.breakpoints.down("lg")]: {
        transform: "translate(0%, -60%)",
        width: "150px",
        padding: 8,
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
        padding: 16,
      },
      "& h5": {
        fontSize: "1rem",
      },
      "& img": {
        height: 70,
        width: 70,
      },
    },
    "& .view-container": {
      display: "none",
    },
    "& a": {
      display: "none",
    },
  },
  cardNext: {
    height: "492px",
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("sm")]: {
      height: 280,
    },
    [theme.breakpoints.down("xs")]: {
      height: 180,
    },
    "& .card-container": {
      display: "block",
    },
    "& .view-container": {
      display: "none",
    },
    "& .card-content": {
      left: 0,
      right: "auto",
      margin: 0,
      transform: "translate(0%, -80%)",
      padding: 16,
      textAlign: "center",
      width: "200px",
      [theme.breakpoints.down("lg")]: {
        transform: "translate(0%, -60%)",
        width: "150px",
        padding: 8,
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
        padding: 16,
      },
      "& h5": {
        fontSize: "1rem",
      },
      "& img": {
        height: 70,
        width: 70,
      },
    },
    "& a": {
      display: "none",
    },
  },
  nextSectionButton: {
    margin: "auto",
    cursor: "pointer",
    height: 80,
    [theme.breakpoints.down("md")]: {
      height: 60,
    },
    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
    [theme.breakpoints.down("xs")]: {
      height: 40,
    },
  },
  nextSectionButtonContainer: {
    padding: 32,
    paddingTop: 64,
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      paddingTop: 32,
    },
    textAlign: "center",
  },
  nextSectionButtonText: {
    width: 120,
    fontFamily: "lato",
    padding: 8,
    color: "#555555",
    margin: "auto",
    fontSize: "14px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  loadingContainer: {
    display: "none",
    textAlign: "center",
    padding: "74px 0 74px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "16px 0 32px 0",
    },
  },
});

const DetailView = ({
  classes,
  rectangleBox = {},
  showNextSectionButton = true,
  onSlideChange,
  nextSectionLink,
  isTablet,
  nextSection,
  isMobile,
  activeCard,
  nextCard,
  prevCard,
  handleClose,
}) => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [wrapAround] = useState(true);
  const [cellAlign] = useState("center");
  const [heightMode] = useState("current");
  const [withoutControls] = useState(true);
  const [transitionMode] = useState();

  const carousel = useRef(null);
  const rootElement = useRef(null);

  const reindex = (arr, places) => {
    for (var i = 0; i < places; i++) {
      arr.unshift(arr.pop());
    }
  };

  const beforeSlide = () => {
    const e = carousel.current.frame.getElementsByClassName("card-content");
    for (let i = 0; i < e.length; i++) {
      e[i].style.display = "none";
    }

    setTimeout(() => {
      for (let i = 0; i < e.length; i++) {
        e[i].style.display = "block";
      }
    }, 600);
  };

  const refreshHeight = () => {
    carousel?.current?.setSlideHeightAndWidth();
  };

  const afterSlide = (idx) => {
    const activeCard = document.querySelectorAll(`[data-card-id="${idx}"]`);

    const resourceId =
      activeCard.length && activeCard[0].getAttribute("data-resource-id");
    if (resourceId) {
      onSlideChange(resourceId);
      setSlideIndex(idx);
    }

    window.scrollTo(0, 0);

    carousel?.current?.setDimensions();
    carousel?.current?.setSlideHeightAndWidth();
  };

  const handleKey = useCallback(
    (e) => {
      if (e.keyCode === 37) {
        carousel.current.goToSlide(slideIndex - 1);
      } else if (e.keyCode === 39) {
        carousel.current.goToSlide(slideIndex + 1);
      } else if (e.keyCode === 27) {
        // this.handleClose();
      }
    },
    [slideIndex]
  );

  useEffect(() => {
    refreshHeight();
    document.addEventListener("keydown", handleKey);
    setTimeout(() => {
      carousel?.current?.setSlideHeightAndWidth();
    }, 500);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, [handleKey]);

  let array = [
    <div
      onClick={() => carousel.current.goToSlide(slideIndex - 1)}
      data-resource-id={prevCard && prevCard.props.resourceId}
      key={0}
      className={classes.cardPrev}
    >
      {React.cloneElement(prevCard, { refreshHeight })}
    </div>,
    <div
      data-resource-id={activeCard.props.resourceId}
      key={1}
      className={classes.activeCard}
    >
      {React.cloneElement(activeCard, { refreshHeight })}
    </div>,
    <div
      onClick={() => carousel.current.goToSlide(slideIndex + 1)}
      data-resource-id={nextCard && nextCard.props.resourceId}
      key={2}
      className={classes.cardNext}
    >
      {React.cloneElement(nextCard, { refreshHeight })}
    </div>,
  ];

  const shift = slideIndex - 1 < 0 ? 2 : slideIndex - 1;
  reindex(array, shift);
  const headTitle = `Kinderland - ${activeCard.props.title || ""}`;

  return (
    <div ref={rootElement} className={classes.root}>
      <Helmet>
        <title>{headTitle}</title>
        <meta
          name="description"
          content={
            <FormattedMessage
              id="general.content.title"
              defaultMessage="Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo"
            />
          }
        />
      </Helmet>
      <Carousel
        ref={carousel}
        slideWidth={1.5}
        disableKeyboardControls
        speed={500}
        cellSpacing={isMobile || isTablet ? 8 : 32}
        heightMode={heightMode}
        afterSlide={afterSlide}
        beforeSlide={beforeSlide}
        withoutControls={withoutControls}
        transitionMode={transitionMode}
        cellAlign={cellAlign}
        slidesToShow={2}
        dragging={isMobile || isTablet ? true : false}
        wrapAround={wrapAround}
        slideIndex={slideIndex}
      >
        {array.map((card, idx) => (
          <div style={{ outline: "none" }} key={idx}>
            {idx === slideIndex && (
              <div className={classes.backgroundContainer} />
            )}
            {React.cloneElement(card, {
              "data-card-id": idx,
            })}
          </div>
        ))}
      </Carousel>

      {prevCard && (
        <div
          onClick={() => carousel.current.previousSlide()}
          key="left"
          className={classes.shadowLeft}
        >
          <img
            className={classes.leftArrowIcon}
            src={LeftArrowImg}
            onClick={() => carousel.current.previousSlide()}
            alt="Left arrow"
          />
        </div>
      )}

      {nextCard && (
        <div
          onClick={() => carousel.current.nextSlide()}
          key="right"
          className={classes.shadowRight}
        >
          <img
            className={classes.rightArrowIcon}
            src={RightArrowImg}
            onClick={() => carousel.current.nextSlide()}
            alt="Right arrow"
          />
        </div>
      )}
      {
        <div id="dataviewLoading" className={classes.loadingContainer}>
          <CircularProgress
            classes={{ root: classes.progress }}
            style={{
              margin: "auto",
              color: nextCard.props.primaryColor || "#555",
            }}
            size={isMobile ? 30 : 65}
          />
        </div>
      }

      <div className="next-section-container">
        {showNextSectionButton && nextSection ? (
          <div className={classes.nextSectionButtonContainer}>
            {nextSectionLink ? (
              <NavLink
                scroll={(el) => scrollToElement(el)}
                to={nextSectionLink}
              >
                <div>
                  <img
                    className={classes.nextSectionButton}
                    src={NextSectionButton}
                    alt="Next section"
                  />
                </div>
              </NavLink>
            ) : (
              <div>
                <img
                  onClick={handleClose}
                  className={classes.nextSectionButton}
                  src={NextSectionButton}
                  alt="Next section"
                />
              </div>
            )}

            <div className={classes.nextSectionButtonText}>
              <FormattedMessage
                id="general.nextsectionbutton.title"
                defaultMessage="PREJSŤ NA NASLEDUJÚCE SEKCIE"
              />
            </div>
          </div>
        ) : (
          <div />
        )}

        {nextSectionLink ? (
          <NavLink
            style={{ textDecoration: "none" }}
            scroll={(el) => scrollToElement(el)}
            to={nextSectionLink}
          >
            <div onClick={handleClose} className={classes.nextSection}>
              {nextSection}
            </div>
          </NavLink>
        ) : (
          nextSection && (
            <div onClick={handleClose} className={classes.nextSection}>
              {nextSection}
            </div>
          )
        )}
      </div>
    </div>
  );
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  isTablet: width < 960,
  isLarge: width > 1640,
});

export default compose(
  withSizes(mapSizesToProps),
  withStyles(styles)
)(DetailView);
