import React from "react";
import { withStyles } from "@material-ui/core";
import AdditionalZone from "../../components/Reservation/AdditionalZone";
import { getImage } from "../../helpers";

const styles = () => ({
  root: {},
});

const StepAdditionalItems = ({
  classes,
  extraZone = [],
  onChange,
  selected,
}) => (
  <div>
    <AdditionalZone
      selected={selected}
      onChange={onChange}
      items={extraZone.map((x) => ({
        ...x,
        Items: x.Items.map((i) => ({
          ...i,
          image: i.Image && getImage(i.Image.storage_name),
        })),
      }))}
      // items={{
      //     "FUN & SNACK": [
      //         {
      //             id: 1,
      //             title: "Animátor",
      //             price: 3,
      //             unit: "3 HODINY",
      //             image: Image
      //         },
      //         {
      //             id: 2,
      //             title: "Maľovanie na tvár",
      //             price: 3,
      //             unit: "DIEŤA",
      //             image: Image
      //         },
      //         {
      //             id: 3,
      //             title: "Protišmykové ponožky",
      //             price: 3,
      //             unit: "KUS",
      //             image: Image
      //         },
      //         {
      //             id: 4,
      //             title: "Mini rezne",
      //             price: 3,
      //             unit: "80g",
      //             image: Image
      //         }
      //     ],
      //     "ŠPECIALITY Z NAŠEJ KUCHYNE": [
      //         {
      //             id: 1,
      //             title: "Animátor",
      //             price: 3,
      //             unit: "3 HODINY",
      //             image: Image
      //         },
      //         {
      //             id: 2,
      //             title: "Maľovanie na tvár",
      //             price: 3,
      //             unit: "DIEŤA",
      //             image: Image
      //         }
      //     ]
      // }}
    />
  </div>
);

export default withStyles(styles)(StepAdditionalItems);
