import React, { Fragment } from "react";
import DatePickerInput from "./DatePickerInput";
import { withStyles, Grid } from "@material-ui/core";
import TimeInput from "./TimeInput";

const styles = (theme) => ({
  root: {},
  textField: {
    [theme.breakpoints.down(500)]: {
      margin: "auto",
      width: "100%",
      maxWidth: 240,
      marginTop: 8,
    },
  },
});

const ReservationTimeRange = ({
  timeFrom,
  timeTo,
  dateFrom,
  dateTo,
  onChange = () => {},
  blackList = [],
  classes,
}) => {
  const dateBlackList = [];
  blackList.forEach((x) => {
    try {
      dateBlackList.push(new Date(x.date));
    } catch (e) {
      console.warn(e);
    }
  });

  const getDisabledDays = () => {
    const beforeAfter = {};
    try {
      let date = dateFrom ? new Date(dateFrom) : new Date();
      date.setDate(date.getDate() + 7);
      beforeAfter.before = dateFrom ? new Date(dateFrom) : new Date();
      beforeAfter.after = date;
    } catch (e) {
      console.warn(e);
    }
    return [...dateBlackList, beforeAfter];
  };

  return (
    <Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <DatePickerInput
            disabledDays={[
              ...dateBlackList,
              {
                before: new Date(),
                after: dateTo ? new Date(dateTo) : undefined,
              },
            ]}
            label="Dátum vyzdvihnutia"
            onChange={(value) => onChange("dateFrom", value)}
          />
          <TimeInput
            value={timeFrom}
            classes={{ textField: classes.textField }}
            label="Čas vyzdvihnutia"
            onChange={(e) => onChange("timeFrom", e.target.value)}
          />
        </Grid>
        {dateFrom && timeFrom && (
          <Grid item xs={12}>
            <DatePickerInput
              label="Dátum vrátenia"
              disabledDays={getDisabledDays()}
              onChange={(value) => onChange("dateTo", value)}
            />
            <TimeInput
              classes={{ textField: classes.textField }}
              enabledFrom={
                dateFrom &&
                dateTo &&
                new Date(dateFrom).getDate() === new Date(dateTo).getDate()
                  ? timeFrom
                  : undefined
              }
              value={timeTo}
              onChange={(e) => onChange("timeTo", e.target.value)}
              label="Čas vrátenia"
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(ReservationTimeRange);
