import { defineMessages } from "react-intl";

const messages = defineMessages({
  navigationZones: {
    id: "navigation.zones",
    defaultMessage: "ŽAŽI AJ TY",
  },
  navigationPricing: {
    id: "navigation.pricing",
    defaultMessage: "CENNÍK",
  },
  closeButton: {
    id: "button.close",
    defaultMessage: "ZAVRIEŤ POPIS",
  },
  navigationEvents: {
    id: "navigation.events",
    defaultMessage: "AKCIE A EVENTY",
  },
  navigationRestaurant: {
    id: "navigation.restaurant",
    defaultMessage: "REŠTAURÁCIA",
  },
  navigationReservation: {
    id: "navigation.reservation",
    defaultMessage: "ZAREZERVUJ SI",
  },
  navigationGallery: {
    id: "navigation.gallery",
    defaultMessage: "GALÉRIA",
  },
  navigationBlog: {
    id: "navigation.blog",
    defaultMessage: "AKTUALITY",
  },
  navigationContact: {
    id: "navigation.contact",
    defaultMessage: "KONTAKTY",
  },
  linkZones: {
    id: "link.zones",
    defaultMessage: "zaziajty",
  },
  linkPricing: {
    id: "link.pricing",
    defaultMessage: "cennik",
  },
  linkEvents: {
    id: "link.events",
    defaultMessage: "eventy",
  },
  linkRestaurant: {
    id: "link.restaurant",
    defaultMessage: "restauracia",
  },
  linkReservation: {
    id: "link.reservation",
    defaultMessage: "rezervacia",
  },
  linkGallery: {
    id: "link.gallery",
    defaultMessage: "galeria",
  },
  linkBlog: {
    id: "link.blog",
    defaultMessage: "aktuality",
  },
  linkContact: {
    id: "link.contact",
    defaultMessage: "kontakt",
  },
  linkBonusCard: {
    id: "link.bonuscard",
    defaultMessage: "bonusova-karta",
  },
  linkParty: {
    id: "link.reservation.party",
    defaultMessage: "party",
  },
  linkBowling: {
    id: "link.reservation.bowling",
    defaultMessage: "bowling",
  },
  linkGdpr: {
    id: "link.gdpr",
    defaultMessage: "gdpr",
  },
  linkZone: {
    id: "link.zone",
    defaultMessage: "zona",
  },
  linkEvent: {
    id: "link.event",
    defaultMessage: "event",
  },
  linkVop: {
    id: "link.vop",
    defaultMessage: "vop",
  },

  linkJumpZone: {
    id: "link.zone.jump",
    defaultMessage: "jump",
  },

  notFound: {
    id: "error.notfound",
    defaultMessage: "Stránka nebola nájdená",
  },

  linkFunZone: {
    id: "link.zone.fun",
    defaultMessage: "fun",
  },

  linkCinemaZone: {
    id: "link.zone.cinema",
    defaultMessage: "cinema",
  },

  linkAquaZone: {
    id: "link.zone.aqua",
    defaultMessage: "aqua",
  },

  linkGameZone: {
    id: "link.zone.game",
    defaultMessage: "game",
  },
  disabledDate: {
    id: "reservation.disabled.date",
    defaultMessage:
      "Vo vybranom dátume nie je možné vytvoriť rezerváciu. Zvoľte prosím iný dátum.",
  },
  error: {
    id: "event.reservation.form.error.message",
    defaultMessage: "Ospravedlňujeme sa, vyskytla sa chyba na našom serveri.",
  },
  success: {
    id: "event.reservation.form.success.message",
    defaultMessage: "Ďakujeme za rezerváciu. Tešíme sa na Vás!",
  },
  name: {
    id: "event.reservation.form.name",
    defaultMessage: "Meno a priezvisko",
  },
  address: {
    id: "event.reservation.form.address",
    defaultMessage: "Ulica a popisné číslo",
  },
  email: {
    id: "event.reservation.form.email",
    defaultMessage: "E-mail",
  },
  city: {
    id: "event.reservation.form.city",
    defaultMessage: "Mesto a PSČ",
  },
  telephone: {
    id: "form.telephone",
    defaultMessage: "Telefón",
  },
  loading: {
    id: "form.loading",
    defaultMessage: "Odosielam...",
  },
  nameError: {
    id: "form.name.error",
    defaultMessage: "Zadajte svoje meno a priezvisko",
  },
  addressError: {
    id: "form.address.error",
    defaultMessage: "Zadajte svoju adresu",
  },
  showDescription: {
    id: "button.show.description",
    defaultMessage: "Zobraziť popis",
  },
  errorFirstname: {
    id: "form.firstname.error",
    defaultMessage: "Zadajte meno",
  },
  errorSurname: {
    id: "form.surname.error",
    defaultMessage: "Zadajte priezvisko",
  },
  emailError: {
    id: "form.email.error",
    defaultMessage: "Zadajte email v správnom tvare",
  },
  cityError: {
    id: "form.city.error",
    defaultMessage: "Zadajte obec a PSČ",
  },
  telephoneError: {
    id: "form.telephone.error",
    defaultMessage: "Zadajte telefónne číslo",
  },
  gdprError: {
    id: "form.gdpr.error",
    defaultMessage: "Potvrďte súhlas so spracovaním osobných údajov",
  },
  zipError: {
    id: "form.zip.error",
    defaultMessage: "Napíšte PSČ",
  },
  messageError: {
    id: "form.message.error",
    defaultMessage: "Napíšte počet a variantu lístkov.",
  },
  messagePlaceholder: {
    id: "form.message.placeholder",
    defaultMessage: "SEM NAPÍŠTE POČET A VARIANTU LÍSTKOV",
  },
});

export default messages;
