import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import GdrpAccept from "./GdrpAccept";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    padding: "0 16px 8px 16px",
  },
  label: {
    fontSize: 16,
  },
  textField: {
    borderRadius: 4,
    background: theme.palette.grey[100],
  },
});

const StepContact = ({
  classes,
  firstname = "",
  surname = "",
  email = "",
  gdpr = false,
  zip = "",
  address = "",
  city = "",
  telephone = "",
  errors = {},
  handleChange = () => {},
}) => (
  <div className={classes.root}>
    <Grid spacing={16} container>
      <Grid item xs={12} sm={6}>
        <TextField
          error={errors.firstname ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          fullWidth
          onClick={(e) => e.target.focus()}
          id="firstname"
          variant="outlined"
          className={classes.textField}
          onChange={(e) => handleChange("firstname", e.target.value)}
          value={firstname}
          label={
            errors.firstname ? (
              errors.firstname
            ) : (
              <FormattedMessage
                id="bowling.contact.name"
                defaultMessage="MENO"
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          error={errors.surname ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          fullWidth
          variant="outlined"
          onClick={(e) => e.target.focus()}
          className={classes.textField}
          id="surname"
          value={surname}
          onChange={(e) => handleChange("surname", e.target.value)}
          label={
            errors.surname ? (
              errors.surname
            ) : (
              <FormattedMessage
                id="bowling.contact.surname"
                defaultMessage="PRIEZVISKO"
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          error={errors.email ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          fullWidth
          variant="outlined"
          className={classes.textField}
          id="email"
          onClick={(e) => e.target.focus()}
          onChange={(e) => handleChange("email", e.target.value)}
          value={email}
          label={
            errors.email ? (
              errors.email
            ) : (
              <FormattedMessage
                id="bowling.contact.email"
                defaultMessage="E-MAIL"
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          error={errors.telephone ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          variant="outlined"
          onClick={(e) => e.target.focus()}
          className={classes.textField}
          id="telephone"
          onChange={(e) => handleChange("telephone", e.target.value)}
          value={telephone}
          label={
            errors.telephone ? (
              errors.telephone
            ) : (
              <FormattedMessage
                id="bowling.contact.telephone"
                defaultMessage="TELEFÓN"
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          error={errors.telephone ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          variant="outlined"
          onClick={(e) => e.target.focus()}
          className={classes.textField}
          id="address"
          onChange={(e) => handleChange("address", e.target.value)}
          value={address}
          label={
            errors.address ? (
              errors.address
            ) : (
              <FormattedMessage
                id="order.contact.address"
                defaultMessage="ULICA A POPISNÉ ČÍSLO"
              />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fullWidth
          variant="outlined"
          error={errors.telephone ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          onClick={(e) => e.target.focus()}
          className={classes.textField}
          id="city"
          onChange={(e) => handleChange("city", e.target.value)}
          value={city}
          label={
            errors.city ? (
              errors.city
            ) : (
              <FormattedMessage id="order.contact.city" defaultMessage="OBEC" />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          fullWidth
          variant="outlined"
          error={errors.telephone ? true : false}
          InputLabelProps={{
            classes: {
              root: classes.label,
            },
          }}
          onClick={(e) => e.target.focus()}
          className={classes.textField}
          id="zip"
          onChange={(e) => handleChange("zip", e.target.value)}
          value={zip}
          label={
            errors.zip ? (
              errors.zip
            ) : (
              <FormattedMessage id="order.contact.zip" defaultMessage="PSČ" />
            )
          }
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <GdrpAccept
          checked={gdpr}
          handleChange={() => handleChange("gdpr", !gdpr)}
          error={errors.gdpr}
        />
      </Grid>
    </Grid>
  </div>
);

export default withStyles(styles)(StepContact);
