export const parseDate = (dateStr, locale) => {
  if (!dateStr) {
    return;
  }
  const split = dateStr.split(".");
  const date = split[0];
  const month = split[1];
  const year = split[2];
  return new Date(Date.parse(`${month}/${date}/${year}`));
};

export const formatTime = timeStr => {
  return timeStr.slice(0, 5);
};

export const parseIsoDate = dateStr => {
  return new Date(Date.parse(dateStr));
};

export const formatToIsoDate = date => {
  if (!date) {
    return;
  }

  return date.toISOString().slice(0, 10);
};

export const formatDate = (date, locale) => {
  if (!date) {
    return;
  }
  let formated = date;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }

  switch (locale) {
    case "sk":
      formated = `${day}.${month}.${year}`;
      break;
    default:
      formated = `${day}.${month}.${year}`;
      break;
  }
  return formated;
};
