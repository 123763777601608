import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import DetailView from "../DetailView/DetailView";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    padding: 10,
  },
});

class LazyGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slideIndex: props.slideIndex || 0,
      fullscreenViewIdx: 0,
      rectangle: null,
    };
  }

  toggleFullscreen = (e, idx) => {
    const rectangle = e.currentTarget.getBoundingClientRect();
    this.setState({
      rectangle,
      fullscreenViewIdx: idx,
    });
  };

  handleOnClose = () => {
    this.setState({
      rectangle: null,
    });
  };

  render = () => {
    const { classes, cards = [], fullScreenMode = true } = this.props;

    return (
      <div>
        {this.state.rectangle && fullScreenMode ? (
          <DetailView
            activeItemId={this.state.fullscreenViewIdx}
            items={this.props.items || []}
            cards={this.props.cards || []}
            views={this.props.views || []}
            nextSection={this.props.nextSection}
            handleOnClose={this.handleOnClose}
            rectangleBox={this.state.rectangle}
          />
        ) : null}
        <Grid container spacing={16} className={classes.root}>
          {cards.map((card, idx) => (
            <Grid
              onClick={(e) => this.toggleFullscreen(e, idx)}
              key={idx}
              item
              xs={12}
              sm={6}
            >
              {card}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };
}

export default withStyles(styles)(LazyGrid);
