import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../../resources/img/element-icon-marker.svg";

const AnyReactComponent = ({ text }) => (
  <div>
    <img
      style={{
        top: "100%",
        left: "50%",
        transform: "translate(-50%, -100%)",
        height: 60,
        width: 40
      }}
      src={Marker}
      alt="Marker"
    />
  </div>
);

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 49.413832,
      lng: 19.48787
    },
    zoom: 12
  };

  render() {
    return (
      <div style={{ borderRadius: 16, height: "100%", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyC8n8sWLC2SXZKhtHILmbOFMw7L13D34cw" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={49.413832}
            lng={19.48787}
            text={"KINDERLAND"}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
