import React from "react";
import { injectIntl } from "react-intl";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import BonusCardButton from "./BonusCardButton";
import MenuItems from "./MenuItems";
import Logo from "./Logo";
import LangMenu from "./LangMenu";
import OpeningHours from "./OpeningHours";
import VRButton from "./VRButton";
import LogoEuImg from "../../resources/img/logo-efrr.png";
import Efrr from "../../resources/img/efrr.png";

export const LogoEu = withStyles((theme) => ({
  root: {
    height: 82,
    width: "auto",
    [theme.breakpoints.down("md")]: {
      height: 65,
      marginRight: 8,
      width: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: 50,
      marginLeft: 8,
      marginRight: 8,
      width: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      height: 40,
      marginLeft: 8,
      marginRight: 8,
      width: "auto",
    },
    [theme.breakpoints.down(391)]: {
      height: 35,
      marginTop: 8,
    },
    [theme.breakpoints.down(300)]: {
      height: 30,
      marginTop: 8,
      marginLeft: 8,
      marginRight: 8,
      width: "auto",
    },
  },
}))(({ classes }) => (
  <a href={Efrr} target="_blank" rel="noopener noreferrer">
    <img className={classes.root} src={LogoEuImg} alt="logo" />
  </a>
));

const styles = (theme) => ({
  root: {
    height: 128,
    width: "100%",
    background: "#fff",
    zIndex: "9999",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  navbarContainer: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1524px",
    margin: "auto",
  },
  panelTop: {
    height: 70,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 10,
  },
  list: {
    float: "right",
    margin: 0,
    "& li": {
      margin: "0 14px",
      display: "inline-block",
      [theme.breakpoints.down("md")]: {
        margin: "0 10px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      textAlign: "center",
      padding: 0,
      "& li": {
        display: "block",
      },
    },
  },
  logos: {
    display: "flex",
    float: "left",
    alignItems: "center",
  },
});

const StandardMenu = ({ classes }) => (
  <nav className={classes.root}>
    <div className={classes.navbarContainer}>
      <div className={classes.logos}>
        <Logo />
        <LogoEu />
      </div>
      <div>
        <div className={classes.panelTop}>
          <OpeningHours />
          <VRButton />
          <BonusCardButton />
          <LangMenu />
        </div>
        <div>
          <ul className={classes.list}>
            <MenuItems />
          </ul>
        </div>
      </div>
    </div>
  </nav>
);

export default compose(injectIntl, withStyles(styles))(StandardMenu);
