import React, { Fragment } from "react";
import NavbarLink from "./NavbarLink";
import messages from "../../i18n/messages";
import { injectIntl } from "react-intl";

const MenuItems = ({ intl: { formatMessage }, onItemClick }) => (
  <Fragment>
    <NavbarLink
      section="zones"
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationZones)}
      href={`/#${formatMessage(messages.linkZones)}`}
    />
    <NavbarLink
      key={5}
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationPricing)}
      href={`/${formatMessage(messages.linkPricing)}`}
    />
    {/* <NavbarLink
      key={2}
      section="events"
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationEvents)}
      href={`/#${formatMessage(messages.linkEvents)}`}
    /> */}
    <NavbarLink
      key={3}
      section="restaurant"
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationRestaurant)}
      href={`/#${formatMessage(messages.linkRestaurant)}`}
    />
    <NavbarLink
      key={4}
      section="eshop"
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationReservation)}
      href={`/#${formatMessage(messages.linkReservation)}`}
    />
    <NavbarLink
      key={6}
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationGallery)}
      href={`/${formatMessage(messages.linkGallery)}`}
    />
    <NavbarLink
      key={7}
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationBlog)}
      href={`/${formatMessage(messages.linkBlog)}`}
    />
    <NavbarLink
      key={8}
      handleOnClick={onItemClick}
      title={formatMessage(messages.navigationContact)}
      href={`/${formatMessage(messages.linkContact)}`}
    />
  </Fragment>
);

export default injectIntl(MenuItems);
