import React, { Component, useState, useEffect } from "react";
import Slider from "react-slick";
import { withStyles } from "@material-ui/core";
import { NextButton, PrevButton } from "./NavButtons";
import { useQuery } from "@apollo/react-hooks";
import { Query } from "@apollo/react-components";
import { getAssetPath } from "../../helpers";
import FullScreen from "./FullScreen";
import CloseIcon from "../../resources/img/cancel.svg";
import { NavLink } from "react-router-dom";
import { parse } from "query-string";
import gql from "graphql-tag";

const GALLERY_QUERY = gql`
  query getGalleryByCode($code: String) {
    data: getGalleryByCode(code: $code) {
      id
      title
      code
      Files {
        id
        storage_name
        resource_type
      }
      actionTitle
      actionHref
      Icon {
        storage_name
      }
    }
  }
`;

const styles = (theme) => ({
  root: {
    padding: "64px 0",
    backgroundImage: "linear-gradient(to right, #f6f6f6, #b9b9b9)",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    left: 0,
    right: 0,
  },
  container: {
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  closeButtonContainer: {
    cursor: "pointer",
    position: "absolute",
    top: 24,
    right: 24,
    zIndex: 99999,
    height: 50,
    width: 50,
    "& > a": {
      height: 50,
      width: 50,
      display: "block",
    },
  },
  closeButton: {
    cursor: "pointer",
    height: 50,
    width: 50,
  },
});

const FullScreenGallery = ({ match, classes }) => {
  const [file, setFile] = useState();

  useEffect(() => {
    const { file } = parse(window.location.search);
    setFile(file);
    window.document.body.style.overflow = "hidden";
    return () => {
      window.document.body.style.overflow = "auto";
    };
  }, []);

  if (!file) return null;

  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
  };

  return (
    <div className={classes.root}>
      <div className={classes.closeButtonContainer}>
        <NavLink to={`/galeria`}>
          <img
            className={classes.closeButton}
            src={CloseIcon}
            alt="Close icon"
          />
        </NavLink>
      </div>
      <div className={classes.container}>
        <Query query={GALLERY_QUERY} variables={{ code: match.params.id }}>
          {({ data, error, loading }) => {
            if (loading) return null;
            if (error) return null;

            const files = (data && data.data && data.data.Files) || [];

            const part1 = [];
            const part2 = [];
            let switchPart = false;

            files.forEach((x) => {
              if (file === x.id) {
                switchPart = true;
              }

              if (switchPart) {
                part1.push(x);
              } else {
                part2.push(x);
              }
            });

            const reorderedFiles = [...part1, ...part2];

            return (
              <Slider {...settings}>
                {reorderedFiles.map((image, idx) => (
                  <FullScreen
                    key={idx}
                    bgImage={
                      image.storage_name && getAssetPath(image.storage_name)
                    }
                  />
                ))}
              </Slider>
            );
          }}
        </Query>
      </div>
    </div>
  );
};

export default withStyles(styles)(FullScreenGallery);
