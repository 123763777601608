import { defineMessages } from "react-intl";

const messages = defineMessages({
  error: {
    id: "bonuscard.error.message",
    defaultMessage: "Ospravedlňujeme sa, vyskytla sa chyba na našom serveri."
  },
  success: {
    id: "bonuscard.success.message",
    defaultMessage: "Vaša žiadosť bola odoslaná. Ďakujeme."
  },
  name: {
    id: "bonuscard.form.name",
    defaultMessage: "Meno a priezvisko"
  },
  address: {
    id: "bonuscard.form.address",
    defaultMessage: "Ulica a popisné číslo"
  },
  email: {
    id: "bonuscard.form.email",
    defaultMessage: "E-mail"
  },
  city: {
    id: "bonuscard.form.city",
    defaultMessage: "Mesto"
  },
  psc: {
    id: "bonuscard.form.psc",
    defaultMessage: "PSČ"
  },
  telephone: {
    id: "bonuscard.form.telephone",
    defaultMessage: "Telefón"
  },
  loading: {
    id: "bonuscard.form.loading",
    defaultMessage: "Odosielam..."
  },
  nameError: {
    id: "bonuscard.form.name.error",
    defaultMessage: "Zadajte svoje meno a priezvisko"
  },
  addressError: {
    id: "bonuscard.form.address.error",
    defaultMessage: "Zadajte svoju adresu"
  },
  emailError: {
    id: "bonuscard.form.email.error",
    defaultMessage: "Zadajte email v správnom tvare"
  },
  cityError: {
    id: "bonuscard.form.city.error",
    defaultMessage: "Zadajte obec"
  },
  telephoneError: {
    id: "bonuscard.form.telephone.error",
    defaultMessage: "Zadajte telefónne číslo"
  },
  gdprError: {
    id: "bonuscard.form.gdpr.error",
    defaultMessage: "Potvrďte súhlas so spracovaním osobných údajov"
  },
  pscError: {
    id: "bonuscard.form.psc.error",
    defaultMessage: "Zadajte PSČ"
  }
});

export default messages;
