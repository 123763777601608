import React, { Component } from "react";
import { withStyles, Grid, Typography } from "@material-ui/core";
import { SectionTitle } from "../../components/Typography";
import HeadingCard from "../../components/DetailView/HeadingCard";
import {
  Title,
  Subtitle,
} from "../../components/Reservation/ReservationHeadings";
import StepPersons from "./StepPersons";
import StepSummary from "../../components/Reservation/ReservationSummary";
import StepContact from "../../components/Reservation/ReservationContact";
import ArrowIcon from "../../resources/img/icon-arrow-1.svg";
import Arrow1Icon from "../../resources/img/icon-arrow-2.svg";
import { Query } from "@apollo/react-components";
import { rectangleEffect } from "../../helpers";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";
import gql from "graphql-tag";
import { defineMessages } from "react-intl";
import { getImage } from "../../helpers";
import Validator from "./Validator";

const ReactGA = process.browser ? require("react-ga") : {};

const messages = defineMessages({
  error: {
    id: "reservation.simple.error.message",
    defaultMessage: "Vyplňte prosím všetky údaje.",
  },
  success: {
    id: "reservation.simple.success.message",
    defaultMessage: "Váš termín je zarezerovaný. Tešíme sa na Vás",
  },
  metaDescription: {
    id: "reservation.simple.meta.description",
    defaultMessage:
      "Zábavné centrum, ktoré každodenne spríjemní voľný čas deťom a tiež rodičom. Nájdete u nás: Jump zóna, Fun zóna, Game zóna , 5D cinema, 9D cinema, Námestovo",
  },
});

const QUERY_RESERVATION_CONFIG = gql`
  query SimpleReservationByCode($code: String!) {
    Config: SimpleReservationByCode(code: $code) {
      id
      code
      title
      price
      price_text
      price_child
      ticket_count
      subtitle
      description
      description1
      description2
      date_from
      date_to
      enable_voucher
      CoverImage {
        storage_name
      }
      Thumbnail {
        storage_name
      }
      coverimage_id
      thumbnail_id
    }
  }
`;

const styles = (theme) => ({
  root: {
    padding: 16,
    maxWidth: 1224,
    margin: "auto",
    textAlign: "center",
  },
  section: {
    margin: "64px auto",
    maxWidth: "1224px",
  },
  narrowSection: {
    margin: "64px auto",
    maxWidth: 650,
  },
  titleCaption: {
    fontFamily: "TypoComica",
    color: "#555555",
    marginTop: 8,
    fontSize: 16,
  },
  arrowContainer: {
    textAlign: "center",
  },
  summarySection: {
    backgroundColor: "#fff",
    borderRadius: 16,
    overflow: "hidden",
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    maxWidth: 800,
    padding: 32,
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: "32px 0 16px 0",
    },
  },
});

class SimpleReservation extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    const initialState = {
      data: {
        firstname: "",
        surname: "",
        email: "",
        gdpr: false,
        telephone: "",
        childrenCount: 0,
        adultCount: 0,
        address: "",
        city: "",
        zip: "",
      },
      errors: {
        firstname: "",
        surname: "",
        email: "",
        telephone: "",
        ticketCount: "",
        gdpr: "",
        address: "",
        city: "",
        zip: "",
      },
      message: {
        type: "INFO",
        text: "",
      },
      loading: false,
    };

    return initialState;
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleChangeData = (attr, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [attr]: value,
      },
    });
  };

  handleError = (attr, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [attr]: value,
      },
    });
  };

  setErrors = (errors) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
      message: {
        type: "INFO",
        text: "",
      },
    });
  };

  loading = (loading) => this.setState({ loading });

  handleSubmit = () => {
    const {
      intl: { formatMessage },
    } = this.props;
    this.setMessage(formatMessage(messages.success), "success");
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  setMessage = (text, type) => {
    this.setState({
      message: {
        text,
        type,
      },
    });
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage },
      match: {
        params: { code },
      },
    } = this.props;

    return (
      <Query query={QUERY_RESERVATION_CONFIG} variables={{ code }}>
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error) return null;

          const { Config } = data;

          if (!Config) return null;

          const descriptions = [];
          if (Config.description1) {
            descriptions.push(Config.description1);
          }

          if (Config.description2) {
            descriptions.push(Config.description2);
          }

          return (
            <div className={classes.root}>
              <Helmet>
                <title>{Config.title}</title>
                <meta
                  name="description"
                  content={formatMessage(messages.metaDescription)}
                />
              </Helmet>

              <HeadingCard
                closeLink={`/#${formatMessage(
                  generalMessages.linkReservation
                )}`}
                handleClose={() => rectangleEffect(false)}
                icon={null}
                height="350px"
                bgImage={
                  Config.CoverImage && getImage(Config.CoverImage.storage_name)
                }
                title={Config.title}
                captions={descriptions}
              />
              <br />
              <SectionTitle>
                {Config.subtitle ? (
                  Config.subtitle
                ) : (
                  <FormattedMessage
                    id="reservation.simple.title"
                    defaultMessage="ZAREZERVUJ SI"
                  />
                )}
              </SectionTitle>

              <Typography align="center" className={classes.titleCaption}>
                {Config.description ? (
                  Config.description
                ) : (
                  <FormattedMessage
                    id="reservation.simple.subtitle"
                    defaultMessage="Je to jednoduché a rýchle, v 3 krokoch."
                  />
                )}
              </Typography>

              <div className={classes.section}>
                <Grid justify="center" container spacing={16}>
                  <Grid item lg={12}>
                    <Title number={"1."}>
                      <FormattedMessage
                        id="reservation.simple.personcount.title"
                        defaultMessage="Zadaj počet osôb"
                      />
                    </Title>
                    <Subtitle></Subtitle>
                    <StepPersons
                      simpleMode={Config.price_child ? false : true}
                      priceText={Config.price_text}
                      errors={this.state.errors}
                      adultCount={this.state.data.adultCount}
                      childrenCount={this.state.data.childrenCount}
                      handleChange={this.handleChangeData}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.arrowContainer}>
                <img src={Arrow1Icon} alt="Arrow" />
              </div>

              <div className={classes.narrowSection}>
                <Grid justify="center" container spacing={16}>
                  <Grid item lg={12}>
                    <Title number={"2."}>
                      <FormattedMessage
                        id="reservation.simple.ordercontact.title"
                        defaultMessage="Zadaj svoje kontaktné údaje"
                      />
                    </Title>
                    <StepContact
                      errors={this.state.errors}
                      gdpr={this.state.data.gdpr}
                      zip={this.state.data.zip}
                      address={this.state.data.address}
                      city={this.state.data.city}
                      firstname={this.state.data.firstname}
                      surname={this.state.data.surname}
                      email={this.state.data.email}
                      telephone={this.state.data.telephone}
                      handleChange={this.handleChangeData}
                    />
                  </Grid>
                </Grid>
              </div>

              <div className={classes.arrowContainer}>
                <img src={ArrowIcon} alt="Arrow" />
              </div>

              <div className={classes.section}>
                <div className={classes.summarySection}>
                  <Grid justify="center" container spacing={0}>
                    <Grid style={{ width: "90%" }} item lg={12}>
                      <Title color="#cf2c91" number={"3."}>
                        <FormattedMessage
                          id="reservation.simple.confirm.title"
                          defaultMessage="Skontroluj údaje a potvrď rezerváciu"
                        />
                      </Title>
                      <StepSummary
                        setMessage={this.setMessage}
                        setErrors={this.setErrors}
                        orderType="event"
                        validate={Validator}
                        message={this.state.message}
                        code={Config.code}
                        onSuccess={() => {
                          this.resetState();
                          ReactGA.event({
                            category: Config.title,
                            action: "Odoslanie formulara",
                            label: "Potvrdiť rezerváciu",
                          });
                        }}
                        data={this.state.data}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  };
}

export default injectIntl(withRouter(withStyles(styles)(SimpleReservation)));
