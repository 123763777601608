import React from "react";
import { withStyles, Typography } from "@material-ui/core";
import LinkIcon from "../../resources/img/external-link-symbol-2.svg";
import dateFormat from "dateformat";
import { PostCardTitle } from "../../components/Typography";
import SimpleLink from "../../components/SimpleLink";
import { ExternalLink2 } from "../../components/SvgIcon";
import CategoryPill from "./CategoryPill";

const styles = (theme) => ({
  root: {
    background: "#7f7fc7",
    borderRadius: 16,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    position: "relative",
    textAlign: "center",
    cursor: "pointer",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage:
      "linear-gradient(130deg, rgba(35, 31, 32, 0.16), rgba(35, 31, 32, 0.56))",
    height: 492,
    [theme.breakpoints.down("lg")]: {
      height: 450,
    },
    [theme.breakpoints.down("md")]: {
      height: 380,
    },
    [theme.breakpoints.down("sm")]: {
      height: 300,
    },
    // [theme.breakpoints.down('xs')]: {
    //     height: 180
    // }
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    borderRadius: 16,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  content: {
    padding: 32,
    margin: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("xs")]: {
      padding: 8,
    },
  },
  title: {
    lineHeight: 1.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  date: {
    fontFamily: "TypoComica",
    padding: "8px 0",
    color: "#d1d3d4",

    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  link: {
    position: "absolute",
    left: 10,
    right: 10,
    bottom: 60,
    [theme.breakpoints.down("sm")]: {
      bottom: 15,
    },
  },
  categoriesContainer: {
    float: "right",
    display: "inline-block",
    padding: 8,
    "& > div": {
      display: "inline-block",
    },
  },
});

const PostCard = ({
  classes,
  width = "auto",
  bgImage,
  feed = false,
  title,
  categories = [],
  date,
  actionTitle,
}) => (
  <div
    className={`${classes.root} ${feed ? classes.feed : ""}`}
    style={{ width, backgroundImage: `url(${bgImage})` }}
  >
    <div
      className={classes.imageWrapper}
      style={{
        backgroundImage: `linear-gradient(157deg, rgba(35, 31, 32, 0.5), rgba(35, 31, 32, 0.26))`,
      }}
    >
      <div className={classes.categoriesContainer}>
        {categories.map((x, idx) => (
          <CategoryPill color={x.color} key={idx}>
            {x.title}
          </CategoryPill>
        ))}
      </div>
      <div className={`card-content ${classes.content}`}>
        {date && (
          <Typography className={classes.date} align="center" variant="body1">
            {dateFormat(date, "dddd, d. mmmm yyyy")}
          </Typography>
        )}
        <PostCardTitle additionalStyles={classes.title}>{title}</PostCardTitle>
      </div>
      {actionTitle && (
        <SimpleLink
          responsive
          alwaysDisplayText
          additionalClassses={classes.link}
          icon={LinkIcon}
          svg={<ExternalLink2 />}
        >
          {actionTitle}
        </SimpleLink>
      )}
    </div>
  </div>
);

export default withStyles(styles)(PostCard);
