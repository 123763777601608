import React from "react";
import { withStyles } from "@material-ui/core";
import HeadingCard from "../DetailView/HeadingCard";
import RichTextField from "../../pages/rich-text-field/RichTextField";

const styles = () => ({
  root: {},
  textContainer: {
    marginTop: 32,
    padding: 32,
    borderRadius: 10,
  },
  poster: {},
  posterContainer: {},
});

const FullScreen = ({
  classes,
  title = "",
  elements,
  poster,
  cover = null,
  date = null,
  activeSlideHeight = "auto",
}) => (
  <div>
    <HeadingCard
      height={activeSlideHeight}
      title={title}
      bgImage={cover}
      date={date}
    />

    <RichTextField classes={{ root: classes.textContainer }}>
      {elements}
    </RichTextField>

    {poster ? (
      <div className={classes.posterContainer}>
        <img className={classes.poster} src={poster} alt="Poster" />
      </div>
    ) : null}
  </div>
);

export default withStyles(styles)(FullScreen);
