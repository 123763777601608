import React from "react";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  error: {
    borderRadius: 8,
    border: "1px solid red",
    color: "red",
    maxWidth: 300,
    width: "100%",
    margin: "auto",
    padding: 16,
  },
  success: {
    borderRadius: 8,
    border: "1px solid green",
    color: "green",
    margin: "auto",
    maxWidth: 300,
    width: "100%",
    padding: 16,
  },
  info: {
    padding: 16,
  },
});

const Message = ({ classes, type, message, disableBorder = false }) => {
  const getClasses = () => {
    if (type === "success") {
      return classes.success;
    }
    if (type === "error") {
      return classes.error;
    }
    return classes.info;
  };

  return (
    <div
      style={{ borderWidth: disableBorder ? 0 : 1 }}
      className={getClasses()}
    >
      {message}
    </div>
  );
};

export default withStyles(styles)(Message);
