import React from "react";
import { withStyles, Button, Typography } from "@material-ui/core";
import ArrowRightImage from "../../resources/img/arrow-right-white.svg";
import SimpleLink from "../SimpleLink";
import { HashLink as Link } from "react-router-hash-link";

const styles = (theme) => ({
  root: {
    backgroundSize: "cover",
    backgroundColor: "rgba(35,31,32,0.1)",
    boxShadow: "0 16px 38px 0 rgba(35, 31, 32, 0.16)",
    backgroundPosition: "center",
    height: 500,
    borderRadius: 16,
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      height: 400,
    },
    [theme.breakpoints.down("md")]: {
      height: 350,
    },
    [theme.breakpoints.down("sm")]: {
      height: 250,
    },
    [theme.breakpoints.down("xs")]: {
      height: "75vw",
      textAlign: "center",
    },
  },
  button: {
    height: 50,
    marginTop: 16,
    fontSize: 14,
    letterSpacing: "-0.1px",
    lineHeight: "50px",
    padding: "0 20px 0 26px",
    [theme.breakpoints.down("lg")]: {
      height: 45,
      lineHeight: "45px",
    },
    [theme.breakpoints.down("md")]: {
      height: 40,
      fontSize: 12,
      lineHeight: "40px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
      height: 40,
      fontSize: 14,
      lineHeight: "40px",
    },
  },
  textContainer: {
    position: "absolute",
    margin: 0,
    top: "50%",
    left: "6%",
    maxWidth: "85%",
    marginRight: "-50%",
    transform: "translate(6%, -50%)",
    fontFamily: "PaytoneOne",
    [theme.breakpoints.down("xs")]: {
      transform: "none",
      maxWidth: "100%",
      textAlign: "center",
      margin: 0,
      top: "auto",
      bottom: "10%",
      left: "0",
      right: "0",
      //transform: "translate(-50%, 10%)"
    },
  },
  title: {
    fontSize: 44,
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    fontFamily: "PaytoneOne",
    [theme.breakpoints.down("lg")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
      textAlign: "center",
    },
  },
  text: {
    fontSize: 48,
    fontFamily: "PaytoneOne",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down("lg")]: {
      fontSize: 40,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      textAlign: "center",
    },
    [theme.breakpoints.down(380)]: {
      fontSize: 25,
      textAlign: "center",
    },
  },
});

const Slide = ({
  title = "",
  text = "",
  ctaTitle = "",
  ctaLink = "/",
  ctaColor = "#bc1422",
  fontColor = "#fff",
  classes,
  backgroundImage,
}) => (
  <div
    className={classes.root}
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className={classes.textContainer}>
      <Typography style={{ color: fontColor }} className={classes.title}>
        {title}
      </Typography>
      <Typography style={{ color: fontColor }} className={classes.text}>
        {text}
      </Typography>
      <Link to={ctaLink || "/"}>
        <SimpleLink
          icon={ArrowRightImage}
          fontColor={"#fff"}
          color={ctaColor}
          additionalClassses={classes.button}
        >
          {ctaTitle}
        </SimpleLink>
      </Link>
    </div>
  </div>
);

export default withStyles(styles)(Slide);
