import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import PriceCard from "../PriceCard";
import BonusCard from "../BonusCard";

const styles = () => ({
  root: {
    marginBottom: 16,
  },
});

const BaseInfo = ({ image, color, priceText, bonusCardText, classes }) => {
  return (
    <Grid container className={classes.root} spacing={16}>
      <Grid item xs={12} sm={12} md={6}>
        <PriceCard
          button={false}
          image={image}
          backgroundColor={color}
          price={priceText}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <BonusCard color={color} text={bonusCardText} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(BaseInfo);
