import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme({
  palette: {
    common: {
      black: "#000",
      white: "#fff"
    },
    primary: {
      light: "#cf2c91", // pin-violet
      main: "#cf2c91",
      dark: "#cf2c91"
    },
    secondary: {
      light: "#cf2c91", // orange
      main: "#cf2c91",
      dark: "#cf2c91"
    },
    background: {
      default: "#fff",
      paper: "#fff"
    }
  },
  typography: {
    fontFamily: "lato",
    fontSize: 18,
    headline: {
      fontFamily: "PaytoneOne",
      fontSize: "3rem",
      color: "#231f20"
    },
    title: {
      fontFamily: "PaytoneOne",
      fontSize: "48px",
      color: "#231f20"
    },
    body1: {
      color: "#231f20"
    },
    body2: {
      color: "#fff"
    },
    h5: {
      fontFamily: "PaytoneOne",
      fontSize: "40px",
      color: "#231f20"
    },
    subheading: {
      color: "#888",
      fontSize: "1.8rem",
      fontWeight: "bold"
    },
    subtitle1: {
      fontFamily: "lato",
      color: "#231f20",
      fontSize: "20px",
      paddingBottom: "20px"
    },
    display1: {
      color: "#231f20"
    }
  }
});

// const {
//   breakpoints,
//   typography: { pxToRem }
// } = defaultTheme;

const theme = {
  ...defaultTheme,
  overrides: {
    MuiButton: {
      text: {
        color: "#bc2482",
        fontSize: 14,
        cursor: "pointer"
      },
      outlined: {
        border: "2px solid #bc2482",
        color: "#bc2482",
        boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
        borderRadius: 8,
        fontSize: 14,
        padding: "4px 8px",
        "&:hover": {
          backgroundColor: "rgba(207, 44, 145, 0.08)"
        }
      },
      contained: {
        borderRadius: 8,
        backgroundImage: "linear-gradient(98deg, #cf2c91, #bc2482)",
        boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
        padding: "8px 16px",
        color: "#fff",
        fontSize: 16,
        fontFamily: "lato",
        fontStyle: "normal",
        fontStretch: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        "&:hover": {
          backgroundImage: "linear-gradient(98deg, #cf2c91, #bc2482)"
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
        padding: 4
      }
    }
  }
};

export default theme;
