import React from "react";
import { withStyles } from "@material-ui/core";
import ZoneIcon from "./ZoneIcon";

const styles = () => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    fontFamily: "lato",
    listStyle: "none",
    padding: "0 16px",
  },
});

const ZonesList = ({ classes, availableZones = [], selectedZones = [] }) => (
  <ul className={classes.root}>
    {availableZones.map((zone) => (
      <li key={zone.id}>
        <ZoneIcon
          active={selectedZones.indexOf(zone.id) > -1}
          icon={zone.icon}
          title={zone.title}
          description={zone.description}
        />
      </li>
    ))}
  </ul>
);

export default withStyles(styles)(ZonesList);
