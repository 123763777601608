import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import BonusCardButton from "./BonusCardButton";
import MobileMenuButton from "./MobileMenuButton";
import Logo from "./Logo";
import LangMenu from "./LangMenu";
import { LogoEu } from "./StandardMenu";

const styles = (theme) => ({
  root: {
    position: "fixed",
    height: 80,
    boxShadow: "0 16px 48px 0 rgba(35, 31, 32, 0.16)",
    width: "100%",
    top: 0,
    zIndex: 1000,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mobileMenuContainer: {
    height: 80,
    padding: 20,
    background: "#fff",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
});

const MobileMenu = ({ classes, toggleMenu }) => (
  <div className={classes.root}>
    <div className={classes.mobileMenuContainer}>
      <div>
        <Logo />
        <LogoEu />
      </div>
      <div className={classes.flexContainer}>
        <BonusCardButton />
        <LangMenu />
        <MobileMenuButton toggleMenu={toggleMenu} />
      </div>
    </div>
  </div>
);

export default compose(withStyles(styles))(MobileMenu);
