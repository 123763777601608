import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StandardMenu from './StandardMenu'
import MobileMenu from './MobileMenu'
import SideNav from './SideNav'
import { injectIntl } from 'react-intl';


const styles = theme => ({
  body: {
    transition: 'left 300ms linear',
    left: 0,
  },
  bodyTranslated: {
    transition: 'left 300ms linear',
    left: '-285px',
    position: 'relative',
  },
});

class Navbar extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
  }

  componentDidMount = () =>
    document.body.classList.add(this.props.classes.body);

  toggleMenu = status => {
    typeof window.closeDetailView === 'function' && window.closeDetailView();

    const { toggled } = this.state;
    this.setState({
      toggled: status ? status : !toggled,
    });

    const body = window.document.body;

    if (!toggled) {
      body.classList.remove(this.props.classes.body);
      body.classList.add(this.props.classes.bodyTranslated);
    } else {
      body.classList.add(this.props.classes.body);
      body.classList.remove(this.props.classes.bodyTranslated);
    }
  };

  render = () => {
    
    const { toggled } = this.state;

    return [
      <StandardMenu key="1" />,
      <MobileMenu toggleMenu={this.toggleMenu} key="2" />,
      <SideNav toggled={toggled} toggleMenu={this.toggleMenu} key="3" />,
    ];
  };
}

export default injectIntl(withStyles(styles)(Navbar));
