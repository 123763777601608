import React from "react";
import { withStyles, Button } from "@material-ui/core";

const styles = () => ({
  root: {},
});

const CustomButton = ({
  classes,
  children,
  handleOnClick,
  icon,
  variant = "contained",
}) => {
  return (
    <Button className={classes.root} onClick={handleOnClick} variant={variant}>
      {children}
      {icon}
    </Button>
  );
};

export default withStyles(styles)(CustomButton);
