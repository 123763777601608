import React, { Fragment } from "react";
import DatePickerInput from "./DatePickerInput";
import { withStyles, Grid } from "@material-ui/core";
import TimeInput from "./TimeInput";

const RESERVATION_TIMES = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

const styles = (theme) => ({
  root: {},
  textField: {
    width: "100%",
    marginLeft: 0,
  },
});

const ReservationTimeRange = ({
  timeFrom,
  timeTo,
  dateFrom,
  dateTo,
  onChange = () => {},
  blackList = [],
  classes,
}) => {
  const dateBlackList = [];
  blackList.forEach((x) => {
    try {
      dateBlackList.push(new Date(x.date));
    } catch (e) {
      console.warn(e);
    }
  });

  return (
    <Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <DatePickerInput
            width="100%"
            disabledDays={[
              ...dateBlackList,
              {
                before: new Date(),
              },
            ]}
            label="Dátum"
            onChange={(value) => onChange("date", value)}
          />
        </Grid>
        {dateFrom && (
          <Fragment>
            <Grid item xs={6}>
              <TimeInput
                times={RESERVATION_TIMES}
                value={timeFrom}
                classes={{ textField: classes.textField }}
                label="Čas vyzdvihnutia"
                onChange={(e) => onChange("timeFrom", e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TimeInput
                classes={{ textField: classes.textField }}
                enabledFrom={timeFrom}
                value={timeTo}
                onChange={(e) => onChange("timeTo", e.target.value)}
                label="Čas vrátenia"
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles)(ReservationTimeRange);
