import React, { Component } from "react";
import { withStyles, Typography } from "@material-ui/core";
import StepDate from "../../components/Reservation/StepDate";
import StepContact from "../../components/Reservation/ReservationContact";
import StepSummary from "../../components/Reservation/ReservationSummary";
import HeadingCard from "../../components/DetailView/HeadingCard";
import { Query } from "@apollo/react-components";
import { compose } from "recompose";
import { SectionTitle } from "../../components/Typography";
import {
  Title,
  Subtitle,
} from "../../components/Reservation/ReservationHeadings";
import { rectangleEffect, getImage } from "../../helpers";
import { withRouter } from "react-router-dom";
import Helmet from "react-helmet";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import Validator from "./Validator";
import generalMessages from "../../i18n/messages";
import gql from "graphql-tag";
import dateformat from "dateformat";
import Section from "../../components/General/Section";
import SectionArrow from "../../components/Reservation/SectionArrow";
import BaseInfo from "../../components/Reservation/BaseInfo";
import Content from "../../components/General/Content";
import RichTextField from "../rich-text-field/RichTextField";
const ReactGA = process.browser ? require("react-ga") : {};

export const QUERY_RESERVATION_CONFIG = gql`
  query ReservationSystemConfig($code: String!) {
    Config: ReservationSystemConfig(code: $code) {
      id
      title
      text
      sections {
        number
        title
        subtitle
      }
      subtitle1
      subtitle2
      primary_color
      caption1
      caption2
      price_text
      enabled
      bonus_card_text
      date_blacklist {
        date
      }
      CoverSecondaryImage {
        storage_name
      }
      CoverImage {
        storage_name
      }
      Icon {
        storage_name
      }
      Packages {
        id
        title
        subtitle
        captions
        captions1
        captions2
        price
        priceOld
        primaryColor
        priceExtraAdult
        priceExtraChildren
        units
        Image {
          storage_name
        }
        Zones {
          id
          title
        }
      }
      ExtraZone {
        title
        Items {
          id
          title
          text
          price
          units
          Image {
            storage_name
          }
        }
      }
    }
  }
`;

const styles = (theme) => ({
  summary: {
    padding: 32,
  },
  textContainer: {
    fontSize: 18,
  },
});

class ReservationKaraoke extends Component {
  constructor(props) {
    super(props);

    let d = new Date();

    this.state = {
      data: {
        reservationDate: dateformat(d, "yyyy-mm-dd"),
        reservationTimes: [],
        firstname: "",
        surname: "",
        email: "",
        gdpr: false,
        hasErrors: false,
        telephone: "",
        address: "",
        city: "",
        zip: "",
      },
      errors: {
        maximumReservations: "",
        firstname: "",
        surname: "",
        address: "",
        city: "",
        zip: "",
        email: "",
        telephone: "",
        reservationTimes: "",
        gdpr: "",
      },
      message: {
        type: "INFO",
        text: "",
      },
      loading: false,
    };

    this.initialState = this.state;
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  handleChangeData = (attr, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [attr]: value,
      },
    });
  };

  handleError = (attr, value) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [attr]: value,
      },
    });
  };

  setErrors = (errors) => {
    this.setState({
      errors: {
        ...this.state.errors,
        ...errors,
      },
    });
  };

  loading = (loading) => this.setState({ loading });

  resetState = () => {
    this.setState(this.initialState);
  };

  setMessage = (text, type) => {
    this.setState({
      message: {
        text,
        type,
      },
    });
  };

  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }

    const selectedDay = day;

    this.setState({
      data: {
        ...this.state.data,
        reservationDate: selectedDay,
        reservationTimes: [],
      },
    });
  };

  handleChangeTime = (checked, timeRanges) => {
    if (checked) {
      this.handleChangeData("reservationTimes", [
        ...this.state.data.reservationTimes,
        ...timeRanges.map((tr) => ({
          alleyId: tr.alleyId,
          timeFrom: tr.timeFrom,
          timeTo: tr.timeTo,
        })),
      ]);
    } else {
      let reservationTimes = this.state.data.reservationTimes;

      let newReservationTimes = reservationTimes.filter((rt) => {
        return !timeRanges.find(
          (tr) =>
            rt.alleyId === tr.alleyId &&
            tr.timeFrom === rt.timeFrom &&
            tr.timeTo === rt.timeTo
        );
      });

      this.handleChangeData("reservationTimes", newReservationTimes);
    }
  };

  validate = (reservationData) => {
    const { errors, valid } = Validator({
      data: reservationData,
      formatMessage: this.props.intl.formatMessage,
    });

    this.setErrors(errors);

    return valid;
  };

  render = () => {
    const {
      classes,
      intl: { formatMessage },
    } = this.props;

    return (
      <Query query={QUERY_RESERVATION_CONFIG} variables={{ code: "KARAOKE" }}>
        {({ data, loading, error }) => {
          if (loading) return null;
          if (error) return null;

          const { Config } = data || {};

          if (!Config) return "Rezervačný systém nie je ešte nakonfigurovaný.";

          if (Config.enabled === false) {
            return "Rezervačný systém nie je momentálne dostupný. Skúste prosím neskôr.";
          }

          const getSection = (number) => {
            return (
              (Config.sections &&
                Config.sections.find((section) => section.number == number)) ||
              {}
            );
          };

          return (
            <Content>
              <Helmet>
                <title>{formatMessage(messages.metaTitle)}</title>
                <meta
                  name="description"
                  content={formatMessage(messages.metaDescription)}
                />
              </Helmet>
              <HeadingCard
                closeLink={`/#${formatMessage(
                  generalMessages.linkReservation
                )}`}
                handleClose={() => {
                  rectangleEffect(false);
                }}
                height="350px"
                bgImage={
                  Config.CoverImage && getImage(Config.CoverImage.storage_name)
                }
                icon={Config.Icon && getImage(Config.Icon.storage_name)}
                title={Config.title}
                captions={[Config.caption1, Config.caption2]}
              />

              {Config.price_text && Config.bonus_card_text && (
                <Section>
                  <BaseInfo
                    image={
                      Config.CoverSecondaryImage &&
                      getImage(Config.CoverSecondaryImage.storage_name)
                    }
                    color={Config.primary_color}
                    priceText={Config.price_text}
                    bonusCardText={Config.bonus_card_text}
                  />
                </Section>
              )}

              <Section marginTop={30}>
                <SectionTitle>{Config.subtitle1}</SectionTitle>
                <Typography align="center">{Config.subtitle2}</Typography>
              </Section>

              {Config.text && (
                <Section marginTop={50} textAlign="left" maxWidth={800}>
                  <RichTextField classes={{ root: classes.textContainer }}>
                    {Config.text}
                  </RichTextField>
                </Section>
              )}

              <SectionArrow variant="right" />

              <Section>
                <Title number={"1."}>{getSection(1).title}</Title>
                <Subtitle>{getSection(1).subtitle}</Subtitle>
                <StepDate
                  showTrackTitle={false}
                  reservationSystemId={Config.id}
                  reservationDate={this.state.data.reservationDate}
                  reservationTimes={this.state.data.reservationTimes}
                  dateBlackList={Config.date_blacklist}
                  errors={this.state.errors}
                  handleDayClick={this.handleDayClick}
                  handleChangeTime={this.handleChangeTime}
                />
              </Section>

              <SectionArrow variant="left" />

              <Section maxWidth={800}>
                <Title number={"2."}>{getSection(2).title}</Title>
                <Subtitle>{getSection(2).subtitle}</Subtitle>
                <StepContact
                  firstname={this.state.data.firstname}
                  surname={this.state.data.surname}
                  email={this.state.data.email}
                  zip={this.state.data.zip}
                  address={this.state.data.address}
                  city={this.state.data.city}
                  telephone={this.state.data.telephone}
                  errors={this.state.errors}
                  gdpr={this.state.data.gdpr}
                  handleChange={this.handleChangeData}
                />
              </Section>

              <SectionArrow variant="straight" />

              <Section
                classes={{ root: classes.summary }}
                background={"#fff"}
                maxWidth={800}
              >
                <Title color="#cf2c91" number="3." variant="title">
                  <FormattedMessage
                    id="dart.confirm.title"
                    defaultMessage="Potvrďte rezerváciu"
                  />
                </Title>
                <StepSummary
                  validate={Validator}
                  code="karaoke"
                  setMessage={this.setMessage}
                  setErrors={this.setErrors}
                  message={this.state.message}
                  orderType="karaoke"
                  onSuccess={() => {
                    this.resetState();
                    if (ReactGA && typeof ReactGA.event === "function") {
                      ReactGA.event({
                        category: "Karaoke rezervácia",
                        action: "Odoslanie formulara",
                        label: "Potvrdiť rezerváciu",
                      });
                    }
                  }}
                  data={this.state.data}
                />
              </Section>
            </Content>
          );
        }}
      </Query>
    );
  };
}

export default compose(
  injectIntl,
  withRouter
)(withStyles(styles)(ReservationKaraoke));
