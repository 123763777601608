import React from "react";
import { withStyles } from "@material-ui/core";

export const ExternalLink = withStyles((theme) => ({
  root: {
    marginLeft: 10,
    width: 24,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 24,
      height: 20,
    },
  },
}))(({ color = "#fff", width = 29, height = 25, classes }) => (
  <svg
    className={classes.root}
    xmlns="http://www.w3.org/2000/svg"
    width={29}
    height={25}
    viewBox={`0 0 ${29} ${25}`}
  >
    <defs>
      <path id="a" d="M.023.09h35.888V36H.023z" />
    </defs>
    <g fill="none">
      <g transform="translate(0 -11)">
        <path
          fill={color}
          d="M22.335 25.612h-1.04a.504.504 0 0 0-.372.146.505.505 0 0 0-.146.373v5.194c0 .715-.255 1.326-.763 1.834a2.502 2.502 0 0 1-1.835.763H4.674a2.5 2.5 0 0 1-1.834-.763 2.5 2.5 0 0 1-.762-1.834V17.82c0-.715.254-1.327.762-1.835a2.503 2.503 0 0 1 1.834-.763h11.427a.505.505 0 0 0 .374-.146.506.506 0 0 0 .146-.373v-1.04a.505.505 0 0 0-.146-.372.505.505 0 0 0-.374-.146H4.674c-1.287 0-2.388.457-3.302 1.37C.457 15.433 0 16.534 0 17.822v13.505c0 1.287.457 2.388 1.372 3.302C2.286 35.543 3.387 36 4.674 36H18.18c1.288 0 2.39-.457 3.304-1.372.915-.914 1.372-2.015 1.372-3.302V26.13a.503.503 0 0 0-.52-.52z"
          mask="url(#b)"
        />
      </g>
      <path
        fill={color}
        d="M28.69.31a1.002 1.002 0 0 0-.73-.31h-8.325a1 1 0 0 0-.732.31.997.997 0 0 0-.31.73 1 1 0 0 0 .31.733l2.86 2.86-10.6 10.603a.513.513 0 0 0 0 .748l1.854 1.853a.514.514 0 0 0 .373.163.515.515 0 0 0 .375-.163l10.6-10.601 2.862 2.862c.206.205.45.309.732.309s.525-.104.732-.31c.206-.205.309-.45.309-.731V1.04a.999.999 0 0 0-.31-.732z"
      />
    </g>
  </svg>
));

export const ExternalLink2 = withStyles((theme) => ({
  root: {
    marginLeft: 10,
    width: 27,
    height: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: 27,
      height: 20,
    },
  },
}))(({ color = "#fff", width = 33, height = 23, classes }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classes.root}
    width={33}
    height={23}
    viewBox={`0 0 33 23`}
  >
    <defs>
      <path id="a" d="M.023.09h35.888V36H.023z" />
    </defs>
    <g fill="none">
      <g transform="translate(0 -13)">
        <path
          fill={color}
          d="M16.101 33.922H4.674a2.5 2.5 0 0 1-1.834-.763 2.5 2.5 0 0 1-.762-1.834V17.82c0-.715.254-1.327.762-1.835a2.503 2.503 0 0 1 1.834-.763h11.427c.483 0 .483-2.077 0-2.077H4.674c-1.287 0-2.388.457-3.302 1.37C.457 15.433 0 16.534 0 17.822v13.505c0 1.287.457 2.388 1.372 3.302C2.286 35.543 3.387 36 4.674 36h11.427c.589 0 .589-2.078 0-2.078z"
          mask="url(#b)"
        />
      </g>
      <path
        fill={color}
        d="M33.019 11.728c0-.292-.1-.537-.3-.736l-5.886-5.886a1 1 0 0 0-.736-.3c-.291 0-.536.1-.735.3a1 1 0 0 0-.3.736v4.046H10.07a.513.513 0 0 0-.528.53v2.62a.515.515 0 0 0 .529.529h14.992v4.048c0 .29.1.536.3.736.198.199.444.298.735.299.291 0 .537-.1.736-.3l5.887-5.886c.2-.2.299-.445.299-.736z"
      />
    </g>
  </svg>
));

export const ArrowRight = ({ color = "#fff", width = 33, height = 27 }) => (
  <svg
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    style={{ height }}
    width="33"
    height="33"
    viewBox={`0 0 33 33`}
  >
    <path d="M31.434 16.359c0-.405-.133-.746-.398-1.023l-6.258-4.212a1.305 1.305 0 0 0-.982-.416 1.303 1.303 0 0 0-.98.416 1.42 1.42 0 0 0-.4 1.022l.002 3.135-19.991.055a.67.67 0 0 0-.506.207.731.731 0 0 0-.199.528v.99a.73.73 0 0 0 .198.528.672.672 0 0 0 .507.207l19.99-.027v3.136c0 .404.133.746.398 1.023.266.276.593.414.981.415.388 0 .716-.139.982-.415l6.258-4.547c.266-.277.398-.618.398-1.022z" />
  </svg>
);
