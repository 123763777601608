import React, { useState } from "react";
import DetailView from "../../components/DetailView/DetailView";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ZoneCard from "../../components/Cards/ZoneCard";
import { compose } from "recompose";
import ZoneView from "./ZoneView";
import {
  getAssetPath,
  getImage,
  getShortText,
  rectangleEffect,
} from "../../helpers";
import EventSection from "../LandingPage/EventSection";
import { injectIntl, defineMessages } from "react-intl";
import generalMessages from "../../i18n/messages";
import { useEffect } from "react";

const ZONES = gql`
  query Zones($id: Int, $limit: Int) {
    Zones: getZoneView(id: $id, limit: $limit) {
      id
      code
      title
      title1
      perex_text
      user_id
      primaryColor
      secondaryColor
      text
      cover_image_id
      thumbnail_image_id
      icon_id
      Icon {
        resource_type
        storage_name
      }
      Thumbnail {
        resource_type
        storage_name
      }
      CoverImage {
        resource_type
        storage_name
      }
      CoverSecondaryImage {
        resource_type
        storage_name
      }
      ThumbnailSecondary {
        resource_type
        storage_name
      }
      ZoneSections {
        title
        primaryColor
        priority
        text
        secondaryColor
        SectionImage {
          resource_type
          storage_name
        }
      }
      published
      price_text
      captions
      bonus_card_text
      feed_id
    }
  }
`;

const messages = defineMessages({
  cta: {
    id: "zone.card.cta",
    defaultMessage: "Chcem spoznať viac",
  },
});

const ZonePage = ({ intl: { formatMessage }, history, match }) => {
  const { data, error, loading } = useQuery(ZONES);
  const [state, setState] = useState({ prev: {}, view: {}, next: {} });
  const zones = (data && data.Zones) || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const item = match.params.id;

    let prev = {};
    let view = {};
    let next = {};

    const isNumber = !isNaN(item);
    const attr = isNumber ? "id" : "code";

    for (let i = 0; i < zones.length; i++) {
      if (zones[i][attr] == item) {
        view = zones[i];
        prev = zones[i === 0 ? zones.length - 1 : i - 1];
        next = zones[i === zones.length - 1 ? 0 : i + 1];
      }
    }

    setState({
      prev,
      view,
      next,
    });
  }, [zones, match.params.id]);

  const slideChange = (code) => {
    history.push(`/${formatMessage(generalMessages.linkZone)}/${code}`);
  };

  if (!zones.length || error || loading) return null;

  const { prev, view, next } = state;

  if (!prev || !view || !next) {
    this.props.history.push(`/404`);
    return null;
  }

  return (
    <DetailView
      slideIndex={1}
      onSlideChange={slideChange}
      prevCard={
        <ZoneCard
          resourceId={prev.code}
          icon={prev.Icon && getAssetPath(prev.Icon.storage_name)}
          bgImage={prev.CoverImage && getImage(prev.CoverImage.storage_name)}
          primaryColor={prev.primaryColor}
          secondaryColor={prev.secondaryColor}
          actionTitle={formatMessage(messages.cta)}
          title={getShortText(prev.title)}
        />
      }
      activeCard={
        <ZoneView
          secondaryTitle={view.title1}
          handleClose={() => rectangleEffect(false)}
          closeLink={`/#${formatMessage(generalMessages.linkZones)}`}
          viewId={view.id}
          elements={view.text}
          resourceId={view.code}
          thumbnail={view.Thumbnail && getImage(view.Thumbnail.storage_name)}
          coverImage={view.CoverImage && getImage(view.CoverImage.storage_name)}
          icon={view.Icon && getAssetPath(view.Icon.storage_name)}
          thumbnailSecondary={
            view.ThumbnailSecondary &&
            getImage(view.ThumbnailSecondary.storage_name)
          }
          coverSecondaryImage={view.CoverSecondaryImage}
          price={view.price_text}
          bonusCardText={view.bonus_card_text}
          feed={view.feed_id}
          captions={view.captions ? view.captions : []}
          primaryColor={view.primaryColor}
          secondaryColor={view.secondaryColor}
          title={view.title}
          text={view.text}
          sections={view.ZoneSections || []}
        />
      }
      nextCard={
        <ZoneCard
          resourceId={next.code}
          icon={next.Icon && getAssetPath(next.Icon.storage_name)}
          bgImage={next.CoverImage && getImage(next.CoverImage.storage_name)}
          primaryColor={next.primaryColor}
          secondaryColor={next.secondaryColor}
          actionTitle={formatMessage(messages.cta)}
          title={getShortText(next.title)}
        />
      }
      nextSectionLink={`/#${formatMessage(generalMessages.linkEvents)}`}
      nextSection={<EventSection />}
    />
  );
};

export default compose(injectIntl, withRouter)(ZonePage);
