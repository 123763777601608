import React from "react";
import { Button } from "@material-ui/core";
import RefreshIcon from "../resources/img/refresh.svg";
import { FormattedMessage } from "react-intl";

const RefetchButton = ({ refetch }) => {
  return (
    <Button variant="outlined" color="primary" onClick={() => refetch()}>
      <FormattedMessage
        id="reservation.summary.refetch"
        defaultMessage="Zobraziť sumár objednávky"
      />
      <img
        style={{ width: 35, height: 35, marginLeft: 10 }}
        src={RefreshIcon}
        alt="refresh icon"
      />
    </Button>
  );
};

export default RefetchButton;
