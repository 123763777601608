import gql from "graphql-tag";

export const QUERY_RESERVATION_CONFIG = gql`
  query ReservationSystemConfig($code: String!) {
    Config: ReservationSystemConfig(code: $code) {
      title
      subtitle1
      subtitle2
      text
      sections {
        title
        subtitle
        number
      }
      primary_color
      caption1
      enabled
      caption2
      price_text
      date_blacklist {
        date
      }
      bonus_card_text
      CoverSecondaryImage {
        storage_name
      }
      CoverImage {
        storage_name
      }
      Thumbnail {
        storage_name
      }
      Packages {
        id
        title
        subtitle
        captions
        captions1
        captions2
        price
        priceOld
        primaryColor
        priceExtraAdult
        priceExtraChildren
        units
        Image {
          storage_name
        }
        Zones {
          id
          title
        }
      }
      ExtraZone {
        title
        Items {
          id
          title
          text
          description
          price
          units
          Image {
            storage_name
          }
        }
      }
    }
  }
`;

export const QUERY_PARTY_SUMMARY = gql`
  query getPartySummary($data: String!) {
    Summary: getPartySummary(data: $data) {
      items {
        title
        unitPrice
        count
        price
      }
      totalPrice
    }
  }
`;

export const QUERY_MONTH_RESERVATIONS = gql`
  query getPartyMonthReservations($month: Int!, $year: Int!) {
    Data: getPartyMonthReservations(month: $month, year: $year) {
      date
      count
      # times {
      #     timeFrom
      #     timeTo
      # }
    }
  }
`;

export const QUERY_DAY_RESERVATIONS = gql`
  query getPartyReservationTimes($date: String!) {
    Data: getPartyReservationTimes(date: $date) {
      date
      count
      times {
        timeFrom
        timeTo
      }
    }
  }
`;

export const MUTATION_CREATE_RESERVATION = gql`
  mutation createPartyReservation($data: String!) {
    Data: createPartyReservation(data: $data)
  }
`;
