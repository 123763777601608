import { Grid } from "@material-ui/core";
import { getImage } from "../../helpers";
import PriceListPill from "./PriceListPill";
import PricePill from "./PricePill";
import React from "react";

const PricingItem = ({ pricing }) => {
  return (
    <Grid item xs={12} sm={12} md={6}>
      {pricing.fullsize ? (
        <PriceListPill
          color={pricing.color}
          priceItems={(pricing.Items || []).map(i => ({
            title: i.item,
            text: i.price
          }))}
        />
      ) : (
        <PricePill
          title={pricing.title}
          color={pricing.color}
          image={pricing.Image && getImage(pricing.Image.storage_name)}
          icon={pricing.Icon && getImage(pricing.Icon.storage_name)}
          priceItems={(pricing.Items || []).map(i => ({
            title: i.item,
            text: i.price
          }))}
          ctaTitle={pricing.link_title}
          ctaLink={pricing.link_href}
        />
      )}
    </Grid>
  );
};

export default PricingItem;
