import React from "react";
import { withStyles } from "@material-ui/core";
import EshopPackages from "../../components/Reservation/EshopPackages";
import FunIcon from "../../resources/img/package-fun.svg";
import JumpIcon from "../../resources/img/package-jump.svg";
import CinemaIcon from "../../resources/img/package-cinema.svg";
import GameIcon from "../../resources/img/element-icon-zona-game.svg";
import { getImage } from "../../helpers";

const styles = () => ({
  root: {
    margin: "0 0",
  },
});

const StepPackage = ({ classes, packages = [], handleChange, selected }) => (
  <div className={classes.root}>
    <EshopPackages
      selected={selected}
      handleChange={(packageId) => handleChange("packageId", packageId)}
      availableZones={[
        {
          id: "9",
          title: "FUN",
          icon: FunIcon,
          description: "3 HOD",
        },
        {
          id: "2",
          title: "JUMP",
          icon: JumpIcon,
          description: "3 HOD",
        },
        {
          id: "4",
          title: "5D CINEMA",
          icon: CinemaIcon,
          description: "2 FILMY",
        },
        {
          id: "3",
          title: "GAME",
          icon: GameIcon,
          description: "3 HOD",
        },
      ]}
      packages={packages.map((x) => ({
        ...x,
        image: x.Image && getImage(x.Image.storage_name),
        captions: x.captions,
        items: x.captions1,
        color: x.primaryColor,
        extraFeeItems: x.captions2,
        zones: x.Zones ? x.Zones.map((z) => z.id) : [],
      }))}
    />
  </div>
);

export default withStyles(styles)(StepPackage);
