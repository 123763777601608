import React from "react";
import { Typography, withStyles } from "@material-ui/core";

export const Title = withStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    justifyContent: "center"
  },
  iconContainer: {
    background: "#231f20",
    height: "33px",
    width: "36px",
    minWidth: "36px",
    marginRight: 10,
    transform: "rotate(-5deg)",
    boxShadow: "0 4px 16px 0 rgba(0, 0, 0, 0.16)"
    //  margin: "0 15px 0 0",
  },
  icon: {
    color: "#fff",
    fontFamily: "PaytoneOne",
    margin: "0 0 5px 0",
    fontSize: "24px",
    textAlign: "left",
    width: "25px",
    height: "34px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    float: "right",
    transform: "rotate(5deg)",
    lineHeight: "1.2",
    letterSpacing: "-0.6px"
  },
  title: {
    // paddingBottom: "28px",
    fontFamily: "PaytoneOne",
    //marginBottom: 16,
    // paddingBottom: 8,
    fontSize: 26,
    [theme.breakpoints.down("lg")]: {
      fontSize: 24
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18
    }
  }
}))(({ classes, children, number, color }) => (
  <div className={classes.root}>
    {number ? (
      <div
        style={{ backgroundColor: color || "#231f20" }}
        className={classes.iconContainer}
      >
        <div className={classes.icon}>{number}</div>
      </div>
    ) : null}
    <Typography style={{ color }} align="center" className={classes.title}>
      {children}
    </Typography>
  </div>
));

export const Subtitle = withStyles(theme => ({
  root: {
    marginBottom: 32,
    color: "#555555",
    paddingBottom: 0,
    fontFamily: "lato",
    fontSize: 16,
    [theme.breakpoints.down("lg")]: {
      fontSize: 14
    }
  }
}))(({ classes, children, color, additionalStyles }) => (
  <Typography
    style={{ color }}
    className={`${classes.root} ${additionalStyles}`}
    align="center"
  >
    {children}
  </Typography>
));
