import React, { Component } from "react";
import DetailView from "../../components/DetailView/DetailView";
import { Query } from "@apollo/react-components";
import gql from "graphql-tag";
import { Typography, withStyles } from "@material-ui/core";
import { withRouter } from "react-router";
import EventCard from "../../components/Cards/EventCard";
import EventView from "./EventView";
import { compose } from "recompose";
import { getImage, getShortText, rectangleEffect } from "../../helpers";
import RestaurantSection from "../LandingPage/RestaurantSection";
import { FormattedMessage, injectIntl } from "react-intl";
import generalMessages from "../../i18n/messages";
import Message from "../../components/Reservation/Message";

const EVENTS = gql`
  query Events($id: Int, $limit: Int) {
    Events: getEventView(id: $id, limit: $limit) {
      id
      code
      title
      perex_text
      user_id
      cover_image_id
      date_from
      date_to
      text
      thumbnail_image_id
      Poster {
        storage_name
      }
      Thumbnail {
        storage_name
      }
      CoverImage {
        storage_name
      }
      published
    }
  }
`;

const styles = (theme) => ({});

class EventPage extends Component {
  constructor(props) {
    super(props);

    const { match } = this.props;

    this.state = {
      zoneId: match.params.id,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  slideChange = (code) => {
    const { history } = this.props;
    history.push(`/eventy/${code}`);
  };

  getCards = (items) => {
    const item = this.props.match.params.id;

    let prev = null;
    let view = null;
    let next = null;

    const isNumber = !isNaN(item);
    const attr = isNumber ? "id" : "code";

    for (let i = 0; i < items.length; i++) {
      if (items[i][attr] == item) {
        view = items[i];
        prev = items[i === 0 ? items.length - 1 : i - 1];
        next = items[i === items.length - 1 ? 0 : i + 1];
      }
    }

    return {
      prev,
      view,
      next,
    };
  };

  render = () => (
    <Query query={EVENTS}>
      {({ data, loading, error }) => {
        if (loading) return null;
        if (error) return null;

        const events = data.Events;
        const { prev, view, next } = this.getCards(events);
        const {
          intl: { formatMessage },
        } = this.props;

        if (!prev || !view || !next) {
          this.props.history.push(`/404`);
          return null;
        }
        return (
          <DetailView
            slideIndex={1}
            onSlideChange={this.slideChange}
            prevCard={
              <EventCard
                resourceId={prev.code}
                oldEvent={prev.oldEvent}
                bgImage={
                  prev.Thumbnail && getImage(prev.Thumbnail.storage_name)
                }
                date={prev.date_from}
                actionTitle={
                  <FormattedMessage
                    id="event.prev.moreinfo.title"
                    defaultMessage="Viac o akcii"
                  />
                }
                title={getShortText(prev.title)}
              />
            }
            activeCard={
              <EventView
                resourceId={view.code}
                id={view.id}
                closeLink={`/#${formatMessage(generalMessages.linkEvents)}`}
                handleClose={() => {
                  rectangleEffect(false);
                }}
                elements={view.text}
                title={view.title}
                date={view.date_from}
                cover={
                  view.CoverImage && getImage(view.CoverImage.storage_name)
                }
                poster={view.Poster && getImage(view.Poster.storage_name)}
              />
            }
            nextCard={
              <EventCard
                resourceId={next.code}
                oldEvent={next.oldEventNext}
                bgImage={
                  next.Thumbnail && getImage(next.Thumbnail.storage_name)
                }
                date={next.date_from}
                actionTitle={
                  <FormattedMessage
                    id="event.next.moreinfo.title"
                    defaultMessage="Viac o akcii"
                  />
                }
                title={getShortText(next.title)}
              />
            }
            nextSectionLink={`/#${formatMessage(
              generalMessages.linkRestaurant
            )}`}
            nextSection={<RestaurantSection />}
          />
        );
      }}
    </Query>
  );
}

export default compose(injectIntl, withRouter, withStyles(styles))(EventPage);
