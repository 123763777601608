"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpoints = exports.spacing = exports.screenSizes = void 0;
exports.screenSizes = {
    xs: 500,
    sm: 768,
    md: 1026,
    lg: 1560,
    xl: 2000,
};
const spacing = (spacing) => {
    if (spacing === undefined)
        return;
    return spacing * 4;
};
exports.spacing = spacing;
exports.breakpoints = {
    up: (size) => {
        if (typeof size === "number") {
            return `@media only screen and (min-width: ${size}px)`;
        }
        return `@media only screen and (min-width: ${exports.screenSizes[size]}px)`;
    },
    down: (size) => {
        if (typeof size === "number") {
            return `@media only screen and (max-width: ${size - 1}px)`;
        }
        return `@media only screen and (max-width: ${exports.screenSizes[size] - 1}px)`;
    },
    between: (minSize, maxSize) => {
        return `@media only screen and (min-width: ${exports.screenSizes[minSize]}px) and (max-width: ${exports.screenSizes[maxSize] - 1}px)`;
    },
};
