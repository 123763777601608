import React, { Fragment } from "react";
import { withStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../../i18n/messages";

const styles = () => ({
  label: {
    fontFamily: "lato",
    fontSize: "14px",
  },
  errorMessage: {
    fontFamily: "lato",
    color: "red",
  },
});

const GdrpAccept = ({
  classes,
  checked = false,
  error = "",
  handleChange = () => {},
  intl: { formatMessage },
}) => (
  <div>
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          classes={{ checked: classes.checkedStyle }}
          checked={checked}
          onChange={handleChange}
          value="1"
        />
      }
      labelPlacement="end"
      label={
        <Fragment>
          <a
            style={{ color: "#231f20" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`/${formatMessage(messages.linkGdpr)}`}
          >
            <FormattedMessage
              id="gdpr.confirmationbutton.title"
              defaultMessage="Súhlasím so spracovaním osobných údajov podľa GDPR"
            />
          </a>
          &nbsp;
          <FormattedMessage id="gdpr.and" defaultMessage=" a " />
          &nbsp;
          <a
            style={{ color: "#231f20" }}
            target="_blank"
            rel="noopener noreferrer"
            href={`/${formatMessage(messages.linkVop)}`}
          >
            <FormattedMessage id="gdpr.link.vop" defaultMessage="VOP" />
          </a>
        </Fragment>
      }
    />
    <div className={classes.errorMessage}>{error}</div>
  </div>
);

export default injectIntl(withStyles(styles)(GdrpAccept));
