import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconPlus from "../../resources/img/btn-plus.svg";
import IconMinus from "../../resources/img/btn-minus.svg";

const styles = () => ({
  root: {
    display: "inline-flex",
  },
  input: {
    textAlign: "center",
    border: 0,
    color: "#777",
    appearance: "none",
    height: 27,
    width: "40px",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px",
    "&::-webkit-inner-spin-button": {
      appearance: "none",
    },
    "&::-webkit-outer-spin-button": {
      appearance: "none",
    },
  },
  icon: {
    marginLeft: 6,
    marginRight: 6,
    height: 27,
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

class NumberInput extends Component {
  increase = () => {
    const { max = 99, onChange, value } = this.props;
    const v = value < max ? value + 1 : value;
    onChange(v);
  };

  decrease = () => {
    const { min = 0, onChange, value } = this.props;
    const v = value > min ? value - 1 : value;
    onChange(v);
  };

  onChange = (value) => {
    const { onChange, min = 0, max = 99 } = this.props;

    if (!value) {
      onChange(0);
    }

    const v = parseInt(value);
    if (v >= min && v <= max) {
      onChange(v);
    }
  };

  render = () => {
    const {
      label,
      classes,
      onChange,
      fullwidth,
      value,
      min = 0,
      max = 99,
    } = this.props;
    return (
      <div
        style={{ width: fullwidth ? "100%" : "auto" }}
        className={classes.root}
      >
        <label>
          <Typography className={classes.label}>{label}</Typography>
        </label>
        <div
          style={{ width: fullwidth ? "100%" : "auto" }}
          className={classes.wrapper}
        >
          <img
            src={IconMinus}
            onClick={this.decrease}
            className={classes.icon}
            alt="Minus Icon"
          />
          <input
            min={min}
            max={max}
            onChange={(e) => this.onChange(e.target.value)}
            className={classes.input}
            type="number"
            value={value}
          />
          <img
            className={classes.icon}
            onClick={this.increase}
            src={IconPlus}
            alt="Add Icon"
          />
        </div>
      </div>
    );
  };
}

export default withStyles(styles)(NumberInput);
