import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Query } from "@apollo/react-components";
import Calendar from "../../components/Reservation/Calendar";
import ReservationTimes from "./ReservationTimes";
import { QUERY_MONTH_RESERVATIONS, QUERY_DAY_RESERVATIONS } from "./graphql";
import Message from "../../components/Reservation/Message";
import dateformat from "dateformat";
import { injectIntl } from "react-intl";
import messages from "../../i18n/messages";

const styles = (theme) => ({
  root: {
    padding: "0 0 32px 0",
  },
  errorMessage: {
    fontFamily: "lato",
    color: "red",
    padding: "16px 0 0 0",
  },
  gridItem: {
    [theme.breakpoints.down("xs")]: {
      margin: "auto",
    },
  },
  message: {
    padding: 32,
    paddingTop: 50,
    textAlign: "center",
  },
});

class StepDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMonth: null,
      activeYear: null,
    };
  }

  componentDidMount = () => {
    this.setState({
      activeMonth: new Date().getMonth() + 1,
      activeYear: new Date().getFullYear(),
    });
  };

  handleChangeMonth = (month, year) => {
    this.setState({
      activeMonth: month + 1,
      year: year,
    });
  };

  render = () => {
    const {
      classes,
      errors = {},
      reservationTimes = [],
      handleDayClick = () => {},
      handleChangeTime = () => {},
      intl: { formatMessage },
      reservationDate = null,
      dateBlackList = [],
    } = this.props;

    // let defaultDate = new Date();
    // defaultDate.setDate(defaultDate.getDate() + 1)

    const date = new Date(reservationDate);

    if (!this.state.activeMonth || !this.state.activeYear) {
      return null;
    }

    return (
      <div className={classes.root}>
        <Grid justify="center" className={classes.grid} spacing={16} container>
          <Grid item sm={6}>
            <Query
              query={QUERY_MONTH_RESERVATIONS}
              variables={{
                month: this.state.activeMonth,
                year: this.state.activeYear,
              }}
            >
              {({ data, loading, error }) => {
                if (loading) return null;
                if (error) return null;

                return (
                  <Calendar
                    dateBlackList={dateBlackList}
                    reservationDate={date}
                    month={this.state.month}
                    year={this.state.year}
                    reservations={data.Data}
                    handleChangeMonth={this.handleChangeMonth}
                    handleDayClick={handleDayClick}
                  />
                );
              }}
            </Query>
          </Grid>
          <Grid item sm={6}>
            <Query query={QUERY_DAY_RESERVATIONS} variables={{ date: date }}>
              {({ data, loading, error }) => {
                if (loading) return null;
                if (error) return null;

                return dateBlackList.find(
                  (x) => x.date === dateformat(date, "yyyy-mm-dd")
                ) ? (
                  <Message
                    classes={{ info: classes.message }}
                    type="info"
                    message={formatMessage(messages.disabledDate)}
                  />
                ) : (
                  <ReservationTimes
                    reservationDate={date}
                    reservationTimes={reservationTimes}
                    reservedTimes={data.Data.times}
                    handleChangeTime={handleChangeTime}
                  />
                );
              }}
            </Query>
            <div className={classes.errorMessage}>
              {errors.reservationTimes}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
}

export default injectIntl(withStyles(styles)(StepDate));
