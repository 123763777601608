import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  card: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    boxShadow: "rgba(35, 31, 32, 0.2) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundPosition: "center",
    marginTop: 16,
  },
  cardActive: {
    height: 200,
    width: 150,
    backgroundColor: "#fff",
    padding: 16,
    marginLeft: 16,
    cursor: "pointer",
    marginRight: 16,
    border: "1px solid rgb(207, 44, 145)",
    boxShadow: "rgba(207, 44, 145, 0.4) 0px 16px 48px 0px",
    borderRadius: "16px",
    position: "relative",
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
    marginTop: 16,
  },
  overlay: {
    backgroundColor: `rgba(35, 31, 32, 0.5)`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  title: {
    color: "#fff",
    textTransform: "uppercase",
    textAlign: "center",
    width: "auto",
    padding: "10px 0 10px 0",
    margin: "auto",
    textShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    fontSize: 18,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  price: {
    paddingTop: 12,
    fontSize: "0.8em",
    fontFamily: "TypoComica",
  },
});

const CinemaCard = ({
  classes,
  handleChange = () => {},
  title,
  price,
  active,
  bgImage,
  value,
}) => (
  <div
    onClick={() => handleChange(value)}
    style={{ backgroundImage: `url(${bgImage})` }}
    className={active ? classes.cardActive : classes.card}
  >
    <div className={classes.overlay}>
      <div className={classes.title}>
        {title}
        {price && <div className={classes.price}>{price} € / osoba</div>}
      </div>
    </div>
  </div>
);

export default withStyles(styles)(CinemaCard);
