import React from "react";
import { withStyles, RadioGroup } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormattedMessage } from "react-intl";

const styles = () => ({
  radioControl: {
    backgroundColor: "#fff",
    margin: 5,
    borderRadius: 8,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 16,
  },
  blue: {
    color: "#4a90e2 !important",
  },
  pink: {
    color: "#cf2c91 !important",
  },
  black: {
    color: "#231f20 !important",
  },
});

const StepTheme = ({ handleChange, classes, value = "BOY" }) => (
  <RadioGroup
    aria-label="theme"
    name="theme"
    className={classes.root}
    value={value}
    onChange={(e) => handleChange("theme", e.target.value)}
  >
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.blue,
      }}
      style={{
        border: `#4a90e2 1px solid`,
      }}
      labelPlacement="start"
      value="BOY"
      control={
        <Radio
          classes={{
            root: classes.blue,
            checked: classes.blue,
          }}
        />
      }
      label={
        <FormattedMessage
          id="partytheme.forboy.title"
          defaultMessage="Pre chlapca"
        />
      }
    />
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.pink,
      }}
      style={{
        border: `#cf2c91 1px solid`,
      }}
      labelPlacement="start"
      value="GIRL"
      control={
        <Radio
          classes={{
            root: classes.pink,
            checked: classes.pink,
          }}
        />
      }
      label={
        <FormattedMessage
          id="partytheme.forgirl.title"
          defaultMessage="Pre dievča"
        />
      }
    />
    <FormControlLabel
      classes={{
        root: classes.radioControl,
        label: classes.black,
      }}
      style={{
        border: `#231f20 1px solid`,
      }}
      labelPlacement="start"
      value="UNIVERSAL"
      control={
        <Radio
          classes={{
            root: classes.black,
            checked: classes.black,
          }}
        />
      }
      label={
        <FormattedMessage
          id="partytheme.universal.title"
          defaultMessage="Univerzálna"
        />
      }
    />
  </RadioGroup>
);

export default withStyles(styles)(StepTheme);
