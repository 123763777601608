import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
import MenuItems from "./MenuItems";
import Logo from "./Logo";

const styles = (theme) => ({
  fade: {
    position: "fixed",
    zIndex: 9999,
    cursor: "pointer",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(32, 32, 32, 0.5)",
  },
  sideNavMenu: {
    display: "block",
    position: "fixed",
    color: "#353535",
    background: "#fff",
    top: 0,
    right: 0,
    width: 285,
    right: "-285px",
    height: "100%",
    maxWidth: 285,
    transform: "translate(285px)",
    zIndex: 10000,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  sideNavMenuToggled: {
    display: "block",
    position: "fixed",
    color: "#353535",
    background: "#fff",
    top: 0,
    right: 0,
    transition: "400ms",
    transform: `translateX(0)`,
    height: "100%",
    width: 285,
    maxWidth: 285,
    zIndex: 10000,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  navbarMobileTitleText: {
    fontFamily: "TypoComica",
    padding: "16px 8px 32px 8px",
    textAlign: "center",
    fontSize: "14px",
    color: "#555555",
    height: 30,
  },
  list: {
    float: "right",
    margin: 0,
    "& li": {
      margin: "0 14px",
      display: "inline-block",
      [theme.breakpoints.down("md")]: {
        margin: "0 12px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      textAlign: "center",
      padding: 0,
      "& li": {
        display: "block",
      },
    },
  },
});

const SideNav = ({ classes, toggleMenu, toggled }) => (
  <div
    className={toggled ? classes.fade : undefined}
    onClick={() => toggleMenu(false)}
  >
    <nav
      key={3}
      id="sideNavMenu"
      className={toggled ? classes.sideNavMenuToggled : classes.sideNavMenu}
    >
      <div className={classes.navbarMobileTitleText}>
        <FormattedMessage
          id="sidenav.openinghours.title"
          defaultMessage="Otvorené každý deň od 09:00 do 21:00  "
        />
      </div>
      <Logo center />
      <ul className={classes.list}>
        <MenuItems onItemClick={() => toggleMenu(false)} />
      </ul>
    </nav>
  </div>
);

export default compose(injectIntl, withStyles(styles))(SideNav);
