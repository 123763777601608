import React from "react";
import { withStyles } from "@material-ui/core";
import { hexToRgba } from "../../helpers";
import { FormattedMessage, defineMessages } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
import { compose } from "recompose";
import gql from "graphql-tag";
import { injectIntl } from "react-intl";

const CATEGORIES_QUERY = gql`
  query Categories($filter: String) {
    data: getPageOfCategories(filter: $filter) {
      items {
        id
        title
        color
        code
      }
      totalCount
    }
  }
`;

const localMessages = defineMessages({
  filterAll: {
    id: "blog.filter.all",
    defaultMessage: "VŠETKY"
  }
});

const styles = theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: 32,
    paddingTop: 16,
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }
  },
  categoryList: {
    display: "flex",
    listStyle: "none",
    fontFamily: "lato",
    flexWrap: "wrap",
    textTransform: "uppercase",
    alignContent: "flex-start",
    fontSize: 12,
    justifyContent: "center",
    paddingLeft: 10
  },
  category: {
    borderRadius: 8,
    border: "1px solid black",
    padding: "8px 16px",
    whiteSpace: "nowrap",
    margin: "8px",
    fontWeight: 700,
    color: "#555555",
    fontFamily: "lato",
    cursor: "pointer"
  },
  label: {
    paddingTop: 26,
    fontWeight: 800,
    fontFamily: "lato",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

const CategoryFilter = ({
  classes,
  selectedCategory,
  onCategoryStatusChange,
  intl: { formatMessage }
}) => {
  const { data } = useQuery(CATEGORIES_QUERY);
  const cats = (data && data.data && data.data.items) || [];

  const allPostsCategory = {
    id: undefined,
    title: formatMessage(localMessages.filterAll),
    color: "#231f20"
  };

  const categories = [...cats, allPostsCategory];

  return (
    <div className={classes.root}>
      <span className={classes.label}>
        <FormattedMessage
          id="blog.filter.title"
          defaultMessage="FILTER AKTUALÍT:"
        />
      </span>
      <ul className={classes.categoryList}>
        {categories.map((category, idx) => (
          <li
            style={{
              borderColor: category.color,
              boxShadow:
                category.id === selectedCategory
                  ? `0 2px 48px 0 ${hexToRgba(category.color, 0.3)}`
                  : "inherit",
              backgroundColor:
                category.id === selectedCategory ? "#fff" : "inherit"
            }}
            className={classes.category}
            key={idx}
            onClick={() => onCategoryStatusChange(category.id)}
          >
            {category.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default compose(injectIntl, withStyles(styles))(CategoryFilter);
