import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { withStyles, TextField, InputAdornment } from "@material-ui/core";
import { calendarI18N, getLang } from "../../helpers";
import dateFormat from "dateformat";
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined";

const styles = (theme) => ({
  input: {
    fontSize: 16,
    paddingLeft: 36,
    color: theme.palette.grey[600],
  },
  label: {
    fontSize: 16,
    color: theme.palette.grey[500],
  },
  adornment: {
    color: theme.palette.grey[500],
  },
  root: {
    width: "100%",
  },
  textField: {
    borderRadius: 4,
    background: theme.palette.grey[100],
  },
});

function OverlayComponent({ classNames, children, disabledDays, ...props }) {
  const lang = getLang();

  return (
    <div style={{ zIndex: 9 }} className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {React.cloneElement(children, {
          ...children.props,
          locale: lang,
          disabledDays: disabledDays,
          months: calendarI18N.MONTHS[lang],
          weekdaysLong: calendarI18N.WEEKDAYS_LONG[lang],
          weekdaysShort: calendarI18N.WEEKDAYS_SHORT[lang],
          firstDayOfWeek: calendarI18N.FIRST_DAY_OF_WEEK[lang],
          labels: calendarI18N.LABELS[lang],
        })}
      </div>
    </div>
  );
}

const DatePickerInput = ({
  classes,
  placeholder,
  onChange = () => {},
  label,
  disabledDays,
  width,
}) => {
  return (
    <DayPickerInput
      style={{ width }}
      overlayComponent={(props) => (
        <OverlayComponent {...props} disabledDays={disabledDays} />
      )}
      onDayChange={onChange}
      component={(props) => (
        <TextField
          InputProps={{
            classes: { root: classes.input },
            endAdornment: (
              <InputAdornment className={classes.adornment} position="end">
                <CalendarIcon />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ classes: { root: classes.label } }}
          fullWidth
          classes={{ root: classes.textField }}
          label={label}
          {...props}
          onChange={() => {}}
          placeholder={placeholder}
          value={props.value && dateFormat(props.value, "d.m.yyyy")}
          variant="outlined"
        />
      )}
    />
  );
};

export default withStyles(styles)(DatePickerInput);
